import React, { useContext, useState, useEffect } from 'react'
import CustomInput from '../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import '../Login/Login.css'

import { mapData, handleRedirectInternal } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import UserContext from '../../product/context/user/userContext'
import { LOGO } from '../../Utils'

const ForgotPassword = () => {
    const userContext = useContext(UserContext)

    const { forgotPassword, responseStatus, clearResponse } = userContext

    const history = useHistory()
    const validationArray = Yup.object({
        email: Yup.string().email('Invalid Email Format').required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            forgotPassword(values)
        },
    })

    const forgotValues = [
        {
            label: 'Email Address',
            name: 'email',
            type: 'email',
            placeholder: 'Enter Your Email Address',
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'forgotPassword') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, '')
                }
            }
        }
    }, [responseStatus])

    return (
        <div className="login forgotPassword">
            <Link to="/">
                <img className="brandLogo" src={LOGO} alt={'Jafza Ezone Logo'} />
            </Link>
            <div className="container">
                <div className="d-flex justify-content-center align-items-start">
                    <div className="loginLt">
                        <h2>{'Forgot Password'}</h2>
                        <h4>
                            Please enter your registered email address and we will send you a
                            password reset link shortly.
                        </h4>
                        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                            <div className="row">{Object.values(mapData(forgotValues))}</div>
                            <PrimaryButton
                                type="submit"
                                label={'Send Reset Password Link'}
                                btnSize="large"
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ForgotPassword
