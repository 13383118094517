import React, { useContext, useState, useEffect } from 'react'
import ShippingCommonContext from '../../../../product/context/shipping/common/commonContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Popup from '../../../../custom/components/organisms/Popup'
import { Button } from '@material-ui/core'
import AlertContext from '../../../../product/context/alert/alertContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    dateFormatFront,
    dateTimeFormatFunction,
    capitalize,
} from '../../../../custom/common/components'
import './ShipmentTracking.css'

function ShipmentTracking(props) {
    const shippingCommonContext = useContext(ShippingCommonContext)

    const {
        shipping_tracking,
        shippingTracking,
        responseStatus: responseStatusCommon,
    } = shippingCommonContext

    const [disabled, setDisabled] = useState(false)
    const [trackingInfo, setTrackingInfo] = useState(false)
    const [addAddress, setAddAddress] = useState(false)

    const validationTracking = Yup.object({
        invoice_id: Yup.string().required('Required!'),
    })

    const addressformik = useFormik({
        initialValues: {
            invoice_id: '',
        },
        validationSchema: validationTracking,
        onSubmit: (values) => {
            setDisabled(true)
            console.log('shippingTracking shippingTracking')
            shippingTracking(values)
        },
    })

    useEffect(() => {
        if (props.data && props.data.popup) {
            let data = props.data.data
            let type = props.data.status
            console.log('props.data.data', data)
            if (data) {
                addressformik.values.invoice_id = data.common_invoice
                setAddAddress(!addAddress)
                addressformik.handleSubmit()
            }
        }
    }, [props.data])

    const closePopup = (e) => {
        setAddAddress(false)
    }
    useEffect(() => {
        console.log('shipping_tracking', shipping_tracking)
        if (shipping_tracking) {
            console.log('shipping_tracking', shipping_tracking)
            setTrackingInfo(shipping_tracking)
        } else {
            setTrackingInfo(false)
        }
    }, [shipping_tracking])

    return (
        <Popup
            open={addAddress}
            size="md"
            handleClose={() => closePopup()}
            modaltitle="Tracking Details"
        >
            <div className="trackingInfo">
                {trackingInfo ? (
                    <>
                        {props.data.data?.projects_shipping === 'shipEngine' ? (
                            <>
                                {' '}
                                <div className="carierInfo d-flex justify-content-between flex-wrap">
                                    <div>
                                        <h3>
                                            Actual Delivery Data:{' '}
                                            <span>{trackingInfo.actual_delivery_date}</span>
                                        </h3>
                                        <h3>
                                            Tracking number:{' '}
                                            <span>{trackingInfo.tracking_number}</span>
                                        </h3>
                                    </div>
                                    <div>
                                        <h3>
                                            Status:{' '}
                                            <span
                                                className={`${
                                                    trackingInfo.status === 'DELIVERED' &&
                                                    'delivered'
                                                }`}
                                            >
                                                {trackingInfo.status_description}
                                            </span>
                                        </h3>
                                        <h3>
                                            Description:{' '}
                                            <span>{trackingInfo.carrier_status_description}</span>
                                        </h3>
                                    </div>
                                </div>
                                {trackingInfo.events.length > 0 && (
                                    <div className="table-responsive">
                                        <table className="table text-left">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {trackingInfo.events.map((d, i) => (
                                                    <tr>
                                                        <td>{d.occurred_at}</td>
                                                        <td>
                                                            {d.city_locality}, {d.state_province} -{' '}
                                                            {d.postal_code}
                                                        </td>
                                                        <td>{d.description}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </>
                        ) : props.data.data?.projects_shipping === 'arta' ? (
                            <>
                                {console.log(trackingInfo, 'staticTrackingInfo')}
                                <div className="carierInfo d-flex justify-content-between flex-wrap">
                                    <div>
                                        <h3>
                                            Delivery Start:{' '}
                                            <span>{trackingInfo.schedule.delivery_start}</span>
                                        </h3>
                                        <h3>
                                            Delivery End:{' '}
                                            <span>{trackingInfo.schedule.delivery_end}</span>
                                        </h3>
                                    </div>
                                    <div>
                                        <h3>
                                            Pickup Start Date:{' '}
                                            <span>{trackingInfo.schedule.pickup_start}</span>
                                        </h3>
                                        <h3>
                                            Pickup End Date:{' '}
                                            <span>{trackingInfo.schedule.pickup_end}</span>
                                        </h3>
                                    </div>
                                    <div>
                                        <h3>
                                            Status:{' '}
                                            <span
                                                className={`${
                                                    trackingInfo.status === 'DELIVERED' &&
                                                    'delivered'
                                                }`}
                                            >
                                                {capitalize(trackingInfo.status)}
                                            </span>
                                        </h3>
                                        <h3>
                                            Details
                                            <span className="cursorPointer">
                                                <Button
                                                    onClick={() => {
                                                        window.open(
                                                            trackingInfo.url,
                                                            '_blank',
                                                            'noopener,noreferrer',
                                                        )
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        open_in_new
                                                    </span>
                                                </Button>
                                            </span>
                                        </h3>
                                    </div>
                                </div>
                                {trackingInfo.tracking.length ? (
                                    <>
                                        <div className="table-responsive">
                                            <table className="table text-left">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Location</th>
                                                        <th scope="col">Summary</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {trackingInfo.tracking.map((data, index) => (
                                                        <tr>
                                                            <td>{data.date}</td>
                                                            <td>{data.location}</td>
                                                            <td>{data.summary}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                ) : null}
                            </>
                        ) : null}
                    </>
                ) : null}
            </div>
        </Popup>
    )
}

export default ShipmentTracking
