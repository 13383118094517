import React, { useState, useContext, useEffect } from 'react'

import AuthContext from '../../../../product/context/auth/authContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import UserContext from '../../../../product/context/user/userContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import ProductContext from '../../../../product/context/product/productContext'
import InvoiceContext from '../../../../product/context/invoice/invoiceContext'
import AuctionContext from '../../../../product/context/auction/auctionContext'
import CartContext from '../../../../product/context/cart/cartContext'
import BuyerContext from '../../../../product/context/buyer/buyerContext'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import PayContext from '../../../../product/context/payment/payContext'
import StripeCardContext from '../../../../product/context/stripe/card/cardContext'
import StripeCustomerContext from '../../../../product/context/stripe/customer/customerContext'
import StripeBankContext from '../../../../product/context/stripe/bank/bankContext'

import AppointmentContext from '../../../../product/context/appointment/appointmentContext'
import ShipEngineContext from '../../../../product/context/shipping/shipengine/shipengineContext'

const HeadAlert = () => {
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const customCommonContext = useContext(CustomCommonContext)
    const productContext = useContext(ProductContext)
    const payContext = useContext(PayContext)
    const cartContext = useContext(CartContext)
    const auctionContext = useContext(AuctionContext)
    const userContext = useContext(UserContext)
    const autopltContext = useContext(AutopltContext)
    const stripeBankContext = useContext(StripeBankContext)
    const stripeCardContext = useContext(StripeCardContext)
    const stripeCustomerContext = useContext(StripeCustomerContext)
    const appointmentContext = useContext(AppointmentContext)
    const shipEngineContext = useContext(ShipEngineContext)

    const { setAlert } = alertContext
    const { responseStatus: responseStatusAuth, clearResponse: clearResponseAuth } = authContext

    const {
        responseStatus: responseStatusStripeCustomer,
        clearResponse: clearResponseStripeCustomer,
    } = stripeCustomerContext

    const {
        responseStatus: responseStatusAuction,
        clearResponse: clearResponseAuction,
    } = auctionContext

    const {
        responseStatus: responseStatusStripeBank,
        clearResponse: clearResponseStripeBank,
    } = stripeBankContext

    const {
        responseStatus: responseStatusStripeCard,
        clearResponse: clearResponseStripeCard,
    } = stripeCardContext

    const {
        responseStatus: responseStatusProduct,
        clearResponse: clearResponseProduct,
    } = productContext

    const {
        responseStatus: responseStatusPayment,
        clearResponse: clearResponsePayment,
    } = payContext

    const { responseStatus: responseStatusCart, clearResponse: clearResponseCart } = cartContext

    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext

    const {
        responseStatus: responseStatusAutoPlt,
        clearResponse: clearResponseAutoPlt,
    } = autopltContext

    const {
        responseStatus: responseStatusAppointment,
        clearResponse: clearResponseAppointment,
    } = appointmentContext

    const {
        responseStatus: responseStatusShipEngine,
        clearResponse: clearResponseShipEngine,
    } = shipEngineContext

    useEffect(() => {
        if (responseStatusUser) {
            if (!responseStatusUser.noAlert) {
                setAlert(responseStatusUser.message, responseStatusUser.status)
            }
            clearResponseUser()
        }
        if (responseStatusProduct) {
            if (!responseStatusProduct.noAlert) {
                setAlert(responseStatusProduct.message, responseStatusProduct.status)
            }
            clearResponseProduct()
        }
        if (responseStatusAutoPlt) {
            if (!responseStatusAutoPlt.noAlert) {
                setAlert(responseStatusAutoPlt.message, responseStatusAutoPlt.status)
            }
            clearResponseAutoPlt()
        }
        if (responseStatusAuth) {
            if (responseStatusAuth.from === 'register') {
                if (responseStatusAuth.status === 'success') {
                    setAlert(
                        'Successfully registered! Please check your email to verify and complete account creation',
                        responseStatusAuth.status,
                    )
                    clearResponseAuth()
                } else {
                    setAlert(responseStatus.message, responseStatus.status)
                    clearResponseAuth()
                }
            } else {
                if (!responseStatusAuth.noAlert) {
                    setAlert(responseStatusAuth.message, responseStatusAuth.status)
                }
                clearResponseAuth()
            }
        }
        if (responseStatusPayment) {
            if (!responseStatusPayment.noAlert) {
                setAlert(responseStatusPayment.message, responseStatusPayment.status)
            }
            clearResponsePayment()
        }
        if (responseStatusCart) {
            if (!responseStatusCart.noAlert) {
                setAlert(responseStatusCart.message, responseStatusCart.status)
            }
            clearResponseCart()
        }

        if (responseStatusStripeBank) {
            if (!responseStatusStripeBank.noAlert) {
                setAlert(responseStatusStripeBank.message, responseStatusStripeBank.status)
            }
            clearResponseStripeBank()
        }

        if (responseStatusStripeCustomer) {
            if (!responseStatusStripeCustomer.noAlert) {
                setAlert(responseStatusStripeCustomer.message, responseStatusStripeCustomer.status)
            }

            clearResponseStripeCustomer()
        }

        if (responseStatusAuction) {
            if (!responseStatusAuction.noAlert) {
                setAlert(responseStatusAuction.message, responseStatusAuction.status)
            }

            clearResponseAuction()
        }

        if (responseStatusStripeCard) {
            if (!responseStatusStripeCard.noAlert) {
                setAlert(responseStatusStripeCard.message, responseStatusStripeCard.status)
            }
            clearResponseStripeCard()
        }

        if (responseStatusAppointment) {
            if (!responseStatusAppointment.noAlert) {
                setAlert(responseStatusAppointment.message, responseStatusAppointment.status)
            }
            clearResponseAppointment()
        }

        if (responseStatusShipEngine) {
            if (!responseStatusShipEngine.noAlert) {
                setAlert(responseStatusShipEngine.message, responseStatusShipEngine.status)
            }
            clearResponseShipEngine()
        }
    }, [
        responseStatusUser,
        responseStatusProduct,
        responseStatusAutoPlt,
        responseStatusAuth,
        responseStatusPayment,
        responseStatusCart,
        responseStatusStripeBank,
        responseStatusStripeCustomer,
        responseStatusStripeCard,
        responseStatusAppointment,
        responseStatusShipEngine,
        responseStatusAuction,
    ])

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment') {
                if (responseStatusPayment.status === 'success') {
                    if (responseStatusPayment.data && responseStatusPayment.data.url) {
                        const newWindow = window.open(
                            responseStatusPayment.data.url,
                            '_self',
                            'noopener,noreferrer',
                        )
                        if (newWindow) newWindow.opener = null
                    }
                }
            }
        }
    }, [responseStatusPayment])

    return <></>
}
export default HeadAlert
