import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import './Dashboard.css'
import DashboardLayout from './DashboardLayout'
import CustomDialog from '../../custom/components/organisms/Dialog'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CreditContext from '../../product/context/credit/creditContext'
import AuthContext from '../../product/context/auth/authContext'
import PayContext from '../../product/context/payment/payContext'
import { mapData } from '../../product/common/components'
import { Pagination } from '@material-ui/lab'

import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import {
    currencyFormat,
    dateFormatFront,
    dateTimeFormatFunction,
} from '../../custom/common/components'

import { useFormik } from 'formik'
import * as Yup from 'yup'

function MyWallet() {
    const [toggleDialog, setToggleDialog] = useState(false)
    const creditContext = useContext(CreditContext)
    const authContext = useContext(AuthContext)
    const payContext = useContext(PayContext)

    const { user, isAuthenticated } = authContext
    const { search_allusercredit, getCreditHistory, responseStatus } = creditContext

    const { payCartRosoom } = payContext

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const validationArray = Yup.object({
        amount: Yup.number().min(0).required('Enter Bid Amount'),
    })

    const formik = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (toggleDialog) {
                let formData = {
                    cart_id: 0,
                    payment_object: {
                        credit_notes: 'Wallet Deposit Amount',
                        credit_type: 'user',
                        creditor_id: user.id,
                    },
                    amount: values.amount,
                    pay_type: 'deposit',
                }
                payCartRosoom(formData)
            } else {
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addCreditBalance') {
                if (responseStatus.status === 'success') {
                    setToggleDialog(false)
                }
            }
        }
    }, [responseStatus])

    let [viewProduct, setViewProduct] = useState([])

    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        orderby: 'ucd.id, desc',
        order: '',
    })

    useEffect(() => {
        if (isAuthenticated) {
            getCreditHistory(search)
        }
    }, [search, isAuthenticated])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setViewProduct(search_allusercredit.records.length ? search_allusercredit.records : [])
    }, [search_allusercredit])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const amount = [
        {
            label: 'Enter Amount',
            name: 'amount',
            type: 'number',
            placeholder: 'Enter Amount',
            class: 'mt-3',
            autoFocus: false,
            formik: formik,
        },
    ]
    return (
        <DashboardLayout title={'My Wallet'}>
            <div className="dashboardInner myWallet">
                <div className="walletHead d-flex justify-content-between align-items-center flex-wrap">
                    <h3 className="walletBalance">
                        <span className="material-icons">account_balance_wallet</span>
                        {'My Wallet Balance'}:
                        <span>
                            {user.deposit_amount
                                ? currencyFormat(user.deposit_amount)
                                : currencyFormat(0)}
                        </span>
                    </h3>
                    <Button variant="outlined" onClick={changeDialogStatus}>
                        + {'Add Balance'}
                    </Button>
                </div>
                {/* <h5>Find refunds from your auction deposits here.</h5> */}
                {viewProduct.length ? (
                    <>
                        <div className="table-responsive dpTable">
                            <table className="table text-left">
                                <thead>
                                    <tr>
                                        <th scope="col">{'Date'}</th>
                                        <th scope="col">{'Description'}</th>
                                        <th scope="col">{'Amount'}</th>
                                        <th scope="col">{'Type'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {viewProduct.map((data, index) => (
                                        <tr key={index}>
                                            <td>{dateFormatFront(data.date_added)}</td>
                                            <td>{data.credit_notes || '-'}</td>
                                            <td>{currencyFormat(data.amount)}</td>
                                            <td
                                                className={`${
                                                    data.remove_credit === 0 ? 'credit' : 'debit'
                                                }`}
                                            >
                                                {data.remove_credit === 0 ? (
                                                    <>
                                                        <span className="material-icons">
                                                            south_west
                                                        </span>
                                                        {'Credit'}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-icons">
                                                            north_east
                                                        </span>
                                                        {'Debit'}
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper col-md-12">
                            <h6>
                                {'Showing'} {search_allusercredit.setDisp} {'of'}{' '}
                                {search_allusercredit.totalRecords}
                            </h6>
                            <Pagination
                                count={Math.ceil(search_allusercredit.totalRecords / search.limit)}
                                page={search.page}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>
            <CustomDialog title={'Add Balance'} open={toggleDialog} function={changeDialogStatus}>
                <h5>{'Enter Amount to be Added to Your Wallet'}</h5>
                <form onSubmit={formik.handleSubmit}>
                    {Object.values(mapData(amount))}

                    <div className="actionWrapper">
                        <Button onClick={() => setToggleDialog(false)}>{'Cancel'}</Button>
                        <PrimaryButton type="submit" label={'Proceed to Pay'} />
                    </div>
                </form>
            </CustomDialog>
        </DashboardLayout>
    )
}

export default MyWallet
