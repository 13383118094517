import React, { useCallback, useMemo, useEffect, useState } from 'react'
// import '@algolia/autocomplete-theme-classic'
import algoliasearch from 'algoliasearch/lite'
import {
    InstantSearch,
    Hits,
    Pagination,
    Highlight,
    HitsPerPage,
    Configure,
    connectSearchBox,
    connectStateResults,
} from 'react-instantsearch-dom'
import './ProductView.css'
import { Drawer } from '@material-ui/core'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import Autocomplete from '../../custom/components/organisms/AutoComplete/Suggestion'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import ProductView from '../../custom/components/organisms/ProductView'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'

const APP_ID = process.env.REACT_APP_ALGOLIA_APPID
const APP_KEY = process.env.REACT_APP_ALGOLIA_APIKEY
const searchClient = algoliasearch(APP_ID, APP_KEY)

const VirtualSearchBox = connectSearchBox(() => null)

const PastAuctionPage = () => {
    const APP_INDEX = process.env.REACT_APP_ALGOLIA_INDEX
    const SUGGESTION_INDEX = process.env.REACT_APP_ALGOLIA_SUGGESTION_INDEX
    const [searchState, setSearchState] = useState({})

    // const pageLimit = [
    //     { label: 'Results 36 per page', value: 36, default: true },
    //     { label: 'Results 12 per page', value: 12 },
    //     { label: 'Results 24 per page', value: 24 },
    //     { label: 'Results 72 per page', value: 72 },
    // ]

    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
        key: 'search',
        limit: 0,
    })

    const onSubmit = useCallback(({ state }) => {
        setSearchState((searchState) => ({
            ...searchState,
            query: state.query,
            page: 1,
        }))
    }, [])

    const onReset = useCallback(() => {
        setSearchState((searchState) => ({
            ...searchState,
            query: '',
            page: 1,
        }))
    }, [])

    const plugins = useMemo(
        () => [
            recentSearchesPlugin,
            createQuerySuggestionsPlugin({
                searchClient,
                indexName: SUGGESTION_INDEX,
                getSearchParams() {
                    return recentSearchesPlugin.data.getAlgoliaSearchParams({
                        hitsPerPage: 36,
                    })
                },
                transformSource({ source }) {
                    return {
                        ...source,
                        onSelect(params) {
                            setSearchState((searchState) => ({
                                ...searchState,
                                query: params.item.query,
                                page: 1,
                            }))
                        },
                    }
                },
            }),
        ],
        [],
    )

    return (
        <div className="liveAuction customContainer">
            <div className="row">
                <div className="col-12">
                    <h2 className="laTitle my-5">Past Auction Lots/Items</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <InstantSearch
                        searchClient={searchClient}
                        indexName={APP_INDEX}
                        searchState={searchState}
                        onSearchStateChange={setSearchState}
                    >
                        <VirtualSearchBox />
                        <Configure filters="status:sold OR status:closed" />

                        <div className="search-panel">
                            <div className="search-panel__results">
                                <Autocomplete
                                    initialState={{
                                        query: searchState.query,
                                    }}
                                    openOnFocus={true}
                                    onSubmit={onSubmit}
                                    onReset={onReset}
                                    plugins={plugins}
                                />
                                {/* <div>
                                    <div className="width-max">
                                        <HitsPerPage
                                            items={pageLimit}
                                        />
                                    </div>
                                </div> */}
                                <div className="my-4">
                                    <Content />
                                </div>

                                <div className="pagination page-records justify-content-end">
                                    <Pagination />
                                </div>
                            </div>
                        </div>
                    </InstantSearch>
                </div>
            </div>
        </div>
    )
}

const Content = connectStateResults(({ searchState, searchResults }) =>
    searchResults && searchResults.nbHits !== 0 ? <Hits hitComponent={Hit} /> : <NoRecordsFound />,
)

const Hit = ({ hit }) => {
    const { id } = hit

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })

    return (
        <>
            <div className="w-100">
                {id ? (
                    <GridView
                        data={hit}
                        key={id}
                        favId={`searchProd_${id}`}
                        pastAuction={true}
                        drawerHandler={toggleDrawer('right', true, hit)}
                    />
                ) : null}
            </div>
            <Drawer
                className="rightDrawer"
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >
                <ProductView
                    data={state.data}
                    pastAuctionPage={true}
                    imageClassName={`object-fit`}
                    hideImageGallery={true}
                    drawerHandler={toggleDrawer('right', false)}
                    onClick={() => setState(false)}
                />
            </Drawer>
        </>
    )
}

export default PastAuctionPage
