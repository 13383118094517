import React, { useReducer } from 'react'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../../common/api'
import { response } from '../../common'

import { SHIPPING_TRACKING, CLEAR_RESPONSE, RESPONSE_STATUS } from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        responseStatus: null,
        shipping_tracking: null,
    }

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const shippingTracking = async (formData) => {
        const from = 'getTrackingDetails'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'shipping/common'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SHIPPING_TRACKING,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CommonContext.Provider
            value={{
                responseStatus: state.responseStatus,
                shipping_tracking: state.shipping_tracking,
                shippingTracking,
                clearResponse,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
