import { Button, Tooltip } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
// import CustomInput from '../../atoms/Inputs/CustomInput'
// import PrimaryButton from '../../atoms/PrimaryButton'
import './ProductCard.css'
import AvatarImage from '../../atoms/AvatarImage'

import { currencyFormat } from '../../../common/components'
import BuynowContext from '../../../../product/context/buynow/buynowContext'
import AuthContext from '../../../../product/context/auth/authContext'
import { mapData } from '../../../../product/common/components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import debounce from 'lodash.debounce'
import InfoIcon from '@material-ui/icons/Info'

const CartItem = (props) => {
    const [product, setProduct] = useState()
    const [reload, setReload] = useState(false)
    const { addToCart } = useContext(BuynowContext)
    const authContext = useContext(AuthContext)
    const { user } = authContext
    useEffect(() => {
        let data
        if (
            props.data.custom_projects_lot_location !== null &&
            props.data.custom_projects_lot_location !== ''
        ) {
            if (props.data.custom_projects_sku !== null && props.data.custom_projects_sku !== '') {
                data = {
                    ...props.data,
                    projects_title: `${props.data.custom_projects_sku} (${props.data.custom_projects_lot_location}) ${props.data.projects_title}`,
                }
            } else {
                data = {
                    ...props.data,
                    projects_title: `(${props.data.custom_projects_lot_location}) ${props.data.projects_title}`,
                }
            }
            setProduct(data)
        } else {
            if (props.data.custom_projects_sku !== null && props.data.custom_projects_sku !== '') {
                data = {
                    ...props.data,
                    projects_title: `${props.data.custom_projects_sku} ${props.data.projects_title}`,
                }
                setProduct(data)
            } else {
                setProduct(props.data)
            }
        }
        // setProduct(props.data)
    }, [props.data])

    const validationArray = Yup.object({
        quantity: Yup.number()
            .min(1, `${'Min Quantity'} `)
            .max(
                product ? product.projects_qty - product.projects_sold : 0,
                `${'Max Quantity'} ${product ? product.projects_qty - product.projects_sold : 0}`,
            )
            .required('Enter Quantity'),
    })

    const formik = useFormik({
        initialValues: {
            quantity: 0,
        },
        validationSchema: validationArray,
        onSubmit: debounce((values) => {
            addToCart({
                cart: [
                    {
                        product_id: product.project_id,
                        qty: values.quantity,
                    },
                ],
            })
        }, 1000),
    })

    useEffect(() => {
        if (product) {
            formik.values.quantity = product ? product.qty : 0
            setReload(!reload)
        }
    }, [product])

    useEffect(() => {
        if (product) {
            if (formik.values.quantity !== product.qty) {
                formik.submitForm()
            }
        }
    }, [formik.values.quantity])

    const formikQuantity = [
        {
            label: '',
            name: 'quantity',
            type: 'number',
            placeholder: 'Qty',
            size: 'small',
            autoFocus: false,
            formik: formik,
        },
    ]

    return (
        <>
            {product ? (
                <>
                    <div className="listActBtn cartBtn">
                        {props.from === 'buynowcart' ? (
                            <>
                                <Button
                                    className="removeCart"
                                    onClick={() =>
                                        props.changeOnCart({
                                            cart_id: product.cart_id,
                                            qty: product.qty,
                                            product_id: product.projects_id,
                                            payment_req_logs_id: product.payment_req_logs_id,
                                            user_id: product.payment_req_logs_user_id,
                                        })
                                    }
                                >
                                    Remove from cart
                                </Button>
                            </>
                        ) : props.from == 'cart' ? (
                            <>
                                {
                                    product.in_cart === 0 ? (
                                        <Button
                                            className="removeCart"
                                            onClick={() =>
                                                props.changeOnCart({
                                                    cart: 1,
                                                    id: product.projects_id,
                                                })
                                            }
                                        >
                                            {'Add to cart'}
                                        </Button>
                                    ) : null

                                    // : (
                                    //     <Button
                                    //         className="removeCart"
                                    //         onClick={() =>
                                    //             props.changeOnCart({
                                    //                 cart: 0,
                                    //                 id: product.projects_id,
                                    //             })
                                    //         }
                                    //     >
                                    //         {'Remove from cart'}
                                    //     </Button>
                                    // )
                                }
                            </>
                        ) : null}
                    </div>
                    <div className="cartItem d-flex justify-content-between align-items-start">
                        <div className="itemInfo npsCartWrpr d-flex justify-content-start">
                            <div className="pclImg">
                                <AvatarImage
                                    data={product}
                                    folder="product"
                                    avatar="projects_avatar"
                                    avatar_type="custom_projects_avatar_type"
                                />
                            </div>
                            <div className="listContent row w-100">
                                <h2 id="list_prodtitle" className="listProdTitle col-12">
                                    {product.projects_title}
                                </h2>
                                {props.from == 'buynowcart' ||
                                props.from == 'buynowcheckout' ||
                                (props.from == 'invoice' && product.buynow_autype === 'buynow') ? (
                                    <div className="printlist">
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Amount</label>
                                                <span>{currencyFormat(product.amount)}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Quantity</label>
                                                <span>{product.qty}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Sub Total</label>
                                                <span>{currencyFormat(product.per_total)}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Sales Tax ({product.tax_percent}%)</label>
                                                <span className="d-flex align-items-center">
                                                    {`US $${parseFloat(product.total_tax).toFixed(
                                                        2,
                                                    )}`}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Total</label>
                                                <span>{currencyFormat(product.total_amount)}</span>
                                            </h5>
                                        </div>
                                    </div>
                                ) : props.from == 'cart' ? (
                                    <div className="printlist">
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Subtotal</label>
                                                <span id="sub_total">
                                                    {currencyFormat(product.per_total)}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Sales Tax ({product.tax_percent}%)</label>
                                                <span className="" id="sales_tax">
                                                    {`US $${parseFloat(product.total_tax).toFixed(
                                                        2,
                                                    )}`}
                                                    {user?.collect_tax == 1 &&
                                                        product?.tax_desc !== null &&
                                                        product?.tax_desc !== '' && (
                                                            <div className="pdtDscTxt">
                                                                {product.tax_desc}
                                                            </div>
                                                        )}

                                                    {/* {user?.collect_tax == 1 &&
                                                        product?.tax_desc !== null &&
                                                        product?.tax_desc !== '' && (
                                                            <Tooltip
                                                                title={product.tax_desc}
                                                                placement="top"
                                                            >
                                                                <InfoIcon className="ml-2" />
                                                            </Tooltip>
                                                        )} */}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Total</label>
                                                <span id="total">
                                                    {currencyFormat(product.total_amount)}
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                ) : props.from == 'checkout' ||
                                  (props.from == 'invoice' && product.buynow_autype === 'live') ? (
                                    <div className="printlist">
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Amount</label>
                                                <span>{currencyFormat(product.amount)}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Quantity</label>
                                                <span>{product.qty}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Sub Total</label>
                                                <span>{currencyFormat(product.per_total)}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Sales Tax ({product.tax_percent}%)</label>
                                                <span className="">
                                                    {`US $${parseFloat(product.total_tax).toFixed(
                                                        2,
                                                    )}`}
                                                    {!props.buynow &&
                                                        user?.collect_tax == 1 &&
                                                        product?.tax_desc !== null &&
                                                        product?.tax_desc !== '' && (
                                                            <div className="pdtDscTxt">
                                                                {product.tax_desc}
                                                            </div>
                                                        )}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center col-2">
                                            <h5>
                                                <label>Total</label>
                                                <span>{currencyFormat(product.total_amount)}</span>
                                            </h5>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}
export default CartItem
