import React from 'react'
import { isValidURL } from '../../../../product/common/components'

const addDefaultSrc = (e) => {
    e.target.src = '/assets/images/noimage.png'
}

const AvatarImage = ({ onClick, data, className, folder, avatar, avatar_type }) => {
    return (
        <>
            <img
                onClick={onClick || null}
                src={
                    isValidURL(data[avatar || `avatar`])
                        ? data[avatar || `avatar`]
                        : `${global.site_url}/uploads/${folder || 'product'}/${
                              data[avatar || `avatar`]
                          }`
                }
                alt={data.title}
                onError={addDefaultSrc}
                className={className || ''}
            />
            {/* {parseInt(data[avatar_type || 'avatar_type'], 10) === 2 ? (
                <img
                    onClick={onClick || null}
                    src={data[avatar || 'avatar']}
                    alt={data.title}
                    onError={addDefaultSrc}
                    className={className || ''}
                />
            ) : (
                <img
                    onClick={onClick || null}
                    src={`${global.site_url}/uploads/${folder || 'product'}/${
                        data[avatar || 'avatar']
                    }`}
                    alt={data.title}
                    onError={addDefaultSrc}
                    className={className || ''}
                />
            )} */}
        </>
    )
}

export default AvatarImage
