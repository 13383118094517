export const messageHandler = (data, product, user, setAlert, setViewProduct, type) => {
    let user_id = user.id
    product = Array.isArray(product) ? product : [product]
    const index = product.findIndex((s) => s.id === parseInt(data.id, 10))
    let productToChange = product[index]
    if (index !== -1) {
        if (type === 'realclosedupdates') {
            if (data.usr !== '') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'won',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct([...product])
                } else if (parseInt(productToChange.bid_or_not) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'lost',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct([...product])
                }
            } else {
                if (parseInt(productToChange.bid_or_not) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                        bidtopstatus: 'lost',
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                    }
                    setViewProduct([...product])
                }
            }
        } else if (type === 'bidAddtime') {
            let date_closed = product[index].date_closed
            if (parseInt(data.bpop_belowFive) === 1) {
                date_closed = data.bpop_belowFiveIncrement
            }
            if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                setAlert(data.error, 'error')
            }
            if (data.status !== 'failed') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    setAlert('Bid placed successfully', 'success')
                    product[index] = {
                        ...productToChange,
                        next_bid: data.bpop_cuser_nextbid,
                        wprice: data.bpop_wprice,
                        bidtopstatus: data.bpop_bidstatus,
                        bid_count: data.bpop_bidcount,
                        bid_or_not: 1,
                        date_closed,
                        cbidtext: 'Current Bid',
                    }
                    setViewProduct([...product])
                } else {
                    console.log('coming inside 3333')
                    if (parseInt(productToChange.bid_or_not) > 0) {
                        console.log('coming inside 55555', user_id, productToChange, data)
                        if (user_id === parseInt(data.bpop_higher)) {
                            console.log('123123213', parseInt(productToChange.next_bid))
                            if (parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)) {
                                console.log('faasdfsadf')
                                product[index] = {
                                    ...productToChange,
                                    next_bid: data.bpop_nextbid_org,
                                    wprice: data.bpop_wprice,
                                    bidtopstatus: 'winner',
                                    bid_count: data.bpop_bidcount,
                                    date_closed,
                                }
                                setViewProduct([...product])
                            } else {
                                product[index] = {
                                    ...productToChange,
                                    wprice: data.bpop_wprice,
                                    bidtopstatus: 'winner',
                                    bid_count: data.bpop_bidcount,
                                    date_closed,
                                }
                                setViewProduct([...product])
                            }
                        } else {
                            product[index] = {
                                ...productToChange,
                                next_bid: data.bpop_nextbid_org,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                bidtopstatus: 'outbid',
                                bid_or_not: 1,
                                date_closed,
                            }
                            setViewProduct([...product])
                        }
                    } else {
                        console.log('coming inside 6777777', data)
                        product[index] = {
                            ...productToChange,
                            next_bid: data.bpop_nextbid_org,
                            wprice: data.bpop_wprice,
                            bid_count: data.bpop_bidcount,
                            cbidtext: 'Current Bid',
                            date_closed,
                        }
                        setViewProduct([...product])
                    }
                }
                // getBidHistoryProduct({ product_id: project_id })
            }
        } else if (type === 'watchlistAdded') {
            product[index] = {
                ...productToChange,
                watchlistid: data.status === 'added' ? 1 : 0,
            }
            setViewProduct([...product])
        }
    }
}
