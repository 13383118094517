import React, { useEffect, useState } from 'react'
import { create } from 'jss'
import rtl from 'jss-rtl'
import i18n from './product/common/language-i18n'
import { useTheme } from '@material-ui/core/styles'

import {
    MuiThemeProvider,
    StylesProvider,
    createMuiTheme,
    jssPreset,
} from '@material-ui/core/styles'
import i18next from 'i18next'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

function withRoot(Component) {
    function WithRoot(props) {
        const [currDir, setCurrDir] = useState('ltr')
        useEffect(() => {
            i18n.language === 'for_arabic' ? setCurrDir('rtl') : setCurrDir('ltr')
        }, [currDir])
        // JssProvider allows customizing the JSS styling solution.
        return (
            <StylesProvider jss={jss}>
                <MuiThemeProvider
                    theme={createMuiTheme({
                        direction: currDir,
                    })}
                >
                    <Component {...props} />
                </MuiThemeProvider>
            </StylesProvider>
        )
    }

    return WithRoot
}

export default withRoot
