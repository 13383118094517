import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import algoliasearch from 'algoliasearch'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { LOGO, LOGO_WHITE, SITE_NAME } from '../../../../Utils'
import { Link } from 'react-router-dom'
import {
    Badge,
    Button,
    Divider,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import AuthContext from '../../../../product/context/auth/authContext'
import CommonContext from '../../../../product/context/common/commonContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import { handleRedirectInternal, useCustomMediaQuery } from '../../../../product/common/components'
import './Header.css'
import BidHistory from '../../../components/organisms/BidHistory'
import LanguageSwitch from '../LanguageSwitch'
import BuynowContext from '../../../../product/context/buynow/buynowContext'
import Loaders from '../Loaders'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import TertiaryButton from '../../../components/atoms/TertiaryButton'
import CustomCommonContext from '../../../context/common/commonContext'
import Login from '../../../../views/Login'
import Registration from '../../../../views/Registration'
import Popover from '@material-ui/core/Popover'
import MenuList from '@material-ui/core/MenuList'
import NotifyContext from '../../../../product/context/notify/notifyContext'
import ProductContext from '../../../../product/context/product/productContext'
import Autocomplete from '../../organisms/AutoComplete'
import PrivacyPolicy from '../../../../views/Static/PrivacyPolicy'

const Header = () => {
    const APP_ID = process.env.REACT_APP_ALGOLIA_APPID
    const APP_KEY = process.env.REACT_APP_ALGOLIA_APIKEY
    const APP_INDEX = process.env.REACT_APP_ALGOLIA_INDEX
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const customCommonContext = useContext(CustomCommonContext)

    const productContext = useContext(ProductContext)
    const { getAllActiveSorts } = productContext

    const {
        loginModal,
        setLoginModal,
        signupModal,
        setSignupModal,
        allCategory,
    } = customCommonContext
    const { getAllNotifications, all_notifications, responseStatus, markAsRead } = useContext(
        NotifyContext,
    )
    const { setAlert } = alertContext
    const { user, isAuthenticated, loadPendingCount, isAdmin, cartCount, logout } = authContext

    const [state, setState] = useState({
        right: false,
        top: false,
    })
    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [notiDropDown, setNotiDropDown] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)

    const { searchValue, setSearchValue, isLoading } = commonContext
    const [searchText, setSearchText] = useState('')
    const [search, setSearch] = useState({})
    const [unreadMsg, setUnreadMsg] = useState(0)
    const [userAuctionGroups, setUserAuctionGroups] = useState('')
    const mainMenu = useRef()
    const notiDrop = useRef()

    useEffect(() => {
        if (location.pathname === '/login') {
            setLoginModal(true)
        }
    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            // setLoginModal(false)
            getAllNotifications()
            if (user.user_auction_groups?.length) {
                const userGroups = user.user_auction_groups.length
                let user_auction_groups = ''
                user.user_auction_groups.map(
                    (auction_group) =>
                        (user_auction_groups += ` OR auction_groups:${auction_group}`),
                )
                setUserAuctionGroups(user_auction_groups)
            }
        } else {
            setUserAuctionGroups(``)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (all_notifications) {
            let data = all_notifications.filter((d) => d.read_msg === 0)
            setUnreadMsg(data.length)
        }
    }, [all_notifications])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus == 'success') {
                getAllNotifications()
            }
        }
    }, [responseStatus])

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setanchormenu(null)
        setanchorcart(null)
        setSearchText('')
        setUserAuctionGroups(``)
    }

    const searchClient = algoliasearch(APP_ID, APP_KEY)

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }
    const handleMenuNoti = (event) => {
        setNotiDropDown(event.currentTarget)
    }

    const handleClose = (history, path) => {
        markAsRead()
        setanchormenu(null)
        setNotiDropDown(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const getSearchBarObjet = (value) => {
        return {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                searchbar: {
                    value: value,
                    type: 'like',
                    field: 'p.title', //p.desc_proc,
                },
            },
        }
    }

    const getCategoryObjet = (value) => {
        return {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                category: {
                    value: [JSON.stringify(value)],
                    type: 'array',
                    field: 'it.categoryTypeId',
                },
            },
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSearchButtonClick = () => {
        if (searchText) {
            setSearchValue(getSearchBarObjet(searchText)).then(() => {
                setState({ ...state, top: false })
                if (location.pathname != '/search') {
                    handleRedirectInternal(history, 'search')
                }
            })
        } else if (location.pathname != '/search') {
            handleRedirectInternal(history, 'search')
        }

        // if (searchText != '') {
        // } else {
        //     // setSearch({
        //     //     ...search,
        //     //     filters: {
        //     //         ...search.filters,
        //     //         searchbar: {
        //     //             ...search.filters.searchbar,
        //     //             value: searchValue.filters.searchbar.value,
        //     //         },
        //     //     },
        //     // })
        // }
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (searchText) {
                setSearchValue(getSearchBarObjet(searchText)).then(() => {
                    setState({ ...state, top: false })
                    if (location.pathname != '/search') {
                        handleRedirectInternal(history, 'search')
                    }
                })
            } else if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        }
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    // useEffect(() => {
    //     if (searchValue && searchValue.filters && searchValue.filters.searchbar) {
    //         setSearchText(searchValue.filters.searchbar.value)
    //     }
    // }, [searchValue])

    useEffect(() => {
        getAllActiveSorts({})
    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            let auctionFilter = {
                table: 'buynow',
                field: 'id',
                extraField: '',
                filters: {
                    buynow_autype: {
                        value: 'live',
                        type: 'in',
                        field: 'b.buynow_autype',
                    },
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'b.paid',
                    },
                    partial: {
                        value: '0',
                        type: 'in',
                        field: 'b.partial',
                    },
                    active: {
                        value: '2',
                        type: 'notin',
                        field: 'b.active',
                    },
                    active2: {
                        value: '3',
                        type: 'notin',
                        field: 'b.active',
                    },
                },
            }
            let buyNowFilter = {
                table: 'cart_temp',
                field: 'cart_id',
                extraField: '',
                filters: {
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'crt.paid',
                    },
                },
            }

            loadPendingCount(buyNowFilter, 'buynowCount')

            loadPendingCount(auctionFilter, 'auctionCount')
        }
    }, [isAuthenticated, history.location.pathname])

    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
    })

    useEffect(() => {
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
    }, [cartCount])

    const handleCategoryClick = (catId) => {
        setSearchValue(getCategoryObjet(catId)).then(() => {
            setState({ ...state, top: false, ['left']: false })
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        })

        // setSearchValue(searchObj)
        // handleRedirectInternal(history, 'search')
    }

    const sortCategoryAlphabetical = (a, b) => {
        var nameA = a.description.toUpperCase() // ignore upper and lowercase
        var nameB = b.description.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }

        // names must be equal
        return 0
    }

    const generateQueryParams = (type, id) => {
        let queryObject = {}
        queryObject.searchQuery = id
        const params = new URLSearchParams(queryObject)
        return params.toString()
    }

    const handleSearchAutocompleteTextChange = (item, reset) => {
        if (item.state.query) {
            setSearchValue(getSearchBarObjet(item.state.query)).then(() => {
                setState({ ...state, top: false })
                if (location.pathname != '/search') {
                    handleRedirectInternal(history, 'search')
                }
            })
        } else if (location.pathname != '/search') {
            handleRedirectInternal(history, 'search')
        }

        return true
    }

    const getUserFullName = () => {
        if (isAuthenticated) {
            let firstName =
                user.first_name && user.first_name !== ''
                    ? user.first_name?.charAt(0).toUpperCase() + user.first_name?.slice(1)
                    : ''
            let lastName =
                user.last_name && user.last_name !== ''
                    ? user.last_name?.charAt(0).toUpperCase() + user.last_name?.slice(1)
                    : ''
            return firstName + ' ' + lastName
        } else return ''
    }

    return (
        <>
            {useCustomMediaQuery('(min-width: 1024px)') ? (
                <>
                    {/* DESKTOP NAVIGATION */}
                    <Loaders name="home" isLoading={isLoading} />
                    <header className="mainHeader customContainer d-flex justify-content-between align-items-center noPrint">
                        <div className="headLt d-flex justify-content-start align-items-center">
                            <Link to="/">
                                <img src={LOGO_WHITE} alt={`${SITE_NAME} Logo`} id="nps_logo" />
                            </Link>
                            <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <Autocomplete
                                    openOnFocus={true}
                                    onSubmit={(stateData) => {
                                        stateData.setIsOpen(false)
                                        handleSearchAutocompleteTextChange(stateData)
                                    }}
                                    getSources={({ query }) => [
                                        {
                                            sourceId: 'products',
                                            onSelect({ setIsOpen, setQuery, event, item }) {
                                                handleRedirectInternal(
                                                    history,
                                                    `productview/${item?.id}`,
                                                )
                                            },
                                            getItems() {
                                                const searchParams = {
                                                    query,
                                                    filters: `status:open AND auction_groups:0 ${userAuctionGroups}`,
                                                }
                                                return getAlgoliaResults({
                                                    searchClient,
                                                    queries: [
                                                        {
                                                            indexName: APP_INDEX,
                                                            ...searchParams,
                                                        },
                                                    ],
                                                })
                                            },

                                            templates: {
                                                header() {
                                                    return 'Suggestions'
                                                },
                                                item({ item, components }) {
                                                    return (
                                                        <>
                                                            {item?.objectID ? (
                                                                <div className="aa-ItemTitle">
                                                                    <components.Highlight
                                                                        hit={item}
                                                                        attribute="title"
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )
                                                },
                                            },
                                        },
                                    ]}
                                />
                                {/* <input
                                    className="form-control"
                                    type="search"
                                    placeholder="Search NPS Products"
                                    aria-label="Search"
                                    value={searchText}
                                    id="homepage_search_inputfield"
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button
                                    className="btn"
                                    onClick={handleSearchButtonClick}
                                    id="search_button"
                                >
                                    <span className="material-icons">search</span>
                                </Button> */}
                            </div>
                        </div>
                        <div>
                            <div className="headRt deskNav d-flex justify-content-start align-items-center">
                                <ul className="d-flex justify-content-start align-items-center">
                                    <li>
                                        <NavLink
                                            activeClassName="active"
                                            to="/auction"
                                            id="navbar_auction"
                                        >
                                            Auctions
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            activeClassName="active"
                                            to="/about"
                                            id="navbar_about"
                                        >
                                            About
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            activeClassName="active"
                                            to="/support"
                                            id="navbar_support"
                                        >
                                            Support
                                        </NavLink>
                                    </li>
                                    <li>
                                        {/* <NavLink activeClassName="active" to="/contactus"> */}
                                        <NavLink
                                            activeClassName="active"
                                            to={{
                                                pathname:
                                                    'https://npsstore.com/contact-us#section-6-99',
                                            }}
                                            target="_blank"
                                            id="navbar_contactus"
                                        >
                                            Contact us
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            activeClassName="active"
                                            to="/pastAuction"
                                            id="navbar_pastauction"
                                        >
                                            Past Auctions
                                        </NavLink>
                                    </li>
                                    {isAuthenticated ? (
                                        <>
                                            <li className="headCart">
                                                <Button
                                                    ref={notiDrop}
                                                    aria-controls="simple-notification"
                                                    aria-haspopup="true"
                                                    onClick={handleMenuNoti}
                                                >
                                                    <Badge badgeContent={unreadMsg} color="primary">
                                                        <span
                                                            className="material-icons"
                                                            id="notifications_icon"
                                                        >
                                                            notifications
                                                        </span>
                                                    </Badge>
                                                </Button>
                                                <Popover
                                                    id="simple-notification"
                                                    anchorEl={notiDrop.current}
                                                    keepMounted
                                                    class={`notiDropMenu ${
                                                        document.body.dir === 'rtl' && 'rtl'
                                                    }`}
                                                    onClose={() => handleClose(history, '')}
                                                    open={Boolean(notiDropDown)}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <div className="ntiHdr">
                                                        <span className="material-icons">
                                                            notifications
                                                        </span>
                                                        <h4 className="m-0">Notification</h4>
                                                    </div>
                                                    <ul className="notiList">
                                                        {all_notifications.length ? (
                                                            all_notifications.map((data) => (
                                                                <li
                                                                    className={`${
                                                                        data.read_msg === 0
                                                                            ? 'newNoti'
                                                                            : ''
                                                                    }`}
                                                                    key={data.id}
                                                                >
                                                                    {data.comment}
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <li className="emtNoti">
                                                                <span className="material-icons">
                                                                    notifications
                                                                </span>
                                                                <h4 className="m-0">
                                                                    No Notification Found!
                                                                </h4>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </Popover>
                                            </li>
                                            <li className="headCart">
                                                <Button
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'favorites/watchlist',
                                                        )
                                                    }
                                                >
                                                    <Badge color="primary">
                                                        <span
                                                            className="material-icons"
                                                            id="favorite_icon"
                                                        >
                                                            visibility
                                                        </span>
                                                    </Badge>
                                                </Button>
                                            </li>

                                            <li className="headCart">
                                                {/* <Button
                                                    onClick={() =>
                                                        handleRedirectInternal(history, 'cart')
                                                    }
                                                >
                                                    <Badge
                                                        badgeContent={count.auctionCount}
                                                        color="primary"
                                                    >
                                                        <span className="material-icons">
                                                            shopping_cart
                                                        </span>
                                                    </Badge>
                                                </Button> */}

                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleCart}
                                                >
                                                    <Badge
                                                        badgeContent={
                                                            count.buynowCount + count.auctionCount
                                                        }
                                                        color="primary"
                                                    >
                                                        <span
                                                            className="material-icons"
                                                            id="shopping_cart_icon"
                                                        >
                                                            shopping_cart
                                                        </span>
                                                    </Badge>
                                                </Button>
                                                <Popover
                                                    anchorEl={anchorcart}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    id="simple-cart"
                                                    keepMounted
                                                    class={`headerDropMenu ${
                                                        document.body.dir === 'rtl' && 'rtl'
                                                    }`}
                                                    open={Boolean(anchorcart)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id="menu-list-grow"
                                                        onKeyDown={handleListKeyDown}
                                                    >
                                                        <MenuItem
                                                            onClick={() =>
                                                                handleClose(history, 'buynowcart')
                                                            }
                                                        >
                                                            <Badge
                                                                badgeContent={count.buynowCount}
                                                                color="primary"
                                                            >
                                                                <span className="material-icons">
                                                                    shopping_bag
                                                                </span>
                                                            </Badge>
                                                            {'Buy Now Cart'}
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() =>
                                                                handleClose(history, 'cart')
                                                            }
                                                        >
                                                            <Badge
                                                                badgeContent={count.auctionCount}
                                                                color="primary"
                                                            >
                                                                <span className="material-icons">
                                                                    gavel
                                                                </span>
                                                            </Badge>

                                                            {'Auction Cart'}
                                                        </MenuItem>
                                                    </MenuList>
                                                </Popover>
                                            </li>
                                            <li>
                                                <Button
                                                    ref={mainMenu}
                                                    id="acccount_button"
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleMenu}
                                                >
                                                    {/* {user.avatar &&
                                                    user.avatar !==
                                                        'file_upload-1625693520372_947677.jpeg' ? (
                                                        <img
                                                            className="userProfileIcon"
                                                            src={`${global.site_url}/uploads/profile/${user.avatar}`}
                                                        />
                                                    ) : ( */}
                                                    <span className="small material-icons">
                                                        account_circle
                                                    </span>
                                                    {/* )} */}
                                                </Button>
                                                <Popover
                                                    id="simple-menu"
                                                    anchorEl={mainMenu.current}
                                                    keepMounted
                                                    class={`headerDropMenu ${
                                                        document.body.dir === 'rtl' && 'rtl'
                                                    }`}
                                                    onClose={() => handleClose(history, '')}
                                                    open={Boolean(anchormenu)}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id="menu-list-grow"
                                                        onKeyDown={handleListKeyDown}
                                                    >
                                                        <li className="text-center px-3 mt-2">
                                                            <div className="font-weight-bold">
                                                                {getUserFullName()}
                                                            </div>
                                                            <div>{user.email}</div>
                                                        </li>
                                                        <hr />
                                                        <MenuItem
                                                            onClick={() =>
                                                                handleClose(history, 'auctions/all')
                                                            }
                                                        >
                                                            <span className="material-icons">
                                                                gavel
                                                            </span>
                                                            {'My Bids'}
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'account/profile',
                                                                )
                                                            }
                                                        >
                                                            <span className="material-icons">
                                                                person
                                                            </span>
                                                            {'Profile'}
                                                        </MenuItem>
                                                        <MenuItem onClick={() => onLogOut()}>
                                                            <span className="material-icons">
                                                                power_settings_new
                                                            </span>
                                                            {'Logout'}
                                                        </MenuItem>
                                                    </MenuList>
                                                </Popover>

                                                {/* <Menu
                                                    id="simple-menu"
                                                    anchormenu={mainMenu.current}
                                                    keepMounted
                                                    class={`headerDropMenu ${
                                                        document.body.dir === 'rtl' && 'rtl'
                                                    }`}
                                                    open={Boolean(anchormenu)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem
                                                        onClick={() =>
                                                            handleClose(history, 'auctions/all')
                                                        }
                                                    >
                                                        <span className="material-icons">
                                                            gavel
                                                        </span>
                                                        {'my bids'}
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() =>
                                                            handleClose(history, 'account/profile')
                                                        }
                                                    >
                                                        <span className="material-icons">
                                                            person
                                                        </span>
                                                        {'profile'}
                                                    </MenuItem>
                                                    <MenuItem onClick={() => onLogOut()}>
                                                        <span className="material-icons">
                                                            power_settings_new
                                                        </span>
                                                        {'logout'}
                                                    </MenuItem>
                                                </Menu> */}
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li>
                                                <TertiaryButton
                                                    id="login"
                                                    label="Login"
                                                    btnSize="small"
                                                    onClick={() => setLoginModal(true)}
                                                />
                                            </li>
                                            <li>
                                                <SecondaryButton
                                                    id="sign_up"
                                                    label="Sign up"
                                                    btnSize="small"
                                                    onClick={() => setSignupModal(true)}
                                                />
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </header>
                    <div className="categoryHeader noPrint">
                        <ul className="customContainer">
                            <>
                                <ListItem button onClick={toggleDrawer('left', true)}>
                                    <span
                                        id="allcategories_dropdown"
                                        className="material-icons-round"
                                    >
                                        menu
                                    </span>{' '}
                                    All Categories
                                </ListItem>
                                {[...allCategory]
                                    .filter((main) => main.active === 1 && main.level === 0)
                                    .sort(sortCategoryAlphabetical)
                                    .slice(0, 0)
                                    .map((data, index) => (
                                        <ListItem
                                            button
                                            className="categoryItem"
                                            key={index}
                                            onClick={() => handleCategoryClick(data.id)}
                                        >
                                            {data.description}
                                        </ListItem>
                                    ))}
                            </>
                        </ul>
                    </div>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader customContainer d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Link to="/">
                                <img src={LOGO_WHITE} alt={`${SITE_NAME} Logo`} />
                            </Link>
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li>
                                    <Button onClick={toggleDrawer('top', true)}>
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>
                                {isAuthenticated && (
                                    <>
                                        <li className="headCart">
                                            <Link to="/cart">
                                                <Badge color="primary">
                                                    <span className="material-icons">
                                                        shopping_cart
                                                    </span>
                                                </Badge>
                                            </Link>
                                        </li>
                                        <li className="headCart">
                                            <Link to="/buynowcart">
                                                <Badge color="primary">
                                                    <span className="material-icons">
                                                        shopping_bag
                                                    </span>
                                                </Badge>
                                            </Link>
                                        </li>
                                    </>
                                )}
                                <li>
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder="Search NPS Products"
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button className="btn" onClick={handleSearchButtonClick}>
                                    <span className="material-icons">search</span>
                                </Button>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img src={LOGO} alt={`${SITE_NAME} Logo`} height="60" />
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('right', false)}>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/auction">
                                            <span className="material-icons">live_tv</span>
                                            {'Live Auctions'}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/pastAuction">
                                            <span className="material-icons">live_tv</span>
                                            {'Past Auctions'}
                                        </NavLink>
                                    </ListItem>
                                    {/* <ListItem button>
                                        <NavLink activeClassName="active" to="/search">
                                            <span className="material-icons">category</span>
                                            {'products'}
                                        </NavLink>
                                    </ListItem> */}

                                    {isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to={'/account/profile'}
                                                >
                                                    <span className="material-icons">person</span>
                                                    {'Profile'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/auctions/all"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    {'My Bids'}
                                                </NavLink>
                                            </ListItem>
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/purchases/buynow"
                                                >
                                                    <span className="material-icons">
                                                        shopping_basket
                                                    </span>
                                                    {'my purchases'}
                                                </NavLink>
                                            </ListItem> */}

                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/cart">
                                                    <span className="material-icons">
                                                        shopping_cart
                                                    </span>
                                                    {'Auction Cart'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/buynowcart">
                                                    <span className="material-icons">
                                                        shopping_bag
                                                    </span>
                                                    {'Buy Now Cart'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/purchases/buynow"
                                                >
                                                    <span className="material-icons">
                                                        shopping_basket
                                                    </span>
                                                    {'My Purchases'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/favorites/watchlist"
                                                >
                                                    <span className="material-icons">
                                                        visibility
                                                    </span>
                                                    {'Favorites'}
                                                </NavLink>
                                            </ListItem>
                                            {/* <ListItem button>
                                                <NavLink activeClassName="active" to="/wallet">
                                                    <span className="material-icons">
                                                        account_balance_wallet
                                                    </span>
                                                    {'my wallet'}
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/transactions"
                                                >
                                                    <span className="material-icons">
                                                        swap_vert
                                                    </span>
                                                    My Transactions
                                                </NavLink>
                                            </ListItem> */}
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/invoices/all"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    {'My Invoices'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/saved_search"
                                                >
                                                    <span className="material-icons">bookmark</span>
                                                    {'My Saved Searches'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/appointment">
                                                    <span className="material-icons">
                                                        calendar_month
                                                    </span>
                                                    {'My Appointments'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/contactus">
                                                    <span className="material-icons">
                                                        contact_support
                                                    </span>
                                                    {'Contact'}
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/about">
                                                    <span className="material-icons">info</span>
                                                    {'About'}
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    to=""
                                                    ActiveclassName="inactive"
                                                    onClick={() => setLoginModal(true)}
                                                >
                                                    <span className="material-icons">login</span>
                                                    {'Login'}
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    to=""
                                                    ActiveclassName="inactive"
                                                    onClick={() => setSignupModal(true)}
                                                >
                                                    <span className="material-icons">
                                                        app_registration
                                                    </span>
                                                    {'Register'}
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}
                                    {isAuthenticated && (
                                        <ListItem
                                            className="mt-3"
                                            button
                                            onClick={() => onLogOut()}
                                        >
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            {'Logout'}
                                        </ListItem>
                                    )}
                                </ul>
                                {/* <ul className="navRespLinks">
                                    <ListItem button>
                                        <LanguageSwitch />
                                    </ListItem>
                                </ul> */}
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}

            <React.Fragment>
                <SwipeableDrawer
                    className="allCategoriesDrawer"
                    anchor={'left'}
                    open={state['left']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={false}
                    onClose={toggleDrawer('left', false)}
                    onOpen={toggleDrawer('left', true)}
                >
                    <div>
                        <div className="acdHead d-flex justify-content-between align-items-center">
                            <h4>All Categories</h4>
                            <Button onClick={toggleDrawer('left', false)}>
                                <span className="material-icons">close</span>
                            </Button>
                        </div>
                        <ul onClick={() => toggleDrawer('left', false)}>
                            {[...allCategory]
                                .filter((main) => main.active === 1 && main.level === 0)
                                .sort(sortCategoryAlphabetical)
                                .map((data, index) => (
                                    <ListItem
                                        button
                                        className="allCategoryItem"
                                        key={index}
                                        onClick={() => handleCategoryClick(data.id)}
                                    >
                                        {data.description}
                                    </ListItem>
                                ))}
                        </ul>
                    </div>
                </SwipeableDrawer>
            </React.Fragment>
            <BidHistory />
            <Login />
            <Registration />
            <PrivacyPolicy />
            <Button
                className="scrollTop noPrint"
                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
            >
                <span className="material-icons">keyboard_arrow_up</span>
            </Button>
        </>
    )
}
export default Header
