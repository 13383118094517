import React, { useContext, useEffect } from 'react'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import './ContactUs.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import UserContext from '../../product/context/user/userContext'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../product/context/auth/authContext'
function ContactUs() {
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const { submitContactUs, responseStatus } = userContext
    const { user } = authContext
    const history = useHistory()
    const validationArray = Yup.object({
        first_name: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z]*$/g, 'The Special Characters Not Allowed')
            .max(15, 'Maximum 15 Characters')
            .typeError('Required!')
            .required('Required'),
        last_name: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z]*$/g, 'The Special Characters Not Allowed')
            .max(15, 'Maximum 15 Characters')
            .typeError('Required!')
            .required('Required'),
        email: Yup.string()
            .email('Invalid Email Format')
            .typeError('Required!')
            .required('Required'),
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'Only Numbers Are Allowed')
            .typeError('Required!')
            .required('Required'),
        message: Yup.string().typeError('Required!').required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            message: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            submitContactUs(values)
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'submitContactUs') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, '')
                }
            }
        }
    }, [responseStatus])

    const contactInfo = [
        {
            label: 'First Name',
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter Your First Name',
            class: 'col-sm-6 col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: 'Last Name',
            name: 'last_name',
            type: 'text',
            placeholder: 'Enter Your Last Name',
            class: 'col-sm-6 col-12',
            formik: formik,
        },
        {
            label: 'Email Address',
            name: 'email',
            type: 'text',
            placeholder: 'Enter Your Email Address',
            class: 'col-sm-6 col-12',
            formik: formik,
        },
        {
            label: 'Phone Number',
            name: 'phone',
            type: 'number',
            placeholder: 'Phone Number',
            class: 'col-sm-6 col-12',
            formik: formik,
        },
        {
            label: 'Message',
            name: 'message',
            type: 'textarea',
            placeholder: 'Enter Your Message',
            class: 'col-12',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (user) {
            formik.values.first_name = user.first_name
            formik.values.last_name = user.last_name
            formik.values.phone = user.phone
            formik.values.email = user.email
        }
    }, [user])

    return (
        <div className="contact-main-container">
            <div className="contact-banner">
                <img src="/assets/images/contact_bg.png" alt="" />
            </div>
            <div className="container cu-margin-125">
                <div className="row">
                    <div className="col-xs-12 col-md-10 padtop mx-auto">
                        <div className="contactform">
                            <div className="cu-head">{'Contact Us'}</div>
                            <div className="text-center scontact-us-wrap flex-btnscol">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        {Object.values(mapData(contactInfo))}
                                        <div className="col-12">
                                            <PrimaryButton label={'Submit'} type="submit" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xs-12 col-md-4 padtop">
                        <div className="contactform cfRt">
                            <div className="cu-head">{'reach us'}</div>
                            <div className="media">
                                <div className="media-left">
                                    <span className="material-icons">location_on</span>
                                </div>
                                <div className="media-body">
                                    <h5 className="ru-head">{'jafza head office'}</h5>
                                    <a
                                        href="https://goo.gl/maps/R22YL28GNSVzCdq68"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="ru-con"
                                    >
                                        {'address of jafza zone')}
                                    </a>
                                </div>
                            </div>
                            <div className="media">
                                <div className="media-left">
                                    <span className="material-icons">query_builder</span>
                                </div>
                                <div className="media-body">
                                    <h5 className="ru-head">{operating hours')}</h5>
                                    <p className="ru-con">{ 'operating hrs')}</p>
                                    <p className="ru-con">{'working days'}</p>
                                </div>
                            </div>
                            <div className="media">
                                <div className="media-left">
                                    <span className="material-icons">call</span>
                                </div>
                                <div className="media-body">
                                    <h5 className="ru-head">{t('call_within_uae')}</h5>
                                    <a href="tel:80052392" className="ru-con">
                                        <span dir="ltr">800-Jafza (80052392)</span>
                                    </a>
                                </div>
                            </div>
                            <div className="media">
                                <div className="media-left">
                                    <span className="material-icons">public</span>
                                </div>
                                <div className="media-body">
                                    <h5 className="ru-head">{'call international')}</h5>
                                    <a href="tel:+971 4445 3270" className="ru-con">
                                        <span dir="ltr">+971 4445 3270</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ContactUs
