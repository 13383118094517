import React, { useState, useContext, useEffect } from 'react'
import './Login.css'
import { NavLink, useHistory } from 'react-router-dom'
import CustomInput from '../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import CheckBox from '../../product/components/atoms/CheckBox'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import { Link } from 'react-router-dom'
import { Button, Divider } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../product/context/auth/authContext'
import { handleRedirectInternal, mapData } from '../../product/common/components'
import { LOGO, SITE_NAME } from '../../Utils'
import { Fade } from '@material-ui/core'
import { Modal } from '@material-ui/core'
import CustomCommonContext from '../../custom/context/common/commonContext'

const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const Login = () => {
    const classes = useStyles()
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { loginModal, setLoginModal, signupModal, setSignupModal } = customCommonContext
    const { login, responseStatus, clearResponse, isAuthenticated } = authContext
    const [toggleForgotPassword, setToggleForgotPassword] = useState(false)
    const [toggleLogin, setToggleLogin] = useState({
        popup: loginModal,
    })

    useEffect(() => {
        setToggleLogin({ popup: loginModal })
    }, [loginModal])

    let [passwordShown, setPasswordShown] = useState(false)

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid Email Format').required('Required'),
        password: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            email: localStorage.email ? localStorage.email : '',
            //password: localStorage.password ? localStorage.password : '',
            remember_me: localStorage.remember_me ? localStorage.remember_me : false,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (values.remember_me) {
                localStorage.email = values.email
                // localStorage.password = values.password
                localStorage.remember_me = values.remember_me
                // localStorage.email = ''
                //localStorage.password = ''
                // localStorage.remember_me = ''
            } else {
                delete localStorage.email
                delete localStorage.password
                delete localStorage.remember_me
            }

            login(values)
            setLoginModal(false)
        },
    })

    const rememberMe = {
        formik: formik,
        data: [
            {
                label: 'Remember Me',
                name: 'remember_me',
                type: 'checkbox',
                placeholder: 'Remember Me',
                class: '',
            },
        ],
    }

    const loginInfo = {
        formik: formik,
        data: [
            {
                label: 'Email address',
                name: 'email',
                type: 'email',
                placeholder: 'Enter your email address',
                class: 'col-12',
                autoFocus: true,
            },
            {
                label: 'Password',
                name: 'password',
                type: passwordShown ? 'text' : 'password',
                placeholder: 'Enter your password',
                class: 'col-12',
                endAdornment: passwordShown ? (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility_off
                    </span>
                ) : (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility
                    </span>
                ),
            },
        ],
    }
    useEffect(() => {
        //console.log('LOCAL VSTORAGE', localStorage)
    }, [])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                clearResponse()
                if (responseStatus.status === 'success') {
                    if (!formik.values.remember_me) {
                        formik.resetForm()
                    }
                    handleRedirectInternal(history, 'auction')
                } else if (responseStatus.status != 'success') {
                    if (!formik.values.remember_me) {
                        formik.resetForm()
                    }
                }
            }
        }
    }, [responseStatus])

    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={toggleLogin.popup}
            className={`${classes.modal} customModal`}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={toggleLogin.popup}>
                <div className="login">
                    <button
                        type="button"
                        className="close"
                        id="close_loginpopup"
                        onClick={() => {
                            setLoginModal(false)
                            setToggleForgotPassword(false)
                            formik.resetForm()
                        }}
                    >
                        <span className="material-icons">close</span>
                    </button>
                    <Link to="/">
                        <img className="brandLogo" src={LOGO} alt={`${SITE_NAME} Logo`} />
                    </Link>
                    <h2>LOGIN</h2>
                    <h4>Continue logging to your account</h4>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">{mapData(loginInfo)}</div>
                        <div className="d-flex loginActBox justify-content-between align-items-center">
                            {mapData(rememberMe)}
                            <Link
                                to="/forgot_password"
                                id="forgot_password_link"
                                onClick={() => setLoginModal(false)}
                            >
                                Forgot Password?
                            </Link>
                        </div>
                        <PrimaryButton id="login_button" label="Login" type="submit" />
                    </form>
                    <h6 className="loginMiscAction ">
                        Don&apos;t have an account?{' '}
                        <a
                            className="cursorDecoy"
                            id="signup_link"
                            onClick={() => {
                                setLoginModal(false), setSignupModal(true)
                            }}
                        >
                            Sign up
                        </a>
                    </h6>
                    <h6 className="loginMiscAction ">
                        Email still not verified?{' '}
                        <NavLink
                            onClick={() => {
                                setLoginModal(false)
                            }}
                            className="cursorDecoy"
                            to="resend_verify_mail"
                        >
                            Verify now
                        </NavLink>
                    </h6>
                </div>
            </Fade>
        </Modal>
    )
}

export default Login
