import React, { useContext, useEffect, useState } from 'react'
import { mapData } from '../../../../product/common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import StripeBankContext from '../../../../product/context/stripe/bank/bankContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Popup from '../../organisms/Popup'

function BankDetails(props) {
    const authContext = useContext(AuthContext)
    const { user } = authContext
    const { createStripeBank, deleteStripeBank, updateStripeBank, verifyStripeBank } = useContext(
        StripeBankContext,
    )
    const [disabled, setDisabled] = useState(false)
    const [reload, setReload] = useState(false)

    const accountHolderType = [
        { value: 'individual', show: 'Individual' },
        { value: 'company', show: 'Company' },
    ]

    const validationBank = Yup.object({
        bank_account_holder_name: Yup.string('Must be string').when(['payment_method'], {
            is: (a) => a === 'bank',
            then: Yup.string().required('Required!'),
        }),
        bank_account_number: Yup.number('Number required')
            .positive('Positive only allowed')
            .when(['payment_method'], {
                is: (a) => a === 'bank',
                then: Yup.number('Number required').required('Required!'),
            }),
        bank_account_holder_type: Yup.string().when(['payment_method'], {
            is: (a) => a === 'bank',
            then: Yup.string().required('Required!'),
        }),
        bank_routing_number: Yup.number()
            .positive('Positive only allowed')
            .when(['payment_method'], {
                is: (a) => a === 'bank',
                then: Yup.number().required('Required!'),
            }),
    })

    const bankFormik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user ? user.stripe_id : '',
            bank_country: 'us',
            bank_currency: 'usd',
            bank_account_holder_name: '',
            bank_account_holder_type: '',
            bank_routing_number: '',
            bank_account_number: '',
            bank_token: true,
        },
        validationSchema: validationBank,
        onSubmit: (values) => {
            console.log(values)
            createStripeBank(values)
            // updateStripeCard(values)
        },
    })

    const validationupdateBank = Yup.object({
        account_holder_name: Yup.string(),
        account_holder_type: Yup.string(),
    })

    const validationVerifyBank = Yup.object({
        amount_1: Yup.number(),
        amount_2: Yup.number(),
    })

    const updateBankformik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: '',
            source_id: '',
            account_holder_name: '',
            account_holder_type: '',
        },
        validationSchema: validationupdateBank,
        onSubmit: (values) => {
            updateStripeBank(values)
        },
    })

    const verifyBankformik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: '',
            source_id: '',
            amount_1: '',
            amount_2: '',
        },
        validationSchema: validationVerifyBank,
        onSubmit: (values) => {
            verifyStripeBank(values)
        },
    })

    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                deleteStripeBank({
                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                    customer_id: user.stripe_id,
                    source_id: props.data.data.id,
                })
            } else if (props.data.status === 'update') {
                updateBankformik.values.source_id = props.data.data.id
                updateBankformik.values.customer_id = props.data.data.customer
                updateBankformik.values.account_holder_name = props.data.data.account_holder_name
                updateBankformik.values.account_holder_type = props.data.data.account_holder_type
                setReload(!reload)
            } else if (props.data.status === 'verify') {
                verifyBankformik.values.source_id = props.data.data.id
                verifyBankformik.values.customer_id = props.data.data.customer
            }
        }
    }, [props.data.data])

    const bankUpdate = [
        {
            label: 'Account holder name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: 'col-12',
            name: 'account_holder_name',
            formik: updateBankformik,
        },
        {
            label: 'Account Type',
            type: 'select',
            options: accountHolderType,
            placeholder: 'Enter account holder type',
            class: 'col-12',
            name: 'bank_account_holder_type',
            formik: updateBankformik,
        },
    ]

    const bankVerify = [
        {
            label: 'Enter amount 1',
            type: 'number',
            placeholder: 'Enter credited amount 1',
            class: 'col-12',
            name: 'amount_1',
            formik: verifyBankformik,
        },
        {
            label: 'Enter amount 2',
            type: 'text',
            placeholder: 'Enter credited amount 2',
            class: 'col-12',
            name: 'amount_2',
            formik: verifyBankformik,
        },
    ]

    const bankDetails = [
        {
            label: 'Account Holder Name',
            type: 'text',
            placeholder: 'Enter account holder name',
            class: 'col-12',
            name: 'bank_account_holder_name',
            autoFocus: true,
            formik: bankFormik,
        },
        {
            label: 'Account Number',
            type: 'string',
            placeholder: 'Enter account number',
            name: 'bank_account_number',
            class: 'col-12',
            formik: bankFormik,
        },
        {
            label: 'Account Type',
            type: 'select',
            options: accountHolderType,
            placeholder: 'Enter account holder type',
            class: 'col-12',
            name: 'bank_account_holder_type',
            formik: bankFormik,
        },
        {
            label: 'Routing Number',
            type: 'string',
            placeholder: 'Enter routing number',
            class: 'col-12',
            name: 'bank_routing_number',
            formik: bankFormik,
        },
    ]

    return (
        <>
            <Popup
                open={props.data.popup && props.data.status === 'new'}
                size="md"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Add Bank Details"
            >
                <div className="bankDetails">
                    <form onSubmit={bankFormik.handleSubmit} autoComplete="nofill">
                        {Object.values(mapData(bankDetails))}
                        <div className="col-12">
                            <PrimaryButton
                                // disabled={disabled}
                                btnSize="large"
                                type="submit"
                                label={'Save'}
                            />
                        </div>
                    </form>
                </div>
            </Popup>

            <Popup
                open={props.data.popup && props.data.status === 'update'}
                handleClose={() => props.function(false, 'update', null)}
                size="md"
                modaltitle="Update Bank Details"
            >
                <form onSubmit={updateBankformik.handleSubmit} autoComplete="nofill">
                    <div className="row">
                        {Object.values(mapData(bankUpdate))}
                        <div className="col-12">
                            <PrimaryButton
                                disabled={disabled}
                                btnSize="large"
                                type="submit"
                                label={'Save'}
                            />
                        </div>
                    </div>
                </form>
            </Popup>

            <Popup
                open={props.data.popup && props.data.status === 'verify'}
                handleClose={() => props.function(false, 'verify', null)}
                size="md"
                modaltitle="Verify Bank Details"
            >
                <form onSubmit={verifyBankformik.handleSubmit} autoComplete="nofill">
                    <div className="row">
                        {Object.values(mapData(bankVerify))}
                        <div className="col-12">
                            <PrimaryButton
                                disabled={disabled}
                                btnSize="large"
                                type="submit"
                                label={'Save'}
                            />
                        </div>
                    </div>
                </form>
            </Popup>
        </>
    )
}

export default BankDetails
