import React, { useEffect, useContext, useState, useRef } from 'react'
import openSocket from 'socket.io-client'
import ProductContext from '../../product/context/product/productContext'
import AlertContext from '../../product/context/alert/alertContext'
import { messageHandler } from '../Common/socketHandler'
import AuthContext from '../../product/context/auth/authContext'
import Loaders from '../../custom/components/molecules/Loaders'
import ProductView from '../../custom/components/organisms/ProductView'
import BuyerContext from '../../product/context/buyer/buyerContext'
import BuynowContext from '../../product/context/buynow/buynowContext'
import Popup from '../../custom/components/organisms/Popup'
import '../../custom/components/organisms/ProductView/ProductView.css'
import AuctionContext from '../../product/context/auction/auctionContext'

function ProductViewPage(props) {
    const productContext = useContext(ProductContext)
    const { user } = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const auctionContext = useContext(AuctionContext)
    const { responseStatus: responseStatusAuction } = auctionContext

    const searchParams = new URLSearchParams(props.location.search)
    const fromPastAuction = searchParams.get('pastAuction') ? true : false
    const {
        search_allproducts,
        getAllSearchProducts,
        increaseProductViewCount,
        responseStatus,
    } = productContext
    const { setAlert } = alertContext

    const {
        bidCancel,
        responseStatus: responseStatusBid,
        clearResponse: clearResponseBid,
    } = useContext(BuyerContext)
    const { responseStatus: buynowResponse, clearResponse: clearBuynowResponse } = useContext(
        BuynowContext,
    )

    let [viewProduct, setViewProduct] = useState([])

    const [isLoading, setIsLoading] = useState(true)
    const [errorPopup, setErrorPopup] = useState(false)
    const [biddingErrorMessage, setBiddingErrorMessage] = useState('')

    useEffect(() => {
        if (responseStatusBid) {
            if (responseStatusBid.from === 'bidCancel') {
                if (responseStatusBid.status === 'success') {
                    getAllSearchProducts(search)
                    setAlert(responseStatusBid.message, 'success')
                    clearResponseBid()
                } else {
                    setAlert(responseStatusBid.message, 'error')
                    clearResponseBid()
                }
            }
        }
    }, [responseStatusBid])

    const [search, setSearch] = useState({
        limit: 1,
        page: 1,
        orderby: 'p.id, asc',
        pastAuction: false,
        market_status: ['open', 'relisted', 'closed', 'sold', 'unsold'],
        filters: {
            projectid: {
                value: '',
                type: 'in',
                field: 'p.id',
            },
        },
    })

    // useEffect(() => {
    //     loadUser()
    // }, [])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        const socket = openSocket(global.site_url, { transports: ['websocket'] })

        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (
                data.status === 'failed' &&
                data.error?.indexOf('suspended') !== -1 &&
                userRef?.current.id == data.bpop_cbidder
            ) {
                setErrorPopup(true)
                setBiddingErrorMessage(data.error)
            } else {
                handler(data, 'bidAddtime')
            }
            console.log(data, 'viewproduct1')
            //getAllSearchProducts(search)
        })
        socket.on('cancelBidEmitted', (data) => {
            // getAllSearchProducts({
            //     ...search,
            //     filters: {
            //         ...search.filters,
            //         projectid: {
            //             ...search.filters.projectid,
            //             value: props.match.params.pid,
            //         },
            //     },
            // })
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('cancelBidEmitted', () => {
                // getAllSearchProducts(search)
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (buynowResponse) {
            if (buynowResponse.from === 'addToCart' && buynowResponse.status === 'error') {
                setErrorPopup(true)
                setBiddingErrorMessage(buynowResponse.message)
            }
            clearBuynowResponse()
        }
    }, [buynowResponse])

    useEffect(() => {
        if (search_allproducts.records.length) {
            setViewProduct(search_allproducts.records.length ? search_allproducts.records[0] : [])
        }
        setIsLoading(false)
    }, [search_allproducts])
    /*useEffect(() => {
        if (Array.isArray(viewProduct)) {
            setViewProduct(viewProduct[0])
            console.log(viewProduct, 'viewProduct')
        }
    }, [viewProduct])*/
    useEffect(() => {
        if (search.filters.projectid.value) {
            setIsLoading(true)
            getAllSearchProducts(search)
            // increaseProductViewCount({ id: search.filters.projectid.value })
        }
    }, [search])

    useEffect(() => {
        setSearch({
            ...search,
            pastAuction: fromPastAuction,
            filters: {
                ...search.filters,
                projectid: {
                    ...search.filters.projectid,
                    value: props.match.params.pid,
                },
            },
        })

        window.scrollTo(0, 0)
    }, [props.match.params.pid])

    useEffect(() => {
        if (responseStatusAuction) {
            if (responseStatusAuction.from === 'updateAuctionTerms') {
                if (responseStatusAuction.status === 'success') {
                    getAllSearchProducts(search)
                }
            }
        }
    }, [responseStatusAuction])

    // const productContext = useContext(ProductContext)
    // const [product, setProduct] = React.useState()
    // useEffect(() => {
    //     setProduct(props.data)
    // }, [props.data])

    return (
        <div className="productViewPage">
            {isLoading ? (
                <Loaders name="product_view" isLoading={isLoading} loop={1} />
            ) : viewProduct ? (
                <ProductView
                    data={Array.isArray(viewProduct) ? viewProduct[0] : viewProduct}
                    standalone={true}
                    loading={false}
                />
            ) : null}
            <Popup open={errorPopup} size="md" handleClose={() => setErrorPopup(false)}>
                <h4 className="suspended-error">
                    {biddingErrorMessage}
                    <br />
                    Please contact customer service
                </h4>
            </Popup>
        </div>
    )
}

export default ProductViewPage
