import React, { useState, useContext, useEffect } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Button, Tooltip } from '@material-ui/core'
// import CustomInput from '../../atoms/Inputs/CustomInput'
// import PrimaryButton from '../../atoms/PrimaryButton'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import CustomDialog from '../../../../custom/components/organisms/Dialog'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import './ProductView.css'
import Buynow from '../../molecules/Buynow/BuynowItem'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import { dateFormatFront, currencyFormat, handleRedirectInternal } from '../../../common/components'
import { mapData } from '../../../../product/common/components'
// import TertiaryButton from '../../atoms/TertiaryButton'

import { useHistory } from 'react-router-dom'

import Timer from '../../../../product/common/timer'
import Bidding from '../../molecules/Bidding/BiddingItem'
import ProductContext from '../../../../product/context/product/productContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import BuyerContext from '../../../../product/context/buyer/buyerContext'
import AuthContext from '../../../../product/context/auth/authContext'
import AuctionContext from '../../../../product/context/auction/auctionContext'
import Loaders from '../../molecules/Loaders'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ShippingData from '../shippingPrice'
import { Link } from 'react-router-dom'
import AvatarImage from '../../atoms/AvatarImage'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '10px',
    },
}))(MuiAccordionDetails)
const ProductView = (props) => {
    console.log(props, 'props12112')
    const [expanded, setExpanded] = useState()
    const [images, setImages] = useState([])
    let [viewProduct, setViewProduct] = useState([])
    const [product, setProduct] = useState()
    const [isLoading, setIsLoading] = useState(props.loading && true)
    const history = useHistory()

    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const buyerContext = useContext(BuyerContext)
    const alertContext = useContext(AlertContext)
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)
    const auctionContext = useContext(AuctionContext)
    const { updateAuctionTerms } = auctionContext

    const { setAlert } = alertContext
    const { isAuthenticated, user } = authContext
    const {
        bidCancel,
        responseStatus: responseStatusBid,
        clearResponse: clearResponseBid,
    } = buyerContext
    const {
        search_allsimilar,
        getAllSimilarProducts,
        search_allbidhistory,
        getAllBidHistory,
        getAllSearchProducts,
        search_allproducts,
        increaseProductViewCount,
    } = productContext
    const [toggleDialog, setToggleDialog] = useState(false)
    const { configVariables, allCategory, allCondition, allLocations } = customCommonContext
    const { setBidHistoryValue } = productCommonContext

    const didUserBid = (userID, bidHistory) => {
        let didBid = false
        bidHistory?.forEach((element, index, array) => {
            if (element.user_id === userID) {
                didBid = true
            }
        })
        return didBid
    }
    const productBiddingStatus = (userInfo, productInfo) => {
        if (product.bidtopstatus && product.bidtopstatus !== '') return product.bidtopstatus

        if (userInfo && productInfo) {
            // return none if no top status
            if (productInfo.bidhistory?.length < 1) {
                return 'none'
            }
            if (didUserBid(userInfo.id, productInfo.bidhistory)) {
                if (productInfo.market_status === 'closed') {
                    if (productInfo.bidwinneruser === userInfo.id) {
                        return 'won'
                    } else {
                        return 'lost'
                    }
                }
                if (productInfo.market_status === 'open') {
                    if (productInfo.bidhistory[0].user_id === userInfo.id) {
                        return 'winner'
                    } else {
                        return 'outbid'
                    }
                }
            }
            return 'none'
            //check if user is winning

            //check
        }
    }

    useEffect(() => {
        setProduct(props.data)
        if (
            props.data &&
            Object.keys(props.data).length &&
            props?.data?.user_auction_custom_data_terms == null &&
            props?.data?.auctionlot_market_status == 'open' &&
            isAuthenticated
        ) {
            console.log('is thi printyyu')
            formikTerms.setFieldValue('auction_id', props.data?.auctionid)
            formikTerms.setFieldValue('id', props.data.id)
            setToggleDialog(true)
        } else {
            setToggleDialog(false)
        }
    }, [props.data])

    useEffect(() => {
        if (parseInt(product?.id) > 0) {
            getAllBidHistory({
                limit: 3,
                page: 1,
                id: product?.id,
            })
            // increaseProductViewCount({ id: product.id })
        }
    }, [product ? product?.id : 0])

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const formikTerms = useFormik({
        initialValues: {
            auction_id: 0,
            id: 0,
        },
        onSubmit: (values) => {
            updateAuctionTerms({ ...values, terms: 1 })
            setToggleDialog(false)
        },
    })

    // const TermsBidding = {
    //     formik: formikTerms,
    //     data: [
    //         {
    //             label: (
    //                 <h6
    //                     dangerouslySetInnerHTML={{
    //                         __html: props?.data?.custom_auctionlot_terms,
    //                     }}
    //                 ></h6>
    //             ),
    //             name: 'terms',
    //             type: 'checkbox',
    //             placeholder: '',
    //             class: 'col-12 auth-links-wrap',
    //         },
    //     ],
    // }

    // useEffect(() => {
    //     setIsLoading(props.loading && true)

    //     if (product) {
    //         const search = {
    //             limit: 3,
    //             page: 1,
    //             orderby: 'p.date_closed,asc',
    //             order: '',
    //             similars: {
    //                 category: {
    //                     value: [product.categoryTypeId],
    //                     type: 'array',
    //                     field: 'it.categoryTypeId',
    //                 },
    //                 condition: {
    //                     value: [product.conditionTypeId],
    //                     type: 'array',
    //                     field: 'it.conditionTypeId',
    //                 },
    //                 location: {
    //                     value: [product.location_id],
    //                     type: 'array',
    //                     field: 'p.location_id',
    //                 },
    //                 auctionid: {
    //                     value: [product.auctionid],
    //                     type: 'array',
    //                     field: 'p.auctionid',
    //                 },
    //             },
    //             filters: {
    //                 product_id: {
    //                     value: product.id,
    //                     type: 'notin',
    //                     field: 'p.id',
    //                 },
    //             },
    //         }
    //         getAllSimilarProducts(search)
    //     }
    // }, [product ? product.id : 0])

    // useEffect(() => {
    //     setIsLoading(false)

    //     console.log('search_allproducts', search_allsimilar)
    //     setViewProduct(search_allsimilar.records.length ? search_allsimilar.records : [])
    // }, [search_allsimilar])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const addDefaultSrc = (e) => {
        e.target.src = '/assets/images/noimage.png'
    }

    useEffect(() => {
        let noImageArray = [
            {
                original: '/assets/images/noimage.png',
                thumbnail: '/assets/images/noimage.png',
            },
        ]
        if (product) {
            if (product.allattachmentlist) {
                if (product.allattachmentlist.length) {
                    product.allattachmentlist.forEach(function (data) {
                        if (data.type === 2) {
                            data['original'] = data.file_name
                            data['thumbnail'] = data.file_name
                        } else {
                            data['original'] = global.images_url + data['file_name']
                            data['thumbnail'] = global.images_url + data['file_name']
                        }
                    })
                    setImages(product.allattachmentlist)
                    // console.log('images: ', images)
                } else {
                    setImages(noImageArray)
                }
            } else {
                setImages(noImageArray)
            }
        } else {
            setImages(noImageArray)
        }
    }, [product])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const cancelBid = () => {
        if (search_allbidhistory?.records?.length > 0) {
            let topValid = search_allbidhistory.records.find((bid) => bid.declined === 0)
            bidCancel({
                id: topValid.bidid,
                type: topValid.proposal === 'hard_bid' ? 'hard' : 'proxy',
            })
        }
    }

    useEffect(() => {
        if (responseStatusBid) {
            if (responseStatusBid.from === 'bidCancel') {
                if (responseStatusBid.status === 'success') {
                    getAllBidHistory({
                        limit: 3,
                        page: 1,
                        id: product?.id,
                    })
                    getAllSearchProducts(
                        {
                            limit: 12,
                            page: 1,
                            orderby: 'p.id, desc',
                            order: '',
                            filters: {
                                auctionid: {
                                    value: Number(product.auctionid),
                                    type: 'in',
                                    field: 'p.auctionid',
                                },
                            },
                        },
                        'refreshproducts',
                    )
                    setAlert(responseStatusBid.message, 'success')
                    clearResponseBid()
                    if (typeof props.onClick !== 'undefined') {
                        props.onClick()
                    }
                } else {
                    setAlert(responseStatusBid.message, 'error')
                    clearResponseBid()
                    if (typeof props.onClick !== 'undefined') {
                        props.onClick()
                    }
                }
            }
        }
    }, [responseStatusBid])

    const isBidCanceAllowed = () => {
        let diff = 0
        if (product.auctionlot_date_closed) {
            diff = new Date(product.auctionlot_date_closed).getTime() - new Date().getTime()
        }
        let allowed = product.custom_auctionlot_bid_retract_minutes * 60 * 1000
        return (
            product.custom_auctionlot_bid_retract_check === 1 &&
            product.custom_auctionlot_bid_retract_minutes > 0 &&
            search_allbidhistory.records?.length > 0 &&
            diff > 0 &&
            diff > allowed
        )
    }

    const substractMinutes = (date, minutes) => {
        const d = new Date(date)
        const newTime = d.getTime() - minutes * 60 * 1000
        const newDate = new Date(newTime)
        return newDate.toISOString()
    }

    return (
        <div
            className={`productView ${props.standalone && 'customContainer standalonePage'}`}
            id="productView"
        >
            {isLoading ? (
                <Loaders name="product_view" isLoading={isLoading} loop={1} />
            ) : product ? (
                <>
                    {!props.standalone && (
                        <>
                            <Button className="closeDrawer" onClick={props.onClick}>
                                <span className="material-icons">close</span>
                            </Button>
                            {/* <Button
                                className="lotPreview"
                                onClick={() =>
                                    handleRedirectInternal(history, `productView/${product.id}`)
                                }
                            >
                                View Full Details
                                <span className="material-icons-outlined">open_in_new</span>
                            </Button> */}
                        </>
                    )}
                    <div className="d-flex flex-wrap">
                        {isAuthenticated && (
                            <>
                                {(product.bidtopstatus === 'outbid' ||
                                    productBiddingStatus(user, product) == 'outbid') && (
                                    <div className="pvBidStatus">
                                        <h4 className="outbid">{'Oops you have been outbid'}.</h4>
                                    </div>
                                )}
                                {(product.bidtopstatus === 'winner' ||
                                    productBiddingStatus(user, product) == 'winner') && (
                                    <div className="pvBidStatus">
                                        <h4 className="winning">
                                            {'Yay you are winning this item'}.
                                        </h4>
                                    </div>
                                )}
                                {(product.bidtopstatus === 'won' ||
                                    productBiddingStatus(user, product) == 'won') && (
                                    <div className="pvBidStatus">
                                        <h4 className="won">
                                            {'Congratulations you have won this item'}
                                        </h4>
                                    </div>
                                )}
                                {(product.bidtopstatus === 'lost' ||
                                    productBiddingStatus(user, product) == 'lost') && (
                                    <div className="pvBidStatus">
                                        <h4 className="lost">
                                            {'Unfortunately you have lost this item'}
                                        </h4>
                                    </div>
                                )}
                            </>
                        )}
                        {/* {console.log(images, 'images')} */}
                        {/* {images.length > 0 ? (
                                <ImageGallery
                                    items={images}
                                    thumbnailPosition="bottom"
                                    showNav={false}
                                    showBullets={false}
                                    showFullscreenButton={true}
                                    showPlayButton={false}
                                    onImageError={addDefaultSrc}
                                    onThumbnailError={addDefaultSrc}
                                />
                            ) : (
                                <img src={`/assets/images/noimage.png`} alt={product.title} />
                            )} */}
                        <div className="pvLt">
                            {!props.hideImageGallery ? (
                                <ImageGallery
                                    items={images}
                                    thumbnailPosition="bottom"
                                    showNav={false}
                                    showBullets={false}
                                    showFullscreenButton={true}
                                    showPlayButton={false}
                                    onImageError={addDefaultSrc}
                                    onThumbnailError={addDefaultSrc}
                                />
                            ) : (
                                <div className="pcgImg text-center">
                                    <AvatarImage
                                        data={product}
                                        className={props.imageClassName}
                                        onClick={props.drawerHandler}
                                        folder="product"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="pvRt">
                            {/* <div className="pvProductInfo mt-4">
                                <small className="actnTtle">Viewed</small>
                                <h2>{product.product_view_counts}</h2>{' '}
                            </div> */}
                            <div className="pvProductInfo mt-4">
                                <small className="actnTtle">{product.auctionlot_title}</small>
                                <h2>{product.title}</h2>{' '}
                            </div>
                            <div>
                                {product.market_status === 'open' ? (
                                    <div className="pvTimerBox">
                                        <span className="material-icons">timer</span>
                                        <h4>
                                            <Timer
                                                date_added={product.date_added}
                                                date_closed={product.date_closed}
                                                withText={1}
                                                endText="Time Left: "
                                                startText="Starts in: "
                                            ></Timer>
                                        </h4>
                                    </div>
                                ) : (
                                    <div className="pvTimerBox">
                                        <span className="material-icons">timer</span>
                                        <label>{'Closed On :'}</label>
                                        <h4>{dateFormatFront(product.date_closed)}</h4>
                                    </div>
                                )}
                                <div className="bdngByWrpr">
                                    <div className="bdPlcWrpr">
                                        {product.auction ? (
                                            <div className="pvActBtn ">
                                                {product.market_status === 'open' ? (
                                                    <>
                                                        <>
                                                            {product.bidhistory?.length > 0 ? (
                                                                <>
                                                                    <div className="pabChild pvCurrentBid d-flex align-items-center">
                                                                        <label>Current Bid</label>
                                                                        <h4>
                                                                            {currencyFormat(
                                                                                product.wprice,
                                                                            )}
                                                                        </h4>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="pabChild pvCurrentBid d-flex align-items-center">
                                                                        <label>
                                                                            Starting Price
                                                                        </label>
                                                                        <h4>
                                                                            {currencyFormat(
                                                                                product.wprice,
                                                                            )}
                                                                        </h4>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {product.qty > 1 && (
                                                                <div className="pabChild pvCurrentBid d-flex align-items-center mt-2">
                                                                    <label>Total Bid</label>

                                                                    <h4>
                                                                        {currencyFormat(
                                                                            product.wprice *
                                                                                product.qty,
                                                                        )}
                                                                    </h4>
                                                                </div>
                                                            )}
                                                        </>
                                                    </>
                                                ) : null}
                                            </div>
                                        ) : null}
                                        <div className="pvBidBox">
                                            {product.market_status === 'open' &&
                                            !product.future_active ? (
                                                <>
                                                    {product.auction ? (
                                                        <Bidding
                                                            data={product}
                                                            type={
                                                                product.custom_auctionlot_bid_type ||
                                                                product.bid_type
                                                            }
                                                        />
                                                    ) : null}
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                    {(product.buynow &&
                                        product.wprice < product.bprice &&
                                        product.auction) ||
                                    (product.buynow && !product.auction) ? (
                                        <div className="bdPlcWrpr">
                                            <div className="pvActBtn">
                                                <div className="pabChild pvCurrentBid d-flex align-items-center">
                                                    <label>Buy now price</label>
                                                    <h4>{currencyFormat(product.bprice)}</h4>
                                                </div>
                                            </div>
                                            <div className="pvBidBox bynwRpr">
                                                {product.market_status === 'open' &&
                                                !product.future_active ? (
                                                    <>
                                                        {product.buynow && (
                                                            <Buynow
                                                                data={product}
                                                                editQuantity={true}
                                                                isOnlyBuynow={
                                                                    product.auction === 0
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {(product.bidtopstatus === 'winner' ||
                                    productBiddingStatus(user, product) == 'winner') &&
                                    isBidCanceAllowed() && (
                                        <>
                                            <div>
                                                <div className="text-right mt-2">
                                                    <Timer
                                                        date_added={
                                                            search_allbidhistory.records[0]
                                                                .created_at
                                                        }
                                                        date_closed={substractMinutes(
                                                            product.auctionlot_date_closed,
                                                            product.custom_auctionlot_bid_retract_minutes,
                                                        )}
                                                        withText={1}
                                                        endText="Cancel By: "
                                                        startText="Starts in: "
                                                        closedText="Cancellation Expired"
                                                    ></Timer>
                                                </div>

                                                <Button
                                                    className="pvBidHistBtn btn my-2"
                                                    onClick={() => cancelBid()}
                                                >
                                                    <span className="material-icons">undo</span>{' '}
                                                    {'Cancel My Bid'}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                {product.auction ? (
                                    <Button
                                        className="pvBidHistBtn mb-4"
                                        id="view_bid_history"
                                        onClick={() => setBidHistoryValue(product.id)}
                                    >
                                        <span className="material-icons">history</span>{' '}
                                        {'View Bid History'}
                                    </Button>
                                ) : null}
                                <div className="pvProductInfo mt-4">
                                    <div className="lctnWrpr mt-3 flex-wrap">
                                        <div className="lctnDtls d-flex align-items-start w-100 pb-2 mb-4">
                                            <p className="brdrHdr">
                                                <span className="material-icons-outlined">
                                                    place
                                                </span>
                                                Location:
                                            </p>
                                            <span>
                                                {' '}
                                                {allLocations
                                                    .filter(
                                                        (value) => product.location_id === value.id,
                                                    )
                                                    .map((location) => (
                                                        //    console.log(location),
                                                        <span className="adrsInfoCrd">
                                                            {location.name} - {location.address},{' '}
                                                            {location.city} {location.state}{' '}
                                                            {location.zipCode}
                                                        </span>
                                                    ))}
                                            </span>
                                        </div>
                                        <div className="lctnDtls d-flex align-items-start w-100 mb-4">
                                            {product.conditionTypeId && allCondition && (
                                                <>
                                                    <p className="brdrHdr">
                                                        <span className="material-icons-outlined">
                                                            inventory_2
                                                        </span>
                                                        Condition:
                                                    </p>

                                                    <span>
                                                        {' '}
                                                        {allCondition
                                                            .filter(
                                                                (value) =>
                                                                    product.conditionTypeId ===
                                                                    value.id,
                                                            )
                                                            .map((location) => (
                                                                //    console.log(location),
                                                                <>
                                                                    <Tooltip
                                                                        title={location.detail}
                                                                    >
                                                                        <span className="adrsInfoCrd cursorHelper">
                                                                            {location.description}
                                                                        </span>
                                                                    </Tooltip>
                                                                </>
                                                            ))}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="lctnDtls d-flex align-items-start w-100 mt-3">
                                        <p className="m-0 mr-2 fxdLable">Condition Notes:</p>
                                        <span>{product.notes ? product.notes : ''}</span>
                                    </div>
                                    <div className="lctnDtls d-flex align-items-start w-100 mt-3">
                                        <p className="m-0 mr-2 fxdLable">Contents:</p>
                                        <span>{product.contents ? product.contents : ''}</span>
                                    </div>
                                    <div className="lctnDtls d-flex align-items-start w-100 mt-3">
                                        <p className="m-0 mr-2 fxdLable">SKU:</p>
                                        <span>{product.sku ? product.sku : ''}</span>
                                    </div>
                                    <div className="lctnDtls d-flex align-items-start w-100 mt-3">
                                        <p className="m-0 mr-2 fxdLable">Quantity:</p>
                                        <span>{product.qty}</span>
                                    </div>
                                    <div className="lctnDtls d-flex align-items-start w-100 mt-3">
                                        <p className="m-0 mr-2 fxdLable">Lot:</p>
                                        <span>{product.lot}</span>
                                    </div>

                                    <div className="lctnDtls d-flex align-items-start w-100 mt-3">
                                        <p className="m-0 mr-2 fxdLable">Unit:</p>
                                        <span>{product.weight_unit}</span>
                                    </div>

                                    <div className="lctnDtls d-flex align-items-start w-100 mt-3">
                                        <p className="m-0 mr-2 fxdLable">State:</p>
                                        <span>{product.origin_state}</span>
                                    </div>

                                    <div className="lctnDtls d-flex align-items-start w-100 mt-3">
                                        <p className="m-0 mr-2 fxdLable">UPC:</p>
                                        <span>{product.upc}</span>
                                    </div>

                                    <div className="lctnDtls d-flex align-items-start w-100 mt-3">
                                        <p className="m-0 mr-2 fxdLable">Model:</p>
                                        <span>{product.model}</span>
                                    </div>

                                    <div className="lctnDtls d-flex align-items-start w-100 mt-3 mb-2">
                                        <p className="m-0 mr-2 fxdLable">Part Number:</p>
                                        <span>{product.part_no}</span>
                                    </div>

                                    <div className="lctnDtls d-flex align-items-start w-100 mt-3 mb-2">
                                        <p className="m-0 mr-2 fxdLable">Lot Location:</p>
                                        <span>{product.lot_location}</span>
                                    </div>

                                    <div className="ldTag my-3">
                                        <img src="/assets/svg/truck.svg" />
                                        <p className="m-0 ml-2">
                                            {/* {product.custom_auctionlot_local_pickup_check === 1 &&
                                            product.custom_auctionlot_shipping_check === 1
                                                ? 'Local pickup & Shipping'
                                                : product.custom_auctionlot_local_pickup_check === 1
                                                ? 'Local Pickup only'
                                                : product.custom_auctionlot_shipping_check === 1
                                                ? 'Shipping only'
                                                : null} */}
                                            {product.locations_local_pickup_check === 1 &&
                                            product.custom_auctionlot_shipping_check === 1
                                                ? 'Local pickup & Shipping'
                                                : product.locations_local_pickup_check === 1
                                                ? 'Local Pickup only'
                                                : product.custom_auctionlot_shipping_check === 1
                                                ? 'Shipping only'
                                                : null}
                                        </p>
                                    </div>

                                    {product.custom_auctionlot_shipping_check == 1 && (
                                        <div className="lctnDtls d-flex align-items-center justify-content-end my-2">
                                            <ShippingData data={product} />
                                        </div>
                                    )}
                                </div>
                                {!props.pastAuctionPage ? (
                                    <>
                                        <Accordion
                                            square
                                            expanded={expanded === `panel1`}
                                            key={1}
                                            onChange={handleChange(`panel1`)}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1d-content"
                                                id={`panel1d-header`}
                                            >
                                                <h6 className="accTitle">Description</h6>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <h6
                                                    dangerouslySetInnerHTML={{
                                                        __html: product.desc_proc,
                                                    }}
                                                ></h6>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion
                                            square
                                            expanded={expanded === `panel2`}
                                            key={2}
                                            onChange={handleChange(`panel2`)}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1d-content"
                                                id={`panel1d-header`}
                                            >
                                                <h6 className="accTitle">Terms & Conditions</h6>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <h6
                                                    dangerouslySetInnerHTML={{
                                                        __html: product.product_terms,
                                                    }}
                                                ></h6>
                                            </AccordionDetails>
                                        </Accordion>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <CustomDialog
                        title="Confirm Terms And Conditions"
                        open={toggleDialog}
                        className="ConfirmModal"
                        disableBackdropClick
                        function={changeDialogStatus}
                    >
                        <>
                            <h5>Please confirm if you want to continue with this action</h5>
                            {/* <form>
                                <div className="row mb-4">
                                    {Object.values(mapData(TermsBidding))}
                                </div>
                                <div className="actionWrapper">
                                    <Button
                                        onClick={() => {
                                            setToggleDialog(false)
                                            if (props.stanadalone) {
                                                history.goBack()
                                            } else {
                                                if (typeof props.onClick !== 'undefined') {
                                                    props.onClick()
                                                }
                                            }
                                        }}
                                    >
                                        Decline
                                    </Button>
                                    <PrimaryButton
                                        onClick={() => formikTerms.handleSubmit()}
                                        id="confirm_bid_submit"
                                        type="button"
                                        disabled={formikTerms.isSubmitting}
                                        label={'Accept'}
                                    />
                                </div>
                            </form> */}

                            <form onSubmit={formikTerms.handleSubmit}>
                                <div className="row mb-4">
                                    <div className="col-12 auth-links-wrap">
                                        <h6
                                            dangerouslySetInnerHTML={{
                                                __html: props?.data?.custom_auctionlot_terms,
                                            }}
                                        ></h6>
                                    </div>
                                </div>

                                <div
                                    className="actionWrapper"
                                    style={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="checkbox-wrapper">
                                        <input
                                            type="checkbox"
                                            id="terms"
                                            name="terms"
                                            checked={1}
                                        />
                                        <label htmlFor="terms">
                                            I agree to the Terms & Conditions
                                        </label>
                                    </div>
                                    <div className="auctionBtnWrapper">
                                        <Button
                                            onClick={() => {
                                                setToggleDialog(false)
                                                // history.goBack()
                                            }}
                                        >
                                            Decline
                                        </Button>
                                        <PrimaryButton
                                            type="submit"
                                            id="confirm_bid_submit"
                                            disabled={formikTerms.isSubmitting}
                                            label={'Accept'}
                                        />
                                    </div>
                                </div>
                            </form>
                        </>
                    </CustomDialog>
                </>
            ) : null}
        </div>
    )
}
export default ProductView
