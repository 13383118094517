import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import {
    COMMON_VALUES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    LOGIN_MODAL,
    SIGNUP_MODAL,
    UPDATE_CART_CHECKOUT,
} from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        allCategory: [],
        allBusinessCategory: [],
        allVatCategory: [],
        allHomeBanner: [],
        allLocations: [],
        allHolidays: [],
        allCondition: [],
        allshipment_types: [],
        allSubCategories: [],
        allNotifications: [],
        allNotificationBanner: [],
        allConfigurationVariables: {},
        configFeatures: {},
        configVariables: {},
        allBidIncrements: [],
        loginModal: false,
        signupModal: false,
        responseStatus: null,
        languages: [],
        pharases: {},
        cart_item: [],
    }

    const { i18n } = useTranslation()

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common')])
        if (res?.data?.status && res.data.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    allCategory: res.data.data.responseData.tableValues.allcategoryTypes,
                    allSubCategories: res.data.data.responseData.tableValues.allsubCategoryTypes,
                    allBusinessCategory:
                        res.data.data.responseData.tableValues.allbusiness_category,
                    allVatCategory: res.data.data.responseData.tableValues.allvat_registration,
                    allNotifications: res.data.data.responseData.tableValues.allall_notifications,
                    allNotificationBanner:
                        res.data.data.responseData.tableValues.allnotification_banner,
                    allConfigurationVariables: res.data.data.responseData.variables,
                    allLocations: res.data.data.responseData.tableValues.alllocations,
                    allHomeBanner: res.data.data.responseData.tableValues.allhome_banner,
                    allHolidays: res.data.data.responseData.tableValues.allholidays,
                    allCondition: res.data.data.responseData.tableValues.allconditionTypes,
                    allshipment_types: res.data.data.responseData.tableValues.allshipment_types,
                    allfaq: res.data.data.responseData.tableValues.allfaq,
                    allBidIncrements: res.data.data.responseData.allBidIncrements,
                    bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
                    configFeatures: res.data.data.responseData.features,
                    configVariables: res.data.data.responseData.variables,
                    languages: res.data.data.responseData.languages,
                    phrases: res.data.data.responseData.phrases,
                },
            })

            //set reload the language phrase
            global.languages = res.data.data.responseData.languages
            global.phrases = res.data.data.responseData.phrases
            for (let i = 0; i < global.languages.length; i++) {
                i18n.addResourceBundle(
                    global.languages[i],
                    'translations',
                    global.phrases[global.languages[i]],
                    true,
                    true,
                )
            }
            i18n.changeLanguage('for_english')
        }
    }

    const updateCart = (payload) =>
        dispatch({
            type: UPDATE_CART_CHECKOUT,
            cart_item: payload,
        })

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    const setLoginModal = () => {
        dispatch({
            type: LOGIN_MODAL,
        })
    }
    const setSignupModal = () => {
        dispatch({
            type: SIGNUP_MODAL,
        })
    }

    return (
        <CommonContext.Provider
            value={{
                allCategory: state.allCategory,
                allBusinessCategory: state.allBusinessCategory,
                allVatCategory: state.allVatCategory,
                allNotifications: state.allNotifications,
                allNotificationBanner: state.allNotificationBanner,
                allSubCategories: state.allSubCategories,
                allLocations: state.allLocations,
                allHolidays: state.allHolidays,
                allCondition: state.allCondition,
                allshipment_types: state.allshipment_types,
                allHomeBanner: state.allHomeBanner,
                allBidIncrements: state.allBidIncrements,
                allfaq: state.allfaq,
                allConfigurationVariables: state.allConfigurationVariables,
                configFeatures: state.configFeatures,
                configVariables: state.configVariables,
                languages: state.languages,
                responseStatus: state.responseStatus,
                loginModal: state.loginModal,
                signupModal: state.signupModal,
                cart_item: state.cart_item,
                getGlobalVariable,
                setLoginModal,
                setSignupModal,
                clearResponse,
                updateCart,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
