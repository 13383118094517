import React, { useContext, useEffect } from 'react'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import '../Dashboard.css'
import DashboardLayout from '../DashboardLayout'

import { mapData } from '../../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import UserContext from '../../../product/context/user/userContext'
import { ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

const MyAccount = () => {
    const userContext = useContext(UserContext)

    const { updateProfile, responseStatus: responseStatusUser } = userContext

    const levenshteinDistance = (str1 = '', str2 = '') => {
        const track = Array(str2.length + 1)
            .fill(null)
            .map(() => Array(str1.length + 1).fill(null))
        for (let i = 0; i <= str1.length; i += 1) {
            track[0][i] = i
        }
        for (let j = 0; j <= str2.length; j += 1) {
            track[j][0] = j
        }
        for (let j = 1; j <= str2.length; j += 1) {
            for (let i = 1; i <= str1.length; i += 1) {
                const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1
                track[j][i] = Math.min(
                    track[j][i - 1] + 1, // deletion
                    track[j - 1][i] + 1, // insertion
                    track[j - 1][i - 1] + indicator, // substitution
                )
            }
        }
        return track[str2.length][str1.length]
    }

    const validationPassword = Yup.object({
        change_password: Yup.boolean(),
        current_password: Yup.string().required('Required'),
        new_password: Yup.string()
            .trim()
            .required('Required')
            .min(10, 'Min 10 characters')
            .matches(
                /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/,
                'Password must contain 3 of the 4 following: Lowercase, Uppercase, Number, Symbol',
            )
            .notOneOf([Yup.ref('current_password')], 'Current password and new password are same!')
            .test(
                'password-similarity',
                'New password is similar to old password, please change 2 or more characters.',
                (value) => levenshteinDistance(value, formikChange.values.current_password) > 1,
            ),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password')], 'Passwords Not Match')
            .required('Required'),
    })

    const formikChange = useFormik({
        initialValues: {
            change_password: 1,
            current_password: '',
            new_password: '',
            confirm_password: '',
        },
        validationSchema: validationPassword,
        onSubmit: (values) => {
            updateProfile(values)
        },
    })

    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'updateProfile' ||
                responseStatusUser.from === 'updatePreference'
            ) {
                if (responseStatusUser.status === 'success') {
                    formikChange.resetForm()
                }
            }
        }
    }, [responseStatusUser])

    const changePassword = [
        {
            label: 'Current Password',
            name: 'current_password',
            type: 'password',
            placeholder: 'Enter Your Current Password',
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
        {
            label: 'Password',
            name: 'new_password',
            type: 'password',
            placeholder: 'Enter Your Password',
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
        {
            label: 'Confirm Password',
            name: 'confirm_password',
            type: 'password',
            placeholder: 'Re-Enter Your Password',
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
    ]

    return (
        <DashboardLayout title={'Profile'}>
            <div className="dashboardInner myAccount">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/profile">
                            {'Profile'}
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/change">
                            {'Change Password'}
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/notifications">
                            {'My Notifications'}
                        </NavLink>
                    </ListItem>
                </div>
                <div className="accountCnt">
                    <form onSubmit={formikChange.handleSubmit} autoComplete="nofill">
                        <div className="accChild">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4> {'Change Password'}</h4>
                            </div>
                            <div className="row">{Object.values(mapData(changePassword))}</div>
                        </div>
                        <PrimaryButton type="submit" label={'Change'} />
                    </form>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default MyAccount
