import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import CustomCommonContext from '../../../context/common/commonContext'
import './Banner.css'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import { useMediaQuery } from 'react-responsive'
import { Button } from '@material-ui/core'

const Banner = () => {
    // const productCommonContext = useContext(ProductCommonContext)
    const customCommonContext = useContext(CustomCommonContext)
    const isMobile = useMediaQuery({
        query: '(max-width: 480px)',
    })
    // const { setSearchValue } = productCommonContext
    const { allHomeBanner, setSignupModal, setLoginModal } = customCommonContext
    const history = useHistory()
    const settings = {
        dots: false,
        arrows: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 6000,
        cssEase: 'linear',
    }

    const handleLink = (data) => {
        if (data == 'signup') {
            setSignupModal(true)
        } else if (data == 'login') {
            setLoginModal(true)
        } else {
            history.push(`${data}`)
        }
    }

    return (
        <div className="heroCarousel">
            <Slider {...settings}>
                {allHomeBanner && allHomeBanner.length
                    ? allHomeBanner.map((data, index) =>
                          data.active === 1 ? (
                              <div className="homeBanner homeMarginBottom" key={`banner_${index}`}>
                                  <div className="bannerImgCnt">
                                      {isMobile ? (
                                          <img
                                              className="bannerImg"
                                              src={`${global.site_url}/uploads/banner/${
                                                  data.image_mobile ? data.image_mobile : data.image
                                              }`}
                                              alt={`banner_${index}`}
                                          />
                                      ) : (
                                          <img
                                              className="bannerImg"
                                              src={`${global.site_url}/uploads/banner/${data.image}`}
                                              alt={`banner_${index}`}
                                          />
                                      )}
                                  </div>

                                  <div className="bannerContent">
                                      <h3
                                          style={{
                                              color: data.color,
                                          }}
                                      >
                                          {data.description}
                                      </h3>
                                      <h1
                                          style={{
                                              color: data.color,
                                          }}
                                      >
                                          {data.title}
                                      </h1>
                                      {data.link ? (
                                          <Button
                                              onClick={() => handleLink(data.link)}
                                              style={{
                                                  border: data.background_color
                                                      ? ''
                                                      : `1px solid ${data.color}`,
                                                  color: data.color,
                                                  backgroundColor: data.background_color,
                                              }}
                                          >
                                              {`${
                                                  data.button_label
                                                      ? data.button_label
                                                      : 'Get Started'
                                              } `}
                                          </Button>
                                      ) : null}
                                  </div>
                              </div>
                          ) : null,
                      )
                    : null}
            </Slider>
        </div>
    )
}

export default Banner
