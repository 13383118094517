import React, { useState, useContext, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import './Dashboard.css'
import SideNav from './SideNav'
import FilterPanel from '../../custom/components/organisms/FilterPanel'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import { Button, Fade, ListItem, MenuItem, Typography } from '@material-ui/core'
import ListView from '../../custom/components/molecules/ProductCard/ListView'
import Drawer from '@material-ui/core/Drawer'
import ProductView from '../../custom/components/organisms/ProductView'
import ProductContext from '../../product/context/product/productContext'
import AuthContext from '../../product/context/auth/authContext'
import BuynowContext from '../../product/context/buynow/buynowContext'
import AuctionContext from '../../product/context/auction/auctionContext'
import AlertContext from '../../product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Link, NavLink } from 'react-router-dom'
import { messageHandler } from '../../product/common/socketHandler'
import { useCustomMediaQuery } from '../../product/common/components'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import DashboardLayout from './DashboardLayout'
import Loaders from '../../custom/components/molecules/Loaders'
import Popup from '../../custom/components/organisms/Popup'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const MyAuctions = (props) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const auctionContext = useContext(AuctionContext)
    const { responseStatus: responseStatusAuction } = auctionContext

    let history = useHistory()

    const classes = useStyles()

    const toggleBottomDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }
    const {
        search_alldashboardproducts,
        getAllDashboardProducts,
        responseStatus: responseStatusProduct,
    } = productContext
    const { user, isAuthenticated, responseStatus, clearResponse, saveSearch } = authContext

    const { setAlert } = alertContext
    const [isLoading, setIsLoading] = useState(true)
    const [searchText, setSearchText] = useState('')

    const [auctionView, setAuctionView] = useState('Grid')
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    let [viewProduct, setViewProduct] = useState([])
    const [errorPopup, setErrorPopup] = useState(false)
    const [biddingErrorMessage, setBiddingErrorMessage] = useState('')

    const { responseStatus: buynowResponse, clearResponse: clearBuynowResponse } = useContext(
        BuynowContext,
    )

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per Page',
        },
        {
            value: '24',
            show: 'Results 24 per Page',
        },
        {
            value: '36',
            show: 'Results 36 per Page',
        },
        {
            value: '72',
            show: 'Results 72 per Page',
        },
    ]

    const [search, setSearch] = useState({
        limit: 12,
        page: 1,
        orderby: '',
        order: '',
        filters: {
            category: {
                value: [],
                type: 'array',
                field: 'it.categoryTypeId',
            },
            condition: {
                value: [],
                type: 'array',
                field: 'it.conditionTypeId',
            },
            location: {
                value: [],
                type: 'array',
                field: 'p.location_id',
            },
            price: {
                value: '',
                type: 'greaterequal',
                field: 'p.wprice',
            },
            misc: {
                value: '',
                type: 'like',
            },
            auctionid: {
                value: [],
                type: 'array',
                field: 'p.auctionid',
            },
        },
        action: props.match.params.type,
    })

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 'p.wprice,asc',
            show: 'Current Price Low to High',
        },
        {
            value: 'p.wprice,desc',
            show: 'Current Price High to Low',
        },
        {
            value: 'p.date_closed,asc',
            show: 'Closing Date',
        },
        {
            value: 1,
            show: 'Ending Today',
        },
        {
            value: 'p.bprice,asc',
            show: 'Buy Now Price Low to High',
        },
        {
            value: 'p.bprice,desc',
            show: 'Buy Now Price High to Low',
        },
    ]

    useEffect(() => {
        setIsLoading(true)

        getAllDashboardProducts(search)
    }, [search])

    useEffect(() => {
        setSearch({ ...search, action: props.match.params.type, page: 1 })
        setSearchText('')
    }, [props.match.params.type])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setIsLoading(false)

        //console.log('search_alldashboardproducts', search_alldashboardproducts)
        setViewProduct(
            search_alldashboardproducts.records.length ? search_alldashboardproducts.records : [],
        )
    }, [search_alldashboardproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    const formik = useFormik({
        initialValues: {
            misc: '',
        },
    })

    // const handleKeyDown = (event) => {
    //     console.log('handleKeyDown')
    //     if (event.key === 'Enter') {
    //         setSearch({ ...search, searchbar: formik.values.searchbar })
    //     }
    // }
    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (
                data.status === 'failed' &&
                data.error?.indexOf('suspended') !== -1 &&
                userRef?.current.id == data.bpop_cbidder
            ) {
                setErrorPopup(true)
                setBiddingErrorMessage(data.error)
            } else {
                handler(data, 'bidAddtime')
            }
        })
        socket.on('cancelBidEmitted', (data) => {
            // getAllDashboardProducts(search)
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('cancelBidEmitted', (data) => {})
            socket.disconnect()
        }
    }, [])

    const clearSearchFilter = (action) => {
        setSearch({
            ...search,
            limit: 10,
            page: 1,
            orderby: 'p.date_closed,asc',
            order: '',
            filters: {
                category: {
                    ...search.filters.category,
                    value: [],
                },
                condition: {
                    ...search.filters.condition,
                    value: [],
                },
                location: {
                    ...search.filters.location,
                    value: [],
                },
                price: {
                    ...search.filters.price,
                    value: '',
                },
                misc: {
                    ...search.filters.misc,
                    value: '',
                },
                auctionid: {
                    ...search.filters.auctionid,
                    value: [],
                },
            },
        })
        formik.values.misc = ''
    }

    const handleSearchTextChange = () => {
        setSearch({
            ...search,
            filters: {
                ...search.filters,
                misc: { ...search.filters.misc, value: searchText },
            },
        })
        getAllDashboardProducts(search)
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearchTextChange()
        }
    }

    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const renderHeading = () => {
        switch (props.match.params.type) {
            case 'auction':
                return 'My Bids'
            case 'watchlist':
                return 'My Watchlist'
            case 'winning':
                return 'Winning Auctions'
            case 'outbid':
                return 'Outbid Auctions'
            case 'won':
                return 'Won Auctions'
            case 'buynow':
                return 'Buy Now'
            case 'auction_paid':
                return 'Auction'
            case 'appointment':
                return 'My Appointments'
            default:
                return 'My Auctions'
        }
    }

    useEffect(() => {
        if (buynowResponse) {
            if (buynowResponse.from === 'addToCart' && buynowResponse.status === 'error') {
                setErrorPopup(true)
                setBiddingErrorMessage(buynowResponse.message)
            }
            clearBuynowResponse()
        }
    }, [buynowResponse])

    useEffect(() => {
        if (responseStatusAuction) {
            if (responseStatusAuction.from === 'updateAuctionTerms') {
                if (responseStatusAuction.status === 'success') {
                    getAllDashboardProducts(search)
                }
            }
        }
    }, [responseStatusAuction])

    useEffect(() => {
        if (responseStatusProduct) {
            if (
                responseStatusProduct.from === 'addWatchlist' ||
                responseStatusProduct.from === 'removeWatchlist'
            ) {
                if (search.action === 'watchlist') {
                    if (responseStatusProduct.status === 'success') {
                        getAllDashboardProducts(search)
                    }
                }
            }
        }
    }, [responseStatusProduct])

    const updateFunction = () => {
        getAllDashboardProducts(search)
    }

    return (
        <DashboardLayout title={renderHeading()}>
            <div className="dashboardInner myAuctions">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <Breadcrumbs aria-label="breadcrumb" className="customBreadcrumbs">
                        <Link to="/auctions/all">{location.pathname.split('/')[1]}</Link>
                        <Typography color="textPrimary">
                            {renderHeading(location.pathname.split('/')[2])}
                        </Typography>
                    </Breadcrumbs>
                    <div className="searchMiscFilters w-100 mb-3 d-flex justify-content-end align-items-end">
                        <div className="headerSearchForm mb-search-form">
                            <input
                                className="form-control"
                                type="search"
                                placeholder="Search NPS Products"
                                aria-label="Search"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={handleSearchKeyDown}
                            />
                            <Button onClick={handleSearchTextChange} className="btn">
                                <span className="material-icons">search</span>
                            </Button>
                        </div>
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => setAuctionView('Grid')}
                                id="mybids_grid"
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => setAuctionView('List')}
                                id="mybids_list"
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                        <CustomSelect
                            label={'Results per Page'}
                            name="resultsPerPage"
                            selectType="noBorder"
                            size="small"
                            value={search.limit}
                            onChange={(event, editor) => {
                                setSearch({ ...search, page: 1, limit: event.target.value })
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        <CustomSelect
                            label={'Sort By'}
                            value={search.orderby}
                            size="small"
                            selectType="noBorder"
                            name="orderby"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                setSearch({ ...search, orderby: event.target.value })
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    </div>
                </div>

                {props.location.pathname == '/auctions/all' && (
                    <div className="auctionStatus">
                        <ListItem button>
                            <NavLink activeClassName="active" to="/auctions/all">
                                {'All'}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/auctions/winning">
                                {'Winning'}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink activeClassName="active" to="/auctions/outbid">
                                {'Currently Outbid'}
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink activeClassName="active" to="/auctions/won">
                                {'Won'}
                            </NavLink>
                        </ListItem>
                    </div>
                )}

                {isLoading ? (
                    <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                ) : viewProduct.length ? (
                    <>
                        <div className={`searchResults ${auctionView}`}>
                            <Drawer
                                className="rightDrawer"
                                anchor={'right'}
                                open={state['right']}
                                onClose={toggleDrawer('right', false)}
                            >
                                <ProductView
                                    data={state.data}
                                    onClick={() => setState(false)}
                                    updateFunction={updateFunction}
                                />
                            </Drawer>
                            {console.log(state.data, 'state.data')}
                            {viewProduct.map((data, index) => (
                                <>
                                    {auctionView === 'Grid' ? (
                                        <>
                                            <GridView
                                                data={data}
                                                from="dashboard"
                                                action={props.match.params.type}
                                                favId={`searchProd_${index}`}
                                                drawerHandler={toggleDrawer('right', true, data)}
                                            />
                                        </>
                                    ) : (
                                        <ListView
                                            data={data}
                                            from="dashboard"
                                            action={props.match.params.type}
                                            favId={`searchProd_${index}`}
                                            drawerHandler={toggleDrawer('right', true, data)}
                                        />
                                    )}
                                </>
                            ))}
                        </div>
                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-4 pagination-wrapper col-md-12">
                            <h6 style={{ marginLeft: '12px' }}>
                                {'Showing'} {search_alldashboardproducts.setDisp} {'Of'}{' '}
                                {search_alldashboardproducts.totalRecords}
                            </h6>
                            <Pagination
                                count={Math.ceil(
                                    search_alldashboardproducts.totalRecords / search.limit,
                                )}
                                id="pagination_auction"
                                page={search.page}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
                <Popup open={errorPopup} size="md" handleClose={() => setErrorPopup(false)}>
                    <h4 className="suspended-error">
                        {biddingErrorMessage}
                        <br />
                        Please contact customer service
                    </h4>
                </Popup>
            </div>
        </DashboardLayout>
    )
}

export default MyAuctions
