import React, { useContext, useEffect, useState } from 'react'
import './FilterPanel.css'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ProductContext from '../../../../product/context/product/productContext'
import CustomCommonContext from '../../../context/common/commonContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import { Button } from '@material-ui/core'
import { mapData } from '../../../../product/common/components'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 50,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)

const FilterPanel = (props) => {
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const productContext = useContext(ProductContext)
    const { active_sorts, getAllActiveSorts } = productContext
    const { allCategory, allCondition, allshipment_types, allLocations } = customCommonContext
    const { setSearchValue } = productCommonContext
    const [expanded, setExpanded] = React.useState('panel0')

    const sortValues = props.sorts

    const [priceRange, setPriceRange] = useState(
        // formik.values.filters.price.value !== '' ? formik.values.filters.price.value : [],
        [0, 10000],
    )

    const sortCategoryAlphabetical = (a, b) => {
        var nameA = a.description.toUpperCase() // ignore upper and lowercase
        var nameB = b.description.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }

        // names must be equal
        return 0
    }

    const filterValues = [
        {
            label: 'Category',
            type: 'check',
            name: 'category',
            options: allCategory
                .filter((main) => {
                    if (
                        main.level === 0 &&
                        (active_sorts && active_sorts.sorts && active_sorts.sorts.sortcategoryTypes
                            ? active_sorts.sorts.sortcategoryTypes.includes(main.id.toString())
                            : true)
                    ) {
                        return true
                    }
                })
                .sort(sortCategoryAlphabetical),
            filter: true,
        },
        {
            label: 'Sub Category',
            type: 'check',
            name: 'subCategory',
            options: allCategory
                .filter((subcat) => {
                    if (
                        subcat.level === 1 &&
                        props.formik &&
                        props.formik.values.filters &&
                        props.formik.values.filters.category.value &&
                        props.formik.values.filters.category.value.length
                            ? props.formik.values.filters.category.value.includes(
                                  subcat.level_id.toString(),
                              )
                            : false
                    ) {
                        return true
                    }
                })
                .sort(sortCategoryAlphabetical),
            filter: true,
        },
        {
            label: 'More Options',
            type: 'check',
            name: 'subCategory',
            options: allCategory
                .filter((subcat) => {
                    if (
                        subcat.level === 1 &&
                        props.formik &&
                        props.formik.values.filters &&
                        props.formik.values.filters.subCategory.value &&
                        props.formik.values.filters.subCategory.value.length
                            ? props.formik.values.filters.subCategory.value.includes(
                                  subcat.level_id.toString(),
                              )
                            : false
                    ) {
                        return true
                    }
                })
                .sort(sortCategoryAlphabetical),
            filter: true,
        },
        {
            label: 'Price',
            type: 'radio',
            name: 'price',
            options: [
                { id: 0, description: 'All' },
                { id: 10, description: 'USD $10 & UP' },
                { id: 100, description: 'USD $100 & UP' },
                { id: 500, description: 'USD $500 & UP' },
                { id: 1000, description: 'USD $1000 & UP' },
                { id: 5000, description: 'USD $5000 & UP' },
            ],
            int: 1,
            filter: true,
        },
        // {
        //     title: 'location',
        //     type: 'check',
        //     name: 'location',
        //     options: allLocations.filter((main) => {
        //         if (
        //             active_sorts && active_sorts.sorts && active_sorts.sorts.sortlocations
        //                 ? active_sorts.sorts.sortlocations.includes(main.id.toString())
        //                 : true
        //         ) {
        //             return true
        //         }
        //     }),
        //     filters: true,
        //     formik: props.formik,
        // },
    ]

    function sliderText(value) {
        return `AED ${value}`
    }

    const handleChange = (panel) => (event, newExpanded) => {
        if (panel === expanded && !newExpanded) {
            setExpanded(newExpanded ? panel : false)
        } else {
            setExpanded(newExpanded ? panel : false)
        }
    }

    // const priceFilter = [
    //     {
    //         type: 'number',
    //         class: 'empPermissions d-flex align-items-center flex-wrap',
    //         name: 'min_price',
    //         placeholder: 'from',
    //         filter: true,
    //         formik: formik,
    //     },
    //     {
    //         type: 'number',
    //         class: 'empPermissions d-flex align-items-center flex-wrap',
    //         name: 'max_price',
    //         placeholder: 'to',
    //         filter: true,
    //         formik: formik,
    //     },
    // ]
    return (
        <div className="filterPanel">
            <div className="d-flex justify-content-between align-items-center filterTitleCnt">
                <h4 className="fpTitle">{'Filters'}</h4>
                {props.clearSearch && (
                    <Button onClick={() => props.clearSearch()} className="filterClear">
                        <span className="material-icons">cancel</span>
                        {'Clear All'}
                    </Button>
                )}
            </div>
            <div className="filterAcc">
                {filterValues.map((data, index) => (
                    <Accordion
                        square
                        key={index}
                        expanded={
                            expanded === `panel${index}`
                            // || formik.values[data.name].length > 0
                        }
                        onChange={handleChange(`panel${index}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            id={`panel${index}d-header`}
                        >
                            <h6 className="accTitle">{data.label}</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/*console.log(data, 'dataaa')*/}
                            {data.options && data.options.length > 0 ? (
                                <div className="filterCheck d-flex align-items-center flex-wrap">
                                    {console.log('[data]', { formik: props.formik, data: [data] })}
                                    {mapData({ formik: props.formik, data: [data] })}
                                </div>
                            ) : (
                                <small>{`${'No'} ${data.label} ${'Found'}`}</small>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    )
}
export default FilterPanel
