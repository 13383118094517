import React, { useState, useContext, useEffect, useRef } from 'react'
import './invoice.css'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import CartItem from '../../custom/components/molecules/ProductCard/CartItem'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import AuthContext from '../../product/context/auth/authContext'
import InvoiceContext from '../../product/context/invoice/invoiceContext'
import DashboardLayout from '../Dashboard/DashboardLayout'
import {
    dateFormatFront,
    currencyFormat,
    dateTimeFormatFunction,
    convertToCustomFormat,
    capitalize,
} from '../../custom/common/components'
import { Button } from '@material-ui/core'
import { LOGO, SITE_NAME } from '../../Utils'
import * as XLSX from 'xlsx'

const Invoice = (props) => {
    const authContext = useContext(AuthContext)
    const invoiceContext = useContext(InvoiceContext)
    const history = useHistory()
    const { search_allinvoiceproducts, getAllInvoiceProducts } = invoiceContext
    const { isAuthenticated } = authContext

    const [print, setPrint] = useState(false)
    const [search, setSearch] = useState({
        invoice_id: props.match.params.id,
        filters: {
            active: {
                type: 'notin',
                field: 'b.active',
                value: 2,
            },
        },
    })

    let [invoiceDetails, setInvoiceDetails] = useState({
        invoiceProducts: [],
        cartValues: {},
        appointmentDetail: {},
        locationDetail: {},
        invoiceTransactions: [],
        invoiceUser: [],
        invoiceAddress: [],
    })

    // temp lang Manager
    const t = (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text.replace(/_/g, ' ')
    }

    useEffect(() => {
        if (isAuthenticated) {
            getAllInvoiceProducts(search)
        }
    }, [isAuthenticated])

    useEffect(() => {
        setInvoiceDetails({
            invoiceProducts: search_allinvoiceproducts.invoicerecords.length
                ? search_allinvoiceproducts.invoicerecords
                : [],
            cartValues: search_allinvoiceproducts.cartvalues,
            appointmentDetail: search_allinvoiceproducts.appointmentrecord,
            locationDetail: search_allinvoiceproducts.locationrecord,
            invoiceTransactions: search_allinvoiceproducts.transactionrecords,
            invoiceUser: search_allinvoiceproducts.invoiceuserrecords,
            invoiceAddress: search_allinvoiceproducts.invoiceaddressrecord,
        })

        console.log('details', invoiceDetails.invoiceProducts)
    }, [search_allinvoiceproducts])

    useEffect(() => {
        setInvoiceDetails({
            invoiceProducts: [],
            cartValues: {},
            appointmentDetail: {},
            locationDetail: {},
            invoiceTransactions: [],
            invoiceUser: [],
            invoiceAddress: [],
        })
        return () => {
            setInvoiceDetails({
                invoiceProducts: [],
                cartValues: {},
                appointmentDetail: {},
                locationDetail: {},
                invoiceTransactions: [],
                invoiceUser: [],
                invoiceAddress: [],
            })
        }
    }, [])

    const ref = React.createRef()
    const options = {}

    const generatePDF = (toPdf) => {
        setPrint(true)
        toPdf()
    }
    const completedPDF = () => {
        setPrint(false)
    }

    const exportToSpreadSheet = () => {
        const generateProjectTitle = (project) => {
            let data
            if (
                project.custom_projects_lot_location !== null &&
                project.custom_projects_lot_location !== ''
            ) {
                if (project.custom_projects_sku !== null && project.custom_projects_sku !== '') {
                    data = {
                        ...project,
                        projects_title: `${project.custom_projects_sku} (${project.custom_projects_lot_location}) ${project.projects_title}`,
                    }
                } else {
                    data = {
                        ...project,
                        projects_title: `(${project.custom_projects_lot_location}) ${project.projects_title}`,
                    }
                }
                return data
            } else {
                if (project.custom_projects_sku !== null && project.custom_projects_sku !== '') {
                    data = {
                        ...project,
                        projects_title: `${project.custom_projects_sku} ${project.projects_title}`,
                    }
                    return data
                } else {
                    return data
                }
            }
        }

        const modifiedProjects = invoiceDetails.invoiceProducts.map((product) =>
            generateProjectTitle(product),
        )

        const invoiceData = {
            'Invoice #': invoiceDetails.cartValues.common_invoice,
            Date: dateFormatFront(invoiceDetails.cartValues.cart_paiddate),
            Status: invoiceDetails.cartValues.cart_paid ? 'Paid' : 'Unpaid',
            Items: invoiceDetails.invoiceProducts?.length || 0,
            'Bid Amount': currencyFormat(invoiceDetails.cartValues.per_total),
            'Sales Tax': currencyFormat(invoiceDetails.cartValues.total_tax),
            'Total Amount': currencyFormat(invoiceDetails.cartValues.total_amount),
        }

        const lot_desc = modifiedProjects.map((project) => project.projects_title)
        const amounts = modifiedProjects.map((project) => currencyFormat(project.amount))
        const qty = modifiedProjects.map((project) => project.qty)
        const sub_totals = modifiedProjects.map((project) => currencyFormat(project.per_total))
        const sales_taxes = modifiedProjects.map((project) => currencyFormat(project.total_tax))
        const totals = modifiedProjects.map((project) => currencyFormat(project.total_amount))

        const worksheet = XLSX.utils.json_to_sheet([invoiceData])
        const workbook = XLSX.utils.book_new()

        const headers = [
            'Lot Description',
            'Amount',
            'Lot Quantity',
            'Lot Sub Total',
            'Lot Sales Tax',
            'Lot Totals',
        ]
        XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'H1' })

        for (let i = 0; i < lot_desc.length; i++) {
            XLSX.utils.sheet_add_aoa(worksheet, [[lot_desc[i]]], { origin: `H${i + 2}` })
        }

        for (let i = 0; i < amounts.length; i++) {
            XLSX.utils.sheet_add_aoa(worksheet, [[amounts[i]]], { origin: `I${i + 2}` })
        }

        for (let i = 0; i < qty.length; i++) {
            XLSX.utils.sheet_add_aoa(worksheet, [[qty[i]]], { origin: `J${i + 2}` })
        }

        for (let i = 0; i < sub_totals.length; i++) {
            XLSX.utils.sheet_add_aoa(worksheet, [[sub_totals[i]]], { origin: `K${i + 2}` })
        }

        for (let i = 0; i < sales_taxes.length; i++) {
            XLSX.utils.sheet_add_aoa(worksheet, [[sales_taxes[i]]], { origin: `L${i + 2}` })
        }

        for (let i = 0; i < totals.length; i++) {
            XLSX.utils.sheet_add_aoa(worksheet, [[totals[i]]], { origin: `M${i + 2}` })
        }
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

        XLSX.writeFile(workbook, `invoice -${invoiceDetails.cartValues.common_invoice}.xlsx`, {
            compression: true,
        })
    }
    return (
        <div className="container-lg productInvoice">
            <h3 className="invoiceTitle">{'Invoice'}</h3>
            <div className="d-flex justify-content-end align-items-center mb-2 noPrint">
                {/* <Breadcrumbs aria-label="breadcrumb" className="naBreadcrumbs">
                        <Link to="/dashboard/won">Dashboard</Link>
                        <Typography color="textPrimary">Invoice</Typography>
                    </Breadcrumbs> */}
                <Button onClick={() => exportToSpreadSheet()}>
                    <span className="material-icons">file_download</span>
                    {'Export To Spreadsheet'}
                </Button>
                <Button onClick={() => window.print()}>
                    <span className="material-icons">print</span>
                    {'Print'}
                </Button>
                <Button
                    onClick={() => {
                        history.goBack()
                        window.scrollTo(0, 0)
                    }}
                    className="moveBack"
                >
                    <span className="material-icons">arrow_back</span>
                    {'Back'}
                </Button>
            </div>
            <div
                className={`invoiceView ${print ? 'invoiceViewPrint' : ''}`}
                ref={ref}
                style={{ height: '100%', width: '100%' }}
            >
                <div className="card-header d-flex justify-content-between align-items-center p-4">
                    <a className="pt-2 d-inline-block" data-abc="true">
                        <img
                            src={LOGO}
                            className="invLogo"
                            alt={SITE_NAME}
                            style={{ width: '140px' }}
                        />
                    </a>
                    <div className="float-right invDate">
                        <h5 className="mb-0">
                            {'Invoice'} #{invoiceDetails.cartValues.common_invoice}{' '}
                        </h5>
                        <h5 className="mb-0">
                            {'Date'}: {dateFormatFront(invoiceDetails.cartValues.cart_paiddate)}
                        </h5>
                        <h5 className="mb-0">
                            {invoiceDetails.invoiceProducts?.length ? (
                                <>
                                    {'Status'}:{' '}
                                    {invoiceDetails.invoiceProducts[0]?.active === 2
                                        ? 'Cancelled'
                                        : invoiceDetails.cartValues.cart_paid
                                        ? 'Paid'
                                        : invoiceDetails.invoiceProducts[0].partial
                                        ? 'Partial'
                                        : 'Unpaid'}
                                </>
                            ) : null}
                        </h5>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row mb-4">
                        {invoiceDetails.invoiceProducts?.length && (
                            <div className="col-sm-6 invAddr">
                                <h5 className="mb-3">From:</h5>
                                <h3 className="text-dark mb-1">{SITE_NAME}</h3>
                                <div>
                                    {
                                        invoiceDetails.invoiceProducts[0]
                                            .custom_projects_origin_address1
                                    }
                                </div>
                                <div>
                                    {invoiceDetails.invoiceProducts[0].custom_projects_origin_city},{' '}
                                    {invoiceDetails.invoiceProducts[0].custom_projects_origin_state}
                                    ,{' '}
                                    {
                                        invoiceDetails.invoiceProducts[0]
                                            .custom_projects_origin_country
                                    }{' '}
                                    - {invoiceDetails.invoiceProducts[0].custom_projects_origin_zip}
                                </div>

                                <div>
                                    Email:{' '}
                                    {invoiceDetails.invoiceProducts[0].custom_projects_origin_email}
                                </div>

                                <div>
                                    Phone:{' '}
                                    {invoiceDetails.invoiceProducts[0].custom_projects_origin_phone}
                                </div>
                            </div>
                        )}
                        {invoiceDetails.invoiceUser?.length ? (
                            <div className="col-sm-6 invAddr text-right">
                                <h5 className="mb-3">To:</h5>
                                <h3 className="text-dark mb-1">
                                    {invoiceDetails.invoiceUser[0].first_name}{' '}
                                    {invoiceDetails.invoiceUser[0].last_name}
                                </h3>
                                {invoiceDetails.invoiceAddress.length ? (
                                    <>
                                        <div>
                                            {invoiceDetails.invoiceAddress[0].address1 &&
                                                invoiceDetails.invoiceAddress[0].address1}
                                        </div>
                                        <div>
                                            {invoiceDetails.invoiceAddress[0].address2 &&
                                                invoiceDetails.invoiceAddress[0].address2}
                                        </div>
                                        {invoiceDetails.invoiceAddress[0].city &&
                                            invoiceDetails.invoiceAddress[0].state && (
                                                <div>
                                                    {invoiceDetails.invoiceAddress[0].city},{' '}
                                                    {invoiceDetails.invoiceAddress[0].state}{' '}
                                                    {invoiceDetails.invoiceAddress[0].country} -{' '}
                                                    {invoiceDetails.invoiceAddress[0].zip}
                                                </div>
                                            )}
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            {invoiceDetails.invoiceUser[0].address1 &&
                                                invoiceDetails.invoiceUser[0].address1}
                                        </div>
                                        <div>
                                            {invoiceDetails.invoiceUser[0].address2 &&
                                                invoiceDetails.invoiceUser[0].address2}
                                        </div>
                                        {invoiceDetails.invoiceUser[0].city &&
                                            invoiceDetails.invoiceUser[0].state && (
                                                <div>
                                                    {invoiceDetails.invoiceUser[0].city},{' '}
                                                    {invoiceDetails.invoiceUser[0].state}{' '}
                                                    {invoiceDetails.invoiceUser[0].country} -{' '}
                                                    {invoiceDetails.invoiceUser[0].zip}
                                                </div>
                                            )}
                                    </>
                                )}

                                <div>Email: {invoiceDetails.invoiceUser[0].email}</div>

                                <div>Phone: {invoiceDetails.invoiceUser[0].phone}</div>
                            </div>
                        ) : null}
                    </div>
                    {/* {invoiceDetails.cartValues.cart_payment ? (
                        <>
                            <h4 className="invoiceSubTitle">
                                Payment Type:{' '}
                                {invoiceDetails.cartValues.cart_payment === 'online' ? (
                                    <>{'online payment'}</>
                                ) : invoiceDetails.cartValues.cart_payment === 'offline' ? (
                                    <>{'offline payment'}</>
                                ) : invoiceDetails.cartValues.cart_payment === 'deposit' ? (
                                    <>{'deposit'}</>
                                ) : null}
                            </h4>
                        </>
                    ) : null} */}
                    {invoiceDetails.invoiceProducts[0]?.delivery_type == 'shipping' && (
                        <>
                            <h4 className="invoiceSubTitle">{'Shipping details'}: </h4>
                            <div>
                                Shipping Carrier:{' '}
                                {invoiceDetails.invoiceProducts[0]
                                    .custom_projects_engine_carrier_code
                                    ? invoiceDetails.invoiceProducts[0].custom_projects_engine_carrier_code.toUpperCase()
                                    : ''}
                            </div>
                            <div>
                                Shipping Service:{' '}
                                {capitalize(
                                    invoiceDetails.invoiceProducts[0].shipping_service_type
                                        ? invoiceDetails.invoiceProducts[0].shipping_service_type
                                        : 'None',
                                )}
                            </div>
                        </>
                    )}
                    {invoiceDetails?.appointmentDetail?.appointmentTime2 ||
                    invoiceDetails?.appointmentDetail?.appointmentTime ? (
                        <>
                            <h4 className="invoiceSubTitle">
                                {'Appointment Scheduled at'}:{' '}
                                {invoiceDetails?.appointmentDetail?.appointmentTime2
                                    ? convertToCustomFormat(
                                          invoiceDetails?.appointmentDetail?.appointmentTime2,
                                      )
                                    : `${dateTimeFormatFunction(
                                          invoiceDetails.appointmentDetail.appointmentTime,
                                          true,
                                      )}`}
                                {' (Utah Time)'}
                            </h4>
                        </>
                    ) : null}
                    {invoiceDetails.invoiceTransactions.length ? (
                        <>
                            <h4 className="invoiceSubTitle">{'Transaction Details'}</h4>
                            <div className="transDet d-flex justify-content-between flex-wrap">
                                <>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                {/* <th scope="col">S.No</th> */}
                                                <th scope="col">{'Transaction ID'}</th>
                                                <th scope="col">{'Paid On'}</th>
                                                <th scope="col">{'Method'}</th>
                                                <th scope="col">{'Amount'}</th>
                                                <th scope="col">{'Status'}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceDetails.invoiceTransactions.map(
                                                (data, index) => (
                                                    <tr key={index}>
                                                        <td
                                                            data-title={'Transaction ID'}
                                                            className="transcationIdCnt"
                                                        >
                                                            {data.trans_id
                                                                ? `# ${data.trans_id}`
                                                                : '-'}
                                                        </td>
                                                        <td data-title={'Paid On'}>
                                                            {dateTimeFormatFunction(
                                                                data.created_at,
                                                            )}
                                                        </td>
                                                        <td data-title={'Card'}>
                                                            {data.card_type == 'offline' &&
                                                            data.payment_method.toLowerCase() ==
                                                                'cash'
                                                                ? 'Manual'
                                                                : capitalize(data.payment_method)}
                                                        </td>
                                                        <td data-title={'Amount'}>
                                                            {currencyFormat(data.amount)}
                                                        </td>
                                                        <td
                                                            data-title={'Payment Status'}
                                                            style={{
                                                                color:
                                                                    data.status === 'success'
                                                                        ? 'green'
                                                                        : 'red',
                                                            }}
                                                        >
                                                            {capitalize(data.status)}
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    </table>
                                </>
                            </div>
                        </>
                    ) : null}
                    <h4 className="invoiceSubTitle mt-5">{'Product Details'}</h4>

                    <div className="table-responsive">
                        {invoiceDetails.invoiceProducts.length ? (
                            <div className="cartProducts">
                                <div>
                                    {invoiceDetails.invoiceProducts.map((data, index) => (
                                        <CartItem data={data} from="invoice" />
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-clear nellisInvTot">
                                    <tbody>
                                        <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">{'Items'}</span>
                                            </td>
                                            <td className="right">
                                                <span className="text-dark">
                                                    {invoiceDetails.invoiceProducts.length}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">{'Bid Amount'}</span>
                                            </td>
                                            <td className="right">
                                                <span className="text-dark">
                                                    {currencyFormat(
                                                        invoiceDetails.cartValues.per_total,
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">{'Sales Tax'}</span>
                                            </td>
                                            <td className="right">
                                                <span className="text-dark">
                                                    {`US $${parseFloat(
                                                        invoiceDetails.cartValues.total_tax,
                                                    ).toFixed(2)}`}
                                                </span>
                                            </td>
                                        </tr>
                                        {invoiceDetails.cartValues.total_shipping ? (
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {'Total Shipping'}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues
                                                                .total_shipping,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                        ) : null}
                                        <tr>
                                            <td className="left" align="right">
                                                <span className="text-dark">{'Total Amount'}</span>
                                            </td>
                                            <td className="right">
                                                <span className="text-dark">
                                                    {currencyFormat(
                                                        invoiceDetails.cartValues.total_amount,
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                        {invoiceDetails.cartValues.total_deposit ? (
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {'Bid Deposit'}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        -{' '}
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.total_deposit,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                        ) : null}

                                        {invoiceDetails.cartValues.total_due_amount &&
                                        invoiceDetails.cartValues.total_paid ? (
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {'Total Paid'}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.total_paid,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                        ) : null}
                                        {invoiceDetails.cartValues.total_due_amount &&
                                        invoiceDetails.cartValues.total_paid ? (
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {'Pending Amount'}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues
                                                                .total_due_amount,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4 className="thankText">{'Thank You for Shopping With Us'}</h4>
        </div>
    )
}

export default Invoice
