import React, { useState, useContext, useEffect } from 'react'
import Lottie from 'react-lottie'
import queryString from 'query-string'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../product/common/components'
import PayContext from '../../product/context/payment/payContext'
import AuthContext from '../../product/context/auth/authContext'
// import animationData from '../../assets/lottie/paymentAnimation.json'
// import successAnimation from '../../assets/lottie/successAnimation.json'
// import failureAnimation from '../../assets/lottie/failureAnimation.json'
import CustomDialog from '../../custom/components/organisms/Dialog'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'

const PaymentSuccess = (props) => {
    const authContext = useContext(AuthContext)
    const payContext = useContext(PayContext)

    const [paymentResponse, setPaymentResponse] = useState(null)
    const {
        payCartStripeResponse,
        responseStatus: responseStatusPayment,
        clearResponse: clearResponsePayment,
    } = payContext

    const [toggleDialogDeposit, setToggleDialogDeposit] = useState(false)

    const changeDialogStatusDeposit = () => {
        setToggleDialogDeposit(!toggleDialogDeposit)
    }

    const { isAuthenticated, loadUser } = authContext
    const history = useHistory()
    // const {
    //     responseCartPayTm,
    //     responseStatus: responseStatusPayment,
    //     clearResponse: clearResponsePayment,
    // } = payContext

    useEffect(() => {
        if (props.location.search) {
            const response = queryString.parse(props.location.search)
            setPaymentResponse(null)
            console.log(response, 'what response')

            payCartStripeResponse(response)
            history.replace({
                search: '',
            })
        }
    }, [props.location.search])

    // useEffect(() => {
    //     if (responseStatusPayment) {
    //         if (responseStatusPayment.from === 'paytmResponse') {
    //             setPaymentResponse(responseStatusPayment)
    //             clearResponsePayment()
    //             setToggleDialogDeposit(true)
    //             loadUser()
    //         }
    //     }
    // }, [responseStatusPayment])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        // animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const paymentSuccessOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        // animationData: successAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const paymentFailureOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        // animationData: failureAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment_response') {
                setPaymentResponse(responseStatusPayment)
                clearResponsePayment()
                setToggleDialogDeposit(true)
            }
        }
    }, [responseStatusPayment])

    const redirectFunction = () => {
        if (paymentResponse.status === 'success') {
            const redirect_url =
                paymentResponse.data.redirect ||
                `appointment/schedule/${paymentResponse.data.common_invoice}`
            handleRedirectInternal(history, `${redirect_url}`)
        } else {
            const redirectPage = paymentResponse?.to === 'buynow' ? 'buynowcart' : 'cart'
            handleRedirectInternal(history, redirectPage)
        }
    }
    return (
        <div className="container">
            <div className="paymentResponse">
                <Lottie options={defaultOptions} speed="0.25" height={300} width={400} />
                <h2>{'Hang in there your payment is being processed'}</h2>
                <h4>{'Please do not press the back button or refresh the page'}</h4>
            </div>
            {paymentResponse && paymentResponse.status ? (
                <CustomDialog
                    title="Payment Status"
                    disableBackdropClick
                    open={toggleDialogDeposit}
                    className="paymentNotifCnt"
                    function={changeDialogStatusDeposit}
                >
                    {paymentResponse.status === 'success' ? (
                        <div className="paymentNotif">
                            <Lottie
                                options={paymentSuccessOptions}
                                speed="0.25"
                                height="auto"
                                width={200}
                            />
                            <div className="paymentContent">
                                <span className="material-icons-outlined ico">check_circle</span>
                                <h4>Payment Success!</h4>
                                {/* <h5>
                                    You have successfully made a purchase of{' '}
                                    {currencyFormat(rosoomResponse.serviceCost)}.
                                </h5> */}
                                <PrimaryButton btnSize="small" onClick={() => redirectFunction()}>
                                    Continue
                                </PrimaryButton>
                            </div>
                        </div>
                    ) : (
                        <div className="paymentNotif failure">
                            <Lottie
                                options={paymentFailureOptions}
                                speed="0.25"
                                height="auto"
                                width={200}
                            />
                            <div className="paymentContent">
                                <span className="material-icons-outlined ico">
                                    report_gmailerrorred
                                </span>
                                <h4>Payment Failed</h4>
                                {/* <h5>{paymentResponse.message ? paymentResponse.message : ''}</h5> */}
                                <PrimaryButton btnSize="small" onClick={() => redirectFunction()}>
                                    Try Again
                                </PrimaryButton>
                            </div>
                        </div>
                    )}
                </CustomDialog>
            ) : null}
        </div>
    )
}

export default PaymentSuccess
