import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import { Button, Drawer } from '@material-ui/core'
import './Home.css'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../product/context/auth/authContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import { withStyles } from '@material-ui/core/styles'
import CommonContext from '../../product/context/common/commonContext'
import AuctionContext from '../../product/context/auction/auctionContext'
import AlertContext from '../../product/context/alert/alertContext'
import ProductContext from '../../product/context/product/productContext'
import BuynowContext from '../../product/context/buynow/buynowContext'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import { makeStyles } from '@material-ui/core/styles'
import openSocket from 'socket.io-client'
import Slider from 'react-slick'
import { handleRedirectInternal } from '../../product/common/components'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import { Pagination } from '@material-ui/lab'
import AuctionCard from '../../custom/components/molecules/ProductCard/AuctionCard'
import ProductView from '../../custom/components/organisms/ProductView'
import { messageHandler } from '../../product/common/socketHandler'
import { useTranslation } from 'react-i18next'
import NotificationBanner from '../../custom/components/molecules/NotificationBanner'

import moment from 'moment'
import Loaders from '../../custom/components/molecules/Loaders'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import TertiaryButton from '../../custom/components/atoms/TertiaryButton'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Banner from '../../custom/components/molecules/Banner'
import Popup from '../../custom/components/organisms/Popup'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '10px',
    },
}))(MuiAccordionDetails)
const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

function Home() {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const auctionContext = useContext(AuctionContext)
    const alertContext = useContext(AlertContext)
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const productContext = useContext(ProductContext)
    const { i18n } = useTranslation()
    const { user, isAuthenticated, saveSearch, callEnd } = authContext
    const {
        search_allauctions,
        getAllAuctionProducts,
        responseStatus: responseStatusAuction,
    } = auctionContext
    const { setAlert } = alertContext
    const [faq, setFaq] = useState([])

    const { search_allproducts, getAllSearchProducts, responseStatus } = productContext
    const {
        loginModal,
        setLoginModal,
        signupModal,
        setSignupModal,
        allCategory,
        allfaq,
    } = customCommonContext
    const { setSearchValue, searchValue, clearSearchValue } = productCommonContext

    const [isLoading, setIsLoading] = useState(false)
    let [viewProduct, setViewProduct] = useState([])

    const [isMostViewedLoading, setIsMostViewedLoading] = useState(false)
    let [viewMostViewedProduct, setViewMostViewedProduct] = useState([])

    const [isMostBidLoading, setIsMostBidLoading] = useState(false)
    let [viewMostBidProduct, setViewMostBidProduct] = useState([])

    let [auctions, setAuctions] = useState([])
    const [expanded, setExpanded] = useState()

    const [previousSlideIndex, setPreviousSlideIndex] = useState(0)
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
    const [nextSlideIndex, setNextSlideIndex] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [swiped, setSwiped] = useState(false)
    const [errorPopup, setErrorPopup] = useState(false)
    const [biddingErrorMessage, setBiddingErrorMessage] = useState('')

    const { responseStatus: buynowResponse, clearResponse: clearBuynowResponse } = useContext(
        BuynowContext,
    )

    useEffect(() => {
        if (allfaq && allfaq.length) {
            const data = allfaq
                .filter((faq) => faq.active === 1)
                .map((faq) => ({ title: faq.question, details: faq.description }))
            setFaq(data)
        }
    }, [allfaq])
    //PRODUCT SLIDER
    const classes = useStyles()

    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })

    const [searchAuction, setSearchAuction] = useState({
        limit: 12,
        page: 1,
        orderby: 'ac.id, desc',
    })
    const getStarted = () => {
        if (isAuthenticated) {
            handleRedirectInternal(history, 'auction')
        } else {
            setSignupModal(true)
        }
    }

    useEffect(() => {
        //Set date 2 days back from current date
        let date = moment(new Date()).subtract(2, 'days')
        let lastTwoDaysDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
        let searchObjRecentProduct = {
            limit: 12,
            page: 1,
            orderby: 'p.date_added, desc',
            order: '',
            filters: {
                date_added: {
                    value: lastTwoDaysDate,
                    type: 'datetimegreaterequal',
                    field: 'p.date_added',
                },
            },
        }
        getAllSearchProducts(searchObjRecentProduct, 'home')

        // let searchObjViewedProduct = {
        //     limit: 12,
        //     page: 1,
        //     orderby: 'product_view_counts, desc',
        //     order: '',
        //     filterView: true,
        //     having: {
        //         product_view: {
        //             value: '0',
        //             type: 'notin',
        //             field: 'product_view_counts',
        //         },
        //     },
        // }
        // getAllSearchProducts(searchObjViewedProduct, 'viewed', 'custom/')

        // let searchObjBidsProduct = {
        //     limit: 12,
        //     page: 1,
        //     orderby: 'bid_count, desc',
        //     order: '',
        //     filterBid: true,
        //     having: {
        //         bid_count: {
        //             value: '0',
        //             type: 'notin',
        //             field: 'bid_count',
        //         },
        //     },
        // }
        // getAllSearchProducts(searchObjBidsProduct, 'bids', 'custom/')

        getAllAuctionProducts(searchAuction, 'home')

        setIsLoading(false)
        setIsMostViewedLoading(false)
        setIsMostBidLoading(false)
    }, [callEnd, searchAuction.page])

    useEffect(() => {
        setIsLoading(true)
        setIsMostViewedLoading(true)
        setIsMostBidLoading(true)
    }, [])

    const onHandlePageAuction = (event, value) => {
        setSearchAuction({ ...searchAuction, page: value })
    }

    useEffect(() => {
        if (search_allproducts.from === 'home') {
            setViewProduct(search_allproducts.records.length ? search_allproducts.records : [])
            setTimeout(() => {
                setIsLoading(false)
            }, 1500)
        }
        if (search_allproducts.from === 'viewed') {
            setViewMostViewedProduct(
                search_allproducts.records.length ? search_allproducts.records : [],
            )
            setTimeout(() => {
                setIsMostViewedLoading(false)
            }, 1500)
        }

        if (search_allproducts.from === 'bids') {
            setViewMostBidProduct(
                search_allproducts.records.length ? search_allproducts.records : [],
            )
            setTimeout(() => {
                setIsMostBidLoading(false)
            }, 1500)
        }
    }, [search_allproducts])

    useEffect(() => {
        if (search_allauctions.from === 'home') {
            setAuctions(search_allauctions.records.length ? search_allauctions.records : [])
        }
    }, [search_allauctions])

    useEffect(() => {
        setViewProduct([])
        setIsMostViewedLoading([])
        setViewMostBidProduct([])
        return () => {
            setViewProduct([])
            setIsMostViewedLoading([])
            setViewMostBidProduct([])
        }
    }, [])

    const viewProductRef = useRef(viewProduct)
    const viewMostViewedProductRef = useRef(viewMostViewedProduct)
    const viewMostBidProductRef = useRef(viewMostBidProduct)

    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        viewMostViewedProductRef.current = viewMostViewedProduct
        viewMostBidProductRef.current = viewMostBidProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )

        messageHandler(
            message,
            viewMostViewedProductRef.current,
            userRef.current,
            setAlert,
            setViewMostViewedProduct,
            type,
        )

        messageHandler(
            message,
            viewMostBidProductRef.current,
            userRef.current,
            setAlert,
            setViewMostBidProduct,
            type,
        )
    }
    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (
                data.status === 'failed' &&
                data.error?.indexOf('suspended') !== -1 &&
                userRef?.current.id == data.bpop_cbidder
            ) {
                setErrorPopup(true)
                setBiddingErrorMessage(data.error)
            } else {
                handler(data, 'bidAddtime')
            }
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (buynowResponse) {
            if (buynowResponse.from === 'addToCart' && buynowResponse.status === 'error') {
                setErrorPopup(true)
                setBiddingErrorMessage(buynowResponse.message)
            }
            clearBuynowResponse()
        }
    }, [buynowResponse])

    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    // SEARCH
    const getSearchBarObjet = (value) => {
        return {
            filters: {
                searchbar: {
                    value: value,
                },
            },
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter' && searchText != '') {
            await setSearchValue(getSearchBarObjet(searchText))
            handleRedirectInternal(history, 'search')
            // history.push({
            //     pathname: '/search',
            //     state: searchText,
            // })
        }
    }

    const handleSearch = async () => {
        if (searchText != '') {
            await setSearchValue(getSearchBarObjet(searchText))
            handleRedirectInternal(history, 'search')
            // history.push({
            //     pathname: '/search',
            //     state: searchText,
            // })
        }
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const updateFunction = () => {
        let date = moment(new Date()).subtract(2, 'days')
        let lastTwoDaysDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
        let searchObjRecentProduct = {
            limit: 12,
            page: 1,
            orderby: 'p.date_added, desc',
            order: '',
            filters: {
                date_added: {
                    value: lastTwoDaysDate,
                    type: 'datetimegreaterequal',
                    field: 'p.date_added',
                },
            },
        }
        getAllSearchProducts(searchObjRecentProduct, 'home')
        getAllAuctionProducts(searchAuction, 'home')
    }

    useEffect(() => {
        if (responseStatusAuction) {
            if (responseStatusAuction.from === 'updateAuctionTerms') {
                if (responseStatusAuction.status === 'success') {
                    updateFunction()
                }
            }
        }
    }, [responseStatusAuction])

    return (
        <div className="home">
            <section className="heroBanner">
                <NotificationBanner />
                <Banner />
            </section>
            {/* <section className="homeBanner homeMarginBottom">
                <img
                    src="./assets/images/banner.webp"
                    onError={(e) => (e.src = './assets/images/banner.png')}
                    alt="banner"
                />
                <div className="bannerContent">
                    <h3>nps store</h3>
                    <h1>AUCTIONS</h1>
                    <SecondaryButton onClick={getStarted} label="Get Started" />
                </div>
            </section> */}
            <section className="homeRecentlyListed homeMarginBottom customContainer">
                <h2 className="homeTitle">RECENTLY LISTED LOTS</h2>

                {viewProduct.length ? (
                    <>
                        <div className="recentItems">
                            {isLoading ? (
                                <Loaders name="product_grid_view" isLoading={isLoading} />
                            ) : (
                                viewProduct.map((data, index) => (
                                    <div key={index}>
                                        <GridView
                                            key={index}
                                            data={data}
                                            favId={`searchProd_${index}`}
                                            drawerHandler={toggleDrawer('right', true, data)}
                                        />
                                    </div>
                                ))
                            )}
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </section>

            {/* <section className="homeRecentlyListed homeMarginBottom customContainer">
                <h2 className="homeTitle">MOST VIEWED</h2>
                {viewMostViewedProduct.length ? (
                    <>
                        <div className="recentItems">
                            {isMostViewedLoading ? (
                                <Loaders name="product_grid_view" isLoading={isMostViewedLoading} />
                            ) : (
                                viewMostViewedProduct.map((data, index) => (
                                    <div key={index}>
                                        <GridView
                                            key={index}
                                            data={data}
                                            favId={`searchProd_${index}`}
                                            drawerHandler={toggleDrawer('right', true, data)}
                                        />
                                    </div>
                                ))
                            )}
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </section>

            <section className="homeRecentlyListed homeMarginBottom customContainer">
                <h2 className="homeTitle">MOST BIDS PLACED</h2>
                {viewMostBidProduct.length ? (
                    <>
                        <div className="recentItems">
                            {isMostBidLoading ? (
                                <Loaders name="product_grid_view" isLoading={isMostBidLoading} />
                            ) : (
                                viewMostBidProduct.map((data, index) => (
                                    <div key={index}>
                                        <GridView
                                            key={index}
                                            data={data}
                                            favId={`searchProd_${index}`}
                                            drawerHandler={toggleDrawer('right', true, data)}
                                        />
                                    </div>
                                ))
                            )}
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </section> */}

            <>
                <Drawer
                    className="rightDrawer"
                    anchor={'right'}
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                >
                    <ProductView
                        data={state.data}
                        onClick={() => setState(false)}
                        updateFunction={updateFunction}
                    />
                </Drawer>

                <Popup open={errorPopup} size="md" handleClose={() => setErrorPopup(false)}>
                    <>
                        <h4 className="suspended-error">
                            {biddingErrorMessage}
                            <br />
                            Please contact customer service
                        </h4>
                    </>
                </Popup>
            </>
            <section className="homeHowItWorks homeMarginBottom customContainer">
                <h2 className="homeTitle">How to get started</h2>

                <div className="hiwOdd">
                    <div className="hiwLt">
                        <img
                            src="./assets/images/hiw1.webp"
                            onError={(e) => (e.src = './assets/images/hiw1.png')}
                            alt="hiw"
                        />
                        <span className="hiwCounter">1</span>
                    </div>
                    <div className="hiwRt">
                        <h3>Create a FREE Account</h3>
                        <p>
                            Creating an account is easy, simply click, “Get Started” then put in
                            your email and a password then press, “Create My Account” You will then
                            receive an email to confirm your account, this will redirect you back to
                            login. You now have an account!
                        </p>
                        <TertiaryButton label="Get started" onClick={getStarted} />
                    </div>
                </div>

                <div className="hiwEven">
                    <div className="hiwRt">
                        <h3>Place Your Bid</h3>
                        <p>
                            Take a look around and once you find your hidden treasure simply place a
                            bid. Some auctions will be automatic bidding and others will be hard
                            bids. Automatic bidding is where you put in the highest price
                            you&apos;re willing to pay and it will only go to that amount if another
                            bidder bids it up. Hard bids will immediately take the bid to that
                            amount. Good luck and Happy Bidding!
                        </p>
                        <TertiaryButton label="Get started" onClick={getStarted} />
                    </div>
                    <div className="hiwLt">
                        <img
                            src="./assets/images/hiw2.webp"
                            onError={(e) => (e.src = './assets/images/hiw2.png')}
                            alt="hiw"
                        />
                        <span className="hiwCounter">2</span>
                    </div>
                </div>

                <div className="hiwOdd">
                    <div className="hiwLt">
                        <img
                            src="./assets/images/hiw3.webp"
                            onError={(e) => (e.src = './assets/images/hiw3.png')}
                            alt="hiw"
                        />
                        <span className="hiwCounter">3</span>
                    </div>
                    <div className="hiwRt">
                        <h3>Pickup/Receive Your Items</h3>
                        <p>
                            We have a lot of different locations where we hold auctions and most
                            have local pickup options and shipping options. When you are bidding on
                            a lot it will inform you if it is local pickup only, shipping only or
                            both. If you win an auction you will be notified and depending on the
                            items you can have them shipped or schedule a pickup.
                        </p>
                        <TertiaryButton label="Get started" onClick={getStarted} />
                    </div>
                </div>
            </section>

            <section className="homeRecentlyListed homeMarginBottom customContainer">
                <h2 className="homeTitle">Live auctions</h2>
                {auctions.length ? (
                    <div className="homeSpecialItems">
                        {isLoading ? (
                            <Loaders name="product_list_view" isLoading={isLoading} />
                        ) : (
                            auctions.map((data, index) => <AuctionCard key={data.id} data={data} />)
                        )}

                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper col-md-12">
                            <h6>
                                {'Showing'} {search_allauctions.setDisp} {'of'}{' '}
                                {search_allauctions.totalRecords}
                            </h6>
                            <Pagination
                                count={Math.ceil(
                                    search_allauctions.totalRecords / searchAuction.limit,
                                )}
                                page={searchAuction.page}
                                onChange={onHandlePageAuction}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    </div>
                ) : (
                    <NoRecordsFound />
                )}
            </section>

            <section className="homeFAQ homeMarginBottom customContainer">
                <h2 className="homeTitle">Frequently asked questions</h2>

                {faq.map((data, index) => (
                    <Accordion
                        square
                        expanded={expanded === `panel${index}`}
                        key={index}
                        onChange={handleChange(`panel${index}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            id={`panel${index}d-header`}
                        >
                            <h6 className="accTitle">{data.title}</h6>
                        </AccordionSummary>
                        <AccordionDetails>{data.details}</AccordionDetails>
                    </Accordion>
                ))}
            </section>
        </div>
    )
}

export default Home
