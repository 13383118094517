import React, { useState, useEffect, useContext, useRef } from 'react'
import CommonContext from '../../product/context/common/commonContext'
import { useDropzone } from 'react-dropzone'
import Loaders from '../../custom/components/molecules/Loaders'
import CustomDialog from '../../product/components/organisms/Dialog'
import { Badge, Button, Dialog } from '@material-ui/core'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { mapData } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import SecondaryButton from '../components/atoms/SecondaryButton'
import AlertContext from '../context/alert/alertContext'
import move from 'lodash-move'
import Reorder, {
    reorder,
    reorderImmutable,
    reorderFromTo,
    reorderFromToImmutable,
} from 'react-reorder'
import { useTranslation } from 'react-i18next'
import ImageCropper from './cropper/imageCropper'
import Webcam from 'react-webcam'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

let randomVariable = Math.random()
const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
}

const Uploader = (props) => {
    let objectImage = props.objectImage === 1 ? true : false
    let disabled = props.disabled ? props.disabled : false
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [editImageModal, setEditImageModal] = useState(false)
    const [editId, setEditId] = useState(null)
    const [editName, setEditName] = useState(null)
    const [currImg, setCurrImg] = useState(null)
    const [cropLoader, setCropLoader] = useState(false)
    const [editImageState, setEditImageState] = useState(false)
    const [capturePicture, setCapturePicture] = useState(false)
    const [selectCropEdit, setSelectCropEdit] = useState(false)

    const commonContext = useContext(CommonContext)
    const alertContext = useContext(AlertContext)
    const {
        uploadImage,
        uploadedFiles,
        resetDropZone,
        imageBaseValue,
        getImageBase,
    } = commonContext
    const { setAlert } = alertContext
    const formik = props.formik

    const { t } = useTranslation()
    const [toggleDialog, setToggleDialog] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }
    const [picture, setPicture] = useState('')
    const [scanToggle, setScanToggle] = useState(false)
    const webcamRef = React.useRef(null)
    const capture = React.useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot({
            width: 1280,
            height: 720,
        })
        setPicture(pictureSrc)
    })

    const closeFunction = () => {
        setCapturePicture(false)
    }

    const urltoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        return new File([u8arr], filename, { type: mime })
    }

    const auctionAvatar = useDropzone({
        accept: props.accept,
        multiple: props.multiple,
        onDrop: (acceptedFiles) => {
            if (!acceptedFiles.length) {
                setIsLoading(false)
                setAlert(
                    t('Something went wrong! Please make sure you upload the expected file type'),
                    'error',
                )
            } else {
                setIsLoading(true)
                let arr = acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                )
                if (props?.getBlobUrl) {
                    props.getBlobUrl(acceptedFiles[0])
                }
                var formData = new FormData()
                formData.append('folder', props.folder)
                formData.append('isCompress', props.isCompress)
                formData.append('quality', props.quality)
                formData.append('resultWidth', props.resultWidth)
                formData.append('resultHeight', props.resultHeight)

                arr.forEach((value, key) => {
                    formData.append('file_upload', value)
                })
                uploadImage(formData, props.name)
            }
        },
    })

    useEffect(() => {
        if (props.name === uploadedFiles.from && uploadedFiles.files.length) {
            if (props.multiple) {
                if (objectImage) {
                    let uploadedImage = []
                    uploadedFiles.files &&
                        uploadedFiles.files.map((uploaded) => {
                            uploadedImage.push({
                                filename: uploaded.file_name,
                                title: '',
                                description: '',
                            })
                        })
                    formik.setFieldValue(props.name, [
                        ...formik.values[props.name],
                        ...uploadedImage,
                    ])
                } else {
                    let uploadedImage = []
                    uploadedFiles.files &&
                        uploadedFiles.files.map((uploaded) => {
                            uploadedImage.push(uploaded.file_name)
                        })
                    formik.setFieldValue(props.name, [
                        ...formik.values[props.name],
                        ...uploadedImage,
                    ])
                }
            } else {
                if (objectImage) {
                    uploadedFiles.files &&
                        uploadedFiles.files.map((uploaded) =>
                            formik.setFieldValue(props.name, [
                                { filename: uploaded.file_name, title: '', description: '' },
                            ]),
                        )
                } else {
                    uploadedFiles.files &&
                        uploadedFiles.files.map((uploaded) =>
                            formik.setFieldValue(props.name, [uploaded.file_name]),
                        )
                }
            }
            setIsLoading(false)
            setReload(!reload)
            resetDropZone(uploadedFiles.from)
        }
    }, [uploadedFiles, editId])

    useEffect(() => {
        if (imageBaseValue.from === randomVariable) {
            if (imageBaseValue?.record && selectCropEdit) {
                let convBase =
                    `data:image/${
                        getFileExtension(formik?.values[props?.name][editId]) || 'png'
                    };base64,` + imageBaseValue?.record
                setCurrImg(convBase)
            }
        }
        // return () => {
        //     setSelectCropEdit(false)
        // }
    }, [imageBaseValue?.record, selectCropEdit])

    useEffect(() => {
        if (props.webcam && picture != '' && picture !== null) {
            const fileConverted = urltoFile(picture, 'test.jpg', 'image/jpeg')
            var formData = new FormData()
            formData.append('folder', props.folder)
            formData.append('isCompress', props.isCompress)
            formData.append('quality', props.quality || '80')
            formData.append('resultWidth', props.resultWidth || '500')
            formData.append('resultHeight', props.resultHeight || '500')
            formData.append('file_upload', fileConverted)
            uploadImage(formData, props.name)
            setCapturePicture(false)
            setPicture('')

            // uploadImage(formData, props.name)
        }
    }, [picture])

    const getFileExtension = (file) => {
        let fileExtent = /[.]/.exec(file) ? /[^.]+$/.exec(file) : undefined
        if (fileExtent && fileExtent.length) {
            return fileExtent[0]
        } else {
            return ''
        }
    }

    const removeFile = (file, from) => {
        if (props.name === from) {
            let allFiles = formik.values[props.name].filter((fileUploaded) =>
                typeof fileUploaded == 'object'
                    ? fileUploaded.filename !== file.filename
                    : fileUploaded !== file,
            )
            formik.setFieldValue(props.name, allFiles)
            setReload(!reload)
        }
    }

    const viewFile = (file, from) => {
        if (props.name === from) {
            const newWindow = window.open(
                isValidHttpUrl(typeof file === 'object' ? file.filename : file)
                    ? typeof file === 'object'
                        ? file.filename
                        : file
                    : `${global.site_url}/uploads/${props.folder}/${
                          typeof file === 'object' ? file.filename : file
                      }`,
                '_blank',
                'noopener,noreferrer',
            )
            if (newWindow) newWindow.opener = null
        }
    }

    const validationArray = Yup.object({
        filename: Yup.string().required('Enter Filename'),
        title: Yup.string().required('Enter Title'),
        description: Yup.string().required('Enter Description'),
    })

    const formikObject = useFormik({
        initialValues: {
            filename: '',
            title: '',
            description: '',
            index: 0,
        },
        validationSchema: validationArray,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (toggleDialog) {
                let index = values.index

                if (typeof formik.values[props.name][index] === 'object') {
                    formik.values[props.name][index].title = values.title
                    formik.values[props.name][index].description = values.description
                } else {
                    formik.values[props.name][index] = {}
                    formik.values[props.name][index].filename = values.filename
                    formik.values[props.name][index].title = values.title
                    formik.values[props.name][index].description = values.description
                }

                setToggleDialog(false)
                formikObject.values.filename = ''
                formikObject.values.title = ''
                formikObject.values.description = ''
            } else {
                setToggleDialog(true)
            }
        },
    })

    const addDetails = (file, from) => {
        if (props.name === from) {
            let index = formik.values[props.name].findIndex((fileUploaded) =>
                typeof fileUploaded === 'object'
                    ? fileUploaded.filename === file
                    : fileUploaded === file,
            )

            let currentValue = formik.values[props.name][index]
            formikObject.setFieldValue('filename', file)
            formikObject.setFieldValue('index', index)

            if (typeof currentValue === 'object') {
                formikObject.setFieldValue('title', currentValue.title || '')
                formikObject.setFieldValue('description', currentValue.description || '')
            }

            setToggleDialog(true)
        }
    }

    const additionalDetails = {
        formik: formikObject,
        data: [
            {
                label: 'Title',
                type: 'text',
                placeholder: 'Enter the title',
                class: 'col-12',
                name: 'title',
            },
            {
                label: 'Description',
                placeholder: 'Enter the description',
                class: 'col-12',
                type: 'textarea',
                name: 'description',
            },
        ],
    }

    const handleScanProduct = () => {
        setScanToggle(true)
    }

    const changeScanDialogStatus = () => {
        setScanToggle(!scanToggle)
    }

    const isValidHttpUrl = (string) => {
        let url

        try {
            url = new URL(string)
        } catch (_) {
            return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
    }

    const onReorder = (e, from, to) => {
        let changedArray = move(formik.values[props.name], from, to)
        formik.setFieldValue([props.name], changedArray)
    }

    const getCroppedImage = (image) => {
        setIsLoading(true)
        let convertedFile = urltoFile(image, formik.values[props.name][editId])
        Object.assign(convertedFile, {
            path: formik.values[props.name][editId],
        })

        var formData = new FormData()
        formData.append('folder', props.folder)
        formData.append('isCompress', props.isCompress)
        formData.append('quality', props.quality || '80')
        formData.append('resultWidth', props.resultWidth || '500')
        formData.append('resultHeight', props.resultHeight || '500')
        formData.append('file_upload', convertedFile)
        uploadImage(formData, props.name)
    }

    const handleImageEditModal = (open, file, index) => {
        setCropLoader(true)
        setEditImageModal(open)
        setEditName(props.name)
        setEditId(index)
        // randomVariable = Math.random()
        setEditImageState(!editImageState)
        setSelectCropEdit(true)

        getImageBase(
            {
                file_folder: props.folder || 'product',
                file_name: file,
            },
            randomVariable,
        )
        setTimeout(() => {
            setCropLoader(false)
        }, 1200)
    }

    return (
        <>
            <div>
                <section className={props.customized_uploader ? 'customizedUploader' : ''}>
                    {props.avatarMode ? (
                        <div
                            {...(!disabled
                                ? auctionAvatar.getRootProps({ className: 'dropzone' })
                                : {})}
                            className="avatarUploader"
                        >
                            <input {...auctionAvatar.getInputProps()} />
                            {formik.values[props.name]?.length ? (
                                formik.values[props.name]?.map((file, index) => (
                                    <div className="thumbAvatar" key={index}>
                                        {typeof file === 'object' ? (
                                            <div className="thumbInner">
                                                {getFileExtension(file.filename) === 'png' ||
                                                getFileExtension(file.filename) === 'jpg' ||
                                                getFileExtension(file.filename) === 'webp' ||
                                                getFileExtension(file.filename) === 'jpeg' ? (
                                                    <div className="thumbCnt">
                                                        <img
                                                            src={
                                                                isValidHttpUrl(file?.filename)
                                                                    ? file?.filename
                                                                    : `${global.site_url}/uploads/${props.folder}/${file.filename}`
                                                            }
                                                            className="img"
                                                        />
                                                        {!disabled && (
                                                            <div className="fileActions">
                                                                <span
                                                                    onClick={() =>
                                                                        removeFile(
                                                                            file.filename,
                                                                            props.name,
                                                                        )
                                                                    }
                                                                    className="cancelBtn material-icons"
                                                                >
                                                                    delete
                                                                </span>
                                                                <span
                                                                    onClick={() =>
                                                                        viewFile(
                                                                            file.filename,
                                                                            props.name,
                                                                        )
                                                                    }
                                                                    className="viewBtn material-icons"
                                                                >
                                                                    visibility
                                                                </span>

                                                                <span
                                                                    onClick={() =>
                                                                        handleImageEditModal(
                                                                            true,
                                                                            file,
                                                                            index,
                                                                        )
                                                                    }
                                                                    className="editBtn material-icons"
                                                                >
                                                                    edit
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <div className="thumbInner">
                                                {getFileExtension(file) === 'png' ||
                                                getFileExtension(file) === 'webp' ||
                                                getFileExtension(file) === 'jpg' ||
                                                getFileExtension(file) === 'jpeg' ? (
                                                    <div className="thumbCnt">
                                                        <img
                                                            src={
                                                                isValidHttpUrl(file)
                                                                    ? file
                                                                    : `${global.site_url}/uploads/${props.folder}/${file}`
                                                            }
                                                            className="img"
                                                        />
                                                        {!disabled && (
                                                            <div className="fileActions">
                                                                <span
                                                                    onClick={(event) => {
                                                                        event.stopPropagation()
                                                                        removeFile(file, props.name)
                                                                    }}
                                                                    className="cancelBtn material-icons"
                                                                >
                                                                    delete
                                                                </span>
                                                                <span
                                                                    onClick={() =>
                                                                        viewFile(file, props.name)
                                                                    }
                                                                    className="viewBtn material-icons"
                                                                >
                                                                    visibility
                                                                </span>

                                                                <span
                                                                    onClick={(event) => {
                                                                        event.stopPropagation()

                                                                        handleImageEditModal(
                                                                            true,
                                                                            file,
                                                                            index,
                                                                        )
                                                                    }}
                                                                    className="editBtn material-icons"
                                                                >
                                                                    edit
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <svg
                                    width="120"
                                    height="120"
                                    viewBox="0 0 200 200"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_4_1005)">
                                        <rect width="200" height="200" rx="100" fill="#F2F2F2" />
                                        <path
                                            d="M78.6667 125.856V126.143C78.5876 132.222 73.4764 136.793 63.3333 139.856C75.5553 148.745 87.778 155.856 100 155.856C112.222 155.856 124.445 148.745 136.667 139.856C126.592 136.789 121.481 132.212 121.333 126.123C121.333 125.037 121.341 123.496 121.349 120.602C121.349 120.142 121.35 119.672 121.352 119.19C122.081 119.293 122.019 119.227 121.352 119.042C121.372 111.429 121.405 101.193 121.45 90.7893C131.005 78.4046 127.495 63.8853 124.501 64.2473C120.831 64.6946 89.0287 34.516 82.9287 32.9686C76.8287 31.4213 61.3333 36.4166 58.6667 50.3333C56 64.25 54.8853 99.3286 65 113.333C67.8782 117.319 72.4116 118.878 78.6 118.01C78.6087 120.923 78.626 122.557 78.6667 125.856Z"
                                            fill="url(#paint0_linear_4_1005)"
                                        />
                                        <path
                                            d="M78.6667 118C93.3334 116.333 102.667 110 102.667 110C102.667 110 92.5734 123.411 78.6667 126V118Z"
                                            fill="#FFBE94"
                                        />
                                        <path
                                            d="M126.333 91C130.927 79.9533 149.333 57.4553 133.467 43.4553C128.133 17.4553 98.6667 20.3333 79 26.3333C65.782 30.366 55.8 38.3333 53.6667 32.3333C40.3333 43.4553 47.0107 54.3333 55.8 57.4553C63.7774 60.2886 77.2667 63.122 100.204 60.4553C104.301 59.9786 103.443 72.5273 105.633 73.884C108.917 75.9193 111.467 63.122 120.472 66.9646C129.477 70.8073 124.133 88.6246 114.467 88.6246C111.133 88.6246 109.467 97.7893 118.467 102.123C125 105.333 123.349 98.178 126.333 91Z"
                                            fill="#C5BFBC"
                                        />
                                        <path
                                            d="M170 160.205C176.347 173.11 180 202.872 180 202.872H20C20 202.872 23.6547 173.107 30 160.205C36.3453 147.304 73.4 135.338 73.4 135.338C80.542 150 119.739 150 126.581 135.333C126.581 135.333 163.653 147.301 170 160.205Z"
                                            fill="#C5BFBC"
                                        />
                                        <mask
                                            id="mask0_4_1005"
                                            style={{ 'mask-type': 'alpha' }}
                                            maskUnits="userSpaceOnUse"
                                            x="20"
                                            y="135"
                                            width="160"
                                            height="68"
                                        >
                                            <path
                                                d="M170 160.205C176.347 173.11 180 202.872 180 202.872H20C20 202.872 23.6547 173.107 30 160.205C36.3453 147.304 73.4 135.338 73.4 135.338C80.542 150 119.739 150 126.581 135.333C126.581 135.333 163.653 147.301 170 160.205Z"
                                                fill="url(#paint1_linear_4_1005)"
                                            />
                                        </mask>
                                        <g mask="url(#mask0_4_1005)">
                                            <path
                                                d="M13.3333 189.333V184H186.667V189.333H13.3333ZM13.3333 176V170.667H186.667V176H13.3333ZM13.3333 162.667V157.333H186.667V162.667H13.3333Z"
                                                fill="#DAD5D2"
                                            />
                                        </g>
                                    </g>
                                    <defs>
                                        <linearGradient
                                            id="paint0_linear_4_1005"
                                            x1="96.7478"
                                            y1="32.698"
                                            x2="96.7478"
                                            y2="155.856"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#FFD4B3" />
                                            <stop offset="1" stopColor="#FFDCC2" />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint1_linear_4_1005"
                                            x1="100"
                                            y1="202.872"
                                            x2="100"
                                            y2="135.333"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#E6864E" />
                                            <stop offset="1" stopColor="#E67240" />
                                        </linearGradient>
                                        <clipPath id="clip0_4_1005">
                                            <rect width="200" height="200" rx="100" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            )}
                        </div>
                    ) : (
                        <div {...auctionAvatar.getRootProps({ className: 'dropzone' })}>
                            <input {...auctionAvatar.getInputProps()} />
                            <span className="material-icons">{props.icon}</span>
                            <h4 className="uploadClass">{props.titleText}</h4>
                            <h6>{props.innerText}</h6>
                        </div>
                    )}
                    <p className="validationError text-left">
                        {formik.touched[props.name] &&
                            formik.errors[props.name] &&
                            formik.errors[props.name]}
                    </p>

                    {props.avatarMode ? null : (
                        <aside className="thumbsContainer">
                            <>
                                {isLoading ? (
                                    <Loaders isLoading={isLoading} />
                                ) : formik.values[props.name] &&
                                  formik.values[props.name].length ? (
                                    <>
                                        {props.reorder ? (
                                            <Reorder
                                                key={Math.random().toString()}
                                                reorderId={Math.random().toString()} // Unique ID that is used internally to track this list (required)
                                                reorderGroup={Math.random().toString()} // A group ID that allows items to be dragged between lists of the same group (optional)
                                                // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
                                                component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                                                className="reorderCnt" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                                                // placeholderClassName="check" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                                                draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                                                // lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                                                // holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                                                touchHoldTime={250} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                                                mouseHoldTime={100} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                                                onReorder={onReorder} // Callback when an item is dropped (you will need this to update your state)
                                                autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                                                disabled={false} // Disable reordering (optional), defaults to false
                                                disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
                                                // placeholder={
                                                //     <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
                                                // }
                                            >
                                                {formik.values[props.name].map((file, index) => (
                                                    <div className="thumb" key={index}>
                                                        <div className="thumbInner">
                                                            {getFileExtension(file) === 'png' ||
                                                            getFileExtension(file) === 'jpg' ||
                                                            getFileExtension(file) === 'webp' ||
                                                            getFileExtension(file) === 'jpeg' ||
                                                            typeof file === 'object' ? (
                                                                <div className="thumbCnt">
                                                                    <Badge>{index + 1}</Badge>
                                                                    {isValidHttpUrl(file) ? (
                                                                        <img
                                                                            src={`${file}`}
                                                                            className="img"
                                                                            id={'thumb_' + index}
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={`${
                                                                                global.site_url
                                                                            }/uploads/${
                                                                                props.isCompress
                                                                                    ? props.folder +
                                                                                      '/compressed'
                                                                                    : props.folder
                                                                            }/${
                                                                                typeof file ===
                                                                                'object'
                                                                                    ? file.filename
                                                                                    : file
                                                                            }`}
                                                                            className="img"
                                                                            id={'thumb_' + index}
                                                                        />
                                                                    )}

                                                                    <div className="fileActions">
                                                                        <span
                                                                            onClick={() =>
                                                                                removeFile(
                                                                                    file,
                                                                                    props.name,
                                                                                )
                                                                            }
                                                                            className="cancelBtn material-icons"
                                                                        >
                                                                            delete
                                                                        </span>
                                                                        <span
                                                                            onClick={() =>
                                                                                viewFile(
                                                                                    file,
                                                                                    props.name,
                                                                                )
                                                                            }
                                                                            className="viewBtn material-icons"
                                                                        >
                                                                            visibility
                                                                        </span>

                                                                        <span
                                                                            onClick={() =>
                                                                                handleImageEditModal(
                                                                                    true,
                                                                                    file,
                                                                                    index,
                                                                                )
                                                                            }
                                                                            className="editBtn material-icons"
                                                                        >
                                                                            edit
                                                                        </span>

                                                                        {objectImage ? (
                                                                            <span
                                                                                onClick={() =>
                                                                                    addDetails(
                                                                                        file.filename,
                                                                                        props.name,
                                                                                    )
                                                                                }
                                                                                className="addBtn material-icons-outlined"
                                                                            >
                                                                                add_circle_outline
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="thumbCnt">
                                                                        <div className="defaultThumb">
                                                                            <span className="material-icons">
                                                                                {getFileExtension(
                                                                                    file,
                                                                                ) === 'pdf'
                                                                                    ? 'picture_as_pdf'
                                                                                    : getFileExtension(
                                                                                          file,
                                                                                      ) === 'doc' ||
                                                                                      getFileExtension(
                                                                                          file,
                                                                                      ) === 'docx'
                                                                                    ? 'description'
                                                                                    : getFileExtension(
                                                                                          file,
                                                                                      ) === 'mp4' ||
                                                                                      getFileExtension(
                                                                                          file,
                                                                                      ) === 'mpeg'
                                                                                    ? 'movie'
                                                                                    : getFileExtension(
                                                                                          file,
                                                                                      ) ===
                                                                                          'mpga' ||
                                                                                      getFileExtension(
                                                                                          file,
                                                                                      ) === 'mp3'
                                                                                    ? 'volume_up'
                                                                                    : 'description'}
                                                                            </span>
                                                                        </div>
                                                                        <div className="fileActions">
                                                                            <span
                                                                                onClick={() =>
                                                                                    removeFile(
                                                                                        file,
                                                                                        props.name,
                                                                                        index,
                                                                                    )
                                                                                }
                                                                                className="cancelBtn material-icons"
                                                                            >
                                                                                delete
                                                                            </span>
                                                                            <span
                                                                                onClick={() =>
                                                                                    viewFile(
                                                                                        file,
                                                                                        props.name,
                                                                                    )
                                                                                }
                                                                                className="viewBtn material-icons"
                                                                            >
                                                                                visibility
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </Reorder>
                                        ) : (
                                            <>
                                                {formik.values[props.name].map((file, index) => (
                                                    <>
                                                        {typeof file === 'object' ? (
                                                            <div className="thumb" key={index}>
                                                                <div className="thumbInner">
                                                                    {getFileExtension(
                                                                        file.filename,
                                                                    ) === 'png' ||
                                                                    getFileExtension(
                                                                        file.filename,
                                                                    ) === 'jpg' ||
                                                                    getFileExtension(
                                                                        file.filename,
                                                                    ) === 'webp' ||
                                                                    getFileExtension(
                                                                        file.filename,
                                                                    ) === 'jpeg' ? (
                                                                        <div className="thumbCnt">
                                                                            <img
                                                                                src={
                                                                                    isValidHttpUrl(
                                                                                        file?.filename,
                                                                                    )
                                                                                        ? file?.filename
                                                                                        : `${global.site_url}/uploads/${props.folder}/${file.filename}`
                                                                                }
                                                                                className="img"
                                                                            />
                                                                            <div className="fileActions">
                                                                                <span
                                                                                    onClick={() =>
                                                                                        removeFile(
                                                                                            file.filename,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="cancelBtn material-icons"
                                                                                >
                                                                                    delete
                                                                                </span>
                                                                                <span
                                                                                    onClick={() =>
                                                                                        viewFile(
                                                                                            file.filename,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="viewBtn material-icons"
                                                                                >
                                                                                    visibility
                                                                                </span>

                                                                                <span
                                                                                    onClick={() =>
                                                                                        handleImageEditModal(
                                                                                            true,
                                                                                            file,
                                                                                            index,
                                                                                        )
                                                                                    }
                                                                                    className="editBtn material-icons"
                                                                                >
                                                                                    edit
                                                                                </span>

                                                                                {objectImage ? (
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            addDetails(
                                                                                                file.filename,
                                                                                                props.name,
                                                                                            )
                                                                                        }
                                                                                        className="addBtn material-icons-outlined"
                                                                                    >
                                                                                        add_circle_outline
                                                                                    </span>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <div className="thumbCnt">
                                                                                <div className="defaultThumb">
                                                                                    <span className="material-icons">
                                                                                        {getFileExtension(
                                                                                            file.filename,
                                                                                        ) === 'pdf'
                                                                                            ? 'picture_as_pdf'
                                                                                            : getFileExtension(
                                                                                                  file.filename,
                                                                                              ) ===
                                                                                                  'doc' ||
                                                                                              getFileExtension(
                                                                                                  file.filename,
                                                                                              ) ===
                                                                                                  'docx'
                                                                                            ? 'description'
                                                                                            : getFileExtension(
                                                                                                  file.filename,
                                                                                              ) ===
                                                                                                  'mp4' ||
                                                                                              getFileExtension(
                                                                                                  file.filename,
                                                                                              ) ===
                                                                                                  'mpeg'
                                                                                            ? 'movie'
                                                                                            : 'description'}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="fileActions">
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            removeFile(
                                                                                                file.filename,
                                                                                                props.name,
                                                                                            )
                                                                                        }
                                                                                        className="cancelBtn material-icons"
                                                                                    >
                                                                                        delete
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            viewFile(
                                                                                                file.filename,
                                                                                                props.name,
                                                                                            )
                                                                                        }
                                                                                        className="viewBtn material-icons"
                                                                                    >
                                                                                        visibility
                                                                                    </span>
                                                                                    {objectImage ? (
                                                                                        <span
                                                                                            onClick={() =>
                                                                                                addDetails(
                                                                                                    file.filename,
                                                                                                    props.name,
                                                                                                )
                                                                                            }
                                                                                            className="addBtn material-icons-outlined"
                                                                                        >
                                                                                            add_circle_outline
                                                                                        </span>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="thumb" key={index}>
                                                                <div className="thumbInner">
                                                                    {getFileExtension(file) ===
                                                                        'png' ||
                                                                    getFileExtension(file) ===
                                                                        'webp' ||
                                                                    getFileExtension(file) ===
                                                                        'jpg' ||
                                                                    getFileExtension(file) ===
                                                                        'jpeg' ? (
                                                                        <div className="thumbCnt">
                                                                            <img
                                                                                src={
                                                                                    isValidHttpUrl(
                                                                                        file,
                                                                                    )
                                                                                        ? file
                                                                                        : `${global.site_url}/uploads/${props.folder}/${file}`
                                                                                }
                                                                                className="img"
                                                                            />
                                                                            <div className="fileActions">
                                                                                <span
                                                                                    onClick={() =>
                                                                                        removeFile(
                                                                                            file,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="cancelBtn material-icons"
                                                                                >
                                                                                    delete
                                                                                </span>
                                                                                <span
                                                                                    onClick={() =>
                                                                                        viewFile(
                                                                                            file,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="viewBtn material-icons"
                                                                                >
                                                                                    visibility
                                                                                </span>

                                                                                <span
                                                                                    onClick={() =>
                                                                                        handleImageEditModal(
                                                                                            true,
                                                                                            file,
                                                                                            index,
                                                                                        )
                                                                                    }
                                                                                    className="editBtn material-icons"
                                                                                >
                                                                                    edit
                                                                                </span>

                                                                                {objectImage ? (
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            addDetails(
                                                                                                file,
                                                                                                props.name,
                                                                                            )
                                                                                        }
                                                                                        className="addBtn material-icons-outlined"
                                                                                    >
                                                                                        add_circle_outline
                                                                                    </span>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <div className="thumbCnt">
                                                                                <div className="defaultThumb">
                                                                                    <span className="material-icons">
                                                                                        {getFileExtension(
                                                                                            file,
                                                                                        ) === 'pdf'
                                                                                            ? 'picture_as_pdf'
                                                                                            : getFileExtension(
                                                                                                  file,
                                                                                              ) ===
                                                                                                  'doc' ||
                                                                                              getFileExtension(
                                                                                                  file,
                                                                                              ) ===
                                                                                                  'docx'
                                                                                            ? 'description'
                                                                                            : getFileExtension(
                                                                                                  file,
                                                                                              ) ===
                                                                                                  'mp4' ||
                                                                                              getFileExtension(
                                                                                                  file,
                                                                                              ) ===
                                                                                                  'mpeg'
                                                                                            ? 'movie'
                                                                                            : 'description'}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="fileActions">
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            removeFile(
                                                                                                file,
                                                                                                props.name,
                                                                                            )
                                                                                        }
                                                                                        className="cancelBtn material-icons"
                                                                                    >
                                                                                        delete
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            viewFile(
                                                                                                file,
                                                                                                props.name,
                                                                                            )
                                                                                        }
                                                                                        className="viewBtn material-icons"
                                                                                    >
                                                                                        visibility
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                ))}
                                            </>
                                        )}
                                    </>
                                ) : null}
                            </>
                        </aside>
                    )}
                </section>
                <div className="camShow">
                    {props.webcam ? (
                        <PrimaryButton
                            id="webcam_id"
                            onClick={() => setCapturePicture(true)}
                            btnSize="small"
                        >
                            <span className="material-icons pr-0">photo_camera</span>
                        </PrimaryButton>
                    ) : null}
                </div>

                <Dialog
                    className="addCaptionModal"
                    open={toggleDialog}
                    onClose={changeDialogStatus}
                    TransitionComponent={Transition}
                >
                    <div>
                        <div className="infoModalTitle">
                            <h4>Add caption details</h4>
                            <Button
                                id="upload_add"
                                className="closeIcon"
                                onClick={changeDialogStatus}
                            >
                                <CloseIcon />
                            </Button>
                        </div>
                        <div className="row">{mapData(additionalDetails)}</div>
                        <div className="btnWrpr">
                            <SecondaryButton
                                id="dialogStatus"
                                onClick={changeDialogStatus}
                                label="Cancel"
                            />
                            <PrimaryButton
                                id="uploader_sub"
                                onClick={formikObject.handleSubmit}
                                type="button"
                                label={'Submit'}
                            />
                        </div>
                    </div>
                </Dialog>

                <CustomDialog
                    title={t('Edit Image')}
                    className="editImageModal"
                    open={editImageModal}
                    maxWidth={'lg'}
                    function={() => setEditImageModal(false)}
                >
                    <div>
                        <ImageCropper
                            imgSrc={currImg}
                            folder={props.folder}
                            loading={cropLoader}
                            editId={editId}
                            getCroppedImage={getCroppedImage}
                            onClose={() => setEditImageModal(false)}
                        />
                    </div>
                </CustomDialog>
            </div>
            <CustomDialog
                title={'Webcam'}
                open={capturePicture}
                maxWidth={'lg'}
                function={changeDialogStatus}
                disableBackdropClick
            >
                <div>
                    {/* <h2 className="mb-5 text-center">React Photo Capture using Webcam Examle</h2> */}
                    <div>
                        {picture == '' ? (
                            <Webcam
                                audio={false}
                                height={120}
                                ref={webcamRef}
                                width={280}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                            />
                        ) : (
                            <img src={picture} />
                        )}
                    </div>
                    <div>
                        <div className="mb-3">
                            {picture != '' ? (
                                <SecondaryButton
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setPicture('')
                                    }}
                                    label="Retake"
                                />
                            ) : (
                                <PrimaryButton
                                    onClick={(e) => {
                                        e.preventDefault()
                                        capture()
                                    }}
                                    label="Capture"
                                />
                            )}
                        </div>
                        <SecondaryButton label="Cancel" onClick={() => closeFunction()} />
                    </div>
                </div>
            </CustomDialog>
        </>
    )
}

export default Uploader
