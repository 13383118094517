import React, { useState, useContext, useEffect } from 'react'
import ProductView from '../../custom/components/organisms/ProductView'

function ProductViewInvite(props) {
    return (
        <div className="productViewInvite">
            <ProductView />
        </div>
    )
}

export default ProductViewInvite
