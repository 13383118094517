import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Drawer, ListItem } from '@material-ui/core'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import './ProductCard.css'
import CommonContext from '../../../../product/context/common/commonContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import AvatarImage from '../../atoms/AvatarImage'
import { dateTimeFormatFunction } from '../../../../product/common/components'
import Timer from '../../../../product/common/timer'
import { dateFormatDay, dateFormatMonth } from '../../../common/components.js'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

function AuctionCard(props) {
    const commonContext = useContext(CommonContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { allLocations } = customCommonContext
    const { setSearchValue } = commonContext
    const [auction, setAuction] = useState({})

    const history = useHistory()
    const classes = useStyles()

    const [state, setState] = useState({
        bottom: false,
    })

    useEffect(() => {
        setAuction(props.data)
    }, [props.data])

    const formik = useFormik({
        initialValues: {
            auctionid: '',
        },
    })

    const auctionRedirect = (id) => {
        if (history?.location?.pathname === '/past-auctions') {
            history.push({
                pathname: '/pastAuction/' + id,
                state: auction,
            })
        } else {
            history.push({
                pathname: '/liveAuction/' + id,
                state: auction,
            })
        }
        window.scrollTo(0, 0)
    }

    const handleRedirectInternal = (path) => {
        history.push(`/${path}`)
        window.scrollTo(0, 0)
    }

    const handleClose = (state) => {
        state(false)
    }

    const handleOpen = (state) => {
        state(true)
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    return (
        <div className="auctionCard d-flex justify-content-between align-items-center">
            <div className="auctionCardLt d-flex">
                <div className="acImg">
                    <AvatarImage data={auction} folder="auction" />

                    <div
                        className="acOverlay cursorDecoy"
                        onClick={() => auctionRedirect(auction.id)}
                    >
                        <span>
                            <h2>{dateFormatDay(auction.date_closed)}</h2>
                            <h4>{dateFormatMonth(auction.date_closed)}</h4>
                        </span>
                        <h5>
                            {history?.location?.pathname === '/past-auctions'
                                ? `${auction?.projects_sold_count} Total Sold Lots/Items`
                                : `${auction?.projects_count} Total Lots/Items`}
                        </h5>
                    </div>
                </div>
                <div className="acContent">
                    <h2 id={`${'list_auctiontitle_' + props.id}`}>{auction.title}</h2>
                    <p
                        className="acAddress"
                        dangerouslySetInnerHTML={{
                            __html: auction.description,
                        }}
                    ></p>
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">today</span>Bidding Starts:
                        </p>
                        <span>{dateTimeFormatFunction(auction.date_added)}</span>
                    </div>
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">event</span>Bidding Starts Closing:
                        </p>
                        <span>
                            {new Date().toISOString() < auction.date_closed
                                ? dateTimeFormatFunction(auction.date_closed)
                                : 'Closing'}
                        </span>
                    </div>
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">place</span>Location:
                        </p>
                        {allLocations
                            .filter((value) => auction.location_id === value.id)
                            .map((location) => (
                                <span>{location.name}</span>
                            ))}
                    </div>
                    <div className="acMiscInfo">
                        <p>
                            <span className="material-icons">gavel</span>Type:
                        </p>
                        <span className="capitalize">{auction.bid_type}</span>
                    </div>
                </div>
            </div>
            <div className="acActBtn">
                <div className="acTimer d-flex justify-content-center align-items-center flex-wrap">
                    <h6>
                        <span className="material-icons">timer</span>
                    </h6>
                    <p>
                        {auction.date_closed ? (
                            <Timer
                                date_added={auction.date_added}
                                date_closed={auction.date_closed}
                                withText={1}
                                endText={
                                    dateTimeFormatFunction(new Date().toISOString()) >
                                    dateTimeFormatFunction(auction.date_closed)
                                        ? 'Started Closing: '
                                        : 'Starts Closing: '
                                }
                                startText={'Starts In' + ':'}
                                closedText="Started Closing"
                            ></Timer>
                        ) : null}
                    </p>
                </div>
                <PrimaryButton
                    onClick={() => auctionRedirect(auction.id)}
                    label="View Auction"
                    btnSize="small"
                    id={'list_viewauction_button_' + props.id}
                />
            </div>
        </div>
    )
}

export default AuctionCard
