import React, { useState, useContext, useEffect, useRef } from 'react'
import '../Dashboard.css'
import DashboardLayout from '../DashboardLayout'
import CheckBox from '../../../product/components/atoms/CheckBox'
import { Button } from '@material-ui/core'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import CustomDialog from '../../../custom/components/organisms/Dialog'
import CustomSelect from '../../../product/components/atoms/Inputs/CustomSelect'
import CustomSearch from '../../../product/components/molecules/CustomSearch'
import AppointmentContext from '../../../product/context/appointment/appointmentContext'
import {
    mapData,
    handleRedirectInternal,
    dateTimeFormatFunction,
    capitalize,
} from '../../../product/common/components'
import AuthContext from '../../../product/context/auth/authContext'
import NoRecordsFound from '../../../product/components/atoms/NoRecordsFound'
import { convertToCustomFormat } from '../../../custom/common/components'

import { useHistory } from 'react-router-dom'
import { Pagination } from '@material-ui/lab'
import { useFormik } from 'formik'
import moment from 'moment'

function MyAppointments() {
    const {
        getAllAppointmentsForUser,
        all_appointments_user,
        appointment_detail_user,
    } = useContext(AppointmentContext)
    const authContext = useContext(AuthContext)

    const { isAuthenticated } = authContext

    const history = useHistory()
    const [toggleDialog, setToggleDialog] = useState(false)
    const [totalPage, setTotalPage] = useState(0)
    const [record, setRecord] = useState([])
    const [currentID, setCurrentID] = useState(0)

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 15,
            show_only: 'all',
            orderby: 'a.id,desc',
            filters: {
                valid: {
                    field: 'a.status',
                    type: 'notin',
                    value: 'open',
                },
                auction: {
                    field: 'ac.title',
                    type: 'like',
                    value: '',
                },
                lot: {
                    field: 'p.title',
                    type: 'like',
                    value: '',
                },
                fromdate: {
                    value: '',
                    type: 'dategreaterequal',
                    field: 'a.appointmentTime',
                },
                todate: {
                    value: '',
                    type: 'datesmallerequal',
                    field: 'a.appointmentTime',
                },
                status: {
                    value: '',
                    type: 'in',
                    field: 'a.status',
                },
            },
            groupby: 'a.id',
        },
        onSubmit: (values) => {
            getAllAppointmentsForUser(values)
        },
    })

    const filterView = [
        { show: 'All', value: 'all' },
        { show: 'Upcoming', value: 'upcoming' },
        { show: 'Past', value: 'past' },
    ]

    const sortShow = [
        {
            value: 'a.id,desc',
            show: 'None',
        },
        {
            value: 'b.common_invoice,asc',
            show: 'Invoice Id',
        },
        {
            value: 'a.appointmentTime,desc',
            show: 'Earliest to Latest',
        },
        {
            value: 'a.appointmentTime,asc',
            show: 'Latest to Earliest',
        },
        {
            value: 'auction_title,asc',
            show: 'Auction Title',
        },
        {
            value: 'a.status,asc',
            show: 'Status',
        },
    ]

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Auction',
                placeholder: 'Enter Auction Title',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'auction',
                filter: true,
            },
            {
                label: 'Lot',
                placeholder: 'Enter Lot Title',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'lot',
                filter: true,
            },
            {
                label: 'From Date',
                placeholder: 'Date Ending from',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'fromdate',
                filter: true,
            },
            {
                label: 'To Date',
                placeholder: 'Date Ending To',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'todate',
                filter: true,
            },
            {
                label: 'Status',
                placeholder: 'Select Status',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'select',
                options: [
                    { show: 'Unfulfilled', value: 'booked' },
                    { show: 'Ready for pickup', value: 'ready_for_pickup' },
                    { show: 'Picked Up', value: 'picked_up' },
                    { show: 'Shipped', value: 'shipped' },
                    { show: 'Partial pickup', value: 'partial_pickup' },
                    { show: 'No show', value: 'no_show' },
                ],
                name: 'status',
                filter: true,
            },
        ],
    }

    useEffect(() => {
        if (isAuthenticated) {
            getAllAppointmentsForUser(formik.values)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            switch (formik.values.show_only) {
                case 'all':
                    formik.values.filters.showOnly = {}
                    break
                case 'upcoming':
                    formik.values.filters.showOnly = {
                        type: 'dategreaterequal',
                        value: moment(moment()).format('MM/DD/YYYY'),
                        field: 'a.appointmentTime',
                    }
                    break
                case 'past':
                    formik.values.filters.showOnly = {
                        type: 'datesmallerequal',
                        value: moment(moment()).format('MM/DD/YYYY'),
                        field: 'a.appointmentTime',
                    }
                    break
                default:
                    formik.values.filters.showOnly = {}
            }
            getAllAppointmentsForUser(formik.values)
        }
    }, [formik.values.show_only, formik.values.orderby])

    useEffect(() => {
        if (all_appointments_user) {
            setRecord(
                all_appointments_user.records?.map((apt) => {
                    apt.status = apt.status === 'booked' ? 'unfulfilled' : apt.status
                    return apt
                }),
            )
            setTotalPage(all_appointments_user.totalRecords)
        }
    }, [all_appointments_user])

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }
    const onHandlePage = async (event, value) => {
        formik.values.page = value
        getAllAppointmentsForUser(formik.values)
    }
    const showAppointmentInfo = (aptId) => {
        getAllAppointmentsForUser(
            {
                ...formik.values,
                groupby: '',
                filters: {
                    ...formik.values.filters,
                    aptId: {
                        type: 'in',
                        field: 'a.id',
                        value: aptId,
                    },
                },
            },
            'appointment_detail',
        )
        setToggleDialog(true)
    }

    return (
        <DashboardLayout title={'My Appointments'}>
            <div className="dashboardInner mySavedSearches">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="searchMiscFilters w-100 mb-3 d-flex justify-content-end align-items-end">
                    <CustomSelect
                        label={'Show Appointments'}
                        value={formik.values.show_only}
                        size="small"
                        selectType="noBorder"
                        name="show_appointments"
                        onChange={(event, editor) => {
                            formik.setFieldValue(`show_only`, event.target.value)
                        }}
                    >
                        {filterView.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        label={'Sort By'}
                        value={formik.values.orderby}
                        size="small"
                        selectType="noBorder"
                        name="orderby"
                        onChange={(event, editor) => {
                            formik.setFieldValue(`orderby`, event.target.value)
                        }}
                    >
                        {sortShow.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                </div>
                {record?.length ? (
                    <>
                        <h5 className="dashSubtitle">
                            {'You can find all your appointments here'}
                        </h5>
                        <div className="table-responsive dpTable">
                            <table className="table text-left text-nowrap">
                                <thead>
                                    <tr>
                                        <th scope="col">{'Date'}</th>
                                        <th scope="col">{'Invoice Id'}</th>
                                        <th scope="col">{'Auction'}</th>
                                        <th scope="col">{'Status'}</th>
                                        <th scope="col">{'Details'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {record.map((data, index) => (
                                        <tr key={`saved_${index}`}>
                                            <td>
                                                {data.appointmentTime2
                                                    ? convertToCustomFormat(data.appointmentTime2)
                                                    : dateTimeFormatFunction(data.appointmentTime)}
                                            </td>
                                            <td>{data.common_invoice}</td>
                                            <td>{data.auction_title}</td>
                                            <td>{capitalize(data.status)}</td>
                                            <td>
                                                <div className="d-flex justify-content-center">
                                                    <Button
                                                        onClick={() => showAppointmentInfo(data.id)}
                                                    >
                                                        <span className="material-icons">info</span>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                count={Math.ceil(totalPage / formik.values.limit)}
                                page={formik.values.page}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>

            <CustomDialog
                title={'Appointment Detail'}
                open={toggleDialog}
                function={changeDialogStatus}
            >
                {/* <h4>Appointment Id: {appointment_detail_user.records?.[0]?.id}</h4> */}
                <table className="table text-left">
                    <thead>
                        <tr>
                            <th scope="col">{'Lot(s)'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appointment_detail_user.records.map((data, idx) => (
                            <tr>
                                <td style={{ wordWrap: 'break-word' }}>{data.project_title}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="actionWrapper">
                    <Button onClick={() => setToggleDialog(false)}>{'Ok'}</Button>
                </div>
            </CustomDialog>
        </DashboardLayout>
    )
}

export default MyAppointments
