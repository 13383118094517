import React, { useState, useRef, useEffect, useContext } from 'react'
import { Button, Fade, Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import './ProductCard.css'
import FavouriteCheckbox from '../../../../product/components/atoms/FavoriteCheckbox'
// import Popup from '../../../../custom/organisms/Popup'
import Timer from '../../../../product/common/timer'
import Buynow from '../Buynow/BuynowItem'
import { dateFormatFront } from '../../../common/components'
import { handleRedirectInternal, currencyFormat } from '../../../common/components'
import CommonContext from '../../../../product/context/common/commonContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Bidding from '../Bidding/BiddingItem'
import UpdatePhoneNumber from './../../organisms/UpdatePhoneNumber/index'
import alertContext from '../../../../product/context/alert/alertContext'
import AvatarImage from '../../atoms/AvatarImage'

const ListView = (props) => {
    const [addCard, setAddCard] = React.useState(false)
    const [product, setProduct] = useState(props.data)
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { user, isAuthenticated } = authContext
    const { setBidHistoryValue } = commonContext
    const { setAlert } = useContext(alertContext)
    const { configVariables, allLocations, allCondition } = customCommonContext
    const [manageuser, setManageUser] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const onMakePaymentClick = (data) => {
        if (user.phone == null) {
            toggleFullScreenUserPopup(true, 'new', null)
            setAlert('Please enter phone number', 'error')
        } else {
            handleRedirectInternal(history, `checkout/auction/${data.group_carts}`)
        }
    }
    const history = useHistory()
    const toggleFullScreenUserPopup = (popup, status, data) => {
        setManageUser({ popup, status, data })
    }
    const toggleModal = (state, action) => {
        setAddCard(!addCard)
    }
    const addDefaultSrc = (e) => {
        e.target.src = '/assets/images/noimage.png'
    }

    const didUserBid = (userID, bidHistory) => {
        let didBid = false
        bidHistory?.forEach((element, index, array) => {
            if (element.user_id === userID) {
                didBid = true
            }
        })
        return didBid
    }
    const productBiddingStatus = (userInfo, productInfo) => {
        if (product.bidtopstatus && product.bidtopstatus !== '') return product.bidtopstatus

        if (userInfo && productInfo) {
            // return none if no top status
            if (productInfo?.bidhistory?.length < 1) {
                return 'none'
            }
            if (didUserBid(userInfo.id, productInfo?.bidhistory)) {
                if (productInfo.market_status === 'closed') {
                    if (productInfo.bidwinneruser === userInfo.id) {
                        return 'won'
                    } else {
                        return 'lost'
                    }
                }
                if (productInfo.market_status === 'open') {
                    if (productInfo?.bidhistory[0]?.user_id === userInfo.id) {
                        return 'winner'
                    } else {
                        return 'outbid'
                    }
                }
            }
            return 'none'
            //check if user is winning

            //check
        }
    }

    useEffect(() => {
        setProduct(props.data)
        console.log('props.data', props.data)
    }, [props.data, props.data.bidtopstatus])

    return (
        <Fade in={true} timeout={600}>
            <div className="productCardList d-flex justify-content-between align-items-start">
                <div className="psListLt d-flex">
                    <div className="pclImg">
                        <AvatarImage
                            data={product}
                            onClick={props.drawerHandler}
                            folder="product"
                        />

                        {product.market_status === 'open' && (
                            <FavouriteCheckbox
                                watchlisted={product.watchlistid}
                                project_id={product.id}
                                icon_type="visibility"
                            />
                        )}

                        <div className="listBidStatus">
                            {isAuthenticated &&
                                history.location.pathname != '/purchases/auction_paid' &&
                                history.location.pathname != '/purchases/buynow' && (
                                    <>
                                        {(product.bidtopstatus === 'outbid' ||
                                            productBiddingStatus(user, product) === 'outbid') && (
                                            <h4 className="productWinningStatus outbid">
                                                {'Outbid'}
                                            </h4>
                                        )}
                                        {(product.bidtopstatus === 'winner' ||
                                            productBiddingStatus(user, product) === 'winner') && (
                                            <h4 className="productWinningStatus winning">
                                                {'Winning'}
                                            </h4>
                                        )}
                                        {(product.bidtopstatus === 'won' ||
                                            productBiddingStatus(user, product) === 'won') && (
                                            <h4 className="productWinningStatus won">{'Won'}</h4>
                                        )}
                                        {(product.bidtopstatus === 'lost' ||
                                            productBiddingStatus(user, product) === 'lost') && (
                                            <h4 className="productWinningStatus lost">{'Lost'}</h4>
                                        )}
                                        {/* {(product.bidtopstatus === 'outbid' ||
                                        product.bidtopstatus === 'winner') &&
                                        product.wprice <= product.rprice && (
                                            <>
                                                <Tooltip title="Reserve price not met">
                                                    <span className="material-icons reserveNotMet">
                                                        lock_open
                                                    </span>
                                                </Tooltip>
                                            </>
                                        )} */}
                                    </>
                                )}
                        </div>
                    </div>

                    <div className="listContent">
                        <h2
                            onClick={props.drawerHandler}
                            className="listProdTitle"
                            id={'searchpage_listProdTitle_' + product.id}
                        >
                            {product.title}
                        </h2>

                        {/* <div className="listBidInfo d-flex justify-content-start align-items-center">
                            <h6 className="txtLabel">{'auction'} id:</h6>
                            <p className="d-flex justify-content-between align-items-center">
                                <span>{`#${product.auctionid}`}</span>
                            </p>
                        </div> */}
                        {product.market_status === 'open' ? (
                            <>
                                {
                                    <div className="listBidInfo d-flex justify-content-start align-items-center">
                                        <h6 className="txtLabel">{product.cbidtext}:</h6>
                                        <p className="d-flex justify-content-between align-items-center">
                                            <span>{currencyFormat(product.wprice)}</span>
                                            <span className="listDivider">|</span>
                                            <span
                                                className="cursorDecoy"
                                                onClick={() => setBidHistoryValue(product.id)}
                                            >
                                                {product.bid_count}
                                                {'Bids'}
                                            </span>
                                        </p>
                                    </div>
                                }
                                {isAuthenticated &&
                                    product?.proxy_bid_details?.user_id === user?.id &&
                                    (product.bidtopstatus === '' ||
                                        product?.bidtopstatus === 'winner') &&
                                    product?.custom_auctionlot_bid_type === 'proxy' && (
                                        <div className="listBidInfo d-flex justify-content-start align-items-center">
                                            <h6 className="txtLabel">{'My Proxy Bid'}:</h6>
                                            <p className="d-flex justify-content-between align-items-center">
                                                <span>
                                                    {currencyFormat(
                                                        product?.proxy_bid_details?.maxamount,
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                    )}
                                {product.buynow && product.wprice < product.bprice ? (
                                    <div className="listBidInfo d-flex justify-content-start align-items-center">
                                        <h6>{'Buy Now Price'}:</h6>
                                        <p className="d-flex justify-content-between align-items-center">
                                            <span>USD ${product.bprice * product.qty}</span>
                                        </p>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            <div className="listBidInfo d-flex justify-content-start align-items-center">
                                <h6>{product.cbidtext}:</h6>
                                <p className="d-flex justify-content-between align-items-center">
                                    <span>{currencyFormat(product.buynowamount)}</span>
                                    {product.market_status === 'open' ? (
                                        <>
                                            <span className="listDivider">|</span>
                                            <span
                                                className="cursorDecoy"
                                                onClick={() => setBidHistoryValue(product.id)}
                                            >
                                                {product.bid_count} {'Bids'}
                                            </span>
                                        </>
                                    ) : null}
                                </p>
                            </div>
                        )}
                        <div className="listBidInfo d-flex align-items-center">
                            <p className="m-0 d-flex align-items-center txtLabel">
                                {/* <span className="material-icons-outlined">place</span> */}
                                <span>Location:</span>
                            </p>
                            {allLocations
                                .filter((value) => product.location_id === value.id)
                                .map((location) => (
                                    //    console.log(location),
                                    <span className="adrsInfoCrd">{location.name}</span>
                                ))}
                        </div>
                        <div className="listBidInfo d-flex align-items-center">
                            <p className="m-0 d-flex align-items-center txtLabel">
                                {/* <span className="material-icons-outlined">place</span> */}
                                <span>Condition:</span>
                            </p>
                            {allCondition
                                .filter((value) => product.conditionTypeId === value.id)
                                .map((location) => (
                                    <Tooltip title={location.detail}>
                                        <span className="adrsInfoCrd">{location.description}</span>
                                    </Tooltip>
                                ))}
                        </div>
                        <div className="listLotDetails">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: product.desc_proc,
                                }}
                            ></p>
                        </div>
                    </div>
                </div>

                <div className="listActBtn">
                    {product.market_status === 'open' ? (
                        <>
                            <div className="listTimer d-flex justify-content-center align-items-center flex-wrap">
                                <p className="d-flex align-items-center">
                                    <span className="material-icons">timer</span>
                                    {product.date_closed ? (
                                        <Timer
                                            date_added={product.date_added}
                                            date_closed={product.date_closed}
                                            withText={1}
                                            endText={'Time Left' + ':'}
                                            startText={'Starts in' + ':'}
                                        ></Timer>
                                    ) : null}
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="listTimer d-flex justify-content-between align-items-center flex-wrap">
                                <h6 className="w-100">Closed on:</h6>
                                <p className="d-flex justify-content-center align-items-center w-100">
                                    {dateFormatFront(product.date_closed)}
                                </p>
                            </div>
                        </>
                    )}
                    {props.from === 'dashboard' &&
                    (props.action === 'won' || props.action === 'buynow') &&
                    product.buynowed &&
                    product.buynowed === user.id ? (
                        <div className="actionAfterWin">
                            {parseInt(product.buynowpaid, 10) === 1 ? (
                                <>
                                    <SecondaryButton
                                        label={'View Invoice'}
                                        btnSize="small"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `invoice/${product.common_invoice}`,
                                            )
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <PrimaryButton
                                        label={'Make Payment'}
                                        btnSize="small"
                                        onClick={() => onMakePaymentClick(product)}
                                    />
                                </>
                            )}
                        </div>
                    ) : product.market_status === 'open' && !product.future_active ? (
                        <>
                            {product.auction ? (
                                <div className="listBidBox">
                                    <Bidding
                                        data={product}
                                        type={
                                            product.custom_auctionlot_bid_type || product.bid_type
                                        }
                                    />
                                </div>
                            ) : null}
                            {product.buynow && !product.auction ? (
                                <div className="listBidBox">
                                    <Buynow
                                        data={product}
                                        editQuantity={false}
                                        isOnlyBuynow={product.auction === 0 ? true : false}
                                    />
                                </div>
                            ) : null}
                        </>
                    ) : product.buynowed && product.buynowed === user.id ? (
                        <div className="actionAfterWin">
                            {parseInt(product.buynowpaid, 10) === 1 ? (
                                <>
                                    <SecondaryButton
                                        label={'View Invoice'}
                                        btnSize="small"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `invoice/${product.common_invoice}`,
                                            )
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <PrimaryButton
                                        label={'Make Payment'}
                                        btnSize="small"
                                        onClick={() => onMakePaymentClick(product)}
                                    />
                                </>
                            )}
                        </div>
                    ) : null}
                    <div className="moreInfo">
                        <Button onClick={props.drawerHandler}>
                            {'Quick View'} <span className="material-icons">arrow_right_alt</span>
                        </Button>
                        <Button
                            onClick={() => window.open(`/productView/${product.id}`)}
                            id="grid_viewdetails_button"
                        >
                            {product.auctionlot_market_status !== 'closed'
                                ? 'View Full Details'
                                : ''}
                        </Button>
                    </div>
                </div>
                <div className="PaymentModal">
                    <UpdatePhoneNumber data={manageuser} function={toggleFullScreenUserPopup} />
                </div>
            </div>
        </Fade>
    )
}
export default ListView
