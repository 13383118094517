import {
    COMMON_VALUES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    LOGIN_MODAL,
    SIGNUP_MODAL,
    UPDATE_CART_CHECKOUT,
} from './commonTypes'

export default (state, action) => {
    switch (action.type) {
        case COMMON_VALUES:
            return {
                ...state,
                allCategory: action.payload.allCategory,
                allLocations: action.payload.allLocations,
                allHolidays: action.payload.allHolidays,
                allSubCategories: action.payload.allSubCategories,
                allCondition: action.payload.allCondition,
                allshipment_types: action.payload.allshipment_types,
                allBusinessCategory: action.payload.allBusinessCategory,
                allVatCategory: action.payload.allVatCategory,
                allBidIncrements: action.payload.allBidIncrements,
                allHomeBanner: action.payload.allHomeBanner,
                allConfigurationVariables: action.payload.allConfigurationVariables,
                allNotifications: action.payload.allNotifications,
                allNotificationBanner: action.payload.allNotificationBanner,
                allfaq: action.payload.allfaq,
                bidIncrementDefault: action.payload.bidIncrementDefault,
                configFeatures: action.payload.configFeatures,
                configVariables: action.payload.configVariables,
                phrases: action.payload.phrases,
                languages: action.payload.languages,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case LOGIN_MODAL:
            return {
                ...state,
                loginModal: !state.loginModal,
            }
        case SIGNUP_MODAL:
            return {
                ...state,
                signupModal: !state.signupModal,
            }
        case UPDATE_CART_CHECKOUT:
            return {
                ...state,
                cart_item: action.cart_item,
            }
        default:
            return state
    }
}
