import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import './Footer.css'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LOGO, SITE_NAME } from '../../../../Utils'
import { mapData } from '../../../../product/common/components'
import { Link } from 'react-router-dom'

import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import { Divider } from '@material-ui/core'
import UserContext from '../../../../product/context/user/userContext'

const Footer = () => {
    const autopltContext = useContext(AutopltContext)
    const userContext = useContext(UserContext)
    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext
    const { submitContactUs, responseStatus: subscribeResponse } = userContext
    const validationArray = Yup.object({
        name: Yup.string().nullable(),
        email: Yup.string().email('Invalid Email Format').required('Required'),
        phone: Yup.string().nullable(),
        zip: Yup.string().nullable().min(3, 'Minimum 3 characters').max(7, 'Maximum 7 characters'),
    })
    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            phone: '',
            zip: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            submitContactUs(values)
        },
    })

    const subscribe = [
        {
            label: 'Name',
            placeholder: 'Enter your name',
            class: 'subsInp',
            type: 'text',
            variant: 'standard',
            name: 'name',
            shrink: false,
            formik: formik,
        },
        {
            label: 'Email',
            placeholder: 'Enter your email address',
            class: 'subsInp',
            type: 'text',
            variant: 'standard',
            name: 'email',
            shrink: false,
            formik: formik,
        },
        {
            label: 'Phone Number',
            placeholder: 'Phone Number',
            class: 'subsInp',
            variant: 'standard',
            type: 'phone',
            countryCodeEditable: false,
            shrink: false,
            name: 'phone',
            formik: formik,
        },
        {
            label: 'Zip',
            placeholder: 'enter zip',
            class: 'subsInp',
            type: 'number',
            variant: 'standard',
            name: 'zip',
            shrink: false,
            formik: formik,
        },
    ]

    useEffect(() => {
        if (
            subscribeResponse &&
            subscribeResponse.from === 'submitContactUs' &&
            subscribeResponse.status === 'success'
        ) {
            formik.resetForm()
        }
        console.log(subscribeResponse, 'subscribeResponse')
    }, [subscribeResponse])
    return (
        <footer className="noPrint">
            <div className="footCnt customContainer d-flex justify-content-between align-items-center flex-wrap">
                <div className="footLinks d-flex justify-content-between">
                    <ul>
                        <li>
                            <h3>NPS Salt Lake City</h3>
                        </li>
                        <li>
                            <h4>Address</h4>
                            <address>1600 Empire Road Salt Lake City, UT 84104</address>
                        </li>
                        <li>
                            <h4>Hours</h4>
                            <p>Mon-Fri 10am - 7pm</p>
                            <p>Sat 10am - 6pm</p>
                            <p>Sun Closed</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h3>NPS Layton</h3>
                        </li>
                        <li>
                            <h4>Address</h4>
                            <address>1150N Main St. Layton, UT 84041</address>
                        </li>
                        <li>
                            <h4>Hours</h4>
                            <p>Mon-Fri 10am - 7pm</p>
                            <p>Sat 10am - 6pm</p>
                            <p>Sun Closed</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h3>NPS Orem</h3>
                        </li>
                        <li>
                            <h4>Address</h4>
                            <address>495 N. State Street Orem, UT 84057</address>
                        </li>
                        <li>
                            <h4>Hours</h4>
                            <p>Mon-Fri 10am - 7pm</p>
                            <p>Sat 10am - 6pm</p>
                            <p>Sun Closed</p>
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <h3>eBay/Curbside Pick-up</h3>
                        </li>
                        <li>
                            <h4>Address</h4>
                            <address>634 Challenger Rd. Salt Lake City, UT 84116</address>
                        </li>
                        <li>
                            <h4>Hours</h4>
                            <p>Mon-Fri 10am - 7pm</p>
                            <p>Sat 10am - 6pm</p>
                            <p>Sun Closed</p>
                        </li>
                    </ul>
                    <div className="footMisc">
                        <div className="footSubscribe">
                            <h3>Stay in touch</h3>
                            <small>Sign up to stay up to date</small>

                            <form
                                onSubmit={formik.handleSubmit}
                                autoComplete="nofill"
                                className="form-inline footSubsForm"
                            >
                                {Object.values(mapData(subscribe))}

                                <SecondaryButton type="submit" label="Subscribe" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Divider />
            <div
                className="footCopyright customContainer d-flex justify-content-between align-items-center"
                dir="ltr"
            >
                <div className="d-flex justify-content-between align-items-center">
                    <span>
                        {SITE_NAME} © {new Date().getFullYear()}.
                    </span>
                    <span className="footDivider">|</span>

                    <span>
                        <Link to="/terms_of_use" id="terms_link">
                            Terms & Conditions
                        </Link>
                    </span>
                    <span className="footDivider">|</span>
                    <span>
                        <Link to="/privacy_policy" id="privacypolicy_link">
                            Privacy Policy
                        </Link>
                    </span>
                </div>
                <ul className="footSocial d-flex justify-content-start align-items-center">
                    <li>
                        <a id="instagram_button" href="https://instagram.com/npsstoreofficial/">
                            <img src="/assets/svg/ig.svg" alt="Instagram logo" />
                        </a>
                    </li>
                    {/* <li>
                        <a href="#">
                            <img src="/assets/svg/tw.svg" alt="Twitter logo" />
                        </a>
                    </li> */}
                    <li>
                        <a id="facebook_button" href="https://www.facebook.com/npsstore/">
                            <img src="/assets/svg/fb.svg" alt="Facebook logo" />
                        </a>
                    </li>
                    <li>
                        <a
                            id="linkedin_button"
                            href="https://www.linkedin.com/company/national-product-sales"
                        >
                            <img src="/assets/svg/linkedin.svg" alt="Web logo" />
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer
