import React, { useState, useContext, useEffect, useRef } from 'react'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import CartItem from '../../custom/components/molecules/ProductCard/CartItem'
import './Checkout.css'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'

import { Button, InputAdornment, Divider, ListItem, Tooltip } from '@material-ui/core'
import RadioBox from '../../product/components/atoms/RadioBox'
import CustomInput from '../../product/components/atoms/Inputs/CustomInput'
import Popup from '../../custom/components/organisms/Popup'
import { useHistory } from 'react-router-dom'
import CartContext from '../../product/context/cart/cartContext'
import BuynowContext from '../../product/context/buynow/buynowContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import PayContext from '../../product/context/payment/payContext'
import CommonContext from '../../product/context/common/commonContext'
import AppointmentContext from '../../product/context/appointment/appointmentContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import CreditContext from '../../product/context/credit/creditContext'
import OfflineContext from '../../product/context/offline/offlineContext'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { useFormik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import {
    currencyFormat,
    dateFormatFrontDay,
    dateTimeFormatFunction,
    dateFormatFrontOnlyDate,
} from '../../custom/common/components'
import Loaders from '../../custom/components/molecules/Loaders'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import MapIcon from '@material-ui/icons/Map'
import BankDetails from '../../custom/components/organisms/BankDetails'
import CardDetails from '../../custom/components/organisms/CardDetails'
import AddressDetails from '../../custom/components/organisms/Address'
import StripeCardContext from '../../product/context/stripe/card/cardContext'
import StripeBankContext from '../../product/context/stripe/bank/bankContext'
import UserContext from '../../product/context/user/userContext'
import ShipEngineContext from '../../product/context/shipping/shipengine/shipengineContext'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import UpdatePhoneNumber from '../../custom/components/organisms/UpdatePhoneNumber'
import UpdateUserName from '../../custom/components/organisms/UpdateUserName'

const today = new Date()
const tomorrow = new Date(today.getTime() + 1 * 24 * 3600000)
const twodays = new Date(today.getTime() + 2 * 24 * 3600000)
const threedays = new Date(today.getTime() + 3 * 24 * 3600000)
const oneweek = new Date(threedays.getTime() + 7 * 24 * 3600000)
const appointmentDate = [
    // {
    //     value: dateFormatFrontOnlyDate(today),
    //     show: dateFormatFrontDay(today),
    //     label: 'today',
    // },
    {
        value: dateFormatFrontOnlyDate(tomorrow),
        show: dateFormatFrontDay(tomorrow),
        label: 'tomorrow',
    },
    {
        value: dateFormatFrontOnlyDate(twodays),
        show: dateFormatFrontDay(twodays),
        label: 'in_2_days',
    },
]

const Checkout = (props) => {
    const cartContext = useContext(CartContext)
    const buynowContext = useContext(BuynowContext)
    const appointmentContext = useContext(AppointmentContext)
    const creditContext = useContext(CreditContext)
    const offlineContext = useContext(OfflineContext)
    const customCommonContext = useContext(CustomCommonContext)
    const userContext = useContext(UserContext)
    const shipEngineContext = useContext(ShipEngineContext)

    const { updateProfile, responseStatus: responseStatusUser } = userContext
    const {
        cart_item,
        allLocations,
        allHolidays,
        configFeatures,
        allConfigurationVariables,
        configVariables,
    } = customCommonContext
    const {
        get_all_card_details,
        getAllStripeCards,
        responseStatus: responseStatusCard,
    } = useContext(StripeCardContext)
    const {
        get_all_bank_details,
        getAllStripeBanks,
        responseStatus: responseStatusBank,
    } = useContext(StripeBankContext)

    const { payCartWallet, responseStatus: responseStatusCredit } = creditContext
    const { payCartOffline, responseStatus: responseStatusOffline } = offlineContext
    const {
        shipping_quotes: shipengine_shipping_quotes,
        confirmShippingQuote: shipEngineConfirmShippingQuote,
        shippingInitiate: shipEngineShippingInitiate,
        responseStatus: responseStatusShipEngine,
        clearResponse: clearShipenginResponse,
    } = shipEngineContext
    const [isLoading, setIsLoading] = useState(true)
    const [loaderState, setLoading] = useState(true)
    const [holidays, setHolidays] = useState([])

    const [appointmentDates, setAppointmentDates] = useState(appointmentDate)

    const authContext = useContext(AuthContext)
    const { user, loadUser } = authContext
    const alertContext = useContext(AlertContext)
    const payContext = useContext(PayContext)
    const commonContext = useContext(CommonContext)
    const history = useHistory()
    const { USStates, static_page, getStaticPage } = commonContext
    let [cartValues, setCartValues] = useState({
        processing_percent: 0,
        total_shipping: 0,
        total_amount: 0,
        total_processed_amount: 0,
        per_total: 0,
        total_tax: 0,
    })
    const cartValuesRef = useRef(cartValues)
    useEffect(() => {
        cartValuesRef.current = cartValues
    })
    let [shippingQuote, setShippingQuote] = useState(null)
    const [checkoutType, setCheckoutType] = useState(props.match.params.type)
    const [reload, setReload] = useState(false)
    let [termsLink, setTermsLink] = useState([])
    const [dateCalendar, setDateCalendar] = useState(false)
    const [deliveryMode, setDeliveryMode] = useState([])

    useEffect(() => {
        getStaticPage({ id: 'partial_payment_terms' })
    }, [])

    useEffect(() => {
        setTermsLink(static_page?.record?.file_path ? static_page.record.file_path : null)
    }, [static_page])

    const viewTerms = () =>
        window.open(
            termsLink
                ? `${global.site_url}/uploads/static_files/${termsLink}`
                : '/assets/docs/termsOfService.pdf',
        )

    let getVariable = null
    let purchaseType = 'auction'

    if (props.match.params.type === 'buynow') {
        getVariable = buynowContext
        purchaseType = 'buynow'
    } else if (props.match.params.type === 'invoice') {
        getVariable = cartContext
        purchaseType = 'invoice'
    } else {
        getVariable = cartContext
    }

    const {
        search_singlecart,
        changeOnCart,
        saveAddress,
        getSingleCartProducts,
        responseStatus,
        clearResponse,
        clearSingleCart,
    } = getVariable
    const {
        updateCheckout,
        responseStatus: responseStatusCart,
        clearResponse: clearResponseCart,
    } = cartContext
    const {
        available_appointments,
        all_appointments_auction,
        getAvailbleAppointments,
        getAllAppointmentsForAuction,
    } = appointmentContext

    const { isAuthenticated } = authContext
    const {
        payCartRosoom,
        payCartStripe,
        responseStatus: responseStatusPayment,
        clearResponse: clearResponsePayment,
    } = payContext
    const { setAlert } = alertContext

    const [manage, setManage] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const calculatePercentage = (amount, percent) => {
        let percentAmount = 0
        let totalAmount = 0

        percentAmount = parseFloat(parseFloat(amount) * parseFloat(percent / 100)).toFixed(2)
        totalAmount = parseFloat(parseFloat(amount) + parseFloat(percentAmount)).toFixed(2)

        return { percentAmount, totalAmount }
    }

    const toggleFullScreenPopup = (popup, status, data) => {
        setManage({ popup, status, data })
    }

    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const [manageuser, setManageUser] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const [managename, setManageName] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullNamePopup = (popup, status, data) => {
        setManageName({ popup, status, data })
    }

    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({
            popup,
            status,
            data,
            onDismiss: () => {
                if (address.length == 0) {
                    toggleFullScreenPopup(true, 'new', null)
                }
            },
        })
    }

    const toggleFullScreenUserPopup = (popup, status, data) => {
        setManageUser({
            popup,
            status,
            data,
            onDismiss: () => {
                if (cards.length == 0) {
                    toggleFullScreenCardPopup(true, 'new', null)
                } else if (address.length == 0) {
                    toggleFullScreenPopup(true, 'new', null)
                }
            },
        })
    }

    const [manageBank, setManageBank] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenBankPopup = (popup, status, data) => {
        setManageBank({ popup, status, data })
    }
    let [changeAddress, setChangeAddress] = useState(false)
    let [changeCard, setChangeCard] = useState(false)
    const [selectedCard, setSelectedCard] = useState(false)
    const [cards, setCards] = useState([])
    const [banks, setBanks] = useState([])
    const [address, setAddress] = useState([])
    const [customDate, setCustomDate] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)

    let [cartProducts, setCartProducts] = useState([])
    // let [availAppoints, setAvailAppoints] = useState([])
    let [allAppointsAuction, setAllAppointsAuction] = useState({})

    let [cartLocation, setCartLocation] = useState(null)

    let [availAppointss, setAvailAppointss] = useState([])
    let [cartLocations, setCartLocations] = useState(null)

    let [allAvailableTime, setAllAvailableTime] = useState([])

    const [search, setSearch] = useState({
        cart_id: props.match.params.type === 'invoice' ? 0 : props.match.params.cart_id,
        invoice_id: props.match.params.type === 'invoice' ? props.match.params.cart_id : 0,
        filters: {
            active: {
                type: 'notin',
                value: 2,
                field: 'b.active',
            },
        },
    })

    const getWeekendDaysBetweenDates = (startDate, endDate) => {
        let st = moment(startDate)
        let et = moment(endDate)
        let dates = []
        while (st.isSameOrBefore(et)) {
            if (st.isoWeekday() === 6 || st.isoWeekday() === 7) {
                dates.push(st.format('YYYY-MM-DD'))
            }
            st.add(1, 'days')
        }
        return dates
    }

    useEffect(() => {
        const weekend = getWeekendDaysBetweenDates(tomorrow, oneweek)
        const holiday =
            allHolidays?.length > 0
                ? allHolidays.filter((h) => h.active == 1).map((h) => h.date)
                : []
        setHolidays([...weekend, ...holiday])
    }, [allHolidays])

    const getStripeBanks = () => {
        getAllStripeBanks({
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user ? user.stripe_id : '',
            object: 'bank_account',
        })
        toggleFullScreenBankPopup(false, 'new', null)
    }

    const getStripeCards = () => {
        getAllStripeCards({
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user ? user.stripe_id : '',
            object: 'card',
        })
        toggleFullScreenCardPopup(false, 'new', null)
    }

    const setUserAddress = () => {
        setAddress(user.address_details.filter((inner) => inner.status === 'active'))

        toggleFullScreenPopup(false, 'new', null)
    }

    const [dataArray, setDataArray] = useState([])
    const [submited, setIsSubmited] = useState(false)
    useEffect(() => {
        if (responseStatusCredit) {
            if (responseStatusCredit.from === 'payCartWallet') {
                if (responseStatusCredit.status === 'success') {
                    handleRedirectInternal(history, 'invoices/all')
                }
            }
        }
    }, [responseStatusCredit])

    useEffect(() => {
        if (responseStatusOffline) {
            if (responseStatusOffline.from === 'payCartOffline') {
                if (responseStatusOffline.status === 'success') {
                    handleRedirectInternal(history, 'invoices/all')
                }
            }
        }
    }, [responseStatusOffline])

    useEffect(() => {
        if (cartLocation) {
            const weeklyHours =
                cartLocation.weeklyHours && cartLocation.weeklyHours !== 'Closed'
                    ? cartLocation.weeklyHours.split('-')
                    : null

            const saturdayHours =
                cartLocation.saturdayHours && cartLocation.saturdayHours !== 'Closed'
                    ? cartLocation.saturdayHours.split('-')
                    : null

            const sundayHours =
                cartLocation.sundayHours && cartLocation.sundayHours !== 'Closed'
                    ? cartLocation.sundayHours.split('-')
                    : null

            const timeFormat = 'h:m a'
            let weeklyHoursStart = weeklyHours
                ? moment(weeklyHours[0], [timeFormat]).format('HH:mm')
                : 0
            let weeklyHoursEnd = weeklyHours
                ? moment(weeklyHours[1], [timeFormat]).format('HH:mm')
                : 0
            let saturdayHoursStart = saturdayHours
                ? moment(saturdayHours[0], [timeFormat]).format('HH:mm')
                : 0
            let saturdayHoursEnd = saturdayHours
                ? moment(saturdayHours[1], [timeFormat]).format('HH:mm')
                : 0

            let sundayHoursStart = sundayHours
                ? moment(sundayHours[0], [timeFormat]).format('HH:mm')
                : 0
            let sundayHoursEnd = sundayHours
                ? moment(sundayHours[1], [timeFormat]).format('HH:mm')
                : 0

            // 1 - Monday 2 - Tuesday
            setDataArray([
                {
                    date: 0,
                    minTime: sundayHoursStart,
                    maxTime: sundayHoursEnd,
                },
                {
                    date: 1,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 2,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 3,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 4,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 5,
                    minTime: weeklyHoursStart,
                    maxTime: weeklyHoursEnd,
                },
                {
                    date: 6,
                    minTime: saturdayHoursStart,
                    maxTime: saturdayHoursEnd,
                },
            ])
        } else {
            setDataArray([
                {
                    date: 0,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 1,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 2,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 3,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 4,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 5,
                    minTime: 8,
                    maxTime: 18,
                },
                {
                    date: 6,
                    minTime: 8,
                    maxTime: 18,
                },
            ])
        }
    }, [cartLocation])

    useEffect(() => {
        if (isAuthenticated) {
            if (user && user.stripe_id) {
                // getStripeBanks()
                getStripeCards()
            }
        }
    }, [isAuthenticated, user])

    useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true)
            getSingleCartProducts(search)
        }
    }, [isAuthenticated])

    // useEffect(() => {
    //     if (cartProducts.length > 0 && !submited) {
    //         getAllAppointmentsForAuction({ auction_id: cartProducts?.[0]?.auctionlot_id })
    //     }
    // }, [cartProducts])

    useEffect(() => {
        if (isAuthenticated) {
            setUserAddress()
        }
    }, [isAuthenticated && user.address_details])

    const confirmShippingQuote = () => {
        if (formikPayment.values.shipping_id) {
            if (cartProducts.length) {
                const shippingQuoteSelected =
                    shippingQuote &&
                    shippingQuote.rate_response &&
                    shippingQuote.rate_response.rates.filter(
                        (inner) => inner.rate_id === formikPayment.values.shipping_id,
                        10,
                    )[0]
                if (shippingQuoteSelected) {
                    /*let totalAmount = calculatePercentage(
                        shippingQuoteSelected?.shipping_amount.amount,
                        configVariables.mark_up_shipping,
                    ).totalAmount*/
                    let totalAmount = shippingQuoteSelected?.shipping_amount.amount
                    console.log(props, cartProducts[0].common_invoice, 'props')
                    //invoice_id:props.data.common_invoice,

                    const payload = {
                        invoice_id: cartProducts[0].common_invoice,
                        cart_id: search.cart_id,
                        shipping_data: { ...shippingQuoteSelected, total: totalAmount },
                        type: props.match.params.type,
                    }
                    shipEngineConfirmShippingQuote(payload)
                }
            }
        }
    }

    useEffect(() => {
        if (responseStatusShipEngine) {
            if (responseStatusShipEngine.from === 'confirmShippingQuote') {
                getSingleCartProducts(search)
                setAlert('Shipping choice updated!', 'success')
            }
        }
    }, [responseStatusShipEngine])

    useEffect(() => {
        setIsLoading(false)

        setCartProducts(search_singlecart.cartrecords.length ? search_singlecart.cartrecords : [])
        console.log(search_singlecart, 'search_singlecart')
        setCartValues({ ...cartValuesRef, ...search_singlecart.cartvalues })

        let location = []
        let locationID = search_singlecart.cartrecords.length
            ? search_singlecart.cartrecords[0].custom_auctionlot_location_id
            : 0
        if (locationID) {
            location = allLocations.filter((inner) => inner.id === locationID)
        }
        setCartLocation(location.length ? location[0] : null)
        // setTimeout(() => {
        //     getAvailbleAppointments({
        //         location_id: location.length ? location[0].id : null,
        //     })
        // }, 300)
    }, [search_singlecart])

    /*useEffect(() => {
        if (search_singlecart?.cartrecords && search_singlecart?.cartvalues) {
            setCartProducts(
                search_singlecart.cartrecords.length ? search_singlecart.cartrecords : [],
            )
            /formikPayment.setFieldValue(
                'address',
                (search_singlecart.cartrecords.length
                    ? search_singlecart.cartrecords[0].invoice_address_id
                    : 0
                ).toString(),
            )/
            /setShippingSelected(
                search_singlecart.cartrecords.length
                    ? search_singlecart.cartrecords[0].projects_shipping
                    : null,
            )/
            if (search_singlecart?.cartvalues) {
                setCartValues({
                    ...cartValuesRef.current,
                    total_shipping: search_singlecart.cartvalues.total_shipping,
                    total_amount:
                        parseFloat(search_singlecart.cartvalues.per_total) +
                        parseFloat(search_singlecart.cartvalues.total_tax),
                    per_total: search_singlecart.cartvalues.per_total,
                    total_tax: search_singlecart.cartvalues.total_tax,
                })
            }
            setIsLoading(false)
        }
    }, [search_singlecart])*/

    // useEffect(() => {
    //     setAvailAppoints(
    //         available_appointments.records.length ? available_appointments.records : [],
    //     )
    //     console.log(available_appointments)
    // }, [available_appointments])

    // useEffect(() => {
    //     setAllAppointsAuction(
    //         all_appointments_auction.records.length ? all_appointments_auction.records : [],
    //     )
    // }, [all_appointments_auction])

    const addressUpdate = (id) => {
        if (props.match.params.type !== 'invoice') {
            //props.match.params.cart_id
            const payload = {
                invoice_id: cartProducts[0].common_invoice,
                invoice_address_id: id,
                cart_id: cartProducts[0].cart_group,
                type: 'tax',
                updateType: props.match.params.type,
                delivery_type: 'shipping',
            }
            console.log('@udpate checkout', payload)
            updateCheckout(payload)
        }
    }

    useEffect(() => {
        if (responseStatusCart) {
            if (responseStatusCart.from === 'update_checkout') {
                if (responseStatusCart.status === 'success') {
                    clearShipenginResponse()
                    shippingQuote = null
                    getSingleCartProducts(search)

                    if (
                        formikPayment.values.address &&
                        Number(formikPayment.values.delivery_mode) === 2
                    ) {
                        requestShipping(
                            true,
                            search.invoice_id,
                            search.cart_id,
                            props.match.params.type,
                        )
                    }
                    clearResponseCart()
                }
            }
        }
        if (responseStatus) {
            if (
                responseStatus.from === 'cartchange' ||
                responseStatus.from === 'cartaddress' ||
                responseStatus.from === 'addToCart'
            ) {
                if (responseStatus.status === 'success') {
                    getSingleCartProducts(search)
                }
            }
        }
    }, [responseStatus, responseStatusCart])

    const validationPayment = Yup.object({
        appointmentID: Yup.string(),
        payment_method: Yup.string().required('Required!'),
        no_of_cards: Yup.string().when(['payment_method'], {
            is: (a) => a === 'card',
            then: Yup.string().required('Required!'),
        }),
        card_number: Yup.string()
            .min(16, 'Invalid credit card number!')
            .max(16, 'Invalid credit card number!')
            .when(['payment_method', 'no_of_cards'], {
                is: (a, b) => a === 'card' && b === 'single',
                then: Yup.string().required('Required!'),
            }),
        card_expire_month: Yup.string().when(['payment_method', 'no_of_cards'], {
            is: (a, b) => a === 'card' && b === 'single',
            then: Yup.string().required('Required!'),
        }),
        card_expire_year: Yup.string().when(['payment_method', 'no_of_cards'], {
            is: (a, b) => a === 'card' && b === 'single',
            then: Yup.string().required('Required!'),
        }),
        card_cvv: Yup.string()
            .min(3, 'Invalid CVV')
            .max(3, 'Invalid CVV')
            .when(['payment_method', 'no_of_cards'], {
                is: (a, b) => a === 'card' && b === 'single',
                then: Yup.string().required('Required!'),
            }),
        bank_account_holder_name: Yup.string('Must be string').when(
            ['payment_method', 'no_of_cards'],
            {
                is: (a, b) => a === 'bank' && b === 'single',
                then: Yup.string().required('Required!'),
            },
        ),
        bank_account_number: Yup.number('Number required')
            .positive('Positive only allowed')
            .when(['payment_method', 'no_of_cards'], {
                is: (a, b) => a === 'bank' && b === 'single',
                then: Yup.number('Number required').required('Required!'),
            }),
        bank_account_holder_type: Yup.string().when(['payment_method', 'no_of_cards'], {
            is: (a, b) => a === 'bank' && b === 'single',
            then: Yup.string().required('Required!'),
        }),
        bank_routing_number: Yup.number()
            .positive('Positive only allowed')
            .when(['payment_method', 'no_of_cards'], {
                is: (a, b) => a === 'bank' && b === 'single',
                then: Yup.number().required('Required!'),
            }),

        // pickupDay: Yup.string().when(['delivery_mode', 'appointmentID'], {
        //     is: (a, b) => a === '1' && !b,
        //     then: Yup.string().required('Appointment Day is required!'),
        // }),
        // pickupTime: Yup.string().when(['delivery_mode', 'appointmentID'], {
        //     is: (a, b) => a === '1' && !b,
        //     then: Yup.string().required('Appointment time is required!'),
        // }),
        delivery_mode: Yup.string().required(),
        address: Yup.string().when(['delivery_mode', 'invoice_id'], {
            is: (a, b) => a === '2' && !b,
            then: Yup.string().required('Required!'),
        }),
        shipping_id: Yup.string().when(['delivery_mode', 'invoice_id'], {
            is: (a, b) => a === '2' && !b,
            then: Yup.string().required('Required!'),
        }),
        partial: Yup.boolean(),
        amount: Yup.number().when('partial', {
            is: true,
            then: Yup.number()
                .max(
                    cartValues ? cartValues.total_due_amount : 0,
                    `Max. Amount ${
                        cartValues ? currencyFormat(cartValues.total_due_amount) : currencyFormat(0)
                    }`,
                )
                .min(
                    cartValues ? (cartValues.total_due_amount / 100) * 25 : 0,
                    `Min. Amount ${
                        cartValues
                            ? currencyFormat((cartValues.total_due_amount / 100) * 25)
                            : currencyFormat(0)
                    }`,
                )
                .required('Required!'),
        }),
    })

    const formikPayment = useFormik({
        initialValues: {
            invoice_id: search.invoice_id,
            cart_id: search.cart_id,
            address: '',
            cards: '',
            appointmentID: '',
            appointmentTime: '',
            pickupDay: '',
            pickupTime: '',
            pickup: '',
            payment_method: 'card',
            payment_ways: '',
            no_of_cards: 'existing',
            card_number: '',
            card_expire_month: '',
            card_expire_year: '',
            card_cvv: '',
            card_amount: 0,
            pay_type:
                checkoutType === 'buynow'
                    ? 'buynow'
                    : checkoutType === 'offer'
                    ? 'offer'
                    : 'auction',
            amount: 0,
            bank_account_holder_name: '',
            bank_account_number: '',
            bank_account_holder_type: '',
            bank_routing_number: '',
            shipping_id: '',
            shipping_service_type: '',
            add_insurance: null,
            insurance_amount: 0,
            partial: false,
        },
        validateOnBlur: false,
        validationSchema: validationPayment,
        onSubmit: (values) => {
            console.log(address, 'cards')
            setLoading(true)
            let appointmentTime = ''
            if (values.pickupDay && values.pickupTime) {
                let pickupChanged = values.pickupDay + ' ' + values.pickupTime
                appointmentTime = moment(pickupChanged, 'MM-DD-YYYY HH:mm').format()
            }
            if (user.phone == null || user.phone == '+' || user.phone == '+1' || user.phone == '') {
                setAlert('Please enter phone number', 'error')
                setLoading(false)
                toggleFullScreenUserPopup(true, 'new', null)
            } else if (
                user.first_name === '' ||
                user.first_name === null ||
                user.last_name === '' ||
                user.last_name === null
            ) {
                setAlert('Please update your first and last name', 'error')
                setLoading(false)
                toggleFullNamePopup(true, 'new', null)
            } else if (cards.length == 0) {
                setAlert('Please enter card details', 'error')
                setLoading(false)
                toggleFullScreenCardPopup(true, 'new', null)
            } else if (address.length == 0) {
                setAlert('Please enter address details', 'error')
                setLoading(false)
                toggleFullScreenPopup(true, 'new', null)
            } else {
                if (
                    purchaseType === 'buynow' ||
                    purchaseType === 'invoice' ||
                    cartProducts[0]?.auctionlot_market_status === 'closed'
                ) {
                    formikPayment.setSubmitting(true)
                    let formData = {
                        invoice_id: values.invoice_id,
                        cart_id: values.cart_id,
                        filters: {
                            active: {
                                type: 'notin',
                                field: 'b.active',
                                value: 2,
                            },
                        },
                        payment_object: {
                            // id: values.appointmentID,
                            appointmentTime: values.appointmentTime ? values.appointmentTime : null,
                            invoice_id: values.invoice_id,
                            location_id: cartLocation.id,
                        },
                        paymentInfo: {
                            source: selectedCard.id,
                            customer: user.stripe_id,
                        },
                        payment_method: 'online',
                        payment_type: selectedCard.object,
                        pay_type: values.pay_type,
                        amount: values.partial ? values.amount : 0,
                        additional_buynow_data: {
                            invoice_address_id: formikPayment.values.address,
                            shipping_service_type: values.shipping_service_type,
                        },
                        additional_buynow_update: true,
                        project: 'NPS',
                        idempotencyCheck: true,
                        // otp_required: true,
                    }
                    console.log(cartValues, values, 'formData')
                    payCartStripe(formData)
                } else {
                    setOpenPopup(true)
                }
            }
        },
    })

    useEffect(() => {
        console.log(formikPayment.values.shipping_service_type)
    }, [formikPayment.values.shipping_service_type])

    // useEffect(() => {
    //     console.log('@search', search)
    // }, [search])

    useEffect(() => {
        if (search && formikPayment.values.address) {
            if (search.invoice_id) {
                console.log('search', search)
                //requestShipping(true, search.invoice_id, search.cart_id, props.match.params.type)
            }
        }
    }, [formikPayment.values.address])
    useEffect(() => {
        if (formikPayment.values.pickupDay) {
            setCustomDate(true)
        }
    }, [formikPayment.values.pickupDay])
    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment') {
                //setAlert(responseStatusPayment.message, responseStatusPayment.status)
                clearResponsePayment()
                if (responseStatusPayment.status === 'success') {
                    if (responseStatusPayment.type === 6) {
                        window.location.replace(responseStatusPayment.data)
                    } else if (
                        responseStatusPayment?.data?.common_invoice &&
                        formikPayment.values.delivery_mode != 2 &&
                        !formikPayment.values.appointmentID
                    ) {
                        if (formikPayment.values.partial) {
                            handleRedirectInternal(history, 'invoices/partial')
                        } else {
                            const redirect_path =
                                responseStatusPayment.data.redirect ||
                                `appointment/schedule/${responseStatusPayment.data.common_invoice}`
                            handleRedirectInternal(history, redirect_path)
                        }
                    } else {
                        handleRedirectInternal(history, 'invoices/all')
                    }
                } else if (responseStatusPayment.status === 'error') {
                    if (
                        responseStatusPayment.type === 0 ||
                        String(responseStatusPayment.message || '').includes('Something went wrong')
                    )
                        setAlert(`Please try again to complete the transaction`, `warning`)
                }
            }
        }
        setLoading(false)
        // if (isAuthenticated && !submited) {
        //     getAllAppointmentsForAuction({ auction_id: cartProducts?.[0]?.auctionlot_id })
        //     formikPayment.values.pickupTime = ''
        // }
    }, [responseStatusPayment])

    useEffect(() => {
        if (props.match.params.type !== 'invoice') {
            if (formikPayment.values.delivery_mode) {
                updateCheckout(
                    {
                        cart_id: search.cart_id,
                        invoice_id: cartProducts[0].common_invoice,
                        delivery_type:
                            formikPayment.values.delivery_mode == 2 ? 'shipping' : 'local_pickup',
                        updateType: props.match.params.type,
                    },
                    true,
                )
            }
        }
    }, [formikPayment.values.delivery_mode])

    const requestShipping = (reInitiate, invoice, cartId, type) => {
        const payload = {
            cart_id: cartId,
            invoice_id: invoice,
            type,
            re_initiate: reInitiate,
        }
        shipEngineShippingInitiate(payload)
    }

    useEffect(() => {
        if (
            formikPayment.values.address &&
            search_singlecart &&
            search_singlecart.cartrecords &&
            search_singlecart.cartrecords.length
        ) {
            setShippingQuote('')
            addressUpdate(formikPayment.values.address)
        }
    }, [formikPayment.values.address])

    useEffect(() => {
        confirmShippingQuote()
    }, [formikPayment.values.shipping_id])
    const handleSelect = (object) => {
        setSelectedCard(object)
        search_singlecart.cartvalues.processing_fees = 0
        search_singlecart.cartvalues.processing_percent = 0
        if (object.object === 'card') {
            formikPayment.setFieldValue('payment_method', 'card')
            formikPayment.setFieldValue('cards', object.id)
            if (object.country === 'US') {
                search_singlecart.cartvalues.processing_percent = parseFloat(
                    configVariables.transaction_card,
                )
            } else {
                search_singlecart.cartvalues.processing_percent = parseFloat(
                    configVariables.transaction_international_card,
                )
            }
            let calculatedPercent = calculatePercentage(
                search_singlecart.cartvalues.total_amount,
                search_singlecart.cartvalues.processing_percent,
            )
            search_singlecart.cartvalues.processing_fees = calculatedPercent.percentAmount
            search_singlecart.cartvalues.total_processed_amount = calculatedPercent.totalAmount
        } else {
            formikPayment.setFieldValue('payment_method', 'bank')
            formikPayment.setFieldValue('cards', object.id)

            if (object.country === 'US') {
                search_singlecart.cartvalues.processing_fees = parseFloat(
                    configVariables.transaction_ach,
                )
            } else {
                search_singlecart.cartvalues.processing_fees = parseFloat(
                    configVariables.transaction_international_ach,
                )
            }
            search_singlecart.cartvalues.total_processed_amount =
                search_singlecart.cartvalues.total_amount +
                search_singlecart.cartvalues.processing_fees
        }
    }

    const modeOfPayment = [
        {
            label: 'Make a partial payment',
            placeholder: 'Make a partial payment',
            type: 'switch',
            class: `${
                formikPayment.values.payment_method !== 'offline' ? 'col-12 mb-4' : ' d-none'
            }`,
            name: 'partial',
            formik: formikPayment,
        },
        {
            label: 'Enter amount',
            name: 'amount',
            type: 'number',
            placeholder: 'Enter payment amount',
            class: `col-12 ${formikPayment.values.partial ? '' : 'd-none'}`,
            formik: formikPayment,
        },
        // {
        //     label: [
        //         'I agree to the',
        //         <a target="_blank" onClick={viewTerms}>
        //             {'  '}
        //             {'Terms conditions'}{' '}
        //         </a>,
        //     ],
        //     name: 'termCheck',
        //     type: 'checkbox',
        //     class: `col-12 ${formikPayment.values.partial ? '' : 'd-none'}`,
        //     formik: formikPayment,
        // },
    ]

    useEffect(() => {
        if (cartProducts.length && props.match.params.type === 'invoice') {
            formikPayment.values.pay_type = cartProducts[0].buynow_autype
            setReload(!reload)
        }
    }, [props.match.params, cartProducts])

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'userAddress') {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                }
            }
        }
        if (responseStatusUser) {
            if (responseStatusUser.from === 'updateProfile') {
                loadUser()
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        if (responseStatusBank) {
            if (
                responseStatusBank.from === 'bank_source_create' ||
                responseStatusBank.from === 'card_source_create_customer_card' ||
                responseStatusBank.from === 'bank_source_update' ||
                responseStatusBank.from === 'bank_source_delete'
            ) {
                if (responseStatusBank.status === 'success') {
                    loadUser()
                    getStripeBanks()
                }
            }
        }
    }, [responseStatusBank])

    useEffect(() => {
        if (responseStatusCard) {
            if (responseStatusCard.from === 'card_source_create_customer_card') {
                if (responseStatusCard.status === 'success') {
                    let formData = {
                        stripe_id: responseStatusCard?.data?.id || '',
                    }
                    updateProfile(formData)
                    loadUser()
                } else {
                    setAlert(responseStatusCard.message, 'error')
                }
            }
            if (
                responseStatusCard.from === 'card_source_create' ||
                responseStatusCard.from === 'card_source_update' ||
                responseStatusCard.from === 'card_source_delete'
            ) {
                if (responseStatusCard.status === 'success') {
                    setAlert(responseStatusCard.message, 'success')
                    getStripeCards()
                } else {
                    setAlert(responseStatusCard.message, 'error')
                }
            }
        }
        clearResponse()
    }, [responseStatusCard])

    useEffect(() => {
        if (
            get_all_card_details &&
            get_all_card_details.records.length
            // search_singlecart?.cartvalues?.total_amount <= configVariables?.stripe_ach_limit
        ) {
            if (formikPayment.values.cards === '') handleSelect(get_all_card_details.records[0])
            setCards(get_all_card_details.records)
            formikPayment.setFieldValue('payment_ways', 'both')
        }

        return () => {
            setCards([])
        }
    }, [get_all_card_details])
    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'updateProfile') {
                if (responseStatusUser.status === 'success') {
                    toggleFullScreenUserPopup(false, 'new', null)
                }
            }
        }
    }, [responseStatusUser])

    function intervals(startString, endString) {
        const intervalMinutes =
            cartProducts?.[0]?.custom_auctionlot_local_pickup_slot_timeframe || 30
        var start = moment(startString, 'YYYY-MM-DD HH:mm')
        var end = moment(endString, 'YYYY-MM-DD HH:mm')
        start.minutes(Math.ceil(start.minutes() / intervalMinutes) * intervalMinutes)
        var result = []
        var current = moment(start)
        while (current < end) {
            result.push({
                value: moment(current).format('HH:mm'),
                show: `${current.format('h:mm a')}`,
            })
            current.add(intervalMinutes, 'minutes')
        }
        return result
    }

    useEffect(() => {
        const selectedDate = moment(formikPayment.values.pickupDay, 'MM-DD-YYYY').format()
        let weekDate = parseInt(moment(selectedDate).format('d'))
        let selectedWeekDay = dataArray.filter((x) => x.date === weekDate)
        if (weekDate !== null) {
            if (selectedWeekDay.length > 0) {
                if (selectedWeekDay[0].minTime && selectedWeekDay[0].maxTime) {
                    let dateSelected = moment(formikPayment.values.pickupDay, 'MM-DD-YYYY').format(
                        'YYYY-MM-DD',
                    )
                    let timeMinimum = moment(
                        cartProducts[0].custom_auctionlot_local_pickup_start_hour,
                        'HH:mm a',
                    ).format('HH:mm')
                    let timeMaximum = moment(
                        cartProducts[0].custom_auctionlot_local_pickup_end_hour,
                        'HH:mm a',
                    ).format('HH:mm')
                    timeMinimum = moment(dateSelected + ' ' + timeMinimum).format()
                    timeMaximum = moment(dateSelected + ' ' + timeMaximum).format()
                    const changeData = intervals(timeMinimum, timeMaximum)
                    setAllAvailableTime(changeData)
                } else {
                    setAllAvailableTime([{ value: '', show: 'Closed No Slots Available' }])
                }
            } else {
                setAllAvailableTime([{ value: '', show: 'Closed No Slots Available' }])
            }
        } else {
            setAllAvailableTime([{ value: '', show: `Select A Time` }])
        }
    }, [formikPayment.values.pickupDay])

    useEffect(() => {
        if (dataArray.length > 0) {
            const selectedDate = moment(appointmentDates[0].value, 'MM-DD-YYYY').format()
            let weekDate = parseInt(moment(selectedDate).format('d'))
            let selectedWeekDay = dataArray.filter((x) => x.date === weekDate)
            console.log('dggd1', selectedDate, weekDate, selectedWeekDay)
            if (weekDate !== null) {
                if (selectedWeekDay.length > 0) {
                    if (selectedWeekDay[0].minTime && selectedWeekDay[0].maxTime) {
                        let dateSelected = moment(appointmentDates[0].value, 'MM-DD-YYYY').format(
                            'YYYY-MM-DD',
                        )
                        let timeMinimum = moment(
                            cartProducts?.[0]?.custom_auctionlot_local_pickup_start_hour,
                            'HH:mm a',
                        ).format('HH:mm')
                        let timeMaximum = moment(
                            cartProducts?.[0]?.custom_auctionlot_local_pickup_end_hour,
                            'HH:mm a',
                        ).format('HH:mm')
                        timeMinimum = moment(dateSelected + ' ' + timeMinimum).format()
                        timeMaximum = moment(dateSelected + ' ' + timeMaximum).format()
                        const changeData = intervals(timeMinimum, timeMaximum)
                        if (changeData.length > 0) {
                            if (
                                moment(changeData[changeData.length - 1].value, 'HH:mm')
                                    .format('HH:mm')
                                    .valueOf() <= moment().format('HH:mm').valueOf()
                            ) {
                                // if (holidays.length > 0) {
                                //     const temp_date = appointmentDate.slice(1)
                                //     const final_date = temp_date.filter(
                                //         (d) =>
                                //             !holidays.includes(
                                //                 moment(d.value).format('YYYY-MM-DD'),
                                //             ),
                                //     )
                                //     setAppointmentDates(final_date)
                                // } else {
                                setAppointmentDates(appointmentDate.slice(1))
                                // }
                            }
                        }
                    }
                }
            }
        }
    }, [dataArray])

    // useEffect(() => {
    //     if (holidays.length > 0 && appointmentDates.length > 0) {
    //         const temp_date = appointmentDates.filter(
    //             (d) => !holidays.includes(moment(d.value).format('YYYY-MM-DD')),
    //         )
    //         setAppointmentDates(temp_date)
    //     }
    // }, [holidays])

    const confirmAppointment = (data) => {
        formikPayment.setFieldValue('appointmentID', data.id)
        formikPayment.setFieldValue('appointmentTime', data.appointmentTime)
    }
    const removeAppointment = () => {
        formikPayment.setFieldValue('appointmentID', '')
        formikPayment.setFieldValue('appointmentTime', '')
    }

    useEffect(() => {
        let deliveryInternal = []
        if (search_singlecart.cartrecords[0]) {
            if (search_singlecart.cartrecords[0].custom_auctionlot_shipping_check) {
                deliveryInternal.push({ id: 2, description: 'Shipping' })
            }
            if (search_singlecart.cartrecords[0].projects_local_pickup_check) {
                deliveryInternal.push({ id: 1, description: 'Local Pickup' })
            }
        }
        setDeliveryMode(deliveryInternal)
    }, [search_singlecart])

    useEffect(() => {
        if (deliveryMode.length > 0) {
            if (
                formikPayment.values.delivery_mode == undefined ||
                formikPayment.values.delivery_mode == null
            ) {
                formikPayment.setFieldValue(
                    'delivery_mode',
                    deliveryMode.length ? deliveryMode[0].id : 1,
                )
            }
        }
    }, [deliveryMode])

    useEffect(() => {
        return async () => {
            formikPayment.values.delivery_mode = undefined

            setDeliveryMode([])

            await clearSingleCart()
        }
    }, [])

    let modeOfDelivery = [
        {
            title: 'Mode of delivery',
            type: 'radio',
            name: 'delivery_mode',
            int: 1,
            item: deliveryMode,
            formik: formikPayment,
        },
    ]

    useEffect(() => {
        if (shipengine_shipping_quotes) {
            if (
                shipengine_shipping_quotes &&
                shipengine_shipping_quotes.shipping_quotes &&
                shipengine_shipping_quotes.shipping_quotes.length
            ) {
                setShippingQuote(shipengine_shipping_quotes.shipping_quotes[0].apiResponse)
                console.log(shipengine_shipping_quotes.shipping_quotes[0].apiResponse)
                // setShippingView(true)

                if (cartValuesRef.current.total_shipping !== 0) {
                    setCartValues({
                        ...cartValuesRef.current,
                        total_shipping: 0,
                    })
                }
                formikPayment.setFieldValue('shipping_id', '')
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        }
        return () => {
            setShippingQuote('')
            clearShipenginResponse()
        }
    }, [shipengine_shipping_quotes])
    useEffect(() => {
        if (formikPayment.values.shipping_id) {
            console.log('im changing')
            console.log(formikPayment.values.shipping_id, 'shipping_id')
            if (cartProducts.length) {
                let totalAmount = 0
                const shippingQuoteSelected =
                    shippingQuote &&
                    shippingQuote.rate_response &&
                    shippingQuote.rate_response.rates.filter(
                        (inner) => inner.rate_id === formikPayment.values.shipping_id,
                        10,
                    )[0]
                console.log(shippingQuote, 'shippingQuote')
                console.log(shippingQuoteSelected, 'shippingQuoteSelected')
                if (shippingQuoteSelected) {
                    /*totalAmount = calculatePercentage(
                        shippingQuoteSelected?.shipping_amount.amount,
                        configVariables.mark_up_shipping,
                    ).totalAmount*/
                    formikPayment.setFieldValue(
                        'shipping_service_type',
                        shippingQuoteSelected.service_type,
                    )
                    totalAmount = shippingQuoteSelected?.shipping_amount.amount
                }
                setCartValues({
                    ...cartValuesRef.current,
                    total_shipping: totalAmount,
                })

                console.log(totalAmount, 'total_shipping')
            }
        }
    }, [formikPayment.values.shipping_id])
    useEffect(() => {
        console.log(cartValues.total_shipping, 'total_shipping')
    }, [cartValues.total_shipping])
    useEffect(() => {
        if (formikPayment.values.delivery_mode) {
            let mode = formikPayment.values.delivery_mode
            if (mode == 1) {
                formikPayment.values.address = ''
                formikPayment.setFieldValue('address', '')
                cartValues.total_shipping = 0
                formikPayment.values.shipping_id = ''
                setCartValues({
                    ...cartValuesRef.current,
                    total_shipping: 0,
                })
            }
        }
        return () => {
            formikPayment.values.address = ''
            cartValues.total_shipping = 0
            formikPayment.values.shipping_id = ''
        }
    }, [formikPayment.values.delivery_mode])
    return (
        <div className="checkout customContainer">
            <Loaders name="home" isLoading={loaderState} />
            {isLoading ? (
                <Loaders name="checkout" isLoading={isLoading} loop={1} />
            ) : (
                <>
                    {/* {formikPayment.values.delivery_mode !== 1 && (
                        <>
                            {availAppoints.length > 0 && !formikPayment.values.appointmentID && (
                                <>
                                    {availAppoints.map((data, index) => (
                                        <>
                                            <div className="existingAppointmentBanner d-flex justify-content-between align-items-center flex-wrap">
                                                <span className="material-icons">{'Restore'}</span>
                                                <div>
                                                    <h2>
                                                        {'You Have An Appointment Scheduled For'}{' '}
                                                        {dateTimeFormatFunction(
                                                            data.appointmentTime,
                                                        )}
                                                        .
                                                    </h2>
                                                    <h4>
                                                        {
                                                            'You Can Add Items to the Same Appointment'
                                                        }
                                                    </h4>
                                                </div>
                                                <PrimaryButton
                                                    label={'Continue'}
                                                    onClick={() => confirmAppointment(data)}
                                                    btnSize="small"
                                                />
                                            </div>
                                        </>
                                    ))}
                                </>
                            )}
                        </>
                    )} */}

                    <Breadcrumbs aria-label="breadcrumb" className="naBreadcrumbs mb-2">
                        <Link to="/cart">Cart</Link>
                        <Typography color="textPrimary">{'Checkout'}</Typography>
                    </Breadcrumbs>
                    <form onSubmit={formikPayment.handleSubmit} autoComplete="nofill">
                        <div className="checkoutCnt d-flex justify-content-between">
                            <div className="checkoutLt">
                                <div className="mdOfDlvrWrpr">
                                    {Object.values(mapData(modeOfDelivery))}
                                </div>
                                <div className="review">
                                    <div className="d-flex align-items-center mb-3">
                                        <h1 className="checkoutHead m-0">{'Review Your Order'}</h1>
                                        <span>
                                            {cartProducts.length} {'Items'}
                                        </span>
                                    </div>
                                    {cartProducts.length ? (
                                        <div className="cartProducts">
                                            <div>
                                                {cartProducts.map((data, index) => (
                                                    <CartItem
                                                        key={index}
                                                        data={data}
                                                        from={'checkout'}
                                                        changeOnCart={changeOnCart}
                                                        buynow={
                                                            props.match.params.type === 'buynow'
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>

                                {formikPayment.values.delivery_mode == 1 ? (
                                    <>
                                        <div className="pickupAddress">
                                            <div className="adrsWrapper d-flex align-items-start">
                                                <MapIcon className="mr-2" />
                                                <div className="inrWrpr">
                                                    <h1 className="checkoutHead m-0">
                                                        {'Address For Pickup'}
                                                    </h1>
                                                    <h6>
                                                        {cartLocation && (
                                                            <>
                                                                {cartLocation.name}{' '}
                                                                {cartLocation.address}{' '}
                                                                {cartLocation.city}{' '}
                                                                {cartLocation.state}{' '}
                                                                {cartLocation.zipCode}
                                                            </>
                                                        )}
                                                    </h6>
                                                </div>
                                            </div>
                                            <h5>
                                                <AssignmentIndIcon className="mr-2" />
                                                please bring along your ID for verification
                                            </h5>
                                        </div>
                                        {formikPayment.values.appointmentID ? (
                                            <>
                                                <div className="existingAppointmentBanner d-flex justify-content-between align-items-center flex-wrap">
                                                    <span className="material-icons">
                                                        {'Restore'}
                                                    </span>
                                                    <div>
                                                        <h2>
                                                            {
                                                                'You have an selected your previous appointment scheduled at '
                                                            }
                                                            {dateTimeFormatFunction(
                                                                formikPayment.values
                                                                    .appointmentTime,
                                                            )}
                                                            .
                                                        </h2>
                                                        <h4>
                                                            if you want to remove the previous
                                                            appointment
                                                        </h4>
                                                    </div>
                                                    <PrimaryButton
                                                        label={'Remove'}
                                                        onClick={() => removeAppointment()}
                                                        btnSize="small"
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                            // <div className="schedule">
                                            //     <h1 className="checkoutHead">
                                            //         {'Select a Pickup Date' + ':'}
                                            //     </h1>
                                            //     <div className="chCnt d-flex justify-content-start align-items-center">
                                            //         <div className="selectDate d-flex justify-content-center align-items-center">
                                            //             <input
                                            //                 name="pickupDay"
                                            //                 checked={
                                            //                     formikPayment?.values?.pickupDay >=
                                            //                         dateFormatFrontOnlyDate(
                                            //                             tomorrow,
                                            //                         ) &&
                                            //                     formikPayment?.values?.pickupDay !=
                                            //                         ''
                                            //                         ? true
                                            //                         : false
                                            //                 }
                                            //                 type="radio"
                                            //                 id={`schedule_${appointmentDates.length}`}
                                            //                 hidden
                                            //             ></input>
                                            //             <label>
                                            //                 <ListItem
                                            //                     button
                                            //                     onClick={() =>
                                            //                         setDateCalendar(!dateCalendar)
                                            //                     }
                                            //                 >
                                            //                     {customDate ? (
                                            //                         <p>
                                            //                             {formikPayment?.values
                                            //                                 ?.pickupDay >=
                                            //                             dateFormatFrontOnlyDate(
                                            //                                 tomorrow,
                                            //                             )
                                            //                                 ? formikPayment.values
                                            //                                       .pickupDay
                                            //                                 : dateFormatFrontOnlyDate(
                                            //                                       tomorrow,
                                            //                                   )}
                                            //                         </p>
                                            //                     ) : (
                                            //                         <>
                                            //                             <p>Choose Date</p>
                                            //                             <span>
                                            //                                 ({'Custom Date'})
                                            //                             </span>
                                            //                         </>
                                            //                     )}
                                            //                 </ListItem>
                                            //             </label>

                                            //             {mapData([
                                            //                 {
                                            //                     label: 'Custom Date',
                                            //                     placeholder: 'Appointment',
                                            //                     disablePast: true,
                                            //                     open: dateCalendar,
                                            //                     onClose: () =>
                                            //                         setDateCalendar(false),
                                            //                     class: 'col-md-4 col-sm-6 col-12 d-none',
                                            //                     type: 'dateWithCustomPicker',
                                            //                     name: 'pickupDay',
                                            //                     minDate:
                                            //                         moment(tomorrow).format(
                                            //                             'YYYY-MM-DD',
                                            //                         ),
                                            //                     minDateMessage: `${'date should be greater than the current date'} - ${dateTimeFormatFunction(
                                            //                         tomorrow,
                                            //                     )}`,
                                            //                     // maxDate: dateFormatFrontOnlyDate(
                                            //                     //     oneweek,
                                            //                     // ),
                                            //                     maxDateMessage: `${'date should not be more than 7 days'} - ${dateTimeFormatFunction(
                                            //                         oneweek,
                                            //                     )}`,
                                            //                     shouldEnableDate: cartProducts
                                            //                         ?.at(0)
                                            //                         ?.custom_auctionlot_local_pickup_dates?.split(
                                            //                             ',',
                                            //                         )
                                            //                         .map(
                                            //                             (d) =>
                                            //                                 new Date(parseInt(d))
                                            //                                     .toISOString()
                                            //                                     .split('T')[0],
                                            //                         ),
                                            //                     showTodayButton: false,
                                            //                     formik: formikPayment,
                                            //                 },
                                            //             ])}
                                            //         </div>
                                            //     </div>

                                            //     <p className="scheduleError">
                                            //         {formikPayment.errors.pickupDay &&
                                            //             formikPayment.touched.pickupDay &&
                                            //             formikPayment.errors.pickupDay}
                                            //     </p>

                                            //     {formikPayment.values.pickupDay && (
                                            //         <>
                                            //             <h1 className="checkoutHead">
                                            //                 {'Select A Pickup Time' + ':'}
                                            //             </h1>
                                            //             <div className="chCnt timeSlots d-flex justify-content-start align-items-center flex-wrap">
                                            //                 {allAvailableTime
                                            //                     .filter((availableTimeUnit) =>
                                            //                         formikPayment.values
                                            //                             .pickupDay <=
                                            //                         moment().format('MM-DD-YYYY')
                                            //                             ? moment(
                                            //                                   availableTimeUnit.value,
                                            //                                   'HH:mm',
                                            //                               )
                                            //                                   .format('HH:mm')
                                            //                                   .valueOf() >=
                                            //                               moment()
                                            //                                   .format('HH:mm')
                                            //                                   .valueOf()
                                            //                             : availableTimeUnit.value,
                                            //                     )
                                            //                     .map((data, index) => {
                                            //                         let dateArr =
                                            //                             formikPayment.values.pickupDay.split(
                                            //                                 '-',
                                            //                             )
                                            //                         let slotLeft =
                                            //                             cartProducts?.[0]
                                            //                                 ?.custom_auctionlot_local_pickup_appt_per_slot -
                                            //                             allAppointsAuction.filter(
                                            //                                 (apt) =>
                                            //                                     apt.appointmentTime ==
                                            //                                         new Date(
                                            //                                             `${dateArr[2]}/${dateArr[0]}/${dateArr[1]} ${data.value}`,
                                            //                                         ).toISOString() &&
                                            //                                     apt.status !=
                                            //                                         'open',
                                            //                             ).length
                                            //                         return (
                                            //                             slotLeft > 0 && (
                                            //                                 <div
                                            //                                     className="selectDate d-flex justify-content-center align-items-center"
                                            //                                     key={index}
                                            //                                 >
                                            //                                     <input
                                            //                                         name="pickupTime"
                                            //                                         value={
                                            //                                             data.value
                                            //                                         }
                                            //                                         onChange={
                                            //                                             formikPayment.handleChange
                                            //                                         }
                                            //                                         type="radio"
                                            //                                         id={`scheduleTime_${index}`}
                                            //                                         hidden
                                            //                                     ></input>
                                            //                                     <label
                                            //                                         htmlFor={`scheduleTime_${index}`}
                                            //                                     >
                                            //                                         <ListItem
                                            //                                             button
                                            //                                         >
                                            //                                             <p>
                                            //                                                 {
                                            //                                                     data.show
                                            //                                                 }
                                            //                                             </p>
                                            //                                             <hr />
                                            //                                             <p>
                                            //                                                 {
                                            //                                                     slotLeft
                                            //                                                 }{' '}
                                            //                                                 slot(s)
                                            //                                                 left
                                            //                                             </p>
                                            //                                         </ListItem>
                                            //                                     </label>
                                            //                                 </div>
                                            //                             )
                                            //                         )
                                            //                     })}
                                            //             </div>
                                            //             <p className="scheduleError">
                                            //                 {formikPayment.errors.pickupTime &&
                                            //                     formikPayment.touched.pickupTime &&
                                            //                     formikPayment.errors.pickupTime}
                                            //             </p>
                                            //         </>
                                            //     )}
                                            // </div>
                                        )}
                                    </>
                                ) : formikPayment.values.delivery_mode == 2 ? (
                                    <div className="spngAdrDtls">
                                        <h3 className="shpAdrsTitle">Choose a shipping address</h3>
                                        <div className="grdWrpr">
                                            <div className="adNwAdrs selectDate selectAddress d-flex justify-content-center align-items-center">
                                                <label>
                                                    <ListItem
                                                        button
                                                        onClick={() => {
                                                            toggleFullScreenPopup(true, 'new', null)
                                                        }}
                                                    >
                                                        <img
                                                            className="mx-auto"
                                                            src="/assets/svg/mailbox.svg"
                                                        />
                                                        <p>ADD NEW ADDRESS</p>
                                                    </ListItem>
                                                </label>
                                            </div>
                                            {address &&
                                                address.map((single, i) => (
                                                    <div
                                                        className="selectDate selectAddress d-flex justify-content-center align-items-center"
                                                        key={'cart_' + i}
                                                    >
                                                        <input
                                                            name="pickupDay"
                                                            value="addressField"
                                                            //onChange={formikPayment.handleChange}
                                                            type="radio"
                                                            id={`schedule_` + i}
                                                            hidden
                                                        ></input>

                                                        <label htmlFor={`schedule_` + i}>
                                                            <ListItem
                                                                button
                                                                onClick={() => {
                                                                    setChangeAddress(false)
                                                                    formikPayment.setFieldValue(
                                                                        'address',
                                                                        single.id,
                                                                    )
                                                                }}
                                                            >
                                                                <h6>
                                                                    {single.first_name}{' '}
                                                                    {single.last_name}
                                                                    {console.log(single, 'addres')}
                                                                </h6>
                                                                <p>{single.address1}</p>
                                                                <p>
                                                                    {single.city} {single.state}-
                                                                    {single.zip}
                                                                </p>
                                                                <p>{single.country}</p>
                                                            </ListItem>
                                                        </label>
                                                    </div>
                                                ))}
                                        </div>

                                        {!formikPayment.values.address && submited && (
                                            <p className="custom-error">
                                                {' '}
                                                Please choose one address
                                            </p>
                                        )}
                                        <div className="billingInfo mt-5">
                                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
                                                <h1 className="checkoutHead mb-1 mt-2 w-100">
                                                    Shipping Methods
                                                </h1>
                                                <p>Signature will be required at delivery.</p>
                                            </div>
                                            <div className="smCnt shpngDtlCntnr">
                                                {formikPayment.values.address &&
                                                    cartProducts.length > 0 && (
                                                        <>
                                                            {shippingQuote &&
                                                                shippingQuote.rate_response &&
                                                                shippingQuote.rate_response.rates.map(
                                                                    (data, index) => (
                                                                        <>
                                                                            <ListItem
                                                                                button
                                                                                className={`${
                                                                                    data.rate_id ===
                                                                                    formikPayment
                                                                                        .values
                                                                                        .shipping_id
                                                                                        ? 'checkedButton'
                                                                                        : null
                                                                                }`}
                                                                            >
                                                                                <div
                                                                                    className="selectShipping d-flex justify-content-between align-items-start flex-wrap"
                                                                                    key={
                                                                                        'cart_' +
                                                                                        index
                                                                                    }
                                                                                    onClick={() => {
                                                                                        formikPayment.setFieldValue(
                                                                                            'shipping_id',
                                                                                            data.rate_id,
                                                                                        )
                                                                                        formikPayment.setFieldValue(
                                                                                            'ship',
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <div className="shipInfo">
                                                                                        <h4>
                                                                                            {'$' +
                                                                                                data
                                                                                                    .shipping_amount
                                                                                                    .amount}
                                                                                            {/*<CurrencyContextFormat
                                                                                            amount={
                                                                                                calculatePercentage(
                                                                                                    data
                                                                                                        .shipping_amount
                                                                                                        .amount,
                                                                                                    configVariables.mark_up_shipping,
                                                                                                )
                                                                                                    .totalAmount
                                                                                            }
                                                                                        />*/}
                                                                                            {' | '}
                                                                                            {
                                                                                                data.service_type
                                                                                            }
                                                                                        </h4>
                                                                                        <h6>
                                                                                            {data.estimated_delivery_date
                                                                                                ? 'Delivery by  ' +
                                                                                                  dateFormatFrontDay(
                                                                                                      data.estimated_delivery_date,
                                                                                                  )
                                                                                                : 'Date Not Avaliable'}
                                                                                        </h6>
                                                                                    </div>
                                                                                    {data.rate_id ===
                                                                                    formikPayment
                                                                                        .values
                                                                                        .shipping_id ? (
                                                                                        <CheckCircleIcon className="chkdIcon" />
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </div>
                                                                            </ListItem>
                                                                        </>
                                                                    ),
                                                                )}
                                                        </>
                                                    )}
                                            </div>
                                            {!formikPayment.values.shipping_id &&
                                                submited &&
                                                shippingQuote &&
                                                shippingQuote.rate_response && (
                                                    <p className="custom-error">
                                                        {' '}
                                                        Please choose one shipping method
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                ) : null}
                            </div>

                            <div className="checkoutRt">
                                <div className="orderSummary">
                                    <div>
                                        <h3>{'Order Summary'}</h3>
                                        <p>
                                            <label>{'Items'}:</label>
                                            <span>{cartProducts.length}</span>
                                        </p>
                                        <p>
                                            <label>Bid Amount:</label>
                                            <span>{currencyFormat(cartValues.per_total)}</span>
                                        </p>
                                        <p>
                                            <label>{'Sales Tax'}: </label>
                                            <span>
                                                {`US $${parseFloat(cartValues.total_tax).toFixed(
                                                    2,
                                                )}`}
                                            </span>
                                        </p>
                                        {cartValues.total_shipping ? (
                                            <p>
                                                <label>{'Shipping Fee'}:</label>
                                                <span>
                                                    {currencyFormat(cartValues.total_shipping)}
                                                </span>
                                            </p>
                                        ) : null}
                                        <p>
                                            <label>{'Total Amount'}:</label>
                                            <span>
                                                {currencyFormat(
                                                    parseFloat(cartValues.total_amount),
                                                )}
                                            </span>
                                        </p>
                                        {/* <Tooltip title="You can choose to make a partial payment of 25% and above of your total amount now and pay the remaining amount later."></Tooltip> */}
                                        {console.log(cartValues, 'cart')}
                                        {cartValues.total_deposit ? (
                                            <p>
                                                <label>{'Bid Deposit'}:</label>
                                                <span>
                                                    - {currencyFormat(cartValues.total_deposit)}
                                                </span>
                                            </p>
                                        ) : null}

                                        {cartValues.total_paid && cartValues.total_due_amount ? (
                                            <p>
                                                <label>{'Total Paid'}:</label>
                                                <span>
                                                    {currencyFormat(
                                                        parseFloat(cartValues.total_paid),
                                                    )}
                                                </span>
                                            </p>
                                        ) : null}

                                        {cartValues.total_due_amount && cartValues.total_paid ? (
                                            <p>
                                                <label>{'Pending Amount'}:</label>
                                                <span>
                                                    {currencyFormat(
                                                        parseFloat(cartValues.total_due_amount) +
                                                            parseFloat(cartValues.total_shipping),
                                                    )}
                                                </span>
                                            </p>
                                        ) : null}

                                        {/* <div className="row">{mapData(modeOfPayment)}</div> */}
                                        <hr />
                                        <h4 className="my-4">Card Details</h4>
                                        <div className="billingInfo">
                                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
                                                <h1 className="checkoutHead m-0 mt-2">
                                                    PAYMENT METHOD
                                                </h1>
                                                {!changeCard && (
                                                    <Button
                                                        onClick={() => setChangeCard(!changeCard)}
                                                    >
                                                        Edit
                                                    </Button>
                                                )}
                                            </div>
                                            {changeCard && (
                                                <>
                                                    {(formikPayment.values.payment_method ===
                                                        'card' ||
                                                        formikPayment.values.payment_ways ===
                                                            'both') && (
                                                        <>
                                                            <div className="chCnt d-flex justify-content-start align-items-center flex-wrap">
                                                                {cards &&
                                                                    cards.map((data, index) => (
                                                                        <div className="selectAddress  d-flex justify-content-start align-items-center">
                                                                            <input
                                                                                name="cards"
                                                                                value={data.id}
                                                                                onChange={() =>
                                                                                    handleSelect(
                                                                                        data,
                                                                                    )
                                                                                }
                                                                                type="radio"
                                                                                id={`card_${index}`}
                                                                                hidden
                                                                            ></input>
                                                                            <label
                                                                                htmlFor={`card_${index}`}
                                                                            >
                                                                                <ListItem button>
                                                                                    {data.id ===
                                                                                    formikPayment
                                                                                        .values
                                                                                        .cards ? (
                                                                                        <span className="material-icons">
                                                                                            radio_button_checked
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span className="material-icons">
                                                                                            radio_button_unchecked
                                                                                        </span>
                                                                                    )}
                                                                                    <div
                                                                                        className="savedAddressCard ml-3"
                                                                                        key={index}
                                                                                    >
                                                                                        <div>
                                                                                            <h5>
                                                                                                {
                                                                                                    data.brand
                                                                                                }{' '}
                                                                                                ending
                                                                                                in{' '}
                                                                                                {
                                                                                                    data.last4
                                                                                                }
                                                                                            </h5>
                                                                                            <h5>
                                                                                                EXP.{' '}
                                                                                                {
                                                                                                    data.exp_month
                                                                                                }
                                                                                                /
                                                                                                {
                                                                                                    data.exp_year
                                                                                                }
                                                                                            </h5>
                                                                                        </div>
                                                                                        <Button
                                                                                            onClick={() =>
                                                                                                toggleFullScreenCardPopup(
                                                                                                    true,
                                                                                                    'update',
                                                                                                    data,
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Edit
                                                                                        </Button>
                                                                                        <Button
                                                                                            onClick={() =>
                                                                                                toggleFullScreenCardPopup(
                                                                                                    false,
                                                                                                    'delete',
                                                                                                    data,
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Delete
                                                                                        </Button>
                                                                                    </div>
                                                                                </ListItem>
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    <Divider />
                                                    <>
                                                        {/* <div className="chCnt d-flex justify-content-start align-items-center flex-wrap">
                                                            {banks &&
                                                                banks.map((data, index) => (
                                                                    <div className="selectAddress  d-flex justify-content-start align-items-center">
                                                                        <input
                                                                            name="cards"
                                                                            value={data.id}
                                                                            onChange={() =>
                                                                                handleSelect(data)
                                                                            }
                                                                            type="radio"
                                                                            id={`bank_${index}`}
                                                                            hidden
                                                                        ></input>
                                                                        <label
                                                                            htmlFor={`bank_${index}`}
                                                                        >
                                                                            <ListItem button>
                                                                                {data.id ===
                                                                                formikPayment.values
                                                                                    .cards ? (
                                                                                    <span className="material-icons">
                                                                                        radio_button_checked
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="material-icons">
                                                                                        radio_button_unchecked
                                                                                    </span>
                                                                                )}
                                                                                <div
                                                                                    className="savedAddressCard"
                                                                                    key={index}
                                                                                >
                                                                                    <div>
                                                                                        <h5>
                                                                                            {
                                                                                                data.bank_name
                                                                                            }
                                                                                        </h5>
                                                                                        <h5>
                                                                                            {
                                                                                                data.last4
                                                                                            }
                                                                                            /
                                                                                            {
                                                                                                data.routing_number
                                                                                            }
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                            </ListItem>
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                        </div> */}
                                                        <>
                                                            {formikPayment.values.payment_method ===
                                                                'card' && (
                                                                <Button
                                                                    onClick={() => {
                                                                        toggleFullScreenCardPopup(
                                                                            true,
                                                                            'new',
                                                                            null,
                                                                        )
                                                                    }}
                                                                    className="checkoutAddBtn"
                                                                >
                                                                    <span className="material-icons">
                                                                        add
                                                                    </span>
                                                                    Add New Card
                                                                </Button>
                                                            )}

                                                            {/* <Button
                                                                onClick={() => {
                                                                    toggleFullScreenBankPopup(
                                                                        true,
                                                                        'new',
                                                                        null,
                                                                    )
                                                                }}
                                                                className="checkoutAddBtn"
                                                            >
                                                                <span className="material-icons">
                                                                    add
                                                                </span>
                                                                Add New Bank
                                                            </Button> */}
                                                        </>
                                                        <>
                                                            <PrimaryButton
                                                                type="submit"
                                                                label={'Save'}
                                                                onClick={() => setChangeCard(false)}
                                                            />
                                                        </>
                                                    </>
                                                </>
                                            )}
                                            {!changeCard && selectedCard && (
                                                <>
                                                    {selectedCard.object === 'card' ? (
                                                        <>
                                                            <ListItem button>
                                                                <div className="savedAddressCard">
                                                                    <div>
                                                                        <h5>
                                                                            {selectedCard.brand}{' '}
                                                                            ending in{' '}
                                                                            {selectedCard.last4}
                                                                        </h5>
                                                                        <h5>
                                                                            EXP.{' '}
                                                                            {selectedCard.exp_month}
                                                                            /{selectedCard.exp_year}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </ListItem>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <ListItem button>
                                                                <div className="savedAddressCard">
                                                                    <div>
                                                                        <h5>
                                                                            {selectedCard.bank_name}
                                                                        </h5>
                                                                        <h5>
                                                                            {selectedCard.last4}/
                                                                            {
                                                                                selectedCard.routing_number
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </ListItem>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>

                                        <PrimaryButton
                                            disabled={loaderState}
                                            id="make_payment"
                                            type="submit"
                                            onClick={() => setIsSubmited(true)}
                                            label={'Make Payment'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="PaymentModal">
                        <BankDetails data={manageBank} function={toggleFullScreenBankPopup} />
                        <CardDetails data={manageCard} function={toggleFullScreenCardPopup} />
                        <AddressDetails data={manage} function={toggleFullScreenPopup} />
                        <UpdatePhoneNumber data={manageuser} function={toggleFullScreenUserPopup} />
                        <UpdateUserName data={managename} function={toggleFullNamePopup} />
                    </div>
                    <Popup
                        open={openPopup}
                        size="md"
                        handleClose={() => {
                            setLoading(false)
                            setOpenPopup(false)
                        }}
                        modaltitle="Auction closing"
                        className=""
                    >
                        <div className="awardingCart m-2">
                            <>
                                This auction is currently closing. <br />
                                Please wait for the remaining items to finish <br />
                                processing before submitting payment.
                            </>
                        </div>
                    </Popup>
                </>
            )}
        </div>
    )
}

export default Checkout
