import React, { useState, useContext, useEffect, useRef } from 'react'
import InvoiceCard from '../../custom/components/molecules/InvoiceCard'
import DashboardLayout from './DashboardLayout'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import InvoiceContext from '../../product/context/invoice/invoiceContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import { handleRedirectInternal } from '../../product/common/components'

import { dateTimeFormatFunction, capitalize, currencyFormat } from '../../custom/common/components'

function MyTransactions(props) {
    const authContext = useContext(AuthContext)
    const invoiceContext = useContext(InvoiceContext)

    const { search_allusertransactions, getAllUserTransactions } = invoiceContext
    const { isAuthenticated } = authContext

    let [viewProduct, setViewProduct] = useState([])

    const history = useHistory()

    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        orderby: 1,
    })

    useEffect(() => {
        setSearch({ ...search, action: props.match.params.action })
    }, [props.match.params.action])

    useEffect(() => {
        if (isAuthenticated) {
            getAllUserTransactions(search)
        }
    }, [search, isAuthenticated])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setViewProduct(
            search_allusertransactions.records.length ? search_allusertransactions.records : [],
        )
    }, [search_allusertransactions])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    return (
        <DashboardLayout title={'My Transactions'}>
            <div className="dashboardInner mySavedSearches">
                {viewProduct.length ? (
                    <>
                        <div className="table-responsive dpTable">
                            <table className="table text-left">
                                <thead>
                                    <tr>
                                        <th scope="col">{'Invoice Id'}</th>
                                        <th scope="col">{'Transaction Id'}</th>
                                        <th scope="col">{'Paid on'}</th>
                                        <th scope="col">{'Method'}</th>
                                        <th scope="col">{'Amount'}</th>
                                        <th scope="col">{'Status'}</th>
                                        <th scope="col">{'Action'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {viewProduct.map((data, index) => (
                                        <tr key={index}>
                                            <td data-title={'Invoice Id'}>
                                                {data.buynow_common_invoice
                                                    ? `# ${data.buynow_common_invoice}`
                                                    : '-'}
                                            </td>
                                            <td data-title={'Transaction Id'}>
                                                {data.trans_id ? `${data.trans_id}` : '-'}
                                            </td>
                                            <td data-title={'Paid On'}>
                                                {dateTimeFormatFunction(data.created_at)}
                                            </td>
                                            <td data-title={'Card'}>
                                                {data.payment_method === 'cash' ? (
                                                    <>{'Cash'}</>
                                                ) : (
                                                    <>{capitalize(data.card_type)}</>
                                                )}
                                            </td>
                                            <td data-title={'Amount'}>
                                                {currencyFormat(data.amount)}
                                            </td>
                                            <td
                                                data-title={'Payment Status'}
                                                style={{
                                                    color:
                                                        data.status === 'success' ? 'green' : 'red',
                                                }}
                                            >
                                                {capitalize(data.status)}
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-center">
                                                    <Button
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                `invoice/${data.buynow_common_invoice}`,
                                                            )
                                                        }
                                                    >
                                                        <span className="material-icons">
                                                            open_in_new
                                                        </span>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                                count={Math.ceil(
                                    search_allusertransactions.totalRecords / search.limit,
                                )}
                                page={search.page}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>
        </DashboardLayout>
    )
}

export default MyTransactions
