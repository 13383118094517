import React, { useState, useContext, useEffect, useRef } from 'react'
import InvoiceCard from '../../custom/components/molecules/InvoiceCard'
import DashboardLayout from './DashboardLayout'
import InvoiceContext from '../../product/context/invoice/invoiceContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import ShipmentTracking from '../../custom/components/organisms/ShipmentTracking'
import { ListItem, Button } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

function MyInvoices(props) {
    const authContext = useContext(AuthContext)
    const invoiceContext = useContext(InvoiceContext)
    const { search_alluserinvoices, getAllUserInvoices } = invoiceContext
    const { isAuthenticated } = authContext

    let [viewProduct, setViewProduct] = useState([])
    const [searchText, setSearchText] = useState('')

    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        orderby: 1,
        checkout: 1,
        action: props.match.params.action,
        filters: {
            active: {
                value: '2',
                type: 'notin',
                field: 'b.active',
            },
            misc: {
                value: '',
                type: 'like',
            },
        },
    })

    const handleSearchTextChange = () => {
        setSearch({
            ...search,
            filters: {
                ...search.filters,
                misc: { ...search.filters.misc, value: searchText },
            },
        })
        getAllUserInvoices(search)
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearchTextChange()
        }
    }

    useEffect(() => {
        // setSearch({
        //     ...search,
        //     action:
        //         props.match.params.action === 'all'
        //             ? 'allinv'
        //             : props.match.params.action === 'partial'
        //             ? (props.match.params.action = 'unpaid')
        //             : props.match.params.action,
        // })

        if (
            props.match.params.action === 'paid' ||
            props.match.params.action === 'all' ||
            props.match.params.action === 'unpaid' ||
            props.match.params.action === 'partial'
        ) {
            setSearch({
                ...search,
                action:
                    props.match.params.action === 'all'
                        ? 'allinv'
                        : props.match.params.action === 'partial'
                        ? 'partial'
                        : props.match.params.action === 'unpaid'
                        ? 'unpaid'
                        : props.match.params.action,
                filters: {
                    ...search.filters,
                    partial: {
                        value: '1',
                        type: 'notin',
                        field: 'b.partial_return_invoice',
                    },
                    returned: {
                        value: '1',
                        type: 'notin',
                        field: 'b.returned',
                    },
                },
            })
        } else {
            setSearch({
                ...search,
                action:
                    props.match.params.action === 'all'
                        ? 'allinv'
                        : props.match.params.action === 'partial'
                        ? 'partial'
                        : props.match.params.action === 'unpaid'
                        ? 'unpaid'
                        : props.match.params.action,
                filters: {
                    active: {
                        value: '2',
                        type: 'notin',
                        field: 'b.active',
                    },
                    misc: {
                        value: '',
                        type: 'like',
                    },
                },
            })
        }
    }, [props.match.params.action])

    useEffect(() => {
        if (isAuthenticated) {
            getAllUserInvoices(search)
        }
    }, [search, isAuthenticated])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setViewProduct(search_alluserinvoices.records.length ? search_alluserinvoices.records : [])
    }, [search_alluserinvoices])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const [manageTracking, setManageTracking] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleTrackingPopup = (popup, status, data) => {
        if (popup) {
            setManageTracking({ popup, status, data })
        } else {
            setManageTracking({ popup, status: 'new', data: null })
        }
    }

    return (
        <DashboardLayout title={'My Invoices'}>
            <div className="dashboardInner myInvoices">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoices/all">
                            All
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoices/paid">
                            Paid
                        </NavLink>
                    </ListItem>

                    {/* <ListItem button>
                        <NavLink activeClassName="active" to="/invoices/partial">
                            Partial
                        </NavLink> 
                    </ListItem> */}

                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoices/unpaid">
                            Unpaid
                        </NavLink>
                    </ListItem>
                </div>
                <div className="searchMiscFilters w-100 mb-3 d-flex justify-content-end align-items-end">
                    <div className="headerSearchForm mb-search-form mb-4">
                        <input
                            className="form-control"
                            type="search"
                            placeholder="Search NPS Products"
                            aria-label="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={handleSearchKeyDown}
                        />
                        <Button onClick={handleSearchTextChange} className="btn">
                            <span className="material-icons">search</span>
                        </Button>
                    </div>
                </div>

                {viewProduct.length ? (
                    <>
                        <div className="searchResults Grid">
                            {viewProduct.map((data, index) => (
                                <>
                                    <InvoiceCard
                                        data={data}
                                        toggleTrackingPopup={toggleTrackingPopup}
                                        returned={
                                            props.match.params.action === 'returned' ? true : false
                                        }
                                    />
                                </>
                            ))}
                        </div>
                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-4 pagination-wrapper col-md-12">
                            <h6 style={{ marginLeft: '12px' }}>
                                {'Showing'} {search_alluserinvoices.setDisp} {'Of'}{' '}
                                {search_alluserinvoices.totalRecords}
                            </h6>
                            <Pagination
                                count={Math.ceil(
                                    search_alluserinvoices.totalRecords / search.limit,
                                )}
                                page={search.page}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>
            <ShipmentTracking data={manageTracking} function={toggleTrackingPopup} />
        </DashboardLayout>
    )
}

export default MyInvoices
