import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import CustomCommonContext from '../../../context/common/commonContext'
import Marquee from 'react-fast-marquee'
import './Banner.css'

const Banner = () => {
    const customCommonContext = useContext(CustomCommonContext)
    const { allNotificationBanner, setSignupModal, setLoginModal } = customCommonContext
    const history = useHistory()
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

    const handleLink = (data) => {
        if (data === 'signup') {
            setSignupModal(true)
        } else if (data === 'login') {
            setLoginModal(true)
        } else {
            history.push(`${data}`)
        }
    }

    // Find the first active banner
    const firstActiveBanner = allNotificationBanner?.find((data) => data.active === 1)

    const mqBckgrndColor = firstActiveBanner?.background_color || 'transparent'

    // Filter out empty notification banners
    const filteredBanners = allNotificationBanner?.filter(
        (data) => data.active === 1 && data.title.trim() !== '',
    )

    return (
        <div className="heroCarousel">
            {filteredBanners && filteredBanners.length ? (
                <Marquee style={{ backgroundColor: 'black' }}>
                    {filteredBanners.map((data, index) => (
                        <Link to={data.link} key={index}>
                            <span
                                className="marquee-item"
                                style={{
                                    color: data.text_color,
                                    padding: '6px 0px',
                                    display: 'block',
                                }}
                            >
                                {data.title}
                            </span>
                        </Link>
                    ))}
                </Marquee>
            ) : null}
        </div>
    )
}

export default Banner
