import { Button, Fade, Tooltip } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import './ProductCard.css'
import FavouriteCheckbox from '../../../../product/components/atoms/FavoriteCheckbox'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import Timer from '../../../../product/common/timer'
import Bidding from '../../molecules/Bidding/BiddingItem'
import Buynow from '../../molecules/Buynow/BuynowItem'
import { dateFormatFront } from '../../../common/components'
import { handleRedirectInternal } from '../../../common/components'
import CommonContext from '../../../../product/context/common/commonContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import AuthContext from '../../../../product/context/auth/authContext'
import { currencyFormat } from '../../../common/components'
import UpdatePhoneNumber from './../../organisms/UpdatePhoneNumber/index'
import alertContext from '../../../../product/context/alert/alertContext'
import AvatarImage from '../../atoms/AvatarImage'

const Grid = (props) => {
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { setBidHistoryValue } = commonContext
    const { user, isAuthenticated } = authContext
    // console.log(user, 'user', isAuthenticated, 'is authenticated')
    const { configVariables, allLocations, allCondition } = customCommonContext
    const { setAlert } = useContext(alertContext)

    const [product, setProduct] = useState()

    const history = useHistory()
    const [manageuser, setManageUser] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const onMakePaymentClick = (data) => {
        if (user.phone == null) {
            toggleFullScreenUserPopup(true, 'new', null)
            setAlert('Please enter phone number', 'error')
        } else {
            handleRedirectInternal(history, `checkout/auction/${data.group_carts}`)
        }
    }
    const toggleFullScreenUserPopup = (popup, status, data) => {
        setManageUser({ popup, status, data })
    }
    useEffect(() => {
        setProduct(props.data)
        // console.log('props.data', props.data)
    }, [props.data, props.data.bidtopstatus])

    const addDefaultSrc = (e) => {
        e.target.src = '/assets/images/noimage.png'
    }

    const didUserBid = (userID, bidHistory) => {
        let didBid = false
        if (bidHistory?.length) {
            bidHistory.forEach((element, index, array) => {
                if (element.user_id === userID) {
                    didBid = true
                }
            })
        }
        return didBid
    }
    const productBiddingStatus = (userInfo, productInfo) => {
        if (product.bidtopstatus && product.bidtopstatus !== '') return product.bidtopstatus
        if (userInfo && productInfo) {
            // return none if no top status
            if (productInfo?.bidhistory?.length < 1) {
                return 'none'
            }
            if (didUserBid(userInfo.id, productInfo?.bidhistory)) {
                if (productInfo.market_status === 'closed') {
                    if (productInfo.bidwinneruser === userInfo.id) {
                        return 'won'
                    } else {
                        return 'lost'
                    }
                }
                if (productInfo.market_status === 'open') {
                    if (productInfo?.bidhistory[0]?.user_id === userInfo.id) {
                        return 'winner'
                    } else {
                        return 'outbid'
                    }
                }
            }
            return 'none'
            //check if user is winning

            //check
        }
    }

    return (
        <Fade in={true} timeout={600}>
            <div className="productCardGrid">
                {product ? (
                    <>
                        <div className="pcgImg">
                            <AvatarImage
                                data={product}
                                onClick={props.drawerHandler}
                                folder="product"
                            />
                            {isAuthenticated &&
                                history.location.pathname != '/purchases/auction_paid' &&
                                history.location.pathname != '/purchases/buynow' && (
                                    <>
                                        {(product.bidtopstatus === 'outbid' ||
                                            productBiddingStatus(user, product) === 'outbid') && (
                                            <h4
                                                className="productWinningStatus outbid"
                                                id="mybids_outbid"
                                            >
                                                {'Outbid'}
                                            </h4>
                                        )}
                                        {(product.bidtopstatus === 'winner' ||
                                            productBiddingStatus(user, product) === 'winner') && (
                                            <h4
                                                className="productWinningStatus winning"
                                                id="mybids_winning"
                                            >
                                                {'Winning'}
                                            </h4>
                                        )}
                                        {(product.bidtopstatus === 'won' ||
                                            productBiddingStatus(user, product) === 'won') && (
                                            <h4
                                                className="productWinningStatus won"
                                                id="mybids_won"
                                            >
                                                {'Won'}
                                            </h4>
                                        )}
                                        {(product.bidtopstatus === 'lost' ||
                                            productBiddingStatus(user, product) === 'lost') && (
                                            <h4
                                                className="productWinningStatus lost"
                                                id="mybids_lost"
                                            >
                                                {'Lost'}
                                            </h4>
                                        )}

                                        {/* {(product.bidtopstatus === 'outbid' ||
                                        product.bidtopstatus === 'winner') &&
                                        product.wprice <= product.rprice && (
                                            <>
                                                <Tooltip title="Reserve price not met">
                                                    <span className="material-icons reserveNotMet">
                                                        lock_open
                                                    </span>
                                                </Tooltip>
                                            </>
                                        )} */}
                                    </>
                                )}
                        </div>

                        <h2
                            id={'searchpage_gridProdTitle'}
                            className="gridProdTitle"
                            onClick={props.drawerHandler}
                        >
                            {product.title}
                        </h2>

                        {/* <div className="gridLotDetails d-flex justify-content-start align-items-center">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: product.desc_proc,
                                }}
                            ></p>
                        </div> */}
                        {/* <div className="gridBidInfo d-flex justify-content-between align-items-center">
                            <h6>{'auction'} id:</h6>
                            <p>
                                <span>{`#${product.auctionid}`}</span>
                            </p>
                        </div> */}

                        {product.auction ? (
                            <>
                                {product.market_status === 'open' ? (
                                    <>
                                        <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                            <div>
                                                <p className="" id="grid_starting_price">
                                                    {currencyFormat(product.wprice, false)}
                                                </p>
                                                <h6 id={`${'grid_' + product.cbidtext + '_text'}`}>
                                                    {product.cbidtext}{' '}
                                                </h6>
                                            </div>
                                            {isAuthenticated &&
                                                product?.proxy_bid_details?.user_id === user?.id &&
                                                (product.bidtopstatus === '' ||
                                                    product?.bidtopstatus === 'winner') &&
                                                product?.custom_auctionlot_bid_type === 'proxy' && (
                                                    <div>
                                                        <p id="grid_proxybid_price">
                                                            {currencyFormat(
                                                                product?.proxy_bid_details
                                                                    ?.maxamount,
                                                            )}
                                                        </p>
                                                        <h6 id="grid_myproxybid_text">
                                                            {'My Proxy Bid'}{' '}
                                                        </h6>
                                                    </div>
                                                )}

                                            <div className="d-flex justify-content-between align-items-center">
                                                {product.auction ? (
                                                    <div className="gridMiscInfo">
                                                        <p
                                                            className="cursorDecoy"
                                                            id="grid_bidcount"
                                                            onClick={() =>
                                                                setBidHistoryValue(product.id)
                                                            }
                                                        >
                                                            {product.bid_count}
                                                        </p>
                                                        <span className="material-icons">
                                                            gavel
                                                        </span>
                                                    </div>
                                                ) : null}

                                                {/* <div className="gridMiscInfo">
                                                    <p className="cursorDecoy" id="grid_bidcount">
                                                        {product.product_view_counts}
                                                    </p>
                                                    <span className="material-icons">
                                                        visibility
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                            <p className="d-flex justify-content-between align-items-end w-100">
                                                <div>
                                                    <p
                                                        className={`${
                                                            product.market_status === 'closed'
                                                                ? 'text-danger'
                                                                : ''
                                                        }`}
                                                    >
                                                        {currencyFormat(product.buynowamount)}
                                                    </p>
                                                    <h6>{product.cbidtext}</h6>
                                                </div>
                                                <div className="gridMiscInfo">
                                                    <p
                                                        className="cursorDecoy"
                                                        onClick={() =>
                                                            setBidHistoryValue(product.id)
                                                        }
                                                    >
                                                        {product.bid_count}
                                                    </p>
                                                    <span className="material-icons">gavel</span>

                                                    {/* <p className="cursorDecoy" id="grid_bidcount">
                                                        {product?.product_view_counts}
                                                    </p>
                                                    <span className="material-icons">
                                                        visibility
                                                    </span> */}
                                                </div>
                                            </p>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : product.market_status === 'open' ? (
                            <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="" id="grid_buynow_price">
                                        {currencyFormat(product.bprice)}
                                    </p>
                                    <h6>Buy Now Price </h6>
                                </div>
                            </div>
                        ) : (
                            <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="">{currencyFormat(product.bprice)}</p>
                                    <h6>{product.cbidtext}</h6>
                                </div>
                            </div>
                        )}

                        {props.from === 'dashboard' &&
                        (props.action === 'won' || props.action === 'buynow') &&
                        product.buynowed &&
                        product.buynowed === user?.id ? (
                            <div className="actionAfterWin">
                                {parseInt(product.buynowpaid, 10) === 1 ? (
                                    <>
                                        <SecondaryButton
                                            label={'View Invoice'}
                                            id="grid_viewinvoice"
                                            btnSize="small"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <PrimaryButton
                                            label={'Make Payment'}
                                            id="grid_makepayment"
                                            btnSize="small"
                                            onClick={() => onMakePaymentClick(product)}
                                        />
                                    </>
                                )}
                            </div>
                        ) : product.market_status === 'open' && !product.future_active ? (
                            <>
                                {product.auction ? (
                                    <div className="gridBidBox">
                                        <Bidding
                                            data={product}
                                            type={
                                                product.custom_auctionlot_bid_type ||
                                                product.bid_type
                                            }
                                        />
                                    </div>
                                ) : null}
                                {product.buynow && !product.auction ? (
                                    <div className="gridBidBox">
                                        <Buynow
                                            data={product}
                                            editQuantity={true}
                                            isOnlyBuynow={product.auction === 0 ? true : false}
                                        />
                                    </div>
                                ) : null}
                            </>
                        ) : product.buynowed && product.buynowed === user?.id ? (
                            <div className="actionAfterWin">
                                {parseInt(product.buynowpaid, 10) === 1 ? (
                                    <>
                                        <SecondaryButton
                                            label={'View Invoice'}
                                            btnSize="small"
                                            id="grid_viewinvoice"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <PrimaryButton
                                            label={'Make Payment'}
                                            btnSize="small"
                                            onClick={() => {
                                                handleRedirectInternal(
                                                    history,
                                                    `checkout/auction/${product.group_carts}`,
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        ) : product.market_status === 'open' ? (
                            <div className="gridBidBox"></div>
                        ) : null}

                        {product.market_status === 'open' ? (
                            <div className="gridTimer d-flex justify-content-start align-items-center">
                                <span className="material-icons-outlined">timer</span>
                                <p className="timeChg">
                                    {product.date_closed ? (
                                        <Timer
                                            date_added={product.date_added}
                                            date_closed={product.date_closed}
                                            withText={1}
                                            endText={'Time left' + ':'}
                                            startText={'Starts in' + ':'}
                                        ></Timer>
                                    ) : null}
                                </p>
                            </div>
                        ) : (
                            <div className="gridTimer d-flex justify-content-center align-items-center">
                                <h6>{'Closed On'}:</h6>
                                <p className="d-flex align-items-center">
                                    {dateFormatFront(product.date_closed)}
                                </p>
                            </div>
                        )}

                        <div className="gridAucInfo">
                            <p>
                                <span id="grid_condition_text" className="material-icons-outlined">
                                    inventory_2
                                </span>
                                Condition:
                            </p>
                            {allCondition
                                .filter((value) => product.conditionTypeId === value.id)
                                .map((location) => (
                                    //    console.log(location),
                                    <Tooltip title={location.detail}>
                                        <span className="adrsInfoCrd cursorHelper">
                                            {location.description}
                                        </span>
                                    </Tooltip>
                                ))}
                        </div>

                        <div className="gridAucInfo">
                            <p id="grid_deliverymethod_text">
                                <span className="material-icons-round">warning_amber</span>
                                {/* {product.custom_auctionlot_local_pickup_check === 1 &&
                                product.custom_auctionlot_shipping_check === 1
                                    ? 'Local pickup & Shipping'
                                    : product.custom_auctionlot_local_pickup_check === 1
                                    ? 'Local Pickup only'
                                    : product.custom_auctionlot_shipping_check === 1
                                    ? 'Shipping only'
                                    : null} */}
                                {product.locations_local_pickup_check === 1 &&
                                product.custom_auctionlot_shipping_check === 1
                                    ? 'Local pickup & Shipping'
                                    : product.locations_local_pickup_check === 1
                                    ? 'Local Pickup only'
                                    : product.custom_auctionlot_shipping_check === 1
                                    ? 'Shipping only'
                                    : null}
                            </p>
                        </div>
                        <div className="gridAucInfo">
                            <p>
                                <span className="material-icons-outlined">place</span>
                                Location:
                            </p>
                            {allLocations
                                .filter((value) => product.location_id === value.id)
                                .map((location) => (
                                    //    console.log(location),
                                    <span id="grid_location_text" className="adrsInfoCrd addrsLoc">
                                        {location.name}
                                    </span>
                                ))}
                        </div>
                        <div className="gridAucInfo">
                            <p id="grid_quantity_text">
                                <span className="material-icons-outlined">add_circle_outline</span>
                                Quantity:
                            </p>
                            <span className="adrsInfoCrd qty">{product.qty}</span>
                        </div>

                        {product.lot && (
                            <div className="gridAucInfo">
                                <p>
                                    <span className="material-icons-outlined">
                                        add_circle_outline
                                    </span>
                                    Lot:
                                </p>
                                <span className="adrsInfoCrd qty">{product.lot}</span>
                            </div>
                        )}
                        <div className="moreInfo d-flex justify-content-between align-items-center">
                            <Button onClick={props.drawerHandler} id="grid_viewdetails_button">
                                Quick View
                            </Button>
                            <Button
                                onClick={() => {
                                    const productViewURL = `/productView/${product.id}${
                                        props.pastAuction ? '?pastAuction=true' : ''
                                    }`
                                    window.open(productViewURL)
                                }}
                                id="grid_viewdetails_button"
                            >
                                {`View Full Details`}
                            </Button>
                            {product.market_status === 'open' && (
                                <FavouriteCheckbox
                                    watchlisted={product.watchlistid}
                                    project_id={product.id}
                                    icon_type="visibility"
                                />
                            )}
                        </div>
                    </>
                ) : null}
                <div className="PaymentModal">
                    <UpdatePhoneNumber data={manageuser} function={toggleFullScreenUserPopup} />
                </div>
            </div>
        </Fade>
    )
}

export default Grid
