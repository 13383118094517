import React, { useContext, useEffect, useState } from 'react'
import { mapData } from '../../../../product/common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import ShipengineContext from '../../../../product/context/shipping/shipengine/shipengineContext'
import UserContext from '../../../../product/context/user/userContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../../../product/context/auth/authContext'
import Popup from '../../organisms/Popup'
import AlertContext from './../../../../product/context/alert/alertContext'
import ProductCommonContext from './../../../../product/context/common/commonContext'
import Loaders from '../../molecules/Loaders'

function AddressDetails(props) {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const { user, loadUser } = authContext
    const { setAlert } = useContext(AlertContext)
    const { userAddress, responseStatus: responseStatusUser } = userContext
    const productCommonContext = useContext(ProductCommonContext)
    const { getAllCountries, getAllStates, allCountries, allStates } = productCommonContext
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [reload, setReload] = useState(false)

    const {
        responseStatus: responseStatusShipEngine,
        validateAddress,
        clearResponse: clearShipEngineResponse,
    } = useContext(ShipengineContext)

    const validationAddress = Yup.object({
        first_name: Yup.string()
            .matches(/^[a-zA-Z ]*$/g, 'No Special Characters and Numbers Allowed')
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        last_name: Yup.string()
            .matches(/^[a-zA-Z ]*$/g, 'No Special Characters and Numbers Allowed')
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        address1: Yup.string().required('Required!'),
        country: Yup.string().required('Required!'),
        city: Yup.string().required('Required!'),
        state: Yup.string().required('Required!'),
        zip: Yup.string().required('Required!'),
    })
    const addressformik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            is_primary: '',
            city: '',
            state: '',
            zip: '',
            companyname: '',
            user_id: user ? user.id : '',
            address1: '',
            address2: '',
            phone: user ? user.phone : '',
            country: '',
            id: '',
            isVerified: false,
        },
        validationSchema: validationAddress,
        onSubmit: (values) => {
            console.log(values, props.data, 'values and props')
            setDisabled(true)
            if (!values.isVerified) {
                // delete values.isVerified
                validateAddress(values)
                return false
            } else {
                userAddress(values)
                addressformik.resetForm()
            }
            setLoading(true)
            // updateStripeCard(values)
        },
    })
    useEffect(() => {
        if (props.data.status === 'new') {
            addressformik.values.first_name = ''
            addressformik.values.last_name = ''
            addressformik.values.address1 = ''
            addressformik.values.address2 = ''
            addressformik.values.city = ''
            addressformik.values.state = ''
            addressformik.values.country = ''
            addressformik.values.zip = ''
            addressformik.values.id = ''
            addressformik.values.is_primary = '1'
        }
        console.log(user, 'userrr')
    }, [props.data])
    const defaultFormik = useFormik({
        initialValues: {
            is_primary: '',
            id: '',
            status: '',
        },
        onSubmit: (values) => {
            if (values.id) {
                setDisabled(true)
                userAddress(values)
            }
        },
    })
    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'updateProfile' ||
                responseStatusUser.from === 'updatePreference' ||
                responseStatusUser.from === 'userAddress'
            ) {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                    console.log('get all countrys')
                }
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                defaultFormik.values.id = props.data.data.id
                defaultFormik.values.status = 'inactive'
                defaultFormik.values.is_primary = ''
                defaultFormik.handleSubmit()
            } else if (props.data.status === 'default') {
                defaultFormik.values.id = props.data.data.id
                defaultFormik.values.is_primary = '1'
                defaultFormik.values.status = 'active'
                defaultFormik.handleSubmit()
            } else if (props.data.status === 'update') {
                addressformik.values.first_name = props.data.data.first_name
                addressformik.values.last_name = props.data.data.last_name
                addressformik.values.address1 = props.data.data.address1
                addressformik.values.address2 = props.data.data.address2
                addressformik.values.city = props.data.data.city
                addressformik.values.state = props.data.data.state
                addressformik.values.country = props.data.data.country
                addressformik.values.zip = props.data.data.zip
                addressformik.values.id = props.data.data.id
                addressformik.values.is_primary = props.data.data.is_primary
                setReload(!reload)
            }
            console.log(props.data.data, 'status')
        }
    }, [props.data.data])
    useEffect(() => {
        if (responseStatusUser) {
            setDisabled(false)
            addressformik.resetForm()
            setLoading(false)
        }
    }, [responseStatusUser])
    const addressDetails = {
        formik: addressformik,
        data: [
            {
                label: 'First name',
                type: 'text',
                placeholder: 'Enter your first name',
                class: 'col-12',
                name: 'first_name',
            },
            {
                label: 'Last name',
                placeholder: 'Enter your last name',
                class: 'col-12',
                type: 'text',
                name: 'last_name',
            },
            {
                label: 'Address1',
                placeholder: 'Enter your address1',
                class: 'col-12',
                type: 'text',
                name: 'address1',
            },
            {
                label: 'Address2',
                placeholder: 'Enter your address2',
                class: 'col-12',
                type: 'text',
                name: 'address2',
            },
            {
                label: 'City',
                placeholder: 'Enter your city',
                class: 'col-12',
                type: 'text',
                name: 'city',
            },
            {
                label: 'Country',
                placeholder: 'Select your country',
                class: 'col-12',
                type: 'select',
                options: allCountries
                    .filter((country) => country.isoCode == 'US')
                    .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.isoCode
                        return busCategoryChanged
                    }),
                name: 'country',
            },
            {
                label: 'State',
                placeholder: 'Select your state',
                class: 'col-12',
                type: 'select',
                options: allStates
                    .filter(
                        (state) =>
                            (addressformik.values.country
                                ? addressformik.values.country.indexOf(state.countryCode) !== -1
                                : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                            global.ignoreStates.indexOf(state.isoCode) === -1,
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.isoCode
                        return busCategoryChanged
                    }),
                name: 'state',
            },
            {
                label: 'Zip',
                placeholder: 'Enter your ZIP code',
                class: 'col-6',
                type: 'text',
                name: 'zip',
            },
        ],
    }
    useEffect(() => {
        if (responseStatusShipEngine) {
            if (responseStatusShipEngine.from === 'validateAddress') {
                if (responseStatusShipEngine.status === 'success') {
                    addressformik.setFieldValue('isVerified', true)
                    addressformik.handleSubmit()
                } else {
                    setAlert('Address is invalid please check', 'error')
                    addressformik.handleReset()
                    setDisabled(false)
                }
            }
            clearShipEngineResponse()
        }
    }, [responseStatusShipEngine])

    useEffect(() => {
        if (user) {
            addressformik.values.user_id = user.id
        }
    }, [user])
    return (
        <>
            <Loaders name="home" isLoading={loading} />
            <Popup
                open={
                    props.data.popup &&
                    (props.data.status === 'new' || props.data.status === 'update')
                }
                size="md"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Address Details"
            >
                <div className="addressInfo">
                    <form onSubmit={addressformik.handleSubmit} autoComplete="nofill">
                        {mapData(addressDetails)}
                        <div className="col-12">
                            <PrimaryButton
                                // disabled={disabled}
                                btnSize="large"
                                type="submit"
                                label={'Save'}
                                disabled={disabled}
                            />
                        </div>
                    </form>
                </div>
            </Popup>
        </>
    )
}

export default AddressDetails
