import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PayContext from '../../product/context/payment/payContext'
import AuthContext from '../../product/context/auth/authContext'
import Lottie from 'react-lottie'
import animationData from '../../assets/lottie/paymentAnimation.json'
import queryString from 'query-string'
import { handleRedirectInternal } from '../../product/common/components'
import './Response.css'

const Rosoom = (props) => {
    const payContext = useContext(PayContext)
    const authContext = useContext(AuthContext)

    const { isAuthenticated, loadUser } = authContext
    const history = useHistory()
    const { responseCartRosoom, responseStatus: responseStatusPayment } = payContext

    useEffect(() => {
        if (props.location.search) {
            const response = queryString.parse(props.location.search)
            responseCartRosoom({ response })
        }
    }, [props.location.search])

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'rosoomResponse') {
                if (responseStatusPayment.status === 'success') {
                    if (responseStatusPayment.data && responseStatusPayment.data.pay_type) {
                        if (responseStatusPayment.data.pay_type === 'deposit') {
                            if (isAuthenticated) {
                                loadUser()
                            }
                            handleRedirectInternal(history, 'wallet')
                        } else if (responseStatusPayment.data.pay_type === 'bid_deposit') {
                            handleRedirectInternal(history, 'search')
                        } else {
                            handleRedirectInternal(history, 'invoices/all')
                        }
                    } else {
                        handleRedirectInternal(history, 'invoices/all')
                    }
                } else {
                    if (responseStatusPayment.data && responseStatusPayment.data.pay_type) {
                        if (responseStatusPayment.data.pay_type === 'deposit') {
                            handleRedirectInternal(history, 'wallet')
                        } else if (responseStatusPayment.data.pay_type === 'bid_deposit') {
                            handleRedirectInternal(history, 'search')
                        } else if (responseStatusPayment.data.pay_type === 'buynow') {
                            handleRedirectInternal(history, 'buynowcart')
                        } else if (responseStatusPayment.data.pay_type === 'auction') {
                            handleRedirectInternal(history, 'cart')
                        }
                    } else {
                        handleRedirectInternal(history, 'invoices/all')
                    }
                }
            }
        }
    }, [responseStatusPayment])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    return (
        <div className="container">
            <div className="paymentResponse">
                <Lottie options={defaultOptions} speed="0.25" height={300} width={400} />

                <h2>{'Hang in there your payment is being processed'}</h2>
                <h4>{'Please do not press the back button or refresh the page'}</h4>
            </div>
        </div>
    )
}

export default Rosoom
