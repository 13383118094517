import React, { useContext, useEffect, useState } from 'react'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import AuthContext from '../../../../product/context/auth/authContext'
import ShipEngineContext from '../../../../product/context/shipping/shipengine/shipengineContext'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, useFormik } from 'formik'
import CustomDialog from '../../organisms/Dialog'
import { Button, ListItem } from '@material-ui/core'
import { currencyFormat, dateFormatFrontDay, capitalize } from '../../../common/components'
import { calculatePercentage, handleRedirectInternal } from '../../../../product/common/components'
import commonContext from '../../../context/common/commonContext'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import Loaders from '../../../../custom/components/molecules/Loaders'
import NoRecordsFound from '../../../../product/components/atoms/NoRecordsFound'

function ShippingData(props) {
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(commonContext)
    const { isAuthenticated, user } = authContext

    const { configVariables } = customCommonContext

    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    let [shippingQuote, setShippingQuote] = useState([])
    const {
        shipping_sample_quotes,
        getSampleQuoteRequest: getSampleQuoteRequest,
        responseStatus: responseStatusShipEngine,
    } = useContext(ShipEngineContext)

    const [toggleDialog, setToggleDialog] = useState(false)
    const history = useHistory()

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const validationShipping = Yup.object({
        project_id: Yup.number().required('Required!'),
    })

    const shippingformik = useFormik({
        initialValues: {
            project_id: '',
        },
        validationSchema: validationShipping,
        onSubmit: (values) => {
            setToggleDialog(true)
            setDisabled(true)
            setIsLoading(true)
            getSampleQuoteRequest(values)
        },
    })

    useEffect(() => {
        setToggleDialog(false)
        setShippingQuote([])
        return () => {
            setToggleDialog(false)
            setShippingQuote([])
        }
    }, [props.data])

    useEffect(() => {
        if (props.data) {
            shippingformik.values.project_id = props.data.id
        }
    }, [props.data])

    useEffect(() => {
        if (shipping_sample_quotes.length) {
            let ratesRes = shipping_sample_quotes[0].apiResponse.rate_response.rates
            if (ratesRes.length > 0) {
                setShippingQuote(ratesRes)
                // setToggleDialog(true)
                setDisabled(false)
            } else {
                setDisabled(false)
            }
            setIsLoading(false)
        } else {
            setDisabled(false)
            setIsLoading(false)
        }
    }, [shipping_sample_quotes])
    useEffect(() => {
        if (responseStatusShipEngine?.status === 'error') {
            setIsLoading(false)
        }
    }, [responseStatusShipEngine])
    return (
        <div>
            {isAuthenticated ? (
                <>
                    {props.data.shipping_check == 1 && (
                        <>
                            {user.address_details.length ? (
                                <form onSubmit={shippingformik.handleSubmit} autoComplete="nofill">
                                    <SecondaryButton
                                        btnSize="small mt-3"
                                        type="submit"
                                        label="View Shipping Price"
                                        disabled={disabled}
                                    />
                                </form>
                            ) : (
                                <Link to="/account/profile">
                                    <SecondaryButton
                                        onClick={() =>
                                            handleRedirectInternal(history, 'account/profile')
                                        }
                                        btnSize="small mt-3"
                                        type="button"
                                        label="Add address to check shipping price"
                                        disabled={disabled}
                                    />
                                </Link>
                            )}{' '}
                        </>
                    )}
                </>
            ) : null}
            <CustomDialog
                title={'Shipping Options'}
                open={toggleDialog}
                className="shippingModal"
                // function={changeDialogStatus}
            >
                <>
                    <Button onClick={() => setToggleDialog(false)} className="modalCloseBtn">
                        <span className="material-icons">close</span>
                    </Button>
                    <div>
                        {isLoading ? (
                            <div className="container">
                                <Loaders name="home" isLoading={isLoading} />
                            </div>
                        ) : (
                            <>
                                {shippingQuote && shippingQuote.length ? (
                                    <div className="shippingModalInner">
                                        {shippingQuote.map((data, index) => (
                                            <>
                                                <ListItem button>
                                                    <div
                                                        className="selectShipping d-flex justify-content-between align-items-start flex-wrap"
                                                        key={'cart_' + index}
                                                    >
                                                        <div className="shipInfo">
                                                            <h4>
                                                                {currencyFormat(
                                                                    calculatePercentage(
                                                                        data.shipping_amount.amount,
                                                                        configVariables?.mark_up_shipping
                                                                            ? configVariables.mark_up_shipping
                                                                            : 0,
                                                                    ).totalAmount,
                                                                )}{' '}
                                                                - {data.service_typee}
                                                            </h4>
                                                            {data.estimated_delivery_date
                                                                ? 'Delivery by  ' +
                                                                  dateFormatFrontDay(
                                                                      data.estimated_delivery_date,
                                                                  )
                                                                : 'Date Not Avaliable'}
                                                            <br />
                                                            Service: {capitalize(data.service_type)}
                                                        </div>
                                                    </div>
                                                </ListItem>
                                            </>
                                        ))}
                                    </div>
                                ) : (
                                    <NoRecordsFound />
                                )}
                            </>
                        )}
                    </div>
                </>
            </CustomDialog>
        </div>
    )
}
export default ShippingData
