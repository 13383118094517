import React from 'react'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import './ErrorPage.css'
import { handleRedirectInternal } from '../../product/common/components'
import { useHistory } from 'react-router-dom'

function ErrorPage() {
    const history = useHistory()

    return (
        <div className="errorPage container d-flex justify-content-between flex-wrap text-center">
            <img className="errorPageImg" src="/assets/svg/404.svg" />
            <div className="errorPageActions w-100">
                <h2>{'Not Found 404'}</h2>
                <h4>
                    {'We Couldn&apos;t Find The Page You Are Looking For'} <br />
                    {'You Can Still Try Exploring Hundreds of Other Product'}
                </h4>
                <PrimaryButton
                    label={'Search for Products'}
                    onClick={() => handleRedirectInternal(history, 'search')}
                />
            </div>
        </div>
    )
}

export default ErrorPage
