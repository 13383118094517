import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import '../../molecules/ProductCard/ProductCard.css'
import FavouriteCheckbox from '../../../../product/components/atoms/FavoriteCheckbox'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '../../../../product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import BuyerContext from '../../../../product/context/buyer/buyerContext'
import AuthContext from '../../../../product/context/auth/authContext'
import PayContext from '../../../../product/context/payment/payContext'
import CreditContext from '../../../../product/context/credit/creditContext'

import ProductContext from '../../../../product/context/product/productContext'
import CommonContext from '../../../../product/context/common/commonContext'

import AlertContext from '../../../../product/context/alert/alertContext'
import CustomDialog from '../../organisms/Dialog'
import { handleRedirectInternal } from '../../../common/components'
import { roundUp, truncateDecimal } from '../../../common/components'
import { currencyFormat } from '../../../../custom/common/components'
import { mapData } from '../../../../product/common/components'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import AuctionContext from '../../../../product/context/auction/auctionContext'

const Bidding = (props) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const payContext = useContext(PayContext)
    const creditContext = useContext(CreditContext)
    const commonContext = useContext(CommonContext)
    const customCommonContext = useContext(CustomCommonContext)
    const productContext = useContext(ProductContext)
    const { setAlert } = useContext(AlertContext)
    const { searchValue } = commonContext
    const { getAllSearchProducts, increaseProductViewCount } = productContext

    const { payCartRosoom } = payContext
    const { addBidDeposit, responseStatus: responseStatusCredit } = creditContext
    let { bidConfirm } = buyerContext
    const { loginModal, setLoginModal } = customCommonContext
    const { user, isAuthenticated } = authContext
    const auctionContext = useContext(AuctionContext)
    const { updateAuctionTerms } = auctionContext
    const [product, setProduct] = React.useState()
    const [toggleTermsDialog, setToggleTermsDialog] = useState(false)
    const [canBid, setCanBid] = useState(true)
    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)
    const [toggleDialogDeposit, setToggleDialogDeposit] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const changeDialogStatusDeposit = () => {
        setToggleDialogDeposit(!toggleDialogDeposit)
    }

    useEffect(() => {
        if (responseStatusCredit) {
            // console.log('responseStatusCredit', responseStatusCredit)
            if (responseStatusCredit.from === 'addBidDeposit') {
                if (responseStatusCredit.status === 'success') {
                    setToggleDialogDeposit(false)
                    getAllSearchProducts(searchValue)
                }
            }
        }
    }, [responseStatusCredit])

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (product && user) {
            product.next_bid = roundUp(product.next_bid, 2)
            if (
                product.custom_auctionlot_auction_groups != '' &&
                product.custom_auctionlot_auction_groups != undefined &&
                product.custom_auctionlot_auction_groups != null
            ) {
                const auction_groups = product.custom_auctionlot_auction_groups.split(',')
                if (auction_groups.length > 0) {
                    if (user?.user_auction_groups?.length > 0) {
                        const temp = auction_groups.find((ele) =>
                            user.user_auction_groups.includes(parseInt(ele)),
                        )
                        if (temp) {
                            setCanBid(true)
                        } else {
                            setCanBid(false)
                        }
                    } else {
                        setCanBid(false)
                    }
                }
            }
        }
    }, [product, user])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(
                product ? product.next_bid : 0,
                `${'Min Bid'} ${
                    product ? currencyFormat(roundUp(product.next_bid, 2)) : currencyFormat(0)
                }`,
            )
            .required('Enter Bid Amount'),
    })
    const validationPayment = Yup.object({
        payment_method: Yup.string().required('required'),
    })

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: props.data.id,
            bidding_type: props.type || '',
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            values.wsprice = truncateDecimal(values.wsprice, 4)
            if (product.deposit && !product.bid_deposit_id) {
                setToggleDialogDeposit(true)
            } else {
                try {
                    await validationArray.validate(values, { abortEarly: false })
                    if (toggleDialog) {
                        bidConfirm(values)
                        // increaseProductViewCount({ id: product.id })
                        formik.values.wsprice = ''
                        setToggleDialog(false)
                    } else {
                        if (!product.user_auction_custom_data_terms && isAuthenticated) {
                            formikTerms.setFieldValue('auction_id', product.auctionid)
                            formikTerms.setFieldValue('id', product.id)
                            setToggleTermsDialog(true)
                        } else {
                            setToggleDialog(true)
                        }
                    }
                } catch (error) {
                    console.log(error)
                    // Yup validation failed
                    setToggleDialog(false)
                    setAlert(
                        `Bid value should be greater than ${currencyFormat(
                            roundUp(product.next_bid, 2),
                        )}`,
                        'error',
                    )
                    // You can handle the error here if needed
                }
            }
        },
    })

    useEffect(() => {
        if (props.type) {
            formik.setFieldValue('bidding_type', props.type)
        }
    }, [props.type])

    const formikPayment = useFormik({
        initialValues: {
            cart_id: props.data.id,
            pay_type: 'bid_deposit',
            payment_method: '',
        },
        validationSchema: validationPayment,
        onSubmit: (values) => {
            formikPayment.setSubmitting(true)
            if (values.payment_method === 'wallet') {
                let formData = {
                    amount: product.deposit_amount,
                    product_id: values.cart_id,
                    credit_notes: `Bid Deposit - ${product.title}`,
                    credit_type: 'user',
                    type: 'projects',
                    creditor_id: user.id,
                }
                addBidDeposit(formData)
            } else if (values.payment_method === 'online') {
                let formData = {
                    cart_id: values.cart_id,
                    payment_object: {
                        type: 'projects',
                        amount: 0,
                    },
                    pay_type: values.pay_type,
                }
                payCartRosoom(formData)
            }
        },
    })
    const removeAlpha = (evt) => {
        const re = /^[+-]?\d*(?:[.,]\d*)?$/
        if (re.test(evt.target.value)) {
            formik.handleChange(evt)
        }
        // evt.target.value === 'e' && evt.preventDefault()
    }
    const bidAmount = [
        {
            helperText:
                props.helpText &&
                (product && product.bidhistory.length > 0
                    ? 'Enter ' + currencyFormat(roundUp(product.next_bid, 2)) + ' or more'
                    : product &&
                      'Minimum_Bid' + ' ' + currencyFormat(roundUp(product.next_bid, 2))),
            label: 'Enter Bid Amt',
            name: 'wsprice',
            type: 'number',
            placeholder: `${'Min Bid'} ${
                product && product.next_bid
                    ? currencyFormat(roundUp(product.next_bid, 2))
                    : currencyFormat(0)
            }`,
            class: '',
            size: 'small',
            disabled: canBid ? false : true,
            autoFocus: false,
            formik: formik,
            onChange: removeAlpha,
        },
    ]
    const modeOfPayment = [
        {
            label: 'Select Mode of Payment',
            placeholder: 'Select Mode of Payment',
            type: 'select',
            class: 'col-12',
            options: [
                { value: 'online', show: 'online' },
                { value: 'wallet', show: 'wallet' },
            ],
            name: 'payment_method',
            formik: formikPayment,
        },
    ]

    const changeTermsDialogStatus = () => {
        setToggleTermsDialog(!toggleTermsDialog)
    }

    const formikTerms = useFormik({
        initialValues: {
            auction_id: 0,
            id: 0,
        },
        onSubmit: (values) => {
            updateAuctionTerms({ ...values, terms: 1 })
            changeTermsDialogStatus()
            setToggleDialog(true)
        },
    })

    // const TermsBidding = {
    //     formik: formikTerms,
    //     data: [
    //         {
    //             label: (
    //                 <h6
    //                     dangerouslySetInnerHTML={{
    //                         __html: product?.custom_auctionlot_terms,
    //                     }}
    //                 ></h6>
    //             ),
    //             name: 'terms',
    //             type: 'checkbox',
    //             placeholder: '',
    //             class: 'col-12 auth-links-wrap',
    //         },
    //     ],
    // }

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt d-flex justify-content-between align-items-start">
                                {Object.values(mapData(bidAmount))}

                                {isAuthenticated ? (
                                    product.bidtopstatus === 'winner' ? (
                                        <SecondaryButton
                                            label={
                                                formik.values.bidding_type === 'proxy'
                                                    ? 'Increase Proxy Bid'
                                                    : 'Increase Hard Bid'
                                            }
                                            type="submit"
                                            btnSize="small"
                                            disabled={canBid ? false : true}
                                            id="bid_increase_button"
                                        />
                                    ) : (
                                        <PrimaryButton
                                            label={
                                                formik.values.bidding_type === 'proxy'
                                                    ? 'Place Proxy Bid'
                                                    : 'Place Hard Bid'
                                            }
                                            type="submit"
                                            btnSize="small"
                                            disabled={canBid ? false : true}
                                            id="bid_place_button"
                                        />
                                    )
                                ) : (
                                    <PrimaryButton
                                        label={'Login to Bid'}
                                        btnSize="small"
                                        onClick={() => setLoginModal(true)}
                                        id="test_login"
                                    />
                                )}
                            </div>
                        </form>
                    ) : null}

                    <CustomDialog
                        title={'Confirm Bid'}
                        open={toggleDialog}
                        function={changeDialogStatus}
                    >
                        <h5>{'You are about to place a bid'}!</h5>
                        <h5>{'Please confirm if you want to continue with this action'}</h5>
                        {product.qty > 1 && (
                            <>
                                <h5>
                                    Your bid is ${formik.values.wsprice}. There are {product.qty}{' '}
                                    items.
                                </h5>
                                <h5>if you accept this bid your payment will be:</h5>
                                <h5>
                                    {formik.values.wsprice} x {product.qty} ={' $'}
                                    {parseFloat(formik.values.wsprice * product.qty).toFixed(4)}
                                </h5>
                            </>
                        )}
                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialog(false)}>{'Cancel'}</Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                id="confirmbid_popup"
                                type="button"
                                label={'Submit'}
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title={'Submit a Deposit'}
                        open={toggleDialogDeposit}
                        function={changeDialogStatusDeposit}
                    >
                        <h4>{'Deposit has to be placed before placing bid on the product'}</h4>
                        <h5>
                            {'Deposit Amount'}: {currencyFormat(product.deposit_amount)}
                        </h5>

                        <div className="row">{Object.values(mapData(modeOfPayment))}</div>

                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialogDeposit(false)}>
                                {'Cancel'}
                            </Button>
                            <PrimaryButton
                                onClick={formikPayment.handleSubmit}
                                type="button"
                                label={'Submit'}
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title="Confirm Terms And Conditions"
                        open={toggleTermsDialog}
                        className="ConfirmModal"
                        disableBackdropClick
                        function={changeTermsDialogStatus}
                    >
                        <>
                            <h5>Please confirm if you want to continue with this action</h5>
                            {/* <form>
                                <div className="row mb-4">
                                    {Object.values(mapData(TermsBidding))}
                                </div>
                                <div className="actionWrapper">
                                    <Button
                                        onClick={() => {
                                            setToggleTermsDialog(false)
                                            // history.goBack()
                                        }}
                                    >
                                        Decline
                                    </Button>
                                    <PrimaryButton
                                        onClick={formikTerms.handleSubmit}
                                        id="confirm_bid_submit"
                                        type="button"
                                        disabled={formikTerms.isSubmitting}
                                        label={'Accept'}
                                    />
                                </div>
                            </form> */}
                            <form onSubmit={formikTerms.handleSubmit}>
                                <div className="row mb-4">
                                    <div className="col-12 auth-links-wrap">
                                        <h6
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    product?.terms ||
                                                    product?.custom_auctionlot_terms,
                                            }}
                                        ></h6>
                                    </div>
                                </div>

                                <div
                                    className="actionWrapper"
                                    style={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="checkbox-wrapper">
                                        <input
                                            type="checkbox"
                                            id="terms"
                                            name="terms"
                                            checked={1}
                                        />
                                        <label htmlFor="terms">
                                            I agree to the Terms & Conditions
                                        </label>
                                    </div>
                                    <div className="auctionBtnWrapper">
                                        <Button
                                            onClick={() => {
                                                setToggleTermsDialog(false)
                                            }}
                                        >
                                            Decline
                                        </Button>
                                        <PrimaryButton
                                            type="submit"
                                            id="confirm_bid_submit"
                                            disabled={formikTerms.isSubmitting}
                                            label={'Accept'}
                                        />
                                    </div>
                                </div>
                            </form>
                        </>
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default Bidding
