import React, { useState, useContext, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import FilterPanel from '../../custom/components/organisms/FilterPanel'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import './Search.css'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'
import { Button, IconButton, Fade } from '@material-ui/core'
import ListView from '../../custom/components/molecules/ProductCard/ListView'
import Drawer from '@material-ui/core/Drawer'
import ProductView from '../../custom/components/organisms/ProductView'
import ProductContext from '../../product/context/product/productContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import CommonContext from '../../product/context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { messageHandler } from '../../product/common/socketHandler'
import AuctionContext from '../../product/context/auction/auctionContext'

import {
    useCustomMediaQuery,
    handleRedirectInternal,
    scrollTop,
} from '../../product/common/components'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import Loaders from '../../custom/components/molecules/Loaders'
import CancelIcon from '@material-ui/icons/Cancel'
import BuynowContext from '../../product/context/buynow/buynowContext'
import Popup from '../../custom/components/organisms/Popup'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})
let randomVariable = Math.random()
const Search = (props) => {
    const history = useHistory()
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const auctionContext = useContext(AuctionContext)
    const { responseStatus: responseStatusAuction } = auctionContext

    const { responseStatus: buynowResponse, clearResponse: clearBuynowResponse } = useContext(
        BuynowContext,
    )
    const { setSearchValue, searchValue, clearSearchValue } = commonContext
    const [loading, setLoading] = useState(false)
    const [savedSearchOn, setSavedSearchOn] = useState(false)
    const [savedSearchTemp, setSavedSearchTemp] = useState('')

    const [customPage, setCustomPage] = useState(1)

    const [isLoading, setIsLoading] = useState(true)

    const classes = useStyles()

    const {
        search_allproducts,
        getAllSearchProducts,
        responseStatus,
        addSavedSearch,
    } = productContext
    const { user, isAuthenticated, saveSearch } = authContext

    // const scrollTo = () =>
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth',
    //     })

    const { setAlert } = alertContext

    const [auctionView, setAuctionView] = useState('Grid')
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const [viewProduct, setViewProduct] = useState([])

    const [allViewProduct, setAllViewProduct] = useState({
        records: [],
        totalRecords: 0,
        setDisp: '',
        sorts: {},
    })

    const [errorPopup, setErrorPopup] = useState(false)
    const [biddingErrorMessage, setBiddingErrorMessage] = useState('')

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 Per Page',
        },
        {
            value: '24',
            show: 'Results 24 Per Page',
        },
        {
            value: '36',
            show: 'Results 36 Per Page',
        },
        {
            value: '72',
            show: 'Results 72 Per Page',
        },
    ]

    const sortShow = [
        {
            value: 'p.id, desc',
            show: 'None',
        },
        {
            value: 'p.wprice, asc',
            show: 'Current Price Low to High',
        },
        {
            value: 'p.wprice, desc',
            show: 'Current Price High to Low',
        },
        {
            value: 'p.date_closed, asc',
            show: 'Time Remaining',
        },
        {
            value: 1,
            show: 'Ending Today',
        },
    ]

    const formik = useFormik({
        initialValues: {
            limit: 12,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                category: {
                    value: [],
                    type: 'array',
                    field: 'it.categoryTypeId',
                },
                searchbar: {
                    value:
                        searchValue && searchValue.filters && searchValue.filters.searchbar
                            ? searchValue.filters.searchbar.value
                            : '',
                    type: 'like',
                    field: 'p.title', //p.desc_proc',
                },
                subCategory: {
                    value: [],
                    type: 'array',
                    field: 'it.subCategoryTypeId',
                },
                condition: {
                    field: 'it.conditionTypeId',
                    type: 'array',
                    value: [],
                },
                local_pickup: {
                    field: 'p.local_pickup_check',
                    type: 'array',
                    value: [],
                },
                shipping: {
                    field: 'p.shipping_check',
                    type: 'array',
                    value: [],
                },
                third_sub_category: {
                    value: [],
                    type: 'array',
                    field: 'it.third_sub_categoryID',
                },
                location: {
                    value: [],
                    type: 'array',
                    field: 'p.location_id',
                },
                shipType: {
                    value: [],
                    type: 'findinarray',
                    field: 'it.shipTypeId',
                },
                price: {
                    value: 0,
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                auctionid: {
                    value: [],
                    type: 'array',
                    field: 'p.auctionid',
                },
            },
        },
    })

    useEffect(() => {
        if (searchValue) {
            console.log('searchValue', searchValue)
            let preFillSearch = {}
            if (searchValue.filters.searchbar && searchValue.filters.searchbar.value) {
                setSavedSearchOn(true)
                setSavedSearchTemp(searchValue.filters.searchbar.value)
                formik.setFieldValue('filters.searchbar.value', searchValue.filters.searchbar.value)
            } else if (
                searchValue.filters.category &&
                searchValue.filters.category.value &&
                searchValue.filters.category.value.length > 0
            ) {
                formik.setFieldValue('filters.category.value', searchValue.filters.category.value)
            } else if (
                searchValue.filters.subCategory &&
                searchValue.filters.subCategory.value &&
                searchValue.filters.subCategory.value.length > 0
            ) {
                formik.setFieldValue(
                    'filters.subCategory.value',
                    searchValue.filters.subCategory.value,
                )
            } else if (
                searchValue.filters.third_sub_category &&
                searchValue.filters.third_sub_category.value &&
                searchValue.filters.third_sub_category.value.length > 0
            ) {
                formik.setFieldValue(
                    'filters.third_sub_category.value',
                    searchValue.filters.third_sub_category.value,
                )
            } else if (
                searchValue.filters.location &&
                searchValue.filters.location.value &&
                searchValue.filters.location.value.length > 0
            ) {
                formik.setFieldValue('filters.location.value', searchValue.filters.location.value)
            } else if (searchValue.filters.price && searchValue.filters.price.value) {
                formik.setFieldValue('filters.price.value', searchValue.filters.price.value)
            } else if (
                searchValue.filters.auctionid &&
                searchValue.filters.auctionid.value &&
                searchValue.filters.auctionid.value.length > 0
            ) {
                formik.setFieldValue('filters.auctionid.value', searchValue.filters.auctionid.value)
            }

            clearSearchValue()
        }
    }, [searchValue])

    useEffect(() => {
        setIsLoading(true)
        randomVariable = Math.random()
        setCustomPage(1)
        getAllSearchProducts(
            {
                page: 1,
                ...formik.values,
                skuSearch: formik?.values?.filters?.searchbar?.value || '',
            },
            randomVariable,
        )
    }, [formik.values, isAuthenticated])

    // Used to change page
    const onHandlePage = (event, value) => {
        setCustomPage(value)
        getAllSearchProducts(
            {
                page: value,
                ...formik.values,
                skuSearch: formik?.values?.filters?.searchbar?.value || '',
            },
            randomVariable,
        )
    }

    useEffect(() => {
        if (search_allproducts.from === randomVariable) {
            setIsLoading(false)
            setViewProduct(search_allproducts.records.length > 0 ? search_allproducts.records : [])
            setAllViewProduct(search_allproducts)
            setTimeout(() => {
                scrollTo(0, 0)
            }, 350)
        }
    }, [search_allproducts])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    // Used to display items in wishlist on page load
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'watchlist') {
                handler(
                    {
                        id: responseStatus.data.project_id,
                        status: responseStatus.data.status,
                    },
                    'watchlistAdded',
                )
            }
        }
    }, [responseStatus])

    const updateFunction = () => {
        randomVariable = Math.random()
        getAllSearchProducts(formik.values, randomVariable)
    }

    // Used to saved search message
    // useEffect(() => {
    //     if (responseStatus) {
    //         if (responseStatus.from === 'saved_search_msg' && responseStatus.data) {
    //             setAlert(
    //                 responseStatus.data.message,
    //                 responseStatus.data.status == 'added' ? 'success' : 'error',
    //             )
    //         }
    //     }
    // }, [responseStatus])

    // Used to set and handle sockets
    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (
                data.status === 'failed' &&
                data.error?.indexOf('suspended') !== -1 &&
                userRef?.current.id == data.bpop_cbidder
            ) {
                setErrorPopup(true)
                setBiddingErrorMessage(data.error)
            } else {
                handler(data, 'bidAddtime')
            }
        })
        socket.on('cancelBidEmitted', (data) => {
            // getAllSearchProducts(search)
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (buynowResponse) {
            if (buynowResponse.from === 'addToCart' && buynowResponse.status === 'error') {
                setErrorPopup(true)
                setBiddingErrorMessage(buynowResponse.message)
            }
            clearBuynowResponse()
        }
    }, [buynowResponse])

    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    // Clears search filter values
    const clearSearchFilter = () => {
        console.log('Coming Here')

        formik.setValues({
            limit: formik.values.limit,
            orderby: formik.values.orderby,
            order: formik.values.order,
            filters: {
                category: {
                    value: [],
                    type: 'array',
                    field: 'it.categoryTypeId',
                },
                searchbar: {
                    value:
                        searchValue && searchValue.filters && searchValue.filters.searchbar
                            ? searchValue.filters.searchbar.value
                            : '',
                    type: 'like',
                    field: 'p.title', //p.desc_proc',
                },
                subCategory: {
                    value: [],
                    type: 'array',
                    field: 'it.subCategoryTypeId',
                },
                condition: {
                    field: 'it.conditionTypeId',
                    type: 'array',
                    value: [],
                },
                local_pickup: {
                    field: 'p.local_pickup_check',
                    type: 'array',
                    value: [],
                },
                shipping: {
                    field: 'p.shipping_check',
                    type: 'array',
                    value: [],
                },
                third_sub_category: {
                    value: [],
                    type: 'array',
                    field: 'it.third_sub_categoryID',
                },
                location: {
                    value: [],
                    type: 'array',
                    field: 'p.location_id',
                },
                shipType: {
                    value: [],
                    type: 'findinarray',
                    field: 'it.shipTypeId',
                },
                price: {
                    value: 0,
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                auctionid: {
                    value: [],
                    type: 'array',
                    field: 'p.auctionid',
                },
            },
        })
    }

    // useEffect(() => {
    //     if (props.location.state) {
    //         setSearch({
    //             ...search,
    //             filters: {
    //                 ...search.filters,
    //                 searchbar: {
    //                     ...search.searchbar,
    //                     value: props.location.state,
    //                 },
    //             },
    //         })
    //     }
    // }, [props.location.state])
    const addSavedSearcData = (search_text) => {
        addSavedSearch({ search_text })
    }

    const productView = (data) => {
        handleRedirectInternal(history, 'productView')
    }

    useEffect(() => {
        if (responseStatusAuction) {
            if (responseStatusAuction.from === 'updateAuctionTerms') {
                if (responseStatusAuction.status === 'success') {
                    randomVariable = Math.random()
                    getAllSearchProducts(formik.values, randomVariable)
                }
            }
        }
    }, [responseStatusAuction])

    return (
        <div className="search customContainer">
            <div className="w-100 searchHead d-flex justify-content-between align-items-center flex-wrap">
                {isAuthenticated &&
                    (formik.values?.filters?.searchbar?.value || savedSearchTemp) &&
                    savedSearchOn && (
                        <>
                            <h4>
                                {'You have searched for'}{' '}
                                <span>
                                    &quot;{formik.values.filters.searchbar.value || savedSearchTemp}
                                    &quot;
                                </span>
                            </h4>
                            <Button
                                id="saved_search"
                                onClick={() =>
                                    addSavedSearcData(
                                        formik.values.filters.searchbar.value || savedSearchTemp,
                                    )
                                }
                            >
                                <span className="material-icons">bookmark_add</span>
                                {/* REPLACE THE FOLLOWING ICON THE ONCE SEARCH HAS BEEN SAVED */}
                                {/* <span className="material-icons">bookmark_added</span> */}
                                {'Save This Search'}
                            </Button>
                        </>
                    )}
                <Button
                    className="toggleRespDrawer"
                    variant="outlined"
                    onClick={toggleDrawer('bottom', true)}
                >
                    <span className="material-icons">tune</span>
                    {'Filters'}
                </Button>
            </div>
            <div className="searchMiscFilters w-100 mb-3 d-flex justify-content-end align-items-end">
                <div className="gridListToggle">
                    <Button
                        className={auctionView === 'Grid' ? 'active' : ''}
                        id="searchpage_grid_button"
                        onClick={() => setAuctionView('Grid')}
                    >
                        <span className="material-icons">apps</span>
                    </Button>
                    <Button
                        className={auctionView === 'List' ? 'active' : ''}
                        id="searchpage_list_button"
                        onClick={() => setAuctionView('List')}
                    >
                        <span className="material-icons">view_list</span>
                    </Button>
                </div>
                <CustomSelect
                    label={'Results Per Page'}
                    name="resultsPerPage"
                    selectType="noBorder"
                    value={formik.values.limit}
                    size="small"
                    onChange={(event, editor) => {
                        formik.setFieldValue('limit', event.target.value)
                    }}
                >
                    {pageOptions.map((opt, optindex) => (
                        <option value={opt.value}>{opt.show}</option>
                    ))}
                </CustomSelect>
                <CustomSelect
                    label={'Sort By'}
                    value={formik.values.orderby}
                    size="small"
                    selectType="noBorder"
                    name="orderby"
                    // shrink={search.orderby !== '' ? true : false}
                    onChange={(event, editor) => {
                        formik.setFieldValue('orderby', event.target.value)
                    }}
                >
                    {sortShow.map((opt, optindex) => (
                        <option value={opt.value}>{opt.show}</option>
                    ))}
                </CustomSelect>
            </div>
            <div className="searchCnt d-flex justify-content-start align-items-start">
                <div className="searchLt">
                    {useCustomMediaQuery('(min-width: 1024px)') ? (
                        <div className="deskFilter">
                            <FilterPanel clearSearch={clearSearchFilter} formik={formik} from={1} />
                        </div>
                    ) : (
                        <Drawer
                            anchor="bottom"
                            open={state['bottom']}
                            className="responsiveFilterDrawer"
                            onClose={toggleDrawer('bottom', false)}
                            onOpen={toggleDrawer('bottom', true)}
                        >
                            <div
                                className={clsx(classes.list, 'bottom')}
                                role="presentation"
                                // onClick={toggleDrawer('bottom', false)}
                                onKeyDown={toggleDrawer('bottom', false)}
                            >
                                <div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="sbTitle">{'Sort By'}</h4>
                                        <IconButton
                                            onClick={toggleDrawer('bottom', false)}
                                            aria-label="close"
                                            className="pt-0"
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </div>

                                    <div className="searchAuctions">
                                        <div className="d-flex justify-content-start align-items-center flex-wrap">
                                            {sortShow.map((opt, optindex) => (
                                                <Button
                                                    key={optindex}
                                                    className={
                                                        formik.values.orderby === opt.value
                                                            ? 'active'
                                                            : null
                                                    }
                                                    onClick={() => {
                                                        formik.setFieldValue('orderby', opt.value)
                                                    }}
                                                >
                                                    {opt.show}
                                                </Button>
                                            ))}
                                        </div>
                                    </div>
                                    <h4 className="sbTitle">{'Results Per Page'}</h4>
                                    <div className="searchAuctions">
                                        <div className="d-flex justify-content-start align-items-center flex-wrap">
                                            {pageOptions.map((opt, optindex) => (
                                                <>
                                                    <Button
                                                        className={
                                                            formik.values.limit == opt.value
                                                                ? 'active'
                                                                : null
                                                        }
                                                        onClick={() => {
                                                            formik.setFieldValue('limit', opt.value)
                                                        }}
                                                    >
                                                        {opt.show}
                                                    </Button>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <FilterPanel
                                    clearSearch={clearSearchFilter}
                                    from={1}
                                    formik={formik}
                                />
                            </div>
                        </Drawer>
                    )}
                </div>
                <div className="searchRt">
                    <div className="d-flex justify-content-between align-items-center"></div>
                    {isLoading ? (
                        <Loaders name="product_grid_view" isLoading={true} loop={6} />
                    ) : viewProduct.length > 0 ? (
                        <>
                            <div
                                className={`searchResults ${auctionView}`}
                                id={`searchpage_${auctionView}`}
                            >
                                <Drawer
                                    className="rightDrawer"
                                    anchor={'right'}
                                    open={state['right']}
                                    onClose={toggleDrawer('right', false)}
                                >
                                    <ProductView
                                        data={state.data}
                                        onClick={() => setState(false)}
                                        updateFunction={updateFunction}
                                    />
                                </Drawer>

                                {viewProduct.map((data, index) => (
                                    <>
                                        {auctionView === 'Grid' ? (
                                            <>
                                                <GridView
                                                    data={data}
                                                    favId={`searchProd_${index}`}
                                                    drawerHandler={toggleDrawer(
                                                        'right',
                                                        true,
                                                        data,
                                                    )}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <ListView
                                                    data={data}
                                                    favId={`searchProd_${index}`}
                                                    drawerHandler={toggleDrawer(
                                                        'right',
                                                        true,
                                                        data,
                                                    )}
                                                />
                                            </>
                                        )}
                                    </>
                                ))}
                            </div>

                            <div
                                id="searchpage_pagination"
                                className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper col-md-12"
                            >
                                <h6>
                                    {'Showing'} {allViewProduct.setDisp} {'of'} &nbsp;
                                    {allViewProduct.totalRecords}
                                </h6>
                                <Pagination
                                    count={Math.ceil(
                                        allViewProduct.totalRecords / formik.values.limit,
                                    )}
                                    page={customPage}
                                    id="pagination_search"
                                    onChange={onHandlePage}
                                    siblingCount={3}
                                    showFirstButton
                                    showLastButton
                                    boundaryCount={2}
                                    // onClick={scrollTop}
                                    // onClick={() =>
                                    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                    // }
                                />
                            </div>
                        </>
                    ) : (
                        <NoRecordsFound />
                    )}
                </div>
            </div>
            <Popup open={errorPopup} size="md" handleClose={() => setErrorPopup(false)}>
                <>
                    <h4 className="suspended-error">
                        {biddingErrorMessage}
                        <br />
                        Please contact customer service
                    </h4>
                </>
            </Popup>

            {/* PRODUCT VIEW DRAWER */}
        </div>
    )
}
export default Search
