import React, { useState, useRef, useEffect, useContext } from 'react'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import { useHistory } from 'react-router-dom'
import {
    handleRedirectInternal,
    dateTimeFormatFunction,
    currencyFormat,
} from '../../../../custom/common/components'
import './InvoiceCard.css'

const InvoiceCard = (props) => {
    const [product, setProduct] = useState()
    const history = useHistory()
    useEffect(() => {
        console.log(props.returned)
        setProduct(props.data)
    }, [props.data])
    return (
        <>
            <div className="invoiceCard">
                {product ? (
                    <>
                        <h3 className="custName">
                            {'Invoice'} #
                            {props.returned ? product.return_invoice : product.common_invoice}
                        </h3>
                        {props.returned ? (
                            <>
                                <h5>
                                    {'Returned'}: {dateTimeFormatFunction(product.returned_date)}
                                </h5>
                            </>
                        ) : (
                            <>
                                {product.paid ? (
                                    <>
                                        <h5>
                                            {'Paid'}: {dateTimeFormatFunction(product.paid_date)}
                                        </h5>
                                    </>
                                ) : (
                                    <>
                                        <h5>
                                            {'Created'}:{' '}
                                            {dateTimeFormatFunction(product.date_added)}
                                        </h5>
                                    </>
                                )}
                            </>
                        )}

                        <div className="icItems d-flex justify-content-between align-items-center">
                            <span>{'Total Items'}:</span> <span>{product.total_items}</span>
                        </div>
                        <div className="icPaid d-flex justify-content-between align-items-center">
                            <span>{'Amount'}:</span>{' '}
                            <span>
                                {currencyFormat(
                                    product.total_invoice
                                        ? product.total_invoice
                                        : product.combined_amount,
                                )}
                            </span>
                        </div>
                        {product.payment_method === 'offline' && (
                            <div className="icPaid d-flex justify-content-between align-items-center">
                                <span>{'Payment Status'}:</span> <span>{'Offline'}</span>
                            </div>
                        )}
                        {(product.partial === 1 || (product.paid === 0 && product.partial === 0)) &&
                        product.payment_method !== 'offline' ? (
                            <>
                                {console.log(product, 'product')}
                                <PrimaryButton
                                    label={'Pay Invoice'}
                                    id="pay_invoice"
                                    onClick={() =>
                                        handleRedirectInternal(
                                            history,
                                            product.partial === 1
                                                ? `checkout/invoice/${product.common_invoice}`
                                                : `checkout/auction/${product.cart_group}`,
                                        )
                                    }
                                    btnSize="small"
                                />
                                {props.returned ? (
                                    <PrimaryButton
                                        label={'View Invoice'}
                                        id="viewinvoice_button"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `invoice/returned/${product.common_invoice}`,
                                            )
                                        }
                                        btnSize="small"
                                    />
                                ) : (
                                    <PrimaryButton
                                        label={'View Invoice'}
                                        id="viewinvoice_button"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `invoice/${product.common_invoice}`,
                                            )
                                        }
                                        btnSize="small"
                                    />
                                )}

                                {(product.paid != 0 || product.partial != 0) && (
                                    <>
                                        {/* <PrimaryButton
                                            label={'View Invoice'}
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${product.common_invoice}`,
                                                )
                                            }
                                            btnSize="small"
                                        /> */}
                                        {product.delivery_type != 'local_pickup' ? (
                                            <PrimaryButton
                                                btnSize="mt-1"
                                                onClick={() =>
                                                    props.toggleTrackingPopup(true, 'view', product)
                                                }
                                                label="Tracking Info"
                                                id="tracking_info"
                                            />
                                        ) : null}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {props.returned ? (
                                    <PrimaryButton
                                        label={'View Invoice'}
                                        id="viewinvoice_button"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `invoice/returned/${product.return_invoice}`,
                                            )
                                        }
                                        btnSize="small"
                                    />
                                ) : (
                                    <PrimaryButton
                                        label={'View Invoice'}
                                        id="viewinvoice_button"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `invoice/${product.common_invoice}`,
                                            )
                                        }
                                        btnSize="small"
                                    />
                                )}
                                {product.delivery_type === 'local_pickup' && !props.returned ? (
                                    product.status !== 'no_show' ? (
                                        <PrimaryButton
                                            label={
                                                product.appointment_id > 0
                                                    ? 'Reschedule Appointment'
                                                    : 'Book Appointment'
                                            }
                                            id={
                                                product.appointment_id > 0
                                                    ? 'reschedule_appointment'
                                                    : 'book_appointment'
                                            }
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `appointment/schedule/${product.common_invoice}`,
                                                )
                                            }
                                            btnSize="small"
                                        />
                                    ) : null
                                ) : null}
                                {product.delivery_type != 'local_pickup' && !props.returned ? (
                                    <PrimaryButton
                                        btnSize="mt-1"
                                        onClick={() =>
                                            props.toggleTrackingPopup(true, 'view', product)
                                        }
                                        label="Tracking Info"
                                        id="tracking_info"
                                    />
                                ) : null}
                            </>
                        )}
                    </>
                ) : null}
            </div>
        </>
    )
}

export default InvoiceCard
