import { Button } from '@material-ui/core'
import React from 'react'
import './Dashboard.css'
import SideNav from './SideNav'
import { useHistory } from 'react-router-dom'
const DashboardLayout = (props) => {
    const history = useHistory()
    return (
        <div className="dashboard customContainer">
            <div className="d-flex justify-content-start">
                <div className="dashboardLt">
                    <SideNav />
                </div>
                <div className="dashboardRt">
                    <div className="maTitle d-flex justify-content-between align-items-center w-100">
                        <h2 className="dashTitle">{props.title}</h2>
                        <Button
                            onClick={() => {
                                history.goBack()
                                window.scrollTo(0, 0)
                            }}
                            className="moveBack"
                        >
                            <span className="material-icons">arrow_back</span>
                            {'Back'}
                        </Button>
                    </div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default DashboardLayout
