import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment-timezone'
import axios from 'axios'
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'
import CustomMultiSelect from '../../product/components/atoms/Inputs/CustomMultiSelect'
import MenuItem from '@material-ui/core/MenuItem'
import CustomInput from '../../product/components/atoms/Inputs/CustomInput'
import CustomPhone from '../../product/components/atoms/Inputs/CustomPhone.js'
import CheckBox from '../../product/components/atoms/CheckBox'
import CustomTextArea from '../../product/components/atoms/Inputs/CustomTextArea'
import CKEditor from 'ckeditor4-react'
import { socket } from '../../product/common/socket'

// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

let serverTime = new Date()
const dateFormat = 'MM-DD-YYYY'
const dateTimeFormat = 'MM-DD-YYYY h:mm a'

if (socket) {
    socket.on('sliservertime', (data) => {
        if (moment(data.dTime).isValid()) {
            serverTime = new Date(data.dTime)
        }
    })
}

export const handleRedirectInternal = (history, path) => {
    history.push(`/${path}`)
    window.scrollTo(0, 0)
}
export const dateFormatFront = (data) => {
    return moment(data).isValid()
        ? `${moment(data).format('MMM Do')}, ${moment(data).format('h:mm a')}`
        : '-'
}

export const capitalize = (data) => {
    return data ? data?.charAt(0).toUpperCase() + data.slice(1) : ''
}

export const dateFormatFrontDay = (data) => {
    return `${moment(data).format('MMM Do YYYY')}`
}

export const dateFormatDay = (data) => {
    return `${moment(data).format('DD')}`
}

export const dateFormatMonth = (data) => {
    return `${moment(data).format('MMMM')}`
}

export const roundUp = (num, precision) => {
    if (num) {
        num = num.toString()
        if (num.split('.')[1] !== undefined && parseInt(num.split('.')[0]) >= 1) {
            precision = Math.pow(10, precision)
            return Math.ceil(num * precision) / precision
        } else {
            return num
        }
    }
}

export const currencyFormat = (data) => {
    if (data) {
        let conString = data.toString()
        if (conString.split('.')[1] !== undefined && parseInt(conString.split('.')[0]) < 1) {
            return `US $${parseFloat(data).toFixed(4)}`
        } else {
            return `US $${parseFloat(data).toFixed(2)}`
        }
    }
}

export const checkProductOpen = (date_added, user_role) => {
    let startDate = new Date(serverTime)
    let addDate = new Date(date_added)
    let milliSeconds = 0
    // if (parseInt(user_role) !== 3) {
    //   milliSeconds = 1 * 3600000;
    // } else {
    // }
    let incrementedTime = addDate.getTime() + parseInt(milliSeconds)
    let newaddDate = new Date(incrementedTime)
    if (newaddDate > startDate) {
        return false
        // let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
        // let timerTime = incrementedTime - startDate.getTime();
        // if (timerTime <= 0) {
        //   return true;
        // } else {
        //   return false;
        // }
    } else {
        return true
    }
}

export const maskEmailFront = (data) => {
    data = data.toString()
    var first4 = data.substring(0, 4)
    var last5 = data.substring(data.length - 5)
    var mask = data.substring(4, data.length - 5).replace(/[^]/g, '*')
    return first4 + mask + last5
}

export const dateFormatFrontOnlyDate = (data) => {
    return `${moment(data).format(dateFormat)}`
}

export const dateTimeFormatFunction = (data, utahTime = false) => {
    if (utahTime) {
        return `${moment.tz(data, 'America/Denver').format(dateTimeFormat)}`
    } else {
        return `${moment(data).format(dateTimeFormat)}`
    }
}

export const convertToCustomFormat = (inputTimestamp) => {
    inputTimestamp = inputTimestamp.replace(/[-+]\d{2}:\d{2}$/, '')
    const date = new Date(inputTimestamp)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const customFormattedTimestamp = `${month}-${day}-${year} ${
        hours > 12 ? hours % 12 : hours
    }:${minutes} ${hours < 12 ? 'AM' : 'PM'}`
    return customFormattedTimestamp
}

export const DirectAPICAll = (method, url) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (method === 'get') {
                let res = await axios.get(`${url}`)
                console.log('responsode from api', res)
                resolve(res)
            } else if (method === 'post') {
                let res = await axios.post(`${url}`)
                resolve(res)
            }
        } catch (err) {
            console.log('responsode error from api', err)
            resolve(err)
        }
    })
}

export const useCustomMediaQuery = (queryString) => {
    const [isMatch, setIsMatch] = useState(false)
    const mqChange = (mq) => {
        setIsMatch(mq.matches)
    }

    useEffect(() => {
        const mq = window.matchMedia(queryString)
        mq.addListener(mqChange)
        mqChange(mq)

        return () => {
            mq.removeListener(mqChange)
        }
    }, [queryString])

    return isMatch
}

export const truncateDecimal = (number, decimalPlaces) => {
    const numStr = number.toString()
    const decimalIndex = numStr.indexOf('.')

    // If there's no decimal or if the number already fits, return it as is
    if (decimalIndex === -1 || numStr.length - decimalIndex - 1 <= decimalPlaces) {
        return number
    }

    // Truncate the number by slicing it at the desired decimal places
    const truncatedStr = numStr.slice(0, decimalIndex + decimalPlaces + 1)
    return Number(truncatedStr)
}
