import React, { useReducer } from 'react'
import notifyContext from './notifyContext'
import NotifyReducer from './notifyReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import { GET_ALL_NOTIFICATIONS, RESPONSE_STATUS, CLEAR_RESPONSE } from './notifyTypes'
const NotifyState = (props) => {
    const initialState = {
        all_notifications: '',
        responseStatus: null,
    }
    const [state, dispatch] = useReducer(NotifyReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)
    const getAllNotifications = async (formData) => {
        const from = 'notification'
        const [res] = await Promise.all([apiCall('post', 'notifications', formData, '', 'notify')])
        console.log(res, 'res!')
        if (res.data.status === 'success') {
            dispatch({
                type: GET_ALL_NOTIFICATIONS,
                payload: res.data.data.responseData,
            })
        } else if (res.data.status == 'error') {
            resp.commonResponse(res, from, true)
        } else {
            resp.commonErrorResponse(from)
        }
    }
    const markAsRead = async () => {
        const from = 'markAsRead'
        const [res] = await Promise.all([apiCall('post', 'markAsRead', {}, '', 'notify')])
        if (res.data.status === 'success') {
            dispatch({
                type: RESPONSE_STATUS,
                payload: res.data,
            })
        } else if (res.data.status === 'error') {
            resp.commonResponse(res, from, true)
        } else {
            resp.commonErrorResponse(res, from, true)
        }
    }
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <notifyContext.Provider
            value={{
                all_notifications: state.all_notifications,
                responseStatus: state.responseStatus,
                getAllNotifications,
                markAsRead,
                clearResponse,
            }}
        >
            {props.children}
        </notifyContext.Provider>
    )
}
export default NotifyState
