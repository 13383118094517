import React, { useContext, useState, useEffect } from 'react'
import { Divider, ListItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { NavLink } from 'react-router-dom'
import './Dashboard.css'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { handleRedirectInternal } from '../../product/common/components'
import AuthContext from '../../product/context/auth/authContext'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}))

const SideNav = () => {
    const classes = useStyles()
    const history = useHistory()
    const authContext = useContext(AuthContext)

    const { user } = authContext

    const [expanded, setExpanded] = useState('')

    const handleChange = (panel) => {
        if (expanded === panel) {
            setExpanded('')
        } else {
            setExpanded(panel)
        }
    }

    useEffect(() => {
        if (location.pathname.split('/')[1]) {
            setExpanded(location.pathname.split('/')[1])
        }
    }, [location.pathname])

    return (
        <div className="sideNav">
            <div className="userAvatarProfile">
                <h4>
                    {'Hello'}{' '}
                    {user ? (user.first_name !== 'null' ? user.first_name : user.companyname) : ''}
                </h4>
            </div>
            <ul>
                <ListItem button onClick={() => handleChange('account')}>
                    <div
                        className={`d-flex justify-content-between align-items-center w-100 test ${
                            expanded === 'account' && 'activeDropdown'
                        }`}
                    >
                        <span className="d-flex">
                            <span className="material-icons">person</span>
                            {'My Account'}
                        </span>
                        {expanded === 'account' ? <ExpandLess /> : <ExpandMore />}
                    </div>
                </ListItem>

                <Collapse in={expanded === 'account'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                id="sidebar_profile"
                                to="/account/profile"
                            >
                                {'Profile'}
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                id="sidebar_changepassword"
                                to="/account/change"
                            >
                                {'Change Password'}
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                to="/account/notifications"
                                id="sidebar_notifications"
                            >
                                {'My Notifications'}
                            </NavLink>
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem button onClick={() => handleChange('auctions')}>
                    <div
                        className={`d-flex justify-content-between align-items-center w-100 test ${
                            expanded === 'auctions' && 'activeDropdown'
                        }`}
                    >
                        <span className="d-flex">
                            <span id="sidebar_mybids" className="material-icons">
                                gavel
                            </span>
                            {'My Bids'}
                        </span>
                        {expanded === 'auctions' ? <ExpandLess /> : <ExpandMore />}
                    </div>
                </ListItem>

                <Collapse in={expanded === 'auctions'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                to="/auctions/all"
                                id="sidebar_allbids"
                            >
                                {'All'}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                to="/auctions/winning"
                                id="sidebar_winningbids"
                            >
                                {'Winning'}
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                id="sidebar_outbids"
                                to="/auctions/outbid"
                            >
                                {'Currently Outbid'}
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                id="sidebar_wonbids"
                                to="/auctions/won"
                            >
                                {'Won'}
                            </NavLink>
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button onClick={() => handleChange('purchases')}>
                    <div
                        className={`d-flex justify-content-between align-items-center w-100 test ${
                            expanded === 'purchases' && 'activeDropdown'
                        }`}
                    >
                        <span className="d-flex" id="sidebar_mypurchases">
                            <span className="material-icons">shopping_basket</span>
                            {'My Purchases'}
                        </span>
                        {expanded === 'purchases' ? <ExpandLess /> : <ExpandMore />}
                    </div>
                </ListItem>
                <Collapse in={expanded === 'purchases'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                id="sidebar_buynow"
                                to="/purchases/buynow"
                            >
                                {'Buy Now'}
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                id="sidebar_auction"
                                to="/purchases/auction_paid"
                            >
                                {'Auction'}
                            </NavLink>
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button>
                    <NavLink
                        activeClassName="active"
                        to="/favorites/watchlist"
                        id="sidebar_favorite"
                    >
                        <span className="material-icons">visibility</span>
                        {'My Watchlist'}
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink activeClassName="active" to="/appointment" id="sidebar_myappointments">
                        <span className="material-icons">calendar_month</span>
                        {'My Appointments'}
                    </NavLink>
                </ListItem>

                {/* <ListItem button>
                    <NavLink activeClassName="active" to="/wallet">
                        <span className="material-icons">account_balance_wallet</span>
                        {'my wallet')}
                    </NavLink>
                </ListItem> */}

                {/* <ListItem button>
                    <NavLink activeClassName="active" to="/transactions">
                        <span className="material-icons">account_balance_wallet</span>
                        {'my transactions')}
                    </NavLink>
                </ListItem> */}

                <ListItem button onClick={() => handleChange('invoices')}>
                    <div
                        className={`d-flex justify-content-between align-items-center w-100 test ${
                            expanded === 'invoices' && 'activeDropdown'
                        }`}
                    >
                        <span className="d-flex" id="sidebar_invoices">
                            <span className="material-icons">receipt</span>
                            {'My Invoices'}
                        </span>
                        {expanded === 'invoices' ? <ExpandLess /> : <ExpandMore />}
                    </div>
                </ListItem>

                <Collapse in={expanded === 'invoices'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                to="/invoices/all"
                                id="sidebar_allinvoices"
                            >
                                All
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                to="/invoices/paid"
                                id="sidebar_paidinvoices"
                            >
                                Paid
                            </NavLink>
                        </ListItem>

                        {/* <ListItem button>
                            <NavLink
                                activeClassName="active"
                                to="/invoices/partial"
                                id="sidebar_partialinvoices"
                            >
                                Partial
                            </NavLink>
                        </ListItem> */}

                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                to="/invoices/unpaid"
                                id="sidebar_unpaidinvoices"
                            >
                                Unpaid
                            </NavLink>
                        </ListItem>

                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                to="/invoices/returned"
                                id="sidebar_unpaidinvoices"
                            >
                                Returned
                            </NavLink>
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button>
                    <NavLink activeClassName="active" to="/saved_search" id="sidebar_savedsearches">
                        <span className="material-icons">bookmark</span> {'My Saved Searches'}
                    </NavLink>
                </ListItem>
            </ul>
        </div>
    )
}

export default SideNav
