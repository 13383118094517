import React, { useState, useContext, useEffect, useRef } from 'react'
import algoliasearch from 'algoliasearch'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import ImageGallery from 'react-image-gallery'
import openSocket from 'socket.io-client'
import 'react-image-gallery/styles/css/image-gallery.css'
import './ProductView.css'
import { Breadcrumbs, Drawer, Typography } from '@material-ui/core'
import { Link, useParams, useHistory } from 'react-router-dom'
import Loaders from '../../custom/components/molecules/Loaders'
import ProductContext from '../../product/context/product/productContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import {
    dateFormatFront,
    dateTimeFormatFunction,
    asyncForEach,
} from '../../product/common/components'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
// import Bidding from '../../components/molecules/Bidding/BiddingItem'
import Timer from '../../product/common/timer'
import ListView from '../../custom/components/molecules/ProductCard/ListView'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import ProductView from '../../custom/components/organisms/ProductView'
import AuctionContext from '../../product/context/auction/auctionContext'
import { Button } from '@material-ui/core'
import { DirectAPICAll } from '../../product/common/components'
import { messageHandler } from '../../product/common/socketHandler'
import AlertContext from '../../product/context/alert/alertContext'
import AuthContext from '../../product/context/auth/authContext'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'
import CommonContext from '../../product/context/common/commonContext'
import CustomMultiSelect from '../../product/components/atoms/Inputs/CustomMultiSelect'
import { socket, reInitializeSocket } from '../../product/common/socket'
import ReactPlayer from 'react-player/lazy'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FilterPanel from '../../custom/components/organisms/FilterPanel'
import DirectFilterPanel from '../../custom/components/organisms/DirectFilterPanel'
import { CSVLink, CSVDownload } from 'react-csv'
import Popup from '../../custom/components/organisms/Popup'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { Formik, useFormik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { CSVReader } from 'react-papaparse'
import BuyerContext from '../../product/context/buyer/buyerContext'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Pagination } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import AvatarImage from '../../custom/components/atoms/AvatarImage'
import moment from 'moment'
import CustomInput from '../../product/components/atoms/Inputs/CustomInput'
import Autocomplete from '../../custom/components/organisms/AutoComplete'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)
const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '10px',
    },
}))(MuiAccordionDetails)
function accorianTerms(t) {
    return [
        {
            title: 'Description',
            details: 'Terms and Conditions Details',
        },
    ]
}

function LiveAuction(props) {
    const APP_ID = process.env.REACT_APP_ALGOLIA_APPID
    const APP_KEY = process.env.REACT_APP_ALGOLIA_APIKEY
    const APP_INDEX = process.env.REACT_APP_ALGOLIA_INDEX
    const searchClient = algoliasearch(APP_ID, APP_KEY)
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const commonContext = useContext(CommonContext)
    const customCommonContext = useContext(CustomCommonContext)
    const auctionContext = useContext(AuctionContext)
    const {
        allCategory,
        allCondition,
        allLocations,
        setLoginModal,
        configVariables,
    } = customCommonContext
    const { search_all_pastauction, getAllAuctionProducts } = auctionContext
    const { searchValue, setSearchValue, isLoading } = commonContext

    const { getAllSearchProducts, search_allproducts } = useContext(ProductContext)
    const [auctionProducts, setAuctionProducts] = useState([])
    const [pageFilterDisplay, setPageFilterDisplay] = useState()
    const [auctionDetails, setAuctionDetails] = useState({})
    const [auctionImages, setAuctionImages] = useState([])
    const buyerContext = useContext(BuyerContext)
    let { bidConfirm } = buyerContext
    const [isLoadingAuction, setIsLoadingAuction] = useState(true)
    const [isLoadingProject, setIsLoadingProject] = useState(true)
    const history = useHistory()
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const [viewProduct, setViewProduct] = useState([])
    const [csvData, setCsvData] = useState([])
    const { setAlert } = alertContext
    const { user, isAuthenticated, saveSearch } = authContext
    const [bidInstruction, setBidInstruction] = useState(false)
    const [TableView, setTableView] = useState(false)
    const lvActnRef = useRef()
    const [searchText, setSearchText] = useState('')
    const [searchTextError, setSearchTextError] = useState(null)
    const [termsExpand, setTermsExpand] = useState(false)
    const [expanded, setExpanded] = useState()
    const [canBid, setCanBid] = useState(true)
    const [screenSize, setScreenSize] = useState(getScreenSize())
    const [itemsPerPage, setItemsPerPage] = useState(getItemsPerPage(screenSize))
    const [initialLoad, setInitialLoad] = useState(true)
    const [initialRender, setInitialRender] = useState(true)
    const [projectIDList, setProjectIDList] = useState([])

    const validationArray = Yup.object({
        id: Yup.number(),
        title: Yup.string(),
        amount: Yup.string(),
    })

    function getScreenSize() {
        return window.innerWidth
    }

    // Function to calculate items per page based on screen size
    function getItemsPerPage(screenSize) {
        return screenSize <= 768 ? 12 : 36 // Adjust the breakpoint value (768) as per your requirements
    }

    useEffect(() => {
        // Function to handle screen size changes
        function handleResize() {
            const newScreenSize = getScreenSize()
            if (newScreenSize !== screenSize) {
                setScreenSize(newScreenSize)
                setItemsPerPage(getItemsPerPage(newScreenSize))
            }
        }

        // Attach the event listener for screen size changes
        window.addEventListener('resize', handleResize)

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [screenSize])

    const formikSearch = useFormik({
        initialValues: {
            limit: itemsPerPage,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            market_status: ['sold', 'closed'],
            past_auction: true,
            columns: [
                [
                    'p.id',
                    'p.title',
                    'p.desc_proc',
                    'p.market_status',
                    'p.location_id',
                    'p.avatar',
                    'p.auction',
                    'p.bprice',
                    'p.group_carts',
                    'p.qty',
                    'p.notes',
                    'p.date_added',
                    'p.date_closed',
                    'p.contents',
                ],

                [
                    'it.avatar_url',
                    'it.lot',
                    'it.avatar_type',
                    'it.conditionTypeId',
                    'it.product_terms',
                    'it.sku',
                    'it.dimension_unit',
                    'it.origin_state',
                    'it.upc',
                    'it.model',
                    'it.part_no',
                    'it.lot_location',
                ],
                [
                    'ac.date_closed as auctionlot_date_closed',
                    'ac.market_status as auctionlot_market_status',
                ],
                [
                    'cac.shipping_check as custom_auctionlot_shipping_check',
                    'cac.local_pickup_check as custom_auctionlot_local_pickup_check',
                ],
                ['loc.local_pickup_check as locations_local_pickup_check'],
            ],
            // isSold: true,
            filters: {
                category: {
                    value: [],
                    type: 'array',
                    field: 'it.categoryTypeId',
                },
                searchbar: {
                    value: '',
                    type: 'like',
                    field: 'p.title', //p.desc_proc',
                },
                subCategory: {
                    value: [],
                    type: 'array',
                    field: 'it.subCategoryTypeId',
                },
                third_sub_category: {
                    value: [],
                    type: 'array',
                    field: 'it.third_sub_categoryID',
                },
                // location: {
                //     value: [],
                //     type: 'array',
                //     field: 'p.location_id',
                // },
                shipType: {
                    value: [],
                    type: 'findinarray',
                    field: 'it.shipTypeId',
                },
                price: {
                    value: 0,
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                // pastFrom: {
                //     value: moment(moment()).format('MM-DD-YYYY HH:MM'),
                //     type: 'dategreater',
                //     field: 'cac.past_auction_date',
                // },
                // auctionid: {
                //     value: [],
                //     type: 'array',
                //     field: 'p.auctionid',
                // },
            },
        },
    })

    useEffect(() => {
        if (formikSearch.values.limit !== itemsPerPage) setItemsPerPage(formikSearch.values.limit)
    }, [formikSearch.values.limit])

    // useEffect(() => {
    //     formikSearch.setFieldValue(`filters.auctionid.value`, [Number(props.match.params.aid)])
    //     setIsLoadingAuction(true)
    //     getAllAuctionProducts(
    //         {
    //             limit: 1,
    //             page: 1,
    //             orderby: 'ac.date_closed, asc',
    //             order: '',
    //             market_status: 'closed',
    //             filters: {
    //                 auctionid: {
    //                     value: Number(props.match.params.aid),
    //                     type: 'in',
    //                     field: 'ac.id',
    //                 },
    //             },
    //         },
    //         'pastauction',
    //     )
    // }, [props.match.params.aid])

    useEffect(() => {
        if (!initialLoad) {
            if (String(searchText)?.length > 2) {
                setIsLoadingProject(true)
                getAllSearchProducts(formikSearch.values, 'pastauctions', 'custom/')
                // getAllSearchProducts(formikSearch.values, 'pastauctions', '')
            } else {
                setSearchTextError(`Please enter atleast 3 characters for the Product title or SKU`)
                setInitialRender(true)
            }
        } else {
            setIsLoadingProject(false)
            setInitialLoad(!initialLoad)
        }
        // setTimeout(() => {
        //     setIsLoadingProject(false)
        // }, 1000)
    }, [formikSearch.values])

    useEffect(() => {
        let images = [
            {
                original: `${global.site_url}/uploads/auction/${auctionDetails.avatar}`,
                thumbnail: `${global.site_url}/uploads/auction/${auctionDetails.avatar}`,
            },
        ]
        setAuctionImages(images)
    }, [auctionDetails])

    // Used to change page
    const onHandlePage = (event, value) => {
        formikSearch.setFieldValue(`page`, value)
    }

    useEffect(() => {
        setAuctionProducts([])
        if (search_allproducts.from === 'pastauctions') {
            if (search_allproducts.records?.length) {
                setAuctionProducts(search_allproducts.records)
                setPageFilterDisplay(search_allproducts.setDisp)
            } else {
                setAuctionProducts([])
            }
            setInitialRender(false)
            setIsLoadingProject(false)
        }
    }, [search_allproducts])

    // useEffect(() => {
    //     if (search_all_pastauction.from === 'pastauction') {
    //         if (search_all_pastauction && search_all_pastauction.records.length > 0) {
    //             setAuctionDetails(search_all_pastauction.records[0])
    //             setIsLoadingAuction(false)
    //         } else {
    //             handleRedirectInternal(history, 'auction')
    //         }
    //     }
    // }, [search_all_pastauction])

    // useEffect(() => {
    //     if (auctionDetails) {
    //         if (
    //             auctionDetails.auction_groups != '' &&
    //             auctionDetails.auction_groups != undefined &&
    //             auctionDetails.auction_groups != null
    //         ) {
    //             const auction_groups = auctionDetails?.auction_groups.split(',')
    //             if (auction_groups?.length > 0) {
    //                 if (user?.user_auction_groups.length > 0) {
    //                     const temp = auction_groups.find((ele) =>
    //                         user?.user_auction_groups.includes(parseInt(ele)),
    //                     )
    //                     if (temp) {
    //                         setCanBid(true)
    //                     } else {
    //                         setCanBid(false)
    //                     }
    //                 } else {
    //                     setCanBid(false)
    //                 }
    //             }
    //         }
    //     }
    // }, [auctionDetails])
    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }
    // Clears search filter values
    const clearSearchFilter = () => {
        formikSearch.values = {
            ...formikSearch.values,
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                category: {
                    ...formikSearch.values.filters.category,
                    value: [],
                },
                subCategory: {
                    ...formikSearch.values.filters.subCategory,
                    value: [],
                },
                third_sub_category: {
                    ...formikSearch.values.filters.third_sub_category,
                    value: [],
                },
                shipType: {
                    ...formikSearch.values.filters.shipType,
                    value: [],
                },
                location: {
                    ...formikSearch.values.filters.location,
                    value: [],
                },
                price: {
                    ...formikSearch.values.filters.price,
                    value: 0,
                },
                searchbar: {
                    ...formikSearch.values.filters.searchbar,
                    value: '',
                },
                auctionid: {
                    ...formikSearch.values.filters.auctionid,
                },
            },
        }
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearchTextChange()
        }
    }

    useEffect(() => {
        if (state.right) {
            const index = auctionProducts.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: auctionProducts[index] })
            }
        }
    }, [auctionProducts])

    useEffect(() => {
        if (auctionDetails.id) {
            init()
        }
    }, [auctionDetails])

    // const config = {
    //     iceServers: [
    //         {
    //             urls: process.env.REACT_APP_TURN_URL,
    //             credential: process.env.REACT_APP_TURN_CREDENTIAL,
    //             username: process.env.REACT_APP_TURN_USERNAME,
    //         },
    //     ],
    // }

    // let [uri, setUri] = useState([])

    // let peerConnection
    // const videoRef = useRef()

    const handleSearchTextChange = (userInput) => {
        formikSearch.values.page = 1
        const alphanumericText = String(userInput).replace(/[^a-zA-Z0-9\s]/g, '')
        setSearchText(alphanumericText)
    }

    useEffect(() => {
        if (String(searchText)?.length > 2) {
            formikSearch.setFieldValue(`filters.searchbar.value`, searchText)
            formikSearch.setFieldValue(`skuSearch`, searchText)
        } else {
            setSearchTextError(`Please enter atleast 3 characters for the Product title or SKU`)
            setInitialRender(true)
        }
    }, [searchText])
    // useEffect(() => {
    //     socket.on('offer', (id, description) => {
    //         peerConnection = new RTCPeerConnection(config)
    //         peerConnection
    //             .setRemoteDescription(description)
    //             .then(() => peerConnection.createAnswer())
    //             .then((sdp) => peerConnection.setLocalDescription(sdp))
    //             .then(() => {
    //                 socket.emit('answer', id, peerConnection.localDescription)
    //             })
    //         peerConnection.ontrack = (event) => {
    //             console.log(event.streams[0])
    //             setUri(event.streams[0])
    //         }
    //         peerConnection.onicecandidate = (event) => {
    //             if (event.candidate) {
    //                 socket.emit('candidate', id, event.candidate)
    //             }
    //         }
    //     })
    // }, [socket])

    // useEffect(() => {
    //     socket.on('candidate', (id, candidate) => {
    //         peerConnection
    //             .addIceCandidate(new RTCIceCandidate(candidate))
    //             .catch((e) => console.error(e))
    //     })
    // }, [socket])

    // useEffect(() => {
    //     return () => {
    //         socket.emit('watcher-disconnect', localStorage.getItem('broadcasterID'))
    //         if (peerConnection) {
    //             peerConnection.close()
    //         }
    //         reInitializeSocket()
    //     }
    // }, [])

    // useEffect(() => {
    //     socket.on('disconnectPeer', () => {
    //         peerConnection.close()
    //     })
    // }, [socket])

    // useEffect(() => {
    //     window.onunload = window.onbeforeunload = () => {
    //         socket.emit('watcher-disconnect', localStorage.getItem('broadcasterID'))
    //         if (peerConnection) {
    //             peerConnection.close()
    //         }
    //         reInitializeSocket()
    //     }
    // }, [window])

    // async function init() {
    //     const payload = {
    //         auctionid: auctionDetails.id,
    //     }

    //     const data = await DirectAPICAll(
    //         'post',
    //         `${global.site_url}/api/video/newVideoStream`,
    //         payload,
    //     )
    //     if (data?.data?.data?.responseData?.length) {
    //         localStorage.setItem('broadcasterID', data.data.data.responseData[0].sender_stream)
    //         socket.emit('watcher', data.data.data.responseData[0].sender_stream)
    //     }
    // }

    // const viewProductRef = useRef(auctionProducts)
    // const userRef = useRef(user)

    // useEffect(() => {
    //     viewProductRef.current = auctionProducts
    //     userRef.current = user
    // })

    // const handler = (message, type) => {
    //     messageHandler(
    //         message,
    //         viewProductRef.current,
    //         userRef.current,
    //         setAlert,
    //         setAuctionProducts,
    //         type,
    //     )
    // }

    // useEffect(() => {
    //     const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
    //     socket.on('realclosedupdates', (data) => {
    //         handler(data, 'realclosedupdates')
    //     })
    //     socket.on('bidAddtime', (data) => {
    //         handler(data, 'bidAddtime')
    //     })
    //     socket.on('cancelBidEmitted', (data) => {
    //         formikSearch.values.filters.auctionid.value = [Number(props.match.params.aid)]
    //         getAllSearchProducts(formikSearch.values, 'searchproducts')
    //     })
    //     return () => {
    //         socket.off('realclosedupdates', (data) => {
    //             handler(data, 'realclosedupdates')
    //         })
    //         socket.off('bidAddtime', (data) => {
    //             handler(data, 'bidAddtime')
    //         })
    //         socket.off('cancelBidEmitted', (data) => {})
    //         socket.disconnect()
    //     }
    // }, [])

    const pageOptions = [
        {
            value: '36',
            show: 'Results 36 Per Page',
        },
        {
            value: '12',
            show: 'Results 12 Per Page',
        },
        {
            value: '24',
            show: 'Results 24 Per Page',
        },
        {
            value: '72',
            show: 'Results 72 Per Page',
        },
    ]

    const sortShow = [
        {
            value: 'p.id, desc',
            show: 'None',
        },
        {
            value: 'auctionlot_date_closed, desc',
            show: 'Auction End Date',
        },
        {
            value: 'buynowamount, asc',
            show: 'Sold Price Low to High',
        },
        {
            value: 'buynowamount, desc',
            show: 'Sold Price High to Low',
        },
    ]

    const scrollToFunc = (ref) => window.scrollTo(0, ref.current.offsetTop)

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }
    const addDefaultSrc = (e) => {
        e.target.src = '/assets/images/noimage.png'
    }
    return (
        <div className="liveAuction customContainer">
            {/* {isLoadingAuction ? (
                <div className="liveAucLoader customContainer">
                    <Loaders isLoading={isLoadingAuction} name="product_view" loop={1} />
                </div>
            ) : (
                <>
                    <div className="pvTimerCnt">
                        {auctionDetails.market_status === 'open' ? (
                            <div className="pvTimerBox">
                                <span className="material-icons">timer</span>
                                <h4>
                                    <Timer
                                        date_added={auctionDetails.date_added}
                                        date_closed={auctionDetails.date_closed}
                                        withText={1}
                                        endText={'Time Left' + ':'}
                                        startText={'Starts in' + ':'}
                                    ></Timer>
                                </h4>
                            </div>
                        ) : (
                            <div className="pvTimerBox">
                                <span className="material-icons">timer</span>
                                <label>{'Closed On'}</label>
                                <h4>{dateFormatFront(auctionDetails.date_closed)}</h4>
                            </div>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-12 mb-3">
                            <Breadcrumbs aria-label="breadcrumb" className="customBreadcrumbs">
                                <Link to="/past-auctions">{'Search'}</Link>
                                <Typography color="textPrimary">{auctionDetails.title}</Typography>
                            </Breadcrumbs>
                        </div>
                    </div>
                    <div className="row auctionAssetsCnt">
                        <div className="col-lg-4 col-12 liveAucImages">
                            <AvatarImage
                                data={auctionDetails}
                                className="auctionPageImg"
                                folder="auction"
                            />
                        </div>

                        <div className="col-lg-5 col-12 text-left actnDtlWrpr">
                            <div className="acContent">
                                <h2 className="actnTtrl mb-4">{auctionDetails.title}</h2>
                                <div className="acMiscInfo">
                                    <p>
                                        <span className="material-icons">today</span>Bidding Starts:
                                    </p>
                                    <span>{dateTimeFormatFunction(auctionDetails.date_added)}</span>
                                </div>
                                <div className="acMiscInfo">
                                    <p>
                                        <span className="material-icons">event</span>Bidding Ends:
                                    </p>
                                    <span>
                                        {dateTimeFormatFunction(auctionDetails.date_closed)}
                                    </span>
                                </div>
                                <div className="acMiscInfo">
                                    <p>
                                        <span className="material-icons">place</span>
                                        Location:
                                    </p>
                                    {allLocations
                                        .filter((value) => auctionDetails.location_id === value.id)
                                        .map((location) => (
                                            <span key={location.id}>
                                                {location.name} - {location.address},{' '}
                                                {location.city} {location.state} {location.zipCode}
                                            </span>
                                        ))}
                                </div>
                                <div className="acMiscInfo">
                                    <p>
                                        <span className="material-icons">gavel</span>Type:
                                    </p>
                                    <span className="capitalize">{auctionDetails.bid_type}</span>
                                </div>
                                <div className="acMiscInfo">
                                    <p>Total Lots/Items:</p>
                                    <span>{auctionDetails?.projects_count}</span>
                                </div>
                                <Accordion
                                    square
                                    expanded={expanded === `panel1`}
                                    key={1}
                                    onChange={handleChange(`panel1`)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1d-content"
                                        id={`panel1d-header`}
                                    >
                                        <h6 className="accTitle">Description</h6>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <h6
                                            dangerouslySetInnerHTML={{
                                                __html: auctionDetails.description,
                                            }}
                                        ></h6>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    square
                                    expanded={expanded === `panel2`}
                                    key={2}
                                    onChange={handleChange(`panel2`)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2d-content"
                                        id={`panel2d-header`}
                                    >
                                        <h6 className="accTitle">Terms & Conditions</h6>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <h6
                                            dangerouslySetInnerHTML={{
                                                __html: auctionDetails.terms,
                                            }}
                                        ></h6>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="acActBtn actnTmrDtl">
                                <div className="acTimer">
                                    <h6>
                                        <span className="material-icons">timer</span>
                                    </h6>
                                    <p>
                                        {auctionDetails.date_closed ? (
                                            <>
                                                <Timer
                                                    date_added={auctionDetails.date_added}
                                                    date_closed={auctionDetails.date_closed}
                                                    withText={1}
                                                    endText={'Time Left' + ':'}
                                                    startText={'Starts in' + ':'}
                                                ></Timer>
                                            </>
                                        ) : null}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )} */}

            {isLoadingProject ? (
                <div className="liveAucLoader grid customContainer">
                    <Loaders name="product_grid_view" isLoading={isLoadingProject} loop={4} />
                </div>
            ) : (
                <>
                    <div className="row" ref={lvActnRef}>
                        <div className="col-12">
                            <h2 className="laTitle my-4 mt-5">Past Auction Lots/Items</h2>
                        </div>
                        <div className="srcFltrWpr col-12 d-flex align-items-center justify-content-between">
                            <div className="my-2 headerSearchForm border-0 d-block sd">
                                <Autocomplete
                                    openOnFocus={true}
                                    onSubmit={(stateData) => {
                                        stateData.setIsOpen(false)
                                        const { state } = stateData
                                        handleSearchTextChange(state.query)
                                    }}
                                    getSources={({ query }) => [
                                        {
                                            sourceId: 'products',
                                            onSelect({ setIsOpen, setQuery, event, item }) {
                                                handleRedirectInternal(
                                                    history,
                                                    `productview/${item?.id}?pastAuction=true`,
                                                )
                                            },
                                            getItems() {
                                                const searchParams = {
                                                    query,
                                                    filters:
                                                        'status:closed OR status:sold OR status:unsold',
                                                    params: {
                                                        hitsPerPage: 36,
                                                    },
                                                }
                                                return getAlgoliaResults({
                                                    searchClient,
                                                    queries: [
                                                        {
                                                            indexName: APP_INDEX,
                                                            ...searchParams,
                                                        },
                                                    ],
                                                })
                                            },
                                            templates: {
                                                header() {
                                                    return 'Suggestions'
                                                },
                                                item({ item, components }) {
                                                    return (
                                                        <>
                                                            {item?.objectID ? (
                                                                <div className="aa-ItemTitle">
                                                                    <components.Highlight
                                                                        hit={item}
                                                                        attribute="title"
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )
                                                },
                                            },
                                        },
                                    ]}
                                />
                                <p className="error-message">{searchTextError}</p>

                                {/* <CustomInput
                                    className="form-control"
                                    type="search"
                                    autoFocus={true}
                                    error={searchTextError ? true : false}
                                    helperText={searchTextError}
                                    placeholder="Search Past Products"
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value)
                                        String(searchText)?.length
                                            ? setSearchTextError('')
                                            : setInitialRender(true)
                                    }}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button
                                    onClick={handleSearchTextChange}
                                    disabled={searchText?.length > 0 ? false : true}
                                    className="btn"
                                >
                                    <span className="material-icons">search</span>
                                </Button> */}
                            </div>
                            <div className="searchMiscFilters w-100 mb-5 d-flex justify-content-end align-items-end">
                                {/* <Button
                                    className="fltrBtn"
                                    onClick={toggleDrawer('left', true, null)}
                                >
                                    Filters <span className="material-icons ml-2">filter_alt</span>
                                </Button> */}
                                <div className="mr-2">
                                    <CustomSelect
                                        label={'Results per Page'}
                                        name="resultsPerPage"
                                        selectType="noBorder"
                                        value={itemsPerPage}
                                        size="small"
                                        onChange={(event, editor) => {
                                            formikSearch.setFieldValue(`page`, 1)
                                            formikSearch.setFieldValue(`limit`, event.target.value)
                                        }}
                                    >
                                        {pageOptions.map((opt, optindex) => (
                                            <option key={optindex} value={opt.value}>
                                                {opt.show}
                                            </option>
                                        ))}
                                    </CustomSelect>
                                </div>
                                <CustomSelect
                                    label={'Sort by'}
                                    value={formikSearch.values.orderby}
                                    size="small"
                                    selectType="noBorder"
                                    name="orderby"
                                    // shrink={search.orderby !== '' ? true : false}
                                    onChange={(event, editor) => {
                                        formikSearch.values.page = 1
                                        formikSearch.setFieldValue(`orderby`, event.target.value)
                                    }}
                                >
                                    {sortShow.map((opt, optindex) => (
                                        <option value={opt.value}>{opt.show}</option>
                                    ))}
                                </CustomSelect>
                            </div>
                        </div>
                    </div>
                    {initialRender ? (
                        <>
                            <h2 className="my-10 search-text">
                                Search for Products from Past Auctions
                            </h2>
                        </>
                    ) : (
                        <>
                            {auctionProducts?.length > 0 ? (
                                <>
                                    <div className="row">
                                        <div className="searchResults col-md-12 col-12">
                                            {auctionProducts.map((data, index) => (
                                                <GridView
                                                    data={data}
                                                    key={index}
                                                    favId={`searchProd_${index}`}
                                                    drawerHandler={toggleDrawer(
                                                        'right',
                                                        true,
                                                        data,
                                                    )}
                                                />
                                            ))}
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper col-md-12">
                                            <h6>
                                                {'Showing'} {pageFilterDisplay} {'of'} &nbsp;
                                                {search_allproducts.totalRecords}
                                            </h6>
                                            <Pagination
                                                count={Math.ceil(
                                                    search_allproducts.totalRecords / itemsPerPage,
                                                )}
                                                page={formikSearch.values.page}
                                                onChange={onHandlePage}
                                                siblingCount={3}
                                                showFirstButton
                                                showLastButton
                                                boundaryCount={2}
                                            />
                                        </div>
                                        {/* <div className="col-12"> */}
                                        <Drawer
                                            className="rightDrawer"
                                            anchor={'right'}
                                            open={state['right']}
                                            onClose={toggleDrawer('right', false)}
                                        >
                                            <ProductView
                                                data={state.data}
                                                drawerHandler={toggleDrawer('right', false)}
                                                onClick={() => setState(false)}
                                            />
                                        </Drawer>
                                        {/* <Drawer
                            className="fltrLeftDrawer"
                            anchor={'left'}
                            open={state['left']}
                            onClose={toggleDrawer('left', false)}
                        >
                            <DirectFilterPanel
                                formik={formikSearch}
                                clearSearch={clearSearchFilter}
                                from={2}
                            />
                        </Drawer> */}
                                        {/* </div> */}
                                    </div>
                                </>
                            ) : (
                                <div className="my-10">
                                    <NoRecordsFound />
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default LiveAuction
