import React, { useState, useEffect, useContext } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import commonContext from '../../custom/context/common/commonContext'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '10px',
    },
}))(MuiAccordionDetails)
const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const Faq = () => {
    const [expanded, setExpanded] = useState()
    const [faq, setFaq] = useState([])
    const customCommonContext = useContext(commonContext)
    const { allfaq } = customCommonContext
    useEffect(() => {
        if (allfaq && allfaq.length) {
            const data = allfaq
                .filter((faq) => faq.active === 1)
                .map((faq) => ({ title: faq.question, details: faq.description }))
            console.log(data, 'dataa')
            setFaq(data)
        }
    }, [allfaq])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    return (
        <div>
            <section className="homeFAQ homeMarginBottom customContainer pt-5">
                <h2 className="homeTitle mb-4 text-left">Frequently asked questions</h2>
                <p style={{ textAlign: 'left' }}>
                    Each auction will have its own set of rules and requirements as well as hours of
                    operation. Always refer to the event details of the auction you are bidding in
                    for specific information.
                </p>
                <br />
                {faq.map((data, index) => (
                    <Accordion
                        square
                        expanded={expanded === `panel${index}`}
                        key={index}
                        onChange={handleChange(`panel${index}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            id={`panel${index}d-header`}
                        >
                            <h6 className="accTitle">{data.title}</h6>
                        </AccordionSummary>
                        <AccordionDetails>{data.details}</AccordionDetails>
                    </Accordion>
                ))}
            </section>
        </div>
    )
}

export default Faq
