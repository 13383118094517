import React from 'react'
import './CustomAlert.css'

function CustomAlert({ type, icon, label }) {
    return (
        /* USE TYPE AS ERROR, WARNING, INFO OR SUCCESS */
        <div className={`customAlert ${type}`}>
            <span className="material-icons">{icon ? icon : 'info'}</span>
            <h4>{label}</h4>
        </div>
    )
}

export default CustomAlert
