import React, { useState, useContext, useEffect } from 'react'
import ProductContext from '../../../context/product/productContext'
import AuthContext from '../../../context/auth/authContext'

import './FavoriteCheckbox.css'

const FavoriteCheckbox = ({
    watchlisted,
    project_id,
    fill,
    addLabel,
    removeLabel,
    className,
    trigger = null,
    follows = '',
    add_watchlist_message,
    remove_watchlist_message,
    icon_type,
}) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext

    const [checked, setChecked] = useState(parseInt(watchlisted) > 0 ? true : false)
    const {
        addWatchlist,
        removeWatchlist,
        addFollower,
        getFollowers,
        increaseProductViewCount,
    } = productContext

    useEffect(() => {
        setChecked(parseInt(watchlisted) > 0 ? true : false)
        return () => {
            setChecked(false)
        }
    }, [project_id, watchlisted])

    const toggleFavourite = () => {
        if (checked) {
            removeWatchlist({ project_id }, null, remove_watchlist_message)
            setChecked(false)
        } else {
            if (trigger) trigger({ project_id })
            addWatchlist({ project_id }, null, add_watchlist_message)
            if (global?.watchCount == true) {
                increaseProductViewCount({ id: project_id })
            }
            if (follows != '') {
                addFollower({ follows: follows, rating: 5 })
                setTimeout(() => {
                    getFollowers()
                }, 500)
            }
            setChecked(true)
        }
    }

    return (
        <>
            {isAuthenticated && (
                <div
                    className={`favoriteCheck d-flex justify-content-center align-items-center ${
                        checked ? 'checked' : ''
                    } ${className || ''}`}
                >
                    <input
                        id={project_id}
                        type="checkbox"
                        checked={checked}
                        onChange={toggleFavourite}
                    />
                    {checked ? (
                        <label htmlFor={project_id}>
                            <span className="material-icons">
                                {icon_type === 'visibility' ? 'visibility' : 'favorite'}
                            </span>
                            {removeLabel}
                        </label>
                    ) : (
                        <label htmlFor={project_id}>
                            {fill ? (
                                <span className="material-icons ntChckd">favorite</span>
                            ) : (
                                <span
                                    className={
                                        icon_type === 'visibility'
                                            ? 'material-icons-outlined'
                                            : 'material-icons'
                                    }
                                >
                                    {icon_type === 'visibility' ? 'visibility' : 'favorite_border'}
                                </span>
                            )}
                            {addLabel}
                        </label>
                    )}
                </div>
            )}
        </>
    )
}
export default FavoriteCheckbox
