import React, { useContext, useState, useEffect } from 'react'
import CustomInput from '../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import '../Login/Login.css'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory, Redirect } from 'react-router-dom'
import AlertContext from '../../product/context/alert/alertContext'
import UserContext from '../../product/context/user/userContext'
import Lottie from 'react-lottie'
import mailVerify from '../../assets/lottie/mailVerify.json'

const VerifyEmail = (props) => {
    const userContext = useContext(UserContext)
    const alertContext = useContext(AlertContext)
    const { verifyEmail, responseStatus, clearResponse } = userContext

    const history = useHistory()

    const verify_email = () => {
        const values = {}
        values.user_token = props.match.params.token
        values.status = 'active'
        verifyEmail(values)
    }
    useEffect(() => {
        verify_email()
    }, [])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        animationData: mailVerify,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    return (
        <div>
            <div className="errorPage container d-flex justify-content-between flex-wrap text-center">
                <Lottie options={defaultOptions} speed="1" height={300} width={400} />

                {responseStatus && responseStatus.status == 'success' && <Redirect to="/" />}
            </div>
        </div>
    )
}
export default VerifyEmail
