import React, { useState, useContext, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { mapData } from '../../product/common/components'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import UserContext from '../../product/context/user/userContext'
import AuthContext from '../../product/context/auth/authContext'
import CustomDialog from '../../custom/components/organisms/Dialog'
import PhoneValidationComponent from '../../product/components/species/components/user/PhoneValidationComponent'

import '../Login/Login.css'

const PhoneVerify = (props) => {
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const { updateProfile, responseStatus } = userContext
    const { user, isAuthenticated, loadUser } = authContext
    const [isLoading, setIsLoading] = useState(false)
    const [phonePopup, setPhonePopup] = useState(false)
    const [phoneVerify, setPhoneVerify] = useState(false)

    useEffect(() => {
        setPhonePopup(props.open)
    }, [props.open])

    const validationArray = Yup.object({
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .length(17, 'Invalid Phone Number')
            .required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            phone_verified: 0,
            phone: '',
            isVerifyPhone: true,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (values.phone) {
                setIsLoading(true)
                setPhoneVerify(true)
            }
        },
    })

    useEffect(() => {
        if (user) {
            formik.setFieldValue('id', user.id)
            formik.setFieldValue('phone', user.phone)
        }
    }, [user])

    const changePhonePopup = () => {
        setPhonePopup(!phonePopup)
    }

    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }

    const phoneValues = [
        {
            autoFocus: true,
            label: 'Phone',
            name: 'phone',
            type: 'phone',
            placeholder: 'Enter your phone',
            class: 'col-12',
            countryCodeEditable: false,
            formik: formik,
            disabled: false,
        },
    ]

    const updateFieldAndProfile = () => {
        const userObj = {
            id: user.id,
            phone_verified: 1,
            phone: formik?.values?.phone,
            phone_verifysent: 1,
        }
        updateProfile(userObj, false)
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.status === 'success') {
                if (responseStatus.from === 'customVerifyPhoneVerifyCode' && user?.id) {
                    updateFieldAndProfile()
                } else if (responseStatus.from === 'updateProfile') {
                    setPhonePopup(false)
                    setIsLoading(false)
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (!phoneVerify) {
            setIsLoading(false)
        }
    }, [phoneVerify])

    return (
        <>
            <CustomDialog
                className="modalView"
                function={() => {}}
                reason="backdropClick"
                open={phonePopup}
                title={'Verify Phone Number'}
            >
                <>
                    <h4 className="subTit">Enter phone number to validate your account</h4>
                    <div className="row bidding-row">{Object.values(mapData(phoneValues))}</div>
                    <p className="forPass"></p>
                    <div className="actionWrapper justify-content-center">
                        <PrimaryButton
                            id="submit"
                            onClick={formik.handleSubmit}
                            type="button"
                            label="Confirm"
                            disabled={isLoading}
                        />
                    </div>
                </>
            </CustomDialog>
            <PhoneValidationComponent
                phoneVerify={phoneVerify}
                formik={formik}
                setPhoneVerify={setPhoneVerify}
                customVerifyPhoneVerifyCode={`customVerifyPhoneVerifyCode`}
                changePhoneVerify={() => {
                    formik.validateForm().then(() => {
                        if (!formik.errors.phone) {
                            setPhoneVerify(!phoneVerify)
                        }
                    })
                }}
            />
        </>
    )
}
export default PhoneVerify
