import React, { useContext, useEffect, useState } from 'react'
import { handleRedirectInternal } from '../../../common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import authContext from '../../../../product/context/auth/authContext'
import TertiaryButton from '../../atoms/TertiaryButton'
import CustomDialog from '../../organisms/Dialog'
import { Button } from '@material-ui/core'
import * as Yup from 'yup'
import { mapData } from '../../../../product/common/components'

import BuynowContext from '../../../../product/context/buynow/buynowContext'
import CustomCommonContext from '../../../context/common/commonContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import AuctionContext from '../../../../product/context/auction/auctionContext'

const Buynow = (props) => {
    const { user, isAuthenticated } = useContext(authContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { addToCart, responseStatus, clearResponse } = useContext(BuynowContext)
    const { loginModal, setLoginModal } = customCommonContext
    const { setAlert } = useContext(AlertContext)
    const auctionContext = useContext(AuctionContext)
    const { updateAuctionTerms } = auctionContext

    const [product, setProduct] = useState()
    const [canBid, setCanBid] = useState(true)

    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)
    const [toggleTermsDialog, setToggleTermsDialog] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (product && user) {
            if (
                product.custom_auctionlot_auction_groups != '' &&
                product.custom_auctionlot_auction_groups != undefined &&
                product.custom_auctionlot_auction_groups != null
            ) {
                const auction_groups = product.custom_auctionlot_auction_groups.split(',')
                if (auction_groups.length > 0) {
                    if (user?.user_auction_groups?.length > 0) {
                        const temp = auction_groups.find((ele) =>
                            user?.user_auction_groups.includes(parseInt(ele)),
                        )
                        if (temp) {
                            setCanBid(true)
                        } else {
                            setCanBid(false)
                        }
                    } else {
                        setCanBid(false)
                    }
                }
            }
        }
    }, [product, user])

    const validationArray = Yup.object({
        product_id: Yup.number().required('Product id Required'),
        qty: Yup.number().required('Enter Quantity'),
    })

    const formik = useFormik({
        initialValues: { product_id: props.data.id, qty: 0, cart_group: props.data.auctionid },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (toggleDialog) {
                let cart = []
                cart.push(values)
                addToCart({ cart })
            } else {
                if (!product.user_auction_custom_data_terms && isAuthenticated) {
                    formikTerms.setFieldValue('auction_id', product.auctionid)
                    formikTerms.setFieldValue('id', product.id)
                    setToggleTermsDialog(true)
                } else {
                    setToggleDialog(true)
                }
            }
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                setToggleDialog(false)
                if (responseStatus.status === 'success') {
                    setTimeout(() => {
                        handleRedirectInternal(history, 'buynowcart')
                    }, 2000)
                }
            }
        }
        return () => {
            clearResponse()
        }
    }, [responseStatus])

    const changeTermsDialogStatus = () => {
        setToggleTermsDialog(!toggleTermsDialog)
    }

    const formikTerms = useFormik({
        initialValues: {
            auction_id: 0,
            id: 0,
        },
        onSubmit: (values) => {
            updateAuctionTerms({ ...values, terms: 1 })
            changeTermsDialogStatus()
            setToggleDialog(true)
        },
    })

    // const TermsBidding = {
    //     formik: formikTerms,
    //     data: [
    //         {
    //             label: (
    //                 <h6
    //                     dangerouslySetInnerHTML={{
    //                         __html: product?.custom_auctionlot_terms,
    //                     }}
    //                 ></h6>
    //             ),
    //             name: 'terms',
    //             type: 'checkbox',
    //             placeholder: '',
    //             class: 'col-12 auth-links-wrap',
    //         },
    //     ],
    // }

    // const buynowQuantity = [
    //     {
    //         label: 'enter  quantity',
    //         name: 'qty',
    //         type: 'number',
    //         placeholder: `${'max_quantity'} ${product ? product.qty - product.sold : 0}`,
    //         class: '',
    //         size: 'small',
    //         autoFocus: false,
    //         formik: formik,
    //     },
    // ]

    useEffect(() => {
        if (product) {
            formik.values.qty = product.qty - product.sold
        }
    }, [product])

    return (
        <>
            {product ? (
                <>
                    {(product.market_status === 'open' && product.wprice < product.bprice) ||
                    (!product.auction && product.buynow) ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt beforeLogin d-flex justify-content-between align-items-center">
                                {/* {props.editQuantity && Object.values(mapData(buynowQuantity))} */}
                                {isAuthenticated ? (
                                    <PrimaryButton
                                        label={'Buy Now'}
                                        type="submit"
                                        btnSize="small"
                                        disabled={canBid ? false : true}
                                        id="grid_buynow_button"
                                    />
                                ) : (
                                    <PrimaryButton
                                        label={'Login to Buy'}
                                        btnSize="small"
                                        onClick={() => setLoginModal(true)}
                                        id="grid_logintobuy_button"
                                    />
                                )}
                            </div>
                        </form>
                    ) : null}
                </>
            ) : null}
            <CustomDialog
                title={'Confirm Buy Now'}
                open={toggleDialog}
                function={changeDialogStatus}
            >
                <h5>{'You are about to buy this product'}</h5>
                <h5>{'please confirm if you want to continue with this action'}</h5>
                <div className="actionWrapper">
                    <Button onClick={() => setToggleDialog(false)}>{'Cancel'}</Button>
                    <PrimaryButton onClick={formik.handleSubmit} type="submit" label={'Submit'} />
                </div>
            </CustomDialog>
            <CustomDialog
                title="Confirm Terms And Conditions"
                open={toggleTermsDialog}
                className="ConfirmModal"
                disableBackdropClick
                function={changeTermsDialogStatus}
            >
                <>
                    <h5>Please confirm if you want to continue with this action</h5>
                    {/* <form>
                        <div className="row mb-4">{Object.values(mapData(TermsBidding))}</div>
                        <div className="actionWrapper">
                            <Button
                                onClick={() => {
                                    setToggleTermsDialog(false)
                                    // history.goBack()
                                }}
                            >
                                Decline
                            </Button>
                            <PrimaryButton
                                onClick={formikTerms.handleSubmit}
                                id="confirm_bid_submit"
                                type="button"
                                disabled={formikTerms.isSubmitting}
                                label={'Accept'}
                            />
                        </div>
                    </form> */}

                    <form onSubmit={formikTerms.handleSubmit}>
                        <div className="row mb-4">
                            <div className="col-12 auth-links-wrap">
                                <h6
                                    dangerouslySetInnerHTML={{
                                        __html: product?.terms || product?.custom_auctionlot_terms,
                                    }}
                                ></h6>
                            </div>
                        </div>

                        <div
                            className="actionWrapper"
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <div className="checkbox-wrapper">
                                <input type="checkbox" id="terms" name="terms" checked={1} />
                                <label htmlFor="terms">I agree to the Terms & Conditions</label>
                            </div>
                            <div className="auctionBtnWrapper">
                                <Button
                                    onClick={() => {
                                        setToggleTermsDialog(false)
                                    }}
                                >
                                    Decline
                                </Button>
                                <PrimaryButton
                                    type="submit"
                                    id="confirm_bid_submit"
                                    disabled={formikTerms.isSubmitting}
                                    label={'Accept'}
                                />
                            </div>
                        </div>
                    </form>
                </>
            </CustomDialog>
        </>
    )
}

export default Buynow
