import React, { useEffect, useContext } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Login from '../views/Login'
import Header from '../custom/components/molecules/Header'
import Footer from '../custom/components/molecules/Footer'
import AuthContext from '../product/context/auth/authContext'
import CustomCommonContext from '../custom/context/common/commonContext'
import ForgotPassword from '../views/ForgotPassword'
import ResendVerifyMail from '../views/ResendVerifyMail'
import ResetPassword from '../views/ResetPassword'
import VerifyEmail from '../views/VerifyEmail'
import Search from '../views/Search'
import MyAuctions from '../views/Dashboard/MyAuctions'
import Registration from '../views/Registration'
import Profile from '../views/Dashboard/Profile/index'
import ChangePassword from '../views/Dashboard/Profile/ChangePassword'

import MyInvoices from '../views/Dashboard/MyInvoices'
import MyTransactions from '../views/Dashboard/MyTransactions'
import Invoice from '../views/Invoice'
import ReturnInvoice from '../views/Invoice/return'
import SavedCard from '../views/Dashboard/SavedCard'
import LiveAuction from '../views/ProductView/LiveAuction'
import PastAuctionDetail from '../views/ProductView/PastAuctionDetail'
import ProductViewInvite from '../views/ProductView/ProductView_Invite'
import Home from '../views/Home'
import Cart from '../views/Cart'
import BuynowCart from '../views/BuyNowCart'
import Checkout from '../views/Checkout'
import PaymentSuccess from '../views/Checkout/PaymentSuccess'

import MyWallet from '../views/Dashboard/MyWallet'
import ErrorPage from '../views/ErrorPage'
import Auction from '../views/Auction'
import PastAuction from '../views/PastAuction'
import Notifications from '../views/Dashboard/Notifications'
import ContactUs from '../views/ContactUs'
import StaticPage from '../views/Static'
import SavedSearch from '../views/Dashboard/SavedSearch'
import MyAppointments from '../views/Dashboard/Appointment/MyAppointments'
import AppointmentManage from '../views/Dashboard/Appointment/AppointmentManage'
import RosoomResponse from '../views/Rosoom/Response'
import ProductCommonContext from '../product/context/common/commonContext'
import Faq from '../views/Static/Faq'
import ProductViewPage from '../views/ProductView/ProductViewPage'
import PastAuctionPage from '../views/ProductView/PastAuction'
const Routes = () => {
    const authContext = useContext(AuthContext)
    const { loadUser, callEnd } = authContext
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const { getGlobalVariable } = customCommonContext
    const { loaderSet, getAllCountries, getAllStates } = productCommonContext
    useEffect(() => {
        loaderSet(true)
        if (localStorage.token) {
            loadUser(localStorage.token)
        } else {
            loaderSet(false)
        }

        getGlobalVariable()
        getAllCountries()
        getAllStates()
    }, [])

    useEffect(() => {
        if (callEnd) loaderSet(false)
    }, [callEnd])

    return (
        <>
            <Header />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/verify_email/:token" component={VerifyEmail} />
                <Route exact path="/forgot_password" component={ForgotPassword} />
                <Route exact path="/resend_verify_mail" component={ResendVerifyMail} />
                <Route exact path="/reset_password/:token" component={ResetPassword} />
                <Route exact path="/registration" component={Registration} />
                <Route exact path="/search" component={Search} />
                <Route exact path="/liveAuction/:aid" component={LiveAuction} />
                {/* <Route exact path="/pastAuctions" component={PastAuctionDetail} /> */}
                <Route exact path="/pastAuction" component={PastAuctionPage} />
                <Route exact path="/auction" component={Auction} />
                {/* <Route exact path="/past-auctions" component={PastAuction} /> */}
                <Route exact path="/invite" component={ProductViewInvite} />
                <Route exact path="/productView/:pid" component={ProductViewPage} />
                <Route exact path="/contactus" component={ContactUs} />
                <Route exact path="/saved_search" component={SavedSearch} />
                <Route exact path="/appointment/schedule/:id" component={AppointmentManage} />
                <Route exact path="/appointment" component={MyAppointments} />
                <PrivateRoute exact path="/buynowcart" component={BuynowCart} />
                <PrivateRoute exact path="/checkout/:type/:cart_id" component={Checkout} />
                <Route exact path="/otpredirect" component={PaymentSuccess} />
                <PrivateRoute exact path="/cart" component={Cart} />
                <PrivateRoute exact path="/auctions/:type" component={MyAuctions} />
                <PrivateRoute exact path="/favorites/:type" component={MyAuctions} />
                <PrivateRoute exact path="/purchases/:type" component={MyAuctions} />
                <PrivateRoute exact path="/account/profile" component={Profile} />
                <PrivateRoute exact path="/account/change" component={ChangePassword} />
                <PrivateRoute exact path="/account/notifications" component={Notifications} />
                {/* <PrivateRoute exact path="/company_profile" component={CompanyProfile} /> */}
                <PrivateRoute exact path="/invoices/:action" component={MyInvoices} />
                <PrivateRoute exact path="/transactions" component={MyTransactions} />
                <PrivateRoute exact path="/invoice/:id" component={Invoice} />
                <PrivateRoute exact path="/invoice/returned/:id" component={ReturnInvoice} />

                <PrivateRoute exact path="/wallet" component={MyWallet} />
                <PrivateRoute exact path="/rosoom_success" component={RosoomResponse} />
                <Route exact path="/support" component={Faq} />
                <Route
                    exact
                    path={[
                        '/about',
                        '/services',
                        '/help',
                        '/guidelines',
                        '/privacy_policy',
                        '/terms_of_use',
                    ]}
                    component={StaticPage}
                />
                <Route exact path="*" component={ErrorPage} />
            </Switch>
            <Footer />
        </>
    )
}
export default withRouter(Routes)
