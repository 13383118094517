import React, { useContext } from 'react'
import '../Dashboard.css'
import DashboardLayout from '../DashboardLayout'
import AuthContext from '../../../product/context/auth/authContext'
import SingleProfile from './Profile'
import { ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

const MyAccount = () => {
    const authContext = useContext(AuthContext)

    const { user } = authContext

    return (
        <DashboardLayout title={'Profile'}>
            <div className="dashboardInner myAccount">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/profile">
                            {'Profile'}
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/change">
                            {'Change Password'}
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/notifications">
                            {'My Notifications'}
                        </NavLink>
                    </ListItem>
                </div>
                <div className="accountCnt">
                    <SingleProfile />
                </div>
            </div>
        </DashboardLayout>
    )
}

export default MyAccount
